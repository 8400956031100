import styled from "styled-components";

export const InputWrapper = styled.div`
  width: 100%;
  padding: 8px;
  box-sizing: border-box;

  .MuiFormControl-root {
    width: 100%;
  }

  .MuiInputBase-root {
    span {
      color: #2aa96d;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
      cursor: pointer;
    }
  }

  .MuiInputLabel-root {
    font-size: 14px;
    text-transform: capitalize;
  }

  .MuiFormLabel-root.Mui-focused {
    color: #2aa96d;
  }

  .MuiFilledInput-root:hover {
    border-color: #2aa96d !important;
  }

  .MuiFilledInput-underline:before,
  .MuiFilledInput-underline:after {
    display: none;
  }

  .MuiFilledInput-input {
    font-size: 14px;
    line-height: 20px;
    text-transform: none;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    padding: 28px 8px 8px;
    height: 12px;
  }
  .MuiFilledInput-root,
  .MuiFilledInput-root.Mui-focused,
  .MuiFilledInput-root:hover {
    background-color: transparent;
    border: 1px solid #cbd4da;
    border-radius: 3px;
    color: #637381;
  }

  .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel) {
    margin: 0;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  input:-webkit-autofill {
    -webkit-text-fill-color: #637381 !important;
    padding-left: 10px;
  }
`;
