import styled from "styled-components";


export const TenderTitle = styled.h3`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-bottom: 24px;
  color: #1e2b37;
  font-size: 24px;
  font-weight: normal;
`;

export const ContractDetailTabText = styled.p`
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  padding: 20px 20px 10px 20px;
  color: #5f7483;
`;