import styled from "styled-components";
import { Link } from "react-router-dom";
import { Box } from "@material-ui/core";
import LoadingButton from "../../../../../components/LoadingButton/LoadingButton";

export const ContentWrapper = styled.div`
  padding: 15px 0 80px 0;

  .MuiTableHead-root {
    background-color: white;
    padding: 20px 0 !important;
  }

  .MuiTableCell-head {
    font-weight: bold;
    font-size: 16px;
  }
`;

export const PageTitle = styled.h1`
  color: #1e2b37;
  font-size: 28px;
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: 0;
  font-weight: normal;
`;

export const BreadCrumbWrapper = styled.div`
  margin-bottom: 40px;

  a {
    height: 16px;
    width: 197px;
    color: #00b0f1;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    text-decoration: none;
  }

  a:visited {
    color: #00b0f1;
  }
`;

export const CardWrapper = styled.div`
  border: 1px solid #c9d4db;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 #c9d4db;
  overflow: hidden;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  margin-right: 40px;
  margin-bottom: 40px;
  background-color: ${(props) => (props.gray ? "#FCFCFD" : "#FFFFFF")};
`;

export const CardTop = styled.div`
  background-color: #e4e9ec;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 10px 20px;
`;

export const CardBody = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 15px;
`;

export const CardSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CardLabel = styled.p`
  color: #5f7483;
  font-size: 13px;
  padding-bottom: 5px;
  letter-spacing: 0;
  line-height: 16px;
`;

export const CardContent = styled.p`
  color: #5f7483;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
`;

export const BoldCardContent = styled(CardContent)`
  color: #212b36 !important;
`;

export const StatusCircle = styled.span`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #e5e9ec;
  display: inline-flex;
  margin-right: ${(props) => `${props.marginRight}px` || 0};
`;

export const StatusCircleSuccess = styled(StatusCircle)`
  background-color: #3bd278;
`;

export const StatusCirclePending = styled(StatusCircle)`
  background-color: #f49342;
`;

export const TableWrapper = styled.div`
  .cell-no-padding {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const TableTop = styled.div`
  height: 44px;
  display: flex;
  align-items: center;
  border: 1px solid #c9d4db;
  background-color: #e4e9ec;
  padding-left: 20px;
`;

export const TableHeader = styled.h1`
  color: #5f7483;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 0;
`;

export const CommentsWrapper = styled.div`
  border: 1px solid #c9d4db;
  border-radius: 3px;
  background-color: rgba(228, 233, 236, 0.17);
  margin: 30px 20px 40px;
`;

export const CommentsTop = styled.div`
  background-color: #e4e9ec;
  display: flex;
  align-items: center;
  padding-left: 20px;
  height: 46px;
`;

export const CommentsHeader = styled.div`
  color: #424f5c;
  font-size: 16px;
  font-weight: bold;
`;

export const CommentsBody = styled.div`
  padding: 22px 20px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e4e9ec;
  margin: 15px 0;
`;

export const SerialText = styled.span`
  color: #1e2b37;
  font-size: 14px;
  padding-bottom: 5px;
  padding-left: 20px;
  letter-spacing: 0;
  line-height: 16px;
  font-weight: 600;
`;

export const TopSection = styled(Box)`
  margin-bottom: 20px;
`;

export const UnstyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

export const SectionWrapper = styled.div`
  background: #fcfcfd;
  border: 1px solid #c9d4db;
  padding: 20px;
`;

export const SectionTitle = styled.h3`
  font-size: 17px;
  line-height: 24px;
  color: #1e2b37;
  padding: 20px;
`;

export const ActionButton = styled(LoadingButton)`
  display: flex;
  align-items: center;
  border: ${(props) => (props.disabled ? "1px solid #c9d4db " : "1px solid #3BD278 ")};
  color: #fff !important;
  margin-left: 0px !important;
  background-color: ${(props) => (props.disabled ? "#c9d4db !important " : "#219653 !important")};
`;

export const TitleContainer = styled.div`
  font-size: 16px;
  padding-bottom: 15px;
  color: #1e2b37;
  font-weight: 600;
`;

export const BorderedBox = styled.div`
  padding: 10px 20px 20px;
  margin: 0px -20px 30px;
  border-top: 1px solid #c9d4db;
  border-bottom: 1px solid #c9d4db;
`;

export const CheckBoxText = styled.div`
  color: #212b36;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  display: flex;
  align-items: center;
  margin-top: 15px;
`;

export const SubTitle = styled(CardContent)`
  margin-top: 8px;
  margin-bottom: 30px;
`;

export const GridContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  align-items: center;
  height: 60px;
  background-color: #F2994A;
  color: #FFFFFF;
  font-size: 16px;
  margin-bottom: 0;
  
  .bold{
    font-weight: bold;
    display: inline-flex;
    margin: 0 4px;
  }
`;

export const SubmissionResponseWrapper = styled(CardWrapper)`  
 padding: 20px 0 0 0;
 text-align: center;
`;

export const ResponseTitle = styled.p`
  color: #27AE60;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 24px;  
  font-weight: normal;
`;

export const ResponseText= styled.p`
  color: #4F4F4F;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;  
  font-weight: normal;
  margin: 16px 0px 8px;
`;

export const ResponseContainer = styled.div`  
  padding: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DeadlineRow = styled.div`
display: flex;
gap: 20px;
align-items: center;
`

export const SubmitButton = styled.button`
  color: #ffffff;
  width: 212px;
  height: 41px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  background-color: #03adeb;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  margin-right: 10px;

  &:disabled,
  &[disabled]{
  background-color: #c9d4db;
  cursor: not-allowed;
}
`;

