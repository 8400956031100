export const archiveProcurementMethod = [
  "International Competitive Bidding",
  "National Competitive Bidding",
  "Selective Tendering Method",
  "Request For Quotations",
  "Direct Procurement Method",
  "Emergency Procurement Method",
];

export const archiveProcurementMethodOptions = [
  {
    label: "International Competitive Bidding",
    value: "0",
  },
  {
    label: "National Competitive Bidding",
    value: "1",
  },
  {
    label: "Selective Tendering Method",
    value: "2",
  },
  {
    label: "Request For Quotations",
    value: "3",
  },
  {
    label: "Direct Procurement Method",
    value: "4",
  },
  {
    label: "Emergency Procurement Method",
    value: "5",
  },
];

export const archiveTenderStatus = ["Active", "Completed"];
export const archiveTenderStatusOptions = [
  {
    label: "Active",
    value: "0",
  },
  {
    label: "Completed",
    value: "1",
  },
];
export const archiveTenderingStage = ["Single Stage Tendering", "Two Stage Tendering"];

export const archiveTenderingStageOptions = [
  {
    label: "Single Stage Tendering",
    value: "1",
  },
  {
    label: "Two Stage Tendering",
    value: "2",
  },
];

export const archiveReviewMethod = ["Prior Review", "Post Review"];

export const archiveReviewMethodOptions = [
  {
    label: "Prior Review",
    value: "0",
  },
  {
    label: "Post Review",
    value: "1",
  },
];

export const archiveContractStatus = ["ACCEPTED", "REJECTED", "ACTIVE", "COMPLETED", "TERMINATED"];

export const archiveContractStatusOptions = [
  {
    label: "ACCEPTED",
    value: "0",
  },
  {
    label: "REJECTED",
    value: "1",
  },
  {
    label: "ACTIVE",
    value: "2",
  },
  {
    label: "COMPLETED",
    value: "3",
  },
  {
    label: "TERMINATED",
    value: "4",
  },
];

export const archiveContractDuration = ["Days", "Weeks", "Months"];
export const archiveContractDurationOptions = [
  {
    label: "Days",
    value: "0",
  },
  {
    label: "Weeks",
    value: "1",
  },
  {
    label: "Months",
    value: "2",
  },
];

export const archiveProjectStatus = ["in-progress", "completed", "terminated"];

export const archiveProjectStatusOptions = [
  {
    label: "In Progress",
    value: "0",
  },
  {
    label: "Completed",
    value: "1",
  },
  {
    label: "Terminated",
    value: "2",
  },
];

export const archiveCategory = ["Works", "Goods", "Consultation", "Non-Consultation"];

export const archiveCategoryOptions = [
  {
    label: "Works",
    value: "0",
  },
  {
    label: "Goods",
    value: "1",
  },
  {
    label: "Consultation",
    value: "2",
  },
  {
    label: "Non-Consultation",
    value: "3",
  },
];

export const archiveCategoryFunc = function (key = "") {
  if (typeof key === "string") {
    switch (key) {
      case "works": {
        return "0";
      }
      case "goods": {
        return "1";
      }
      case "consultation": {
        return "2";
      }
      case "non consultation": {
        return "3";
      }

      default:
        return "0";
    }
  }
  return "0";
};

export const archiveProjectStatusFunc = function (key = "") {
  if (typeof key === "string") {
    switch (key) {
      case "in progress": {
        return "0";
      }
      case "completed": {
        return "1";
      }
      case "terminated": {
        return "2";
      }

      default:
        return "0";
    }
  }
  return "0";
};

export const archiveDurationFunc = function (key = "") {
  if (typeof key === "string") {
    switch (key) {
      case "days": {
        return "0";
      }
      case "weeks": {
        return "1";
      }
      case "months": {
        return "2";
      }

      default:
        return "0";
    }
  }
  return "0";
};

export const archiveProcurementMethodFunc = function (key = "") {
  if (typeof key === "string") {
    switch (key) {
      case "international competitive bidding": {
        return "0";
      }
      case "national competitive bidding": {
        return "1";
      }
      case "selective tendering method": {
        return "2";
      }
      case "request for quotations": {
        return "3";
      }
      case "direct procurement method": {
        return "4";
      }
      case "emergency procurement method": {
        return "5";
      }

      default: {
        return "0";
      }
    }
  }
  return "0";
};

export const archiveContractStatusFunc = function (key = "") {
  if (typeof key === "string") {
    switch (key) {
      case "ACCEPTED": {
        return "0";
      }
      case "REJECTED": {
        return "1";
      }
      case "ACTIVE": {
        return "2";
      }
      case "COMPLETED": {
        return "3";
      }
      case "TERMINATED": {
        return "4";
      }

      default: {
        return "0";
      }
    }
  }
  return "0";
};

export const archiveTenderStatusFunc = function (key = "") {
  if (typeof key === "string") {
    switch (key) {
      case "active": {
        return "0";
      }
      case "completed": {
        return "1";
      }

      default:
        return "0";
    }
  }

  return "0";
};

export const archiveTenderingStageFunc = function (key = "") {
  if (typeof key === "string") {
    switch (key) {
      case "single stage tendering": {
        return "1";
      }
      case "two stage tendering": {
        return "2";
      }

      default:
        return "1";
    }
  }

  return "1";
};

export const archiveReviewMethodFunc = function (key = "") {
  if (typeof key === "string") {
    switch (key) {
      case "prior review": {
        return "0";
      }
      case "post review": {
        return "1";
      }

      default:
        return "0";
    }
  }

  return "0";
};
