import Box from "@material-ui/core/Box";
import Pagination from "@material-ui/lab/Pagination";
import Skeleton from "@material-ui/lab/Skeleton";
import React, { useContext, useEffect, useState } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import { useQuery, useMutation, queryCache } from "react-query";
import { useHistory } from "react-router-dom";
import AccessControl from "../../../../components/AccessControl";
import { Roles } from "../../../../components/AccessControl/UserRoles";
import AdminSearchFilter from "../../../../components/AdminSearchFilter";
import YearPicker from "../../../../components/ControlledYearPicker";
import Dropdown from "../../../../components/DropdownField";
import InputField from "../../../../components/InputField";
import MinistryDropdown from "../../../../components/MinistryDropdown";
import SearchTable from "../../../../components/SearchTable";
import TopStatisticsBar from "../../../../components/TopStatisticsBar";
import { useRoles } from "../../../../hooks/useUserDetails";
import generalPlansAPI from "../../../../redux/api/generalPlansAPI";
import procurementAPI from "../../../../redux/api/procurementAPI";
import { formatCurrency } from "../../../../util/formatCurrency";
import ToastContext from "../../../../util/toastContext";
import Breadcrumb from "../../../Common/PageElements/Breadcrumb";
import { PageTitle } from "../../../Common/style";
import withAdminDashboard from "../../HOC/withAdminDashboard";
import { ResponseStatsLabel, ResponseStatsValue } from "../Activitiies/ReceiptOfResponse/style";
import { AddButton, CirclePlusIconWrapper, ContentWrapper, TitleContainer } from "../style";
import { CardsGrid, PlanCard, PlanChip, PlanPrice, PlanTenderCount, PlanTitle } from "./styles";
import TableBody from "./TableBody";
import { IconButton, ClickAwayListener } from "@material-ui/core";
import { CuteModal, ModalWrapper, ModalHeader, MainText, ModalRejectBtn, ModalCloseBtn } from "../../../Vendor/Procurement/style";

// const withIdRoles = [Roles.PROCUREMENT_OFFICER, Roles.PERMANENT_SECRETARY, Roles.COMMISSIONER];

const generateYears = () => {
  let years = [{ label: "All", value: null }];

  const year = new Date().getFullYear();

  //Generate years between current year + 10 to current year - 10
  Array.from({ length: 10 }).forEach((_, idx) => {
    years = years.concat({
      label: year + 10 - idx,
    });
  });

  return years;
};

const GeneralPlans = () => {
  const history = useHistory();
  const GeneralPlanLinks = [{ url: "/admin/dashboard/overview", title: "Home" }];
  const { user, role } = useRoles();
  const isDSPPC = Roles.DSPPC === role;

  const [filters, setFilters] = useState({
    Year: null,
    Search: "",
    Status: null,
    MinistryId: null,
  });
  const [selectedProcurementToDelete, setSelectedProcurementToDelete] = useState(null);

  const updateFilters = (key) => (value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  const generalPlansQueryKey = [
    "generalPlans",
    {
      pageSize: 9,
      pageNumber: pagination.pageNumber,
      Year: filters.Year,
      Search: filters.Search,
      Status: filters.Status,
      // MinistryId: withIdRoles.includes(role) ? user?.ministry?.id : filters.MinistryId,
      MinistryId: user?.ministry?.id
    },
  ];

  const { data: generalPlans = {}, isLoading, error = {}, isError } = useQuery({
    queryFn: procurementAPI.getGeneralPlans,
    queryKey: generalPlansQueryKey,
  });

  const [deletePlan, deletePlanQueryResponse] = useMutation(procurementAPI.deleteGeneralPlans, {
    onSuccess: () => {
      showAlert({
        severity: "success",
        message: "Plan was deleted successfully",
      });
      queryCache.removeQueries(generalPlansQueryKey);
      setSelectedProcurementToDelete(null);
    },
    onError: (error) => {
      showAlert({
        severity: "error",
        message: error.message,
      });
    },
  });

  const { data: annualProcurementPlanSummary = {}, isPlanLoading } = useQuery({
    queryKey: ["annualProcurementPlansSummary"],
    queryFn: generalPlansAPI.getAnnualProcurementPlansSummary,
  });

  const updatePagination = (key) => (value) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      [key]: value,
    }));
  };

  const onClickPlan = (plan) => {
    history.push(`/admin/procurement/plans/${plan.id}`);
  };

  const { showAlert } = useContext(ToastContext);

  useEffect(() => {
    if (isError) {
      showAlert({
        severity: "error",
        message: error?.message,
      });
    }
    //eslint-disable-next-line
  }, [showAlert]);

  const handleShowDeleteProcurementModal = (event, plan) => {
    event.stopPropagation();
    setSelectedProcurementToDelete(plan);
  };

  const handleDeleteProcurement = (event, plan) => {
    event.preventDefault();
    deletePlan(plan);
  };

  const renderLeftContent = () => (
    <>
      <ResponseStatsLabel color="#5F7483">Total Plans</ResponseStatsLabel>
      <ResponseStatsValue>{annualProcurementPlanSummary?.total || 0}</ResponseStatsValue>
    </>
  );

  const renderCenterContent = () =>
    !isPlanLoading && (
      <>
        <Box mr="auto">
          <ResponseStatsLabel color="#03ADEB">In Progress</ResponseStatsLabel>
          <ResponseStatsValue>{annualProcurementPlanSummary?.inProgress || 0}</ResponseStatsValue>
        </Box>
        <Box mr="auto">
          <ResponseStatsLabel color="#FF8D26">Pending Review</ResponseStatsLabel>
          <ResponseStatsValue>{annualProcurementPlanSummary?.pendingReview || 0}</ResponseStatsValue>
        </Box>
        <Box mr="auto">
          <ResponseStatsLabel color="#50B83C">Completed</ResponseStatsLabel>
          <ResponseStatsValue>{annualProcurementPlanSummary?.completed || 0}</ResponseStatsValue>
        </Box>
      </>
    );

  return (
    <ContentWrapper>
      <TitleContainer>
        <div>
          <PageTitle>Annual Procurement Plan</PageTitle>
          <Breadcrumb values={GeneralPlanLinks} />
        </div>
        <AccessControl allowedRoles={[Roles.DSPPC, Roles.TENDER_BOARD, Roles.STATE_MINISTERIAL_TENDER_BOARD]}>
          <YearPicker handleChange={(year) => setFilters({ ...filters, Year: new Date(year).getFullYear() })} />
        </AccessControl>
        <AccessControl allowedRoles={[Roles.PROCUREMENT_OFFICER]}>
          <AddButton onClick={() => history.push("/admin/procurement/plans/notice/new")}>
            <CirclePlusIconWrapper /> Add New
          </AddButton>
        </AccessControl>
      </TitleContainer>

      <AccessControl allowedRoles={[Roles.DSPPC, Roles.MINISTRY_OF_JUSTICE, Roles.TENDER_BOARD, Roles.STATE_MINISTERIAL_TENDER_BOARD]}>
        <TopStatisticsBar
          style={{ width: "603px" }}
          hasApproveStatus={false}
          leftContent={renderLeftContent()}
          centerContent={renderCenterContent()}
        />
      </AccessControl>
      <AdminSearchFilter normalSearch>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <InputField type="text" label="Search name" value={filters.Search} setValue={updateFilters("Search")} />

          <AccessControl allowedRoles={[Roles.DSPPC, Roles.MINISTRY_OF_JUSTICE]}>
            <MinistryDropdown onChange={updateFilters("ministryId")} useReactQuery />
          </AccessControl>

          <Dropdown
            name="status"
            label="Status"
            values={[
              { label: "All", value: null },
              { label: "Completed", value: "Completed" },
              { label: "In Progress", value: "InProgress" },
              { label: "Under Review", value: "Under Review" },
              { label: "Draft", value: "Draft" },
            ]}
            onChange={updateFilters("Status")}
          />
          <AccessControl allowedRoles={[Roles.PROCUREMENT_OFFICER]}>
            <Dropdown name="year" label="Year" values={generateYears()} onChange={updateFilters("Year")} />
          </AccessControl>
        </div>
      </AdminSearchFilter>
      <AccessControl
        allowedRoles={[
          Roles.MODERATOR,
          Roles.BUREAU_OF_PUBLIC_PROCUREMENT,
          Roles.ACCOUNTANT,
          Roles.PROCUREMENT_OFFICER,
          Roles.PERMANENT_SECRETARY,
        ]}
      >
        <>
          <CardsGrid>
            {isLoading
              ? Array.from({ length: 4 }).map((_, idx) => <Skeleton variant="rect" width={"100%"} height={210} />)
              : generalPlans.data?.map((plan) => (
                  <PlanCard onClick={() => onClickPlan(plan)}>
                    {plan.status === "Draft" && (
                      <div className="delete-icon">
                        <IconButton
                          aria-label="Delete procurement"
                          onClick={(event) => handleShowDeleteProcurementModal(event, plan)}
                        >
                          <DeleteIcon
                            style={{
                              color: "#ccc",
                              cursor: "pointer",
                            }}
                          />
                        </IconButton>
                      </div>
                    )}
                    <PlanTitle>{plan.name}</PlanTitle>
                    <PlanChip size="small" type={plan.status} label={plan.status} color="primary" />
                    <PlanTenderCount>{plan.tenders} Tenders</PlanTenderCount>
                    <PlanPrice>N{formatCurrency(plan.amount)}</PlanPrice>
                  </PlanCard>
                ))}
          </CardsGrid>
          {generalPlans.data?.length === 0 && (
            <Box py={3} textAlign="center">
              <h2>No Plans Found</h2>
            </Box>
          )}
        </>
        {Boolean(generalPlans.pagination?.totalPages) && (
          <Box display={"flex"} justifyContent={"flex-end"}>
            <Pagination
              count={generalPlans.pagination?.totalPages}
              page={pagination.pageNumber}
              onChange={(event, value) => updatePagination("pageNumber")(value)}
            />
          </Box>
        )}
      </AccessControl>

      <AccessControl
        allowedRoles={[
          Roles.COMMISSIONER,
          Roles.EXECUTIVE,
          Roles.SSG,
          Roles.GOVERNOR,
          Roles.DSPPC,
          Roles.MINISTRY_OF_JUSTICE,
          Roles.TENDER_BOARD, 
          Roles.STATE_MINISTERIAL_TENDER_BOARD
        ]}
      >
        <SearchTable
          rows={generalPlans}
          columns={["procurement title", "ministry", "status", "tenders", "budget", isDSPPC && " "]}
          page={pagination.pageNumber}
          setPage={updatePagination("pageNumber")}
          rowsPerPage={pagination.pageSize}
          setRowsPerPage={updatePagination("pageSize")}
          loading={isLoading}
          totalNumberOfPages={generalPlans?.pagination?.totalEntries}
        >
          <TableBody generalPlans={generalPlans.data} deletePlan={handleShowDeleteProcurementModal} showDelete={isDSPPC}/>
        </SearchTable>
      </AccessControl>

      <CuteModal open={Boolean(selectedProcurementToDelete)}>
        <ClickAwayListener>
          <ModalWrapper>
            <ModalHeader>Delete "{selectedProcurementToDelete?.name}" plan</ModalHeader>
            <MainText mt="30px">Proceed to delete this procurement plan. You cannot undo this action</MainText>
            <MainText mt="30px"></MainText>
            <Box display="flex" justifyContent="flex-start">
              <ModalRejectBtn
                disabled={deletePlanQueryResponse.isLoading}
                onClick={(event) => handleDeleteProcurement(event, selectedProcurementToDelete?.id)}
              >
                {deletePlanQueryResponse.isLoading ? "Please wait..." : "Delete"}
              </ModalRejectBtn>
              <ModalCloseBtn onClick={(event) => handleShowDeleteProcurementModal(event, null)}>Close</ModalCloseBtn>
            </Box>
          </ModalWrapper>
        </ClickAwayListener>
      </CuteModal>
    </ContentWrapper>
  );
};

export default withAdminDashboard(GeneralPlans);
