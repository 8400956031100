import styled from "styled-components";
import { Chip } from "@material-ui/core";
import LoadingButton from "../../../../components/LoadingButton/LoadingButton";
import { theme } from "../../../../util/theme";

export const CardsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 22px;
  margin: 40px 0;
`;

export const PlanCard = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  background: #ffffff;
  box-shadow: 0 2px 4px #c9d4db;
  border-radius: 3px;
  cursor: pointer;

  .delete-icon {
    position: absolute;
    display: inline-block;
    top: 5px;
    right: 5px;
  }
`;

export const PlanID = styled.span`
  display: inline-flex;
  font-size: 14px;
  text-align: center;
  color: #00b0f1;
  margin-bottom: 5px;
`;

export const PlanTitle = styled.span`
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #1c2125;
  display: inline-flex;
  margin-bottom: 10px;
`;

const chipColors = {
  "In Progress": {
    bgColor: "#FCEBDB",
    color: "#FF8D26",
  },
  "Under Review": {
    bgColor: "#FCEBDB",
    color: "#FF8D26",
  },
  Draft: {
    bgColor: "#414F3E",
    color: "#C9D4DB",
  },
  Completed: {
    bgColor: "#BBE5B3",
    color: "#414F3E",
  },
};

export const PlanChip = styled(Chip)`
  background-color: ${(props) => chipColors[props?.type]?.bgColor} !important;
  color: ${(props) => chipColors[props?.type]?.color} !important;
`;

export const PlanTenderCount = styled.span`
  display: inline-flex;
  font-size: 14px;
  text-align: center;
  color: #00b0f1;
  margin: 10px 0 15px;
`;

export const PlanPrice = styled.span`
  font-size: 14px;
  color: #1c2125;
  display: inline-flex;
`;


export const TextButton = styled(LoadingButton)({
  backgroundColor: "inherit",
  border: "none",
  boxShadow: "none",
  marginRight: "12px",
  height: "41px",
  "& .MuiButton-label": {
    color: theme.color.primary
  },
  "&:hover": {
    background: theme.color.primaryLight,
    color: "#fff"
  },
  "&.MuiButtonBase-root": {
    boxShadow: "none",
    border: "none",
    "&:hover": {
      color: "#fff",
      border: "1px solid blue"
    }
  }
})

export const StyledButtonContainer = styled.div({
  display: "grid",
  gridTemplateColumns: "auto auto",
  gap: "20px"
})
