import styled from "styled-components";

export const StyledFirstCell = styled("div")({
  display: "grid",
  gridTemplateRows: "auto",
  gap: 0,
  "& .top-cell": {
    textTransform: "uppercase",
    color: "#0050C8",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "133%",
  },
  "& .lower-cell": {
    fontWeight: 400,
    fontSize: "14px",
    color: "#1C2125",
    textTransform: "capitalize",
  },
});
