import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { InputField } from "../../../../../shared";
import { lowerCase } from "lodash";
import { CancelButton, UpdateButton } from "../../../Administration/Settings/style";
import { ErrorContainer } from "../../../../../util/theme";
import NumberFormat from "react-number-format";
import { Dropdown } from "../../../../../components";
import { useParams } from "react-router-dom";
import { contractImplementationDefaultValues } from "./validator";
import { archiveProjectStatusFunc, archiveProjectStatusOptions } from "../utils/useArchiveHokk";

const EditEvaluatorsTab = ({ data, mutate, isLoading, handleEdit }) => {
  const { register, watch, errors, handleSubmit, reset, control } = useForm();
  const params = useParams();

  // const procurementPlanOption = ["In progress", "Completed", "Terminated"].map((item) => ({
  //   label: item,
  //   value: item,
  // }));

  useEffect(() => {
    let payload = {};
    if (data) {
      const fields = Object.keys(contractImplementationDefaultValues);
      fields.forEach((field) => {
        if (field === "revisedContractAmount") {
          payload[field] = data[field];
        } else if (field === "percentageCompletion") {
          payload[field] = data?.percentageCompletion;
        } else if (field === "projectStatus") {
          payload[field] = archiveProjectStatusFunc(lowerCase(data?.[field] || ""));
        } else {
          payload[field] = data?.[field] || contractImplementationDefaultValues[field];
        }
      });
      payload = {
        ...payload,
      };

      reset(payload);
    }
  }, [data, reset]);

  const onSubmit = (e) => {
    const payload = {};
    const fields = Object.keys(contractImplementationDefaultValues);
    fields.forEach((field) => {
      if (field === "projectStatus") {
        payload[field] = parseInt(e?.[field] || 0, 10) || contractImplementationDefaultValues[field];
      } else {
        payload[field] = e?.[field] || contractImplementationDefaultValues[field];
      }
    });

    ["contractSum", "contractVariation"].forEach((v) => {
      delete payload[v];
    });
    // data.archiveTender = data.tender;
    // e.id = params.id;
    // delete data.tender;
    mutate({ payload, archiveId: params.id, type: "implementation" });
  };
  const onRenderError = (error) => <ErrorContainer>{error && error.message}</ErrorContainer>;
  return (
    <div>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px" }}>
        <div>
          <Controller
            control={control}
            name="contractSum"
            render={(props) => (
              <NumberFormat
                decimalScale={2}
                customInput={InputField}
                label="Contract Sum"
                thousandSeparator
                prefix="₦"
                variant="outlined"
                disabled
                {...props}
              />
            )}
          />{" "}
        </div>
        <div>
          <Controller
            control={control}
            name="contractVariation"
            render={({ onChange, ...props }) => (
              <NumberFormat
                disabled
                customInput={InputField}
                label="Contract Variation"
                thousandSeparator
                decimalScale={2}
                prefix="₦"
                variant="outlined"
                {...props}
                onValueChange={(values) => {
                  const { value } = values;
                  onChange(value);
                }}
                value={+Number(watch("revisedContractAmount") - watch("contractSum")).toFixed(2)}
              />
            )}
          />{" "}
        </div>
      </div>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px" }}>
        <div>
          <Controller
            control={control}
            name="revisedContractAmount"
            render={({ onChange, ...props }) => (
              <NumberFormat
                customInput={InputField}
                label="Revised Contract Amount"
                thousandSeparator
                prefix="₦"
                decimalScale={2}
                variant="outlined"
                {...props}
                onValueChange={(values) => {
                  const { value } = values;
                  onChange(value);
                }}
              />
            )}
          />
        </div>
        <div>
          <div>
            <Controller
              control={control}
              name="percentageCompletion"
              render={({ onChange, ...props }) => (
                <NumberFormat
                  isAllowed={(values) => {
                    const { value } = values;
                    return value < 101;
                  }}
                  customInput={InputField}
                  label="Percentage Completion"
                  thousandSeparator
                  suffix="%"
                  variant="outlined"
                  {...props}
                  onValueChange={(values) => {
                    const { value } = values;
                    onChange(value);
                  }}
                />
              )}
            />{" "}
            {onRenderError(errors.completion)}
          </div>
        </div>
      </div>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        <div>
          <Controller
            control={control}
            name="projectStatus"
            render={({ value, ...props }) => (
              <Dropdown
                inputRef={register({
                  required: "Enter Project Category",
                })}
                style={{ width: "inherit" }}
                label="Project Status"
                values={archiveProjectStatusOptions}
                {...props}
              />
            )}
          />{" "}
        </div>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "150px 1fr",
          placeItems: "center start",
          marginTop: "30px",
          padding: "7px",
        }}
      >
        <UpdateButton onClick={handleSubmit(onSubmit)}>{isLoading ? "Saving Changes..." : "Save Changes"}</UpdateButton>
        <CancelButton onClick={handleEdit}>Cancel</CancelButton>
      </div>
    </div>
  );
};

EditEvaluatorsTab.propTypes = {};

export default EditEvaluatorsTab;
