import React, { useState } from "react";
import Chip from "@material-ui/core/Chip";
import Menu from "@material-ui/core/Menu";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import EmptyTableBody from "../../../../../components/EmptyTableBody";
import { EmptyTableSubTitle } from "../../../../../components/EmptyTableBody/style";
import { ProfilePicWrapper } from "../../../../../components/AdminDashboardNav/style";
import { StyledTableCell, StyledTableRow } from "../../../../../components/SearchTable";
import { theme } from "../../../../../util/theme";
import { AddButton, CirclePlusIconWrapper } from "../../../../Common/style";
import { useMutation, queryCache } from "react-query";
import adminAPI from "../../../../../redux/api/adminAPI";
import { Roles } from "../../../../../components/AccessControl/UserRoles";
import AccessControl from "../../../../../components/AccessControl";
import ProfileDrawer from "../../../../../components/ProfileDrawer";
import { Divider } from "@material-ui/core";
import { RoleObjMapped } from "../../../../../util/constants";

const StaffTable = ({ toggleDrawer, showAlert, staff = [], filters, pagination, isRowSelected, onSelectRow }) => {
  const allowedRoles = [Roles.DSPPC, Roles.MODERATOR];
  const determineStatus = (status) => (status === "ENABLED" ? "Disable" : "Enable");
  const determineStatusCode = (status) => (status === "ENABLED" ? 1 : 2);

  const [anchorEl, setAnchorEl] = useState(null);
  const [activeRow, setActiveRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [staffInfo, setStaffInfo] = useState(null);
  const [updateStaffStatus] = useMutation(adminAPI.updateStaffStatus, {
    onSuccess({ data }) {
      showAlert({ severity: "success", message: "Status successfully changed" });
      return queryCache.setQueryData(
        [
          "staffs",
          {
            pageSize: pagination.pageSize,
            pageNumber: pagination.pageNumber,
            name: filters.name,
            status: filters.status,
            ministryId: filters.ministryId,
          },
        ],
        (oldStaffs) => ({
          ...oldStaffs,
          data: oldStaffs.data.map((el) => (el.id === data.id ? { ...el, status: data.status } : el)),
        })
      );
    },
  });

  const handleMenu = (event, i) => {
    setAnchorEl(event.currentTarget);
    setActiveRow(i);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setActiveRow(null);
  };

  const updateStatus = async (ministryId, staffId, action, role) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("ministryId", ministryId);
    formData.append("staffId", staffId);
    formData.append("status", action);
    formData.append("role", RoleObjMapped[role]);

    await updateStaffStatus(formData);
    setAnchorEl(null);
    setActiveRow(null);
    setLoading(false);
  };

  return (
    <>
      {staff.length ? (
        staff.map((row, index) => (
          <StyledTableRow
            onClick={(e) => {
              e.stopPropagation();
            }}
            key={`${row.email}_${index}`}
          >
            <StyledTableCell padding="checkbox">
              <Checkbox
                checked={isRowSelected(row)}
                onChange={(e) => {
                  e.stopPropagation();
                  onSelectRow(row);
                }}
                inputProps={{ "aria-label": `Select ${row.firstName} ${row.lastName}` }}
              />
            </StyledTableCell>
            <StyledTableCell component="th" scope="row">
              <ProfilePicWrapper>
                {row.profilePicture ? (
                  <img
                    src={row.profilePicture?.url}
                    alt={`${row.firstName[0]} ${row.lastName[0]}`}
                    loading="lazy"
                    style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                  />
                ) : (
                  row.firstName[0] + row.lastName[0]
                )}
              </ProfilePicWrapper>
              <div>
                <p>
                  {row.firstName} {row.lastName}
                </p>
                <span>{row.email}</span>
              </div>
            </StyledTableCell>
            <StyledTableCell>{row.ministry?.name}</StyledTableCell>
            <StyledTableCell>{row.role}</StyledTableCell>
            <StyledTableCell>
              <Chip
                label={row.status[0].toUpperCase() + row.status.slice(1).toLowerCase()}
                style={{
                  background: row.status.toLowerCase() === "enabled" ? "#BBE5B3" : theme.color.warning,
                  height: "24px",
                }}
              />
            </StyledTableCell>
            <StyledTableCell>
              {loading && index === activeRow ? (
                "Updating..."
              ) : (
                <>
                  <div style={{ cursor: "pointer" }} onClick={(e) => handleMenu(e, index)}>
                    <MoreHorizIcon />
                  </div>
                  <AccessControl allowedRoles={allowedRoles}>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={activeRow === index}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={() => updateStatus(row.ministryId, row.id, determineStatusCode(row.status), row.role)}>
                        {determineStatus(row.status)}
                      </MenuItem>
                      <Divider />
                      <MenuItem onClick={() => setStaffInfo(row)}>Staff Details</MenuItem>
                    </Menu>
                  </AccessControl>
                </>
              )}
            </StyledTableCell>
          </StyledTableRow>
        ))
      ) : (
        <EmptyTableBody>
          <EmptyTableSubTitle>Get started adding your staff</EmptyTableSubTitle>
          <span style={{ width: "75%", marginBottom: "12px" }}>
            Add and update your staff via CSV upload or manually add them one at a time
          </span>
          <div>
            <AddButton
              onClick={toggleDrawer}
              style={{
                minWidth: "133px",
                justifyContent: "center",
              }}
            >
              <CirclePlusIconWrapper />
              <span style={{ fontSize: "14px" }}> Add New</span>
            </AddButton>
          </div>
        </EmptyTableBody>
      )}
      <ProfileDrawer open={Boolean(staffInfo)} onClose={() => setStaffInfo(null)} user={staffInfo} />
    </>
  );
};

export default StaffTable;
