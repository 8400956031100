import React, { useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DateRangeWrapper } from "./style";
import addDays from "date-fns/esm/addDays/index";

const DefaultDateRangePicker = ({
  label = ["Start Date", "End Date"],
  id,
  rightStyle,
  leftStyle,
  datepickerName,
  dateRangeWrapperStyle = {},
  defaultValue,
  handleStartChange,
  handleEndChange,
  ...rest
}) => {
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [startSelectedDate, setStartSelectedDate] = useState(null);
  // eslint-disable-next-line
  let dateLogic = moment(startSelectedDate) > moment(startSelectedDate);

  const handleStartDateChange = (date) => {
    setStartSelectedDate(date);
    handleStartChange && handleStartChange(date);
  };

  const handleEndDateChange = (date) => {
    if (dateLogic) {
      return;
    }
    setSelectedEndDate(date);
    handleEndChange && handleEndChange(date);
  };
  return (
    <DateRangeWrapper style={dateRangeWrapperStyle}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableToolbar
          openTo="year"
          views={["year", "month", "date"]}
          variant="inline"
          format="MM/dd/yyyy"
          placeholder="MM/DD/YYYY"
          margin="normal"
          id={id}
          maxDate={selectedEndDate ? addDays(selectedEndDate, -1) : moment()}
          label={label[0]}
          value={startSelectedDate}
          onChange={handleStartDateChange}
          style={leftStyle}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          InputLabelProps={{
            shrink: true,
          }}
          {...rest}
        />
        <KeyboardDatePicker
          disableToolbar
          openTo="year"
          views={["year", "month", "date"]}
          variant="inline"
          format="MM/dd/yyyy"
          placeholder="MM/DD/YYYY"
          margin="normal"
          id={id}
          label={label[1]}
          value={selectedEndDate}
          minDate={!!startSelectedDate ? addDays(startSelectedDate, 1) : "01/01/1970"}
          onChange={handleEndDateChange}
          style={rightStyle}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          InputLabelProps={{
            shrink: true,
          }}
          {...rest}
        />
      </MuiPickersUtilsProvider>
    </DateRangeWrapper>
  );
};

export default DefaultDateRangePicker;
