export const ContractSignatureStatus = Object.freeze({
  Signed: "SIGNED",
  Unsigned: "UNSIGNED",
});

export const ContractStatus = Object.freeze({
  Accepted: "ACCEPTED",
  Rejected: "REJECTED",
  // N/BL Subject to change, awaiting actual value from
  // backend
  Pending: "PENDING",
});
export const chipMapping = {
  ongoing: "info",
  completed: "success",
  draft: "default",
  error: "error",
  overdue: "error",
  closed: "error",
  done: "success",
  approved: `success`,
  rejected: `error`,
  "in review": "info",
  endorsed: "primary",
  pending: "default",
};

export const supplementaryBudgetStatus = {
  pending: "pending",
  reviewed: "reviewed",
  endorsed: "endorsed",
  approved: "approved",
};

export const supplementChipMapping = {
  pending: {
    bgColor: "#FFF5EA",
    textColor: "#B98900",
  },
  endorsed: {
    bgColor: "#EDF9F0",
    textColor: "#287D3C",
  },
  rejected: {
    bgColor: "#FEEFEF",
    textColor: "#DA1414",
  },
  approved: {
    bgColor: "#F1F2F6",
    textColor: "#0050C8",
  },
  inprogress: {
    bgColor: "#F1F2F6",
    textColor: "#0050C8",
  },
};

const ContractStatusLabel = Object.freeze({
  [ContractStatus.Accepted]: "Cleared",
  [ContractStatus.Rejected]: "Rejected",
  [ContractStatus.Pending]: "Uncleared",
});

const ContractSignatureStatusLabel = Object.freeze({
  [ContractSignatureStatus.Signed]: "Signed",
  [ContractSignatureStatus.Unsigned]: "Unsigned",
});

export const TagColorScheme = Object.freeze({
  pending: { bgColor: "#FFEAD9", textColor: "#ff8d6" },
  inprogress: { bgColor: "#D6EFD2", textColor: "#118043" },
  done: { bgColor: "#DCF6FF", textColor: "#03ADEB" },
});

export const getContractStatusLabel = (statusId) => ContractStatusLabel[statusId];
export const getContractSignatureStatusLabel = (signatureStatusId) => ContractSignatureStatusLabel[signatureStatusId];

export const isContractSigned = (contract) => contract.signatureStatus === ContractSignatureStatus.Signed;

export const ContractDurationType = Object.freeze({
  Month: "month",
  Year: "year",
});

export const VendorRecommendationStatus = Object.freeze({
  Recommended: 1,
  Evaluated: 2,
  Rejected: 3,
});

const ContractDurationTypeLabel = Object.freeze({
  [ContractDurationType.Month]: "Month",
  [ContractDurationType.Year]: "Year",
});

const VendorRecommendationStatusLabel = Object.freeze({
  [VendorRecommendationStatus.Recommended]: "Recommended",
  [VendorRecommendationStatus.Evaluated]: "Evaluated",
  [VendorRecommendationStatus.Rejected]: "Rejected",
});

export const getContractDurationTypeLabel = (durationType) => ContractDurationTypeLabel[durationType];
export const getVendorRecommendationStatusLabel = (recommendationStatus) => VendorRecommendationStatusLabel[recommendationStatus];

export const RECORD_STATUS = {
  APPROVED: "APPROVED",
  NEEDAMENDMENT: "NEEDS AMENDMENT",
  INREVIEW: "IN REVIEW",
  PENDING: "PENDING",
  DRAFT: "DRAFT",
};

export const RECORD_STAGE = {
  ONGOING: "ONGOING",
  COMPLETED: "COMPLETED",
  NOTSTARTED: "NOT STARTED",
};

export const MaxFileSize = 5000000;

export const RoleObjMapped = {
  "Procurement Officer": 1,
  "Permanent Secretary": 2,
  Commissioner: 3,
  Governor: 4,
  DSPPC: 5,
  Moderator: 6,
  Executive: 7,
  SSG: 8,
  "Ministry of Justice": 9,
  Admin: 10,
  "Tender Board": 11,
  "Contractors Reg Board": 12,
  "State Ministerial Tender Board": 13,
  "Supervising Officer": 14,
};
