import React, { useMemo } from "react";
import { useQuery } from "react-query";
import adminAPI from "../../redux/api/adminAPI";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { InputWrapper } from "../InputField/style";

const MinistryDropDownWithSearch = ({ onChange, style, excludeAllOption = false, inputRef }) => {
  const [searchValue, setSearchValue] = React.useState("");

  const { data } = useQuery({
    queryFn: adminAPI.getMinistriesAxios,
    queryKey: [
      "ministries",
      {
        pageSize: 50,
        pageNumber: 1,
        name: searchValue === "All" ? "" : searchValue,
      },
    ],
  });

  const ministryOptions = useMemo(() => {
    let options = [
      {
        id: "",
        name: "All",
      },
    ];

    if (data?.data) {
      options = options.concat(data.data);
    }

    excludeAllOption && options.shift();

    return options.map(({ id, name }) => ({
      label: name,
      value: id,
    }));

    //eslint-disable-next-line
  }, [data]);

  const _onChange = (e, value) => {
    if (value) setSearchValue(value);
  };

  const handleInputChange = (value) => {
    if (value) {
      onChange(value);
    }else{
        onChange(null);
    }
  };

  return (
    <Autocomplete
      noOptionsText="No search result"
      onInputChange={_onChange}
      onChange={(a, b) => handleInputChange(b?.value)}
      clearOnBlur={true}
      id="combo-box-demo"
      options={ministryOptions || []}
      renderInput={(params) => (
        <InputWrapper>
          <TextField
            {...params}
            inputRef={inputRef}
            label="Search Ministry"
            type="text"
            style={{ width: "100%", height: "100%" }}
            variant="filled"
          />
        </InputWrapper>
      )}
      getOptionLabel={(option) => option.label || ""}
      style={style}
    />
  );
};

export default MinistryDropDownWithSearch;
