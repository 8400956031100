import { Box, TableCell, TableRow } from "@material-ui/core";
import moment from "moment";
import React, { useContext, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { GeneralTitlePage } from "../../../../../../components/AddGeneralPlan/style";
import { CardNumberText } from "../../../../../../components/AdminCard/style";
import { SaveButton } from "../../../../../../components/Forms/Common/style";
import SearchTable from "../../../../../../components/SearchTable";
import TopStatisticsBar from "../../../../../../components/TopStatisticsBar";
import Breadcrumb from "../../../../../Common/PageElements/Breadcrumb";
import { ContentWrapper } from "../../../../Administration/Staff/style";
import { CardLabel } from "../../../../PlanDetails/style";
import Grid from "@material-ui/core/Grid";
import { ModdedAvatar, TableCellEmail } from "../../ReceiptOfResponse/VendorsTable/style";
import IconButton from "@material-ui/core/IconButton";
import { ReactComponent as RightIcon } from "../../../../../../assets/right_icon.svg";
import { useMutation, useQuery } from "react-query";
import receiptOfBiddersResponseApi from "../../../../../../redux/api/receiptOfBiddersResponseApi";
import procurementAPI from "../../../../../../redux/api/procurementAPI";
import { useRoles } from "../../../../../../hooks/useUserDetails";
import { Roles } from "../../../../../../components/AccessControl/UserRoles";
import LoadingButton from "../../../../../../components/LoadingButton/LoadingButton";
import CheckIcon from "@material-ui/icons/Check";
import FileProgress from "../../../../../../components/FileProgress/FileProgress";
import { openFileUrlInNewWindow } from "../../../../../Common/util";
import AdminSearchFilter from "../../../../../../components/AdminSearchFilter";
import InputField from "../../../../../../components/InputField";
import AccessControl from "../../../../../../components/AccessControl";
import Dropdown from "../../../../../../components/DropdownField";
import ToastContext from "../../../../../../util/toastContext";
import EvaluatedVendorsTable from "../../../../../../components/EvaluatedVendorsTable/EvaluatedVendorsTable";
import UpdateDeadline from "./UpdateDeadline";

const baseVendorObject = {
  data: {
    responseSummary: {
      total: 0,
      pending: 0,
      evaluated: 0,
    },
    evaluatedVendors: [],
  },
  pagination: {
    totalEntries: 0,
  },
};

const TableBody = ({ procurementId, id, history, vendors = [], canViewVendorDetails }) => {
  const redirectUser = (vendorId) => {
    return history.push(`/admin/procurement/activity/receiptofbiddersresponse/${id}/${procurementId}/evaluation/${vendorId}`);
  };

  return vendors.map(({ companyName, email, userId, registrationGrade }) => {
    return (
      <TableRow key={id}>
        <TableCell>
          <Grid spacing={1} container wrap="nowrap">
            <Box mr={2}>
              <ModdedAvatar size={34} color="#50B83C">
                {companyName?.slice(0, 2)}
              </ModdedAvatar>
            </Box>
            <Grid container direction="column" spacing={1}>
              <span>{companyName}</span>
              <TableCellEmail>{email}</TableCellEmail>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>{registrationGrade}</TableCell>

        <TableCell align="right">
          {canViewVendorDetails() && (
            <IconButton onClick={() => redirectUser(userId)}>
              <RightIcon fill="#637381" width={18} />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
    );
  });
};

const BidderResponseReceipt = () => {
  const { role } = useRoles();
  const { activityId, procurementId } = useParams();
  const history = useHistory();

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  const [filters, setFilters] = useState({
    Name: null,
    Status: (role === Roles.TENDER_BOARD || role === Roles.STATE_MINISTERIAL_TENDER_BOARD) ? 2 : 4,
  });

  const updateFilters = (key) => (value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  const expiryDate = useQuery({
    queryKey: [
      "expiryDate",
      {
        TenderId: procurementId,
      },
    ],
    queryFn: receiptOfBiddersResponseApi.getExpiryDate,
  });

  const biddersResponse = useQuery({
    queryKey: [
      "biddersResponse",
      {
        Status: filters.Status,
        PageSize: pagination.pageSize,
        PageNumber: pagination.pageNumber,
        id: procurementId,
        Name: filters.Name,
      },
    ],
    queryFn: receiptOfBiddersResponseApi.evaluatedBids,
  });

  const activityDetails = useQuery({
    queryKey: [
      "activityDetails",
      {
        activityId,
      },
    ],
    queryFn: procurementAPI.getActivityById,
  });

  const documents = useQuery({
    queryKey: [
      "approvalMemo",
      {
        activityId,
      },
    ],
    queryFn: receiptOfBiddersResponseApi.getApprovalDocument,
  });

  const [needsAmendment, needsAmendmentQuery] = useMutation(receiptOfBiddersResponseApi.evaluationNeedsAmendment, {
    throwOnError: true,
  });

  const [approveEvaluations, approveEvaluationsQuery] = useMutation(receiptOfBiddersResponseApi.approveEvaluations, {
    throwOnError: true,
    onSuccess: () => {
      activityDetails.refetch();
      biddersResponse.refetch();
    },
  });

  const updatePagination = (key) => (value) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      [key]: value,
    }));
  };

  const {
    data: { responseSummary, evaluatedVendors },
    pagination: apiPagination,
  } = Object.assign(baseVendorObject, biddersResponse.data);

  const topStatsBarKeys = useMemo(() => {
    if (biddersResponse.isSuccess && activityDetails.isSuccess) {
      const { procurementPlanActivityStatus } = activityDetails.data || {};

      let show;

      if (role === Roles.TENDER_BOARD || role === Roles.STATE_MINISTERIAL_TENDER_BOARD) {
        show = responseSummary.pending === 0;
      } else {
        show = true;
      }

      return {
        show,
        status: procurementPlanActivityStatus === "Approved" ? "approved" : "pending",
      };
    }

    return {
      show: false,
      status: null,
    };
  }, [biddersResponse.isSuccess, activityDetails.isSuccess, activityDetails.data, role, responseSummary.pending]);

  const { showAlert } = useContext(ToastContext);

  const onClickNeedsAmendment = async () => {
    try {
      await needsAmendment({
        procurementId,
      });

      showAlert({
        message: "Successfully sent amendment request",
        severity: "success",
      });
    } catch (e) {
      showAlert({
        message: e.message,
        severity: "error",
      });
    }
  };

  const onClickApprove = async () => {
    try {
      await approveEvaluations({
        procurementId,
      });

      showAlert({
        message: "Successfully approved evaluations",
        severity: "success",
      });
    } catch (e) {
      showAlert({
        message: e.message,
        severity: "error",
      });
    }
  };

  const renderTotalBidder = () => (
    <Box>
      <CardLabel>Total Bidders</CardLabel>
      <AccessControl allowedRoles={[Roles.PROCUREMENT_OFFICER]}>
        <CardNumberText>{responseSummary.total}</CardNumberText>
      </AccessControl>
      <AccessControl allowedRoles={[Roles.TENDER_BOARD, Roles.STATE_MINISTERIAL_TENDER_BOARD]}>
        <CardNumberText>{responseSummary.evaluated + responseSummary.recommendations}</CardNumberText>
      </AccessControl>
    </Box>
  );

  const renderCenterContent = () => {
    let rightContent;

    if (role === Roles.TENDER_BOARD || role === Roles.STATE_MINISTERIAL_TENDER_BOARD) {
      const doc = documents.data?.pop();

      rightContent = (
        <>
          <Box ml="auto" mr={2.5} minWidth="180px">
            <FileProgress
              progress={100}
              fileName={doc?.file?.original_filename}
              fileSize={doc?.file?.bytes}
              hasEyes
              onClickEyes={() => openFileUrlInNewWindow(doc?.file?.url)}
            />
          </Box>
          <Box display="flex" ml="auto" width="32%" mb={4}>
        <LoadingButton
          color="#F2994A"
          onClick={onClickNeedsAmendment}
          loading={needsAmendmentQuery.isLoading}
          disabled={activityDetails?.data?.procurementPlanActivityStatus === "Approved" || responseSummary.recommendations > 0}
        >
          Needs Amendment
        </LoadingButton>
        <LoadingButton
          color="#219653"
          loading={approveEvaluationsQuery.isLoading}
          onClick={onClickApprove}
          endIcon={<CheckIcon width="10px" />}
          disabled={activityDetails?.data?.procurementPlanActivityStatus === "Approved" || responseSummary.recommendations < 1}
        >
          Approve
        </LoadingButton>
      </Box>
        </>
      );
    } else {
      rightContent = (
        <SaveButton
          onClick={() =>
            history.push(`/admin/procurement/activity/receiptofbiddersresponse/${activityId}/${procurementId}/evaluated/vendor`)
          }
        >
          View Evaluation
        </SaveButton>
      );
    }

    return (
      <>
        <AccessControl allowedRoles={[Roles.PROCUREMENT_OFFICER]}>
          <Box>
            <CardLabel style={{ color: "#FF8D26" }}>Pending Review</CardLabel>
            <CardNumberText>{responseSummary.pending}</CardNumberText>
          </Box>
          <Box marginLeft="55px" marginRight="15px">
            <CardLabel style={{ color: "#219653" }}>Evaluated</CardLabel>
            <CardNumberText>{responseSummary.evaluated}</CardNumberText>
          </Box>
        </AccessControl>
        <AccessControl allowedRoles={[Roles.TENDER_BOARD, Roles.STATE_MINISTERIAL_TENDER_BOARD]}>
          <Box marginLeft="55px" marginRight="15px">
            <CardLabel style={{ color: "#FF8D26" }}>Pending Review</CardLabel>
            <CardNumberText>{responseSummary.evaluated}</CardNumberText>
          </Box>
          <Box marginLeft="55px" marginRight="15px">
            <CardLabel style={{ color: "#219653" }}>Recommendations</CardLabel>
            <CardNumberText>{responseSummary.recommendations}</CardNumberText>
          </Box>
        </AccessControl>
        {rightContent}
      </>
    );
  };

  const canViewVendorDetails = () => {
    let currentDate = moment.parseZone().format();
    return moment.parseZone(expiryDate.data).format() <= currentDate;
  };

  return (
    <ContentWrapper>
      <Box mt={2}>
        <GeneralTitlePage>Receipt of Response</GeneralTitlePage>
        <Breadcrumb
          values={[
            {
              url: "/dashboard/overview",
              title: "Home",
            },
            {
              url: `/admin/procurement/plans`,
              title: "Plans",
            },
            {
              url: `/admin/procurement/plan-details/${procurementId}`,
              title: "Plan Details",
            },
          ]}
        />
      </Box>

      <AccessControl allowedRoles={[Roles.PROCUREMENT_OFFICER]}>
        <Box mb={5.5} mt={2}>
          <UpdateDeadline expiryDate={expiryDate} />
        </Box>
      </AccessControl>

      <Box mb={0.5} mt={2}>
        <TopStatisticsBar
          leftContent={renderTotalBidder()}
          status={topStatsBarKeys.status}
          centerContent={renderCenterContent()}
          hasApproveStatus={topStatsBarKeys.show}
          isLoading={biddersResponse.isLoading || activityId.isLoading || documents.isLoading}
        />
      </Box>
   

      <AdminSearchFilter normalSearch>
        <Grid container justify="space-around" wrap="nowrap">
          <InputField type="text" label="Search name" value={filters.Name} setValue={updateFilters("Name")} />
          <AccessControl allowedRoles={[Roles.PROCUREMENT_OFFICER]}>
            <Dropdown
              name="status"
              label="Status"
              values={[
                { label: "Evaluated", value: 2 },
                { label: "Not Evaluated", value: 4 },
                { label: "Recommended", value: 1 },
                { label: "Not Recommended", value: 9 },
              ]}
              initialState={filters.Status}
              onChange={updateFilters("Status")}
            />
          </AccessControl>
          <AccessControl allowedRoles={[Roles.TENDER_BOARD, Roles.STATE_MINISTERIAL_TENDER_BOARD]}>
            <Dropdown
              name="status"
              label="Status"
              values={[
                { label: "Pending", value: 2 },
                { label: "Not Recommended", value: 9 },
              ]}
              initialState={filters.Status}
              onChange={updateFilters("Status")}
            />
          </AccessControl>
        </Grid>
      </AdminSearchFilter>

      <AccessControl allowedRoles={[Roles.PROCUREMENT_OFFICER]}>
        <SearchTable
          columns={["Name", "Registration Class", "Action"]}
          page={pagination.pageNumber}
          setPage={updatePagination("pageNumber")}
          rowsPerPage={pagination.pageSize}
          setRowsPerPage={updatePagination("pageSize")}
          totalNumberOfPages={apiPagination.totalPages}
          loading={biddersResponse.isLoading}
          empty={evaluatedVendors.length === 0}
        >
          {evaluatedVendors.length > 0 && (
            <TableBody
              procurementId={procurementId}
              id={activityId}
              history={history}
              vendors={evaluatedVendors}
              canViewVendorDetails={canViewVendorDetails}
            />
          )}
        </SearchTable>
      </AccessControl>

      <AccessControl allowedRoles={[Roles.TENDER_BOARD, Roles.STATE_MINISTERIAL_TENDER_BOARD]}>
        <EvaluatedVendorsTable
          pageNumber={pagination.pageNumber}
          pageSize={pagination.pageSize}
          totalPages={apiPagination.totalEntries}
          isLoading={biddersResponse.isLoading}
          setPageSize={updatePagination("pageSize")}
          setPageNumber={updatePagination("pageNumber")}
          vendors={evaluatedVendors}
        />
      </AccessControl>
    </ContentWrapper>
  );
};

export default BidderResponseReceipt;
