import React from "react";
import { Grid, Box } from "@material-ui/core";

import { ContractDetailTabText } from "./style";
import { formatCurrency } from "../../../../util/formatCurrency";

const EvaluatorsTab = ({ data: contractDetails }) => {
  const details = [
    { title: "Contract Sum", value: formatCurrency(contractDetails.contractSum || 0, true) || "--" },
    { title: "Contract Variation", value: formatCurrency(contractDetails.contractVariation || 0, true) || "--" },
    { title: "Revised Contract amount", value: formatCurrency(contractDetails.revisedContractAmount || 0, true) || "--" },

    { title: "Percentage Completion	", value: contractDetails.percentageCompletion + "%" || "--" },
    { title: "Project Status	", value: contractDetails.projectStatus || "--" },
  ];

  return (
    <>
      <Box>
        {details.map((detail, i) => (
          <Grid key={i} style={{ borderBottom: "1px solid #CBD4DA" }} container>
            <Grid item xs={3} style={{ background: "#e4e9ec" }}>
              <ContractDetailTabText style={{ background: "#e4e9ec", boxShadow: "0px -1px 0px #C9D4DB" }}>
                {detail.title}
              </ContractDetailTabText>
            </Grid>
            <Grid item sm style={{ borderLeft: "1px solid #CBD4DA" }}>
              <ContractDetailTabText>{detail.value}</ContractDetailTabText>
            </Grid>
          </Grid>
        ))}
      </Box>
    </>
  );
};

export default EvaluatorsTab;
