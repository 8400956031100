import styled from "styled-components";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";

export const ContentWrapper = styled.div`
  padding: 15px 0 80px 0;

  .MuiTableHead-root {
    background-color: white;
    padding: 20px 0 !important;
  }

  .MuiTableCell-head {
    font-weight: bold;
    font-size: 16px;
  }
`;

export const PageTitle = styled.h1`
  color: #1e2b37;
  font-size: 28px;
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: 0;
  font-weight: normal;
`;

export const BreadCrumbWrapper = styled.div`
  margin-bottom: 40px;

  a {
    height: 16px;
    width: 197px;
    color: #00b0f1;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    text-decoration: none;
  }

  a:visited {
    color: #00b0f1;
  }
`;

export const CardWrapper = styled.div`
  box-sizing: border-box;
  border: 1px solid #c9d4db;
  border-radius: 3px;
  position: relative;
  box-shadow: 0 2px 4px 0 #c9d4db;
  overflow: hidden;
  width: 100%;
  padding: ${(props) => (props.noPadding ? "0px" : "20px")};
  margin-bottom: 40px;
  background-color: ${(props) => (props.gray ? "#FCFCFD" : "#FFFFFF")};
`;

export const CardTop = styled.div`
  background-color: #e4e9ec;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 10px 20px;
`;

export const CardBody = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 15px;
  align-items: start;
`;

export const CardSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CardLabel = styled.p`
  color: ${(props) => props.color || "#5f7483"};
  font-size: 14px;
  padding-bottom: 5px;
  letter-spacing: 0;
  line-height: 16px;
`;

export const CardContent = styled.p`
  color: #5f7483;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
`;

export const BoldCardContent = styled(CardContent)`
  color: #212b36 !important;
`;

export const StatusCircle = styled.span`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #e5e9ec;
  display: inline-flex;
  margin-right: ${(props) => `${props.marginRight}px` || 0};
`;

export const StatusCircleSuccess = styled(StatusCircle)`
  background-color: #3bd278;
`;

export const StatusCirclePending = styled(StatusCircle)`
  background-color: #f49342;
`;

export const TableWrapper = styled.div`
  .cell-no-padding {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const TableTop = styled.div`
  height: 44px;
  display: flex;
  align-items: center;
  border: 1px solid #c9d4db;
  background-color: #e4e9ec;
  padding-left: 20px;
`;

export const TableHeader = styled.h1`
  color: #5f7483;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 0;
`;

export const CommentsWrapper = styled.div`
  border: 1px solid #c9d4db;
  border-radius: 3px;
  background-color: rgba(228, 233, 236, 0.17);
  margin: 30px 20px 40px;
`;

export const CommentsTop = styled.div`
  background-color: #e4e9ec;
  display: flex;
  align-items: center;
  padding-left: 20px;
  height: 46px;
`;

export const CommentsHeader = styled.div`
  color: #424f5c;
  font-size: 16px;
  font-weight: bold;
`;

export const CommentsBody = styled.div`
  padding: 22px 20px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e4e9ec;
  margin: 15px 0;
`;

export const SerialText = styled.span`
  color: #1e2b37;
  font-size: 14px;
  padding-bottom: 5px;
  padding-left: 20px;
  letter-spacing: 0;
  line-height: 16px;
  font-weight: 600;
`;

export const TopSection = styled(Box)`
  margin-bottom: 20px;
`;

export const UnstyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;
export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
export const PrimaryButton = styled.button`
  box-sizing: border-box;
  height: 41px;
  width: 125px;
  border-radius: 3px;
  background-color: #03adeb;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid #ebebeb !important;
  margin-top: 20px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
`;

export const HeadContent = styled.div`
  display: flex;
  align-items: center;

  button {
    all: unset;
    margin-left: 16px;
    color: #0050c8;
    background: transparent;
    cursor: pointer;
  }
`;

export const EditRoadmap = styled.div`
 padding: 24px;

 .title {
  color: #3F5673;
  font-weight: 500;
  font-size: 23px;
 }

 .stage {
  color: #272833;
  font-weight: 600;
  font-size: 20px;
  margin: 32px 0;
 }

 .row {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  width: 100%;
 }

 .stageName {
  color: #393A4A;
  margin-bottom: 16px:
 }

 .error{
  height: 0px;
  margin-top: 6px;
 }

 .divide {
  border: 1px dashed #CDCED9;
  height: 
 }

 button.save-btn {
    all: unset;
    background: #0050C8;
    color: #fff;
    border-radius: 8px;
    padding: 10px 16px;
    margin-top: 12px;
    cursor: pointer;
    cursor: ${(props) => (props.disabled ? "not-allowed" : `pointer`)};
    opacity: ${(props) => (props.disabled ? 0.5 : 1)};

    &:hover {
      opacity: 0.8;
    }

 }
`;

export const ErrorContainer = styled(Typography)`
  margin: 5px 0;
  font-size: 12px !important;
  color: red;
`;
