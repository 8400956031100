import styled from "styled-components";
import { Link } from "react-router-dom";
import { SaveButton } from "../NavigationButtons/style";

export const LandingNavbarContainer = styled.div`
  display: flex;
  align-items: center;
  height: 90px;
  @media screen and (max-width: 600px) {
    display: none;
  }
`;

export const Spacer = styled.div`
  display: flex;
  margin-left: auto;
`;

export const MenuLink = styled(Link)`
  margin-right: 30px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${({ activemenu }) => (activemenu === "true" ? "#03ADEB" : "#000")};
  font-size: 14px;
  svg {
    margin-right: 10px;
  }
`;

export const MobileNavStyle = styled.div`
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 50;
  background: rgba(0, 0, 0, 0.5);
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  left: -100vw;

  &[data-isvisible="true"] {
    animation-name: slide-in;
  }

  &[data-isvisible="false"] {
    animation-name: slide-out;
  }

  @keyframes slide-in {
    100% {
      left: 0;
    }
  }

  @keyframes slide-out {
    0% {
      left: 0;
    }
    100% {
      left: -100vw;
    }
  }
`;

export const MobileNavLink = styled.div`
  display: flex;
  flex-direction: column;
  width: 70vw;
  height: 100%;
  background: #fff;
  color: #1e2b37;

  img {
    height: 50px;
    margin: 40px 0 40px 0;
  }

  a {
    text-decoration: none;
    text-transform: capitalize;
    font-size: 14px;
    margin: 0.5rem 5px;
    padding: 0 1rem;
  }
`;

export const VendorLoginButton = styled(SaveButton)`
  height: 41px;
  width: 151px;
  margin: 0 !important;
`;
