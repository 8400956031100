import React from "react";
import { Controller, useForm } from "react-hook-form";
import { InputField } from "../../../../../shared";
import { CancelButton, UpdateButton } from "../../../Administration/Settings/style";
import { ErrorContainer } from "../../../../../util/theme";
import NumberFormat from "react-number-format";
import { lowerCase } from "lodash";
import { Dropdown } from "../../../../../components";
import { useEffect } from "react";
import { DateRangeWrapper } from "../../../../../components/DateRangePIcker/style";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useParams } from "react-router-dom";
import { contractDetailDefaultProps, contractDetailResolver } from "./validator";
import { format } from "date-fns";
import { archiveCategoryFunc, archiveCategoryOptions } from "../utils/useArchiveHokk";
function EditContractDetailTab({ data, mutate, isLoading, handleEdit }) {
  const { errors, reset, control, handleSubmit } = useForm({
    defaultValues: contractDetailDefaultProps,
    resolver: contractDetailResolver,
  });
  const params = useParams();

  // const categoryOptions = ["Works", "Goods", "Consultation", "Non-Consultation"].map((item) => ({ label: item, value: item }));

  useEffect(() => {
    let payload = {};
    const fields = Object.keys(contractDetailDefaultProps);
    if (data) {
      fields.forEach((field) => {
        if (field === "projectDescription") {
          payload[field] = data?.description || data?.projectDescription || contractDetailDefaultProps[field];
        } else if (field === "budgetYear") {
          const budgetYear = data[field] ? new Date(Date.parse(data[field])) : contractDetailDefaultProps[field];
          payload[field] = budgetYear ? budgetYear : contractDetailDefaultProps[field];
        } else if (field === "budgetAmount") {
          payload[field] = data?.amount || data?.[field] || contractDetailDefaultProps[field];
        } else if (field === "procurementCategory") {
          payload[field] = archiveCategoryFunc(lowerCase(data?.category || "")) || contractDetailDefaultProps[field];
        } else if (field === "projectName") {
          payload[field] = data?.title || contractDetailDefaultProps[field];
        } else if (field === "bidOpeningDate") {
          payload[field] = data?.bidOpeningDate ? new Date(data?.bidOpeningDate) : contractDetailDefaultProps[field];
        } else if (field === "bidSolicitationDate") {
          payload[field] = data?.bidSolicitationDate ? new Date(data?.bidSolicitationDate) : contractDetailDefaultProps[field];
        } else if (field === "startTenderPeriod") {
          payload[field] = data?.bidSolicitationDate ? new Date(data?.bidSolicitationDate) : contractDetailDefaultProps[field];
        } else if (field === "endTenderPeriod") {
          payload[field] = data?.bidOpeningDate ? new Date(data?.bidOpeningDate) : contractDetailDefaultProps[field];
        } else {
          payload[field] = data[field] || contractDetailDefaultProps[field];
        }
      });
      payload = {
        ...payload,
      };
      reset({
        ...payload,
      });
    }
  }, [data, reset]);

  const onSubmit = (e) => {
    const payload = {};
    const fields = Object.keys(contractDetailDefaultProps);
    fields.forEach((field) => {
      if (field === "budgetYear") {
        const f = e[field] && format(new Date(e[field]), "yyyy");
        payload[field] = parseInt(f, 10);
      } else if (field === "procurementPlan") {
        payload[field] = e[field] === "no" ? 0 : 1;
      } else if (field === "procurementCategory") {
        payload[field] = parseInt(e[field], 10);
      } else {
        payload[field] = e[field];
      }
    });
    ["endTenderPeriod", "startTenderPeriod", "projectName", "budgetDescription"].forEach((v) => {
      delete payload[v];
    });
    mutate({ payload, archiveId: params.id, type: "planning" });
  };
  // console.log({ errors });
  const onRenderError = (error) => <ErrorContainer>{error && error.message}</ErrorContainer>;
  return (
    <div>
      <div>
        <Controller
          control={control}
          name="projectName"
          render={(props) => <InputField disabled label="Project Title" style={{ background: "#ffffff" }} {...props} />}
        />
      </div>
      <div>
        <Controller
          control={control}
          name="projectDescription"
          render={({ ...props }) => (
            <InputField label="Project Desciption" multiline style={{ background: "#ffffff" }} {...props} />
          )}
        />
      </div>
      <div>
        <Controller
          control={control}
          name="budgetDescription"
          render={(props) => (
            <InputField disabled multiline label="Budget Description" style={{ background: "#ffffff" }} {...props} />
          )}
        />
      </div>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        <div>
          <DateRangeWrapper width={{ root: "97%" }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Controller
                control={control}
                name="budgetYear"
                render={({ value, onChange, ...props }) => (
                  <KeyboardDatePicker
                    label="Budget Year"
                    disableToolbar
                    openTo="year"
                    views={["year"]}
                    variant="inline"
                    format="yyyy"
                    placeholder="YYYY"
                    margin="normal"
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...props}
                    value={new Date(value)}
                    // onChange={(e) => {
                    //   onChange(e?.getFullYear());
                    //   // return e;
                    // }}
                    onChange={onChange}
                  />
                )}
              />
            </MuiPickersUtilsProvider>
          </DateRangeWrapper>

          {onRenderError(errors.startDate)}
        </div>
        <div style={{ background: "#ffffff" }}>
          <Controller
            control={control}
            name="budgetAmount"
            render={({ onChange, ...props }) => (
              <NumberFormat
                customInput={InputField}
                name="budgetAmount"
                label="Budget Amount"
                thousandSeparator
                prefix="₦"
                variant="outlined"
                onValueChange={(values) => {
                  const { value } = values;
                  onChange(value);
                }}
                {...props}
              />
            )}
          />
        </div>
      </div>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        <div>
          <Controller
            control={control}
            name="procurementPlan"
            render={(props) => (
              <Dropdown
                {...props}
                // defaultValue={capitalizeFirst(watch("procurementPlan"))}
                style={{ width: "inherit" }}
                label="Procurement Plan"
                values={[
                  { value: "no", label: "No" },
                  { value: "yes", label: "Yes" },
                ]}
              />
            )}
          />
        </div>
        <div>
          <DateRangeWrapper>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Controller
                control={control}
                name="startTenderPeriod"
                render={(props) => (
                  <KeyboardDatePicker
                    label="Tendering"
                    disableToolbar
                    disabled
                    disableunderline
                    openTo="year"
                    views={["year", "month", "date"]}
                    variant="inline"
                    format="dd-MMM-yyyy"
                    placeholder="MM/DD/YYYY"
                    margin="normal"
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    {...props}
                  />
                )}
              />
              <Controller
                control={control}
                name="endTenderPeriod"
                render={(props) => (
                  <KeyboardDatePicker
                    label="Period"
                    disabled
                    disableToolbar
                    openTo="year"
                    views={["year", "month", "date"]}
                    variant="inline"
                    format="dd-MMM-yyyy"
                    placeholder="MM/DD/YYYY"
                    margin="normal"
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    {...props}
                  />
                )}
              />
            </MuiPickersUtilsProvider>
          </DateRangeWrapper>
          {/* <Controller control={control} name="procurementPlan" render={(props) => <DefaultDateRangePicker {...props} />} /> */}
        </div>
      </div>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        <div>
          <Controller
            control={control}
            name="procuringEntity"
            render={(props) => (
              <InputField disabled label="Procuring Entity / SubSector" style={{ background: "#ffffff" }} {...props} />
            )}
          />
        </div>
        <div>
          <Controller
            control={control}
            name="procurementCategory"
            render={(props) => {
              return (
                <Dropdown
                  style={{ width: "inherit" }}
                  label="Procurement Category"
                  // disabled
                  {...props}
                  values={archiveCategoryOptions}
                />
              );
            }}
          />{" "}
        </div>
      </div>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        <div>
          <DateRangeWrapper width={{ root: "97%" }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Controller
                control={control}
                name="bidSolicitationDate"
                render={(props) => (
                  <KeyboardDatePicker
                    label="Bid Solicitation Date"
                    disableToolbar
                    openTo="year"
                    views={["year", "month", "date"]}
                    variant="inline"
                    format="dd-MMM-yyyy"
                    placeholder="MM/DD/YYYY"
                    margin="normal"
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...props}
                  />
                )}
              />
            </MuiPickersUtilsProvider>
          </DateRangeWrapper>

          {onRenderError(errors.bidSolicitationDate)}
        </div>
        <div>
          <DateRangeWrapper width={{ root: "97%" }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Controller
                control={control}
                name="bidOpeningDate"
                render={(props) => (
                  <KeyboardDatePicker
                    label="Bid Opening Date"
                    disableToolbar
                    openTo="year"
                    views={["year", "month", "date"]}
                    variant="inline"
                    format="dd-MMM-yyyy"
                    placeholder="MM/DD/YYYY"
                    margin="normal"
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...props}
                  />
                )}
              />
            </MuiPickersUtilsProvider>
          </DateRangeWrapper>

          {onRenderError(errors.bidOpeningDate)}
        </div>
      </div>
      <div style={{ display: "grid", gridTemplateColumns: "150px 1fr", placeItems: "center start", marginTop: "30px" }}>
        <UpdateButton onClick={handleSubmit(onSubmit)}>{isLoading ? "Saving Changes..." : "Save Changes"}</UpdateButton>
        <CancelButton onClick={handleEdit}>Cancel</CancelButton>
      </div>
    </div>
  );
}

EditContractDetailTab.propTypes = {};

export default EditContractDetailTab;
