/* eslint-disable func-names */
import { capitalize } from "lodash";

export const underscoreRemove = (str = "") => {
  return str?.replace(/_/g, " ");
};

export const dashRemove = (str = "") => {
  return str.replace(/\-/g, " ");
};

export const capitalizeWord = (str = "") => {
  const re = /(\b[a-z](?!\s))/g;
  if (str) {
    return str.toLowerCase().replace(re, (x) => capitalize(x));
  }
  return "";
};

export const underSCoreCapitalizeWord = (str = "") => {
  if (str) {
    const removeWOrd = underscoreRemove(str);
    return capitalizeWord(removeWOrd);
  }
  return "";
};

export const dashCapitaliseWord = (str = "") => {
  if (typeof str === "string") {
    const removeWord = dashRemove(str);
    return capitalizeWord(removeWord);
  }

  return "";
};

export const truncateString = (string = "", maxLength = 15) =>
  string.length > maxLength ? `${string.substring(0, maxLength)}…` : string;
