import styled from "styled-components";
import Box from "@material-ui/core/Box"

export const StyledBox = styled(Box)({
  position: 'absolute',
  backgroundColor: 'white',
  color: '#000',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
  borderRadius: '8px',
  '& .MuiBox-root': {
    outline: 'none',
    border: 'none',
  }
})
