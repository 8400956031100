import React, { useContext } from "react";
import format from "date-fns/format";
import { ReactComponent as Logo } from "../../../../../assets/fulllogo.svg";
import watermark11 from "../../../../../assets/watermark-right.png";
import watermark2 from "../../../../../assets/watermark2.png";
import watermark3 from "../../../../../assets/watermark-LB.png";
import dcppImage from "../../../../../assets/dsppc_cno_img.png";
import {
  RedTitle,
  LogoDiv,
  CertificateContainer,
  CertName,
  WaterMark1,
  WaterMark2,
  WaterMark3,
  SignatureTitle,
  QRCodeStyle,
  SignatureBox,
  SignatureContainer,
  CertNoAnswer,
  CertificateItemsTitle,
  CertNoTitle,
  CertificateTitle,
  GreenTitle,
  CertificateBody,
  CertificateItemTitle,
  CertificateItemAnswer,
  CertificateItemsBox,
  CertificateTitleBox,
  CertificateAnswerBox,
  ImageContainer,
  DateWrapper,
} from "./style";
import QRCode from "qrcode.react";
import Loader from "../../../../../components/Common/Loader";
import ToastContext from "../../../../../util/toastContext";
import { useParams } from "react-router-dom";
import { useFetchAuthoriserSignature, useFetchCertificateData } from "../../../../../hooks/queries/useCertificate";
import { formatCurrency } from "../../../../../util/formatCurrency";

function Certificate({ CertRef, isPreview, certifiedData }) {
  const { showAlert } = useContext(ToastContext);
  const { procurementId } = useParams();
  const { data: authorizersData, isFetching } = useFetchAuthoriserSignature({ showAlert });
  const { data: certificateData, isFetching: isFetchingCertData } = useFetchCertificateData({ procurementId, showAlert });

  if (isFetching || isFetchingCertData) {
    return <Loader />;
  }

  return (
    <CertificateContainer ref={CertRef}>
      <WaterMark1>
        <img src={watermark3} alt="Delta state logo" />
      </WaterMark1>
      {!isPreview && (
        <WaterMark2>
          <img src={watermark2} alt="Delta state logo" />
        </WaterMark2>
      )}
      <WaterMark3>
        <img src={watermark11} alt="Delta state logo" />
      </WaterMark3>
      <LogoDiv>
        <Logo />
      </LogoDiv>
      <DateWrapper>{format(new Date(), "dd MMMM, yyyy")}</DateWrapper>
      <CertificateTitle>DELTA STATE PUBLIC PROCUREMENT COMMISSION</CertificateTitle>
      <CertificateItemAnswer style={{ textAlign: "center", marginBottom: "1rem" }}>
        No 6, OKPANAM ROAD, ASABA
      </CertificateItemAnswer>
      <RedTitle>FULFILLMENT OF DUE PROCESS FOR THE AWARD OF CONTRACT</RedTitle>
      <GreenTitle>CERTIFICATE OF NO OBJECTION</GreenTitle>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <CertNoTitle>
          SERIAL NO.
          <CertNoAnswer>{certificateData?.data?.serialNumber || "Not Applicable"}</CertNoAnswer>
        </CertNoTitle>
        <CertNoTitle>
          CONTRACT REF. NO.
          <CertNoAnswer>{certificateData?.data?.packageNumber?.toUpperCase() || "Not Applicable"}</CertNoAnswer>
        </CertNoTitle>
      </div>
      <CertificateBody>
        The Delta State Public Procurement Commission, having examined your request and all documents presented, confirms that the
        procurement process has satisfied the Delta State Public Procurement Law, 2016 requirements for issuance of{" "}
        <span style={{ fontWeight: 700 }}>Certificate of No Objection</span> for the Award of Contract.
      </CertificateBody>
      <CertificateBody>
        Accordingly, based on the information made available to the commission, a{" "}
        <span style={{ fontWeight: 700 }}>Certificate of No Objection</span> is hereby granted as follows:
      </CertificateBody>
      <CertificateItemsTitle>PROJECT DETAILS</CertificateItemsTitle>
      <CertificateItemsBox>
        <CertificateTitleBox>
          <CertificateItemTitle>Project Description</CertificateItemTitle>
        </CertificateTitleBox>
        <CertificateAnswerBox>
          <CertificateItemAnswer>{`${
            certificateData?.data?.projectDescription?.toUpperCase() || "Not Applicable"
          }`}</CertificateItemAnswer>
        </CertificateAnswerBox>
      </CertificateItemsBox>
      <CertificateItemsBox>
        <CertificateTitleBox>
          <CertificateItemTitle>Project Contractor</CertificateItemTitle>
        </CertificateTitleBox>
        <CertificateAnswerBox>
          <CertificateItemAnswer>{certificateData?.data?.contractor?.toUpperCase() || "Not Applicable"}</CertificateItemAnswer>
        </CertificateAnswerBox>
      </CertificateItemsBox>
      <CertificateItemsBox>
        <CertificateTitleBox>
          <CertificateItemTitle>Project cost as requested</CertificateItemTitle>
        </CertificateTitleBox>
        <CertificateAnswerBox>
          <CertificateItemAnswer>{`${
            certificateData?.data?.quotation ? formatCurrency(certificateData?.data?.quotation || 0, true) : "Not Applicable"
          }`}</CertificateItemAnswer>
        </CertificateAnswerBox>
      </CertificateItemsBox>
      <CertificateItemsBox>
        <CertificateTitleBox>
          <CertificateItemTitle>DSPPC reviewed Total Project Cost</CertificateItemTitle>
        </CertificateTitleBox>
        <CertificateAnswerBox>
          <CertificateItemAnswer>{`${
            certificateData?.data?.reviewedQuotation
              ? formatCurrency(certificateData?.data?.reviewedQuotation || 0, true)
              : "Not Applicable"
          }`}</CertificateItemAnswer>
        </CertificateAnswerBox>
      </CertificateItemsBox>
      <CertificateItemsBox>
        <CertificateTitleBox>
          <CertificateItemTitle>Cost Reduction</CertificateItemTitle>
        </CertificateTitleBox>
        <CertificateAnswerBox>
          <CertificateItemAnswer>
            {`${
              certificateData?.data?.costReduction
                ? formatCurrency(certificateData?.data?.costReduction || 0, true)
                : "Not Applicable"
            }`}
          </CertificateItemAnswer>
        </CertificateAnswerBox>
      </CertificateItemsBox>
      <CertificateItemsBox>
        <CertificateTitleBox>
          <CertificateItemTitle>Head/Sub-head/Appropriation Code</CertificateItemTitle>
        </CertificateTitleBox>
        <CertificateAnswerBox>
          <CertificateItemAnswer>
            {`${certificateData?.data?.headCode}/${certificateData?.data?.subHeadCode}/${certificateData?.data?.appropriationCode}` ||
              "NIL"}
          </CertificateItemAnswer>
        </CertificateAnswerBox>
      </CertificateItemsBox>
      <CertificateItemsBox>
        <CertificateTitleBox>
          <CertificateItemTitle>Client Ministry/Department/Agency</CertificateItemTitle>
        </CertificateTitleBox>
        <CertificateAnswerBox>
          <CertificateItemAnswer>{certificateData?.data?.ministry?.toUpperCase() || "Not Applicable"}</CertificateItemAnswer>
        </CertificateAnswerBox>
      </CertificateItemsBox>
      <SignatureBox>
        <div> </div>
        <SignatureContainer style={{ marginRight: "1rem", marginTop: "1rem" }}>
          <ImageContainer>
            <img src={dcppImage} alt="Dsppc-logo" />
          </ImageContainer>
          {/* <CertName style={{ textAlign: 'center' }}>{authorizersData?.data?.name?.toUpperCase() || 'NIL'}</CertName> */}
          <div style={{ textAlign: "center", marginTop: 30 }}>
            <img crossOrigin="anonymous" src={authorizersData?.data?.signatureUrl || ""} alt="Delta state logo" height={30} />
          </div>
          <SignatureTitle>.........................................................................</SignatureTitle>
          <SignatureTitle style={{ textAlign: "center", marginBottom: "2rem" }}>SIGNATURE</SignatureTitle>
          <CertName style={{ textAlign: "center" }}>
            {authorizersData?.data?.position?.toUpperCase() || "Director-General"}
          </CertName>
          {/* <SignatureTitle>.........................................................................</SignatureTitle> */}
        </SignatureContainer>
        <div style={{ position: "relative" }}>
          <QRCodeStyle>
            <QRCode value={`${window.location.origin}/verify-cno/${certificateData?.data?.id}`} renderAs="canvas" size={120} />
          </QRCodeStyle>
        </div>
        {/* <SignatureContainer style={{ marginLeft: '1rem' }}>
          <div style={{ textAlign: 'center' }}>
            <img src={file} alt="Delta state logo" height={30} />
          </div>
          <SignatureTitle>.........................................................................</SignatureTitle>
          <SignatureTitle style={{ textAlign: 'center', marginBottom: '2rem' }}>Signature</SignatureTitle>
          <CertName style={{ textAlign: 'center' }}>{format(new Date(), "dd MMMM, yyyy")}</CertName>
          <SignatureTitle>.........................................................................</SignatureTitle>
          <SignatureTitle style={{ textAlign: 'center' }}>Date</SignatureTitle>
        </SignatureContainer> */}
      </SignatureBox>
    </CertificateContainer>
  );
}

export default Certificate;
