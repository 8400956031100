import React, { useState, useContext, useEffect } from "react";
import { Box, Tab, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useDropzone } from "react-dropzone";
import { saveAs } from "file-saver";
import { useMutation, queryCache } from "react-query";

import {
  CustomDialog,
  CustomDialogTitle,
  // CustomDialogContent,
  CustomErrorDialogContent,
  CustomDialogTitleWrapper,
  SubmitButton,
  PrevBtn,
} from "../../../components/Forms/ConfirmVerificationModal/style";
import { MaxFileSize } from "../../../util/constants";
import contractsAPI from "../../../redux/api/contractAPI";
import ToastContext from "../../../util/toastContext";
import LoadGif from "../../../assets/fileCheck.gif";
import SuccessGif from "../../../assets/fileSuccess.gif";
import ErrorGif from "../../../assets/fileerror.gif";
// import { DropzoneArea } from "../../../components/DocumentUploadField/style";
// import FileProgress from "../../../components/FileProgress/FileProgress";
// import uploadTemplate from "../../../assets/sampleArchive.csv";
import { TabContainer } from "../Procurement/Activitiies/SpecificProcurementNotice/style";
import { PageTabs } from "../../../util/theme";
import ContractUploadTab from "./ContractUploadTab";
import BidderUploadTab from "./BidderUploadTab";

const useStyles = makeStyles({
  tabWrapper: {
    textTransform: "none",
  },
  root: {
    minWidth: 90,
  },
});

const UploadModal = ({ open, toggleModal }) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = React.useState(0);
  const { showAlert } = useContext(ToastContext);

  const saveFile = (data, name) => {
    const csvFile = new File(data, `ARCHIVE_${name}.csv`, { type: "text/csv;charset=utf-8" });
    saveAs(csvFile);
  };
  const [contractFile, setContractFile] = useState(null);
  const [biderFile, setBiderFile] = useState(null);
  const [error, setError] = useState("");
  const [currentScreen, setCurrentScreen] = useState("");
  const [successMsg, setSuccessMsg] = React.useState({ success: 0, failed: 0 });
  const [showErrorGif, setShowErrorGif] = useState(true);
  const handleTabValue = (e, newValue) => {
    setTabValue(newValue);
  };
  const clearModal = () => {
    setTimeout(() => {
      setCurrentScreen("");
      toggleModal();
      setContractFile(null);
      setBiderFile(null);
      queryCache.invalidateQueries("archived-contracts");
    }, 9000);
  };

  const [mutateContract, { isLoading, isSuccess, isError }] = useMutation(contractsAPI.uploadArchiveContract, {
    onError: (e) => {
      showAlert({
        message: "failed to upload contracts",
        severity: "error",
      });
      try {
        if (e.message) {
          const csvArray = formatErrorToCSV(JSON.parse(e.message));
          saveFile(csvArray, "archived_contract_failed_upload");
        }
        setError(JSON.parse(e.message));
      } catch (error) {
        setError([`${e.message}`]);
      }
      setTimeout(() => setShowErrorGif(false), 1500);
    },
    onSuccess: (e) => {
      const formDataBidder = new FormData();
      // console.log({ e });
      if (e?.data?.failedEntries && e?.data?.failedEntries?.length > 0) {
        // console.log("dskdjnkdjnj");
        const csvArray = formatErrorToCsvModified(e?.data?.failedEntries);
        saveFile(csvArray, "archived_contract_failed_upload");
      }
      setSuccessMsg((prev) => ({
        ...prev,
        success: e?.data?.totalEntries,
        failed: e?.data?.totalFailedEntries,
      }));
      showAlert({
        severity: "success",
        durationInMs: 3000,
        message: e?.message,
      });
      if (biderFile) {
        mutateBidder(formDataBidder);
      } else {
        clearModal();
      }
    },
  });

  const formatErrorToCSV = (values = []) => {
    const CSV = [];
    values.forEach((el) => {
      CSV.push(`\n${el}`);
    });

    return CSV;
  };

  const formatErrorToCsvModified = (values = []) => {
    const CSV = [`ocid, failed upload message`];
    values.forEach((field) => {
      const { ocid, errorMessage } = field;

      CSV.push(`\n${ocid},${errorMessage}`);
    });

    return CSV;
  };
  const [mutateBidder, { isLoading: isLoadingBidder, isSuccess: isSuccessBidder, isError: isErrorBidder }] = useMutation(
    contractsAPI.uploadArchiveBidder,
    {
      onError: (e) => {
        showAlert({
          message: "failed to upload Bidder",
          severity: "error",
        });
        try {
          if (e.message) {
            const csvArray = formatErrorToCSV(JSON.parse(e.message));
            saveFile(csvArray, "bidder_failed_upload");
          }
          setError(JSON.parse(e.message));
        } catch (error) {
          // console.log({ e });
          setError([`${e.message}`]);
        }
        setTimeout(() => setShowErrorGif(false), 1500);
      },
      onSuccess: (e) => {
        // console.log({ e });
        if (e?.data?.failedEntries && e?.data?.failedEntries?.length > 0) {
          const csvArray = formatErrorToCsvModified(e?.data?.failedEntries);
          saveFile(csvArray, "bidder_failed_upload");
        }
        setSuccessMsg((prev) => ({
          ...prev,
          success: e?.data?.totalEntries,
          failed: e?.data?.totalFailedEntries,
        }));
        showAlert({
          severity: "success",
          durationInMs: 3000,
          message: e?.message,
        });
        clearModal();
      },
    }
  );

  useEffect(() => {
    const current =
      isLoading || isLoadingBidder
        ? "loading"
        : isSuccess || isSuccessBidder
        ? "success"
        : isError || isErrorBidder
        ? "error"
        : "";
    setCurrentScreen(current);
  }, [isLoading, isSuccess, isError, isLoadingBidder, isSuccessBidder, isErrorBidder]);

  const contentSwitch = (type) => {
    switch (type) {
      case "loading":
        return (
          <>
            <img alt="loading" src={LoadGif} width="100%" height="5%" />
            <Box textAlign="center">
              <CustomDialogTitle disableTypography>Checking Document...</CustomDialogTitle>
            </Box>
          </>
        );
      case "success":
        return (
          <>
            <Box style={{ margin: "0 auto", width: "50%" }} textAlign="center">
              {" "}
              <img alt="success" src={SuccessGif} width="50%" height="1.3%" />
            </Box>{" "}
            <Box textAlign="center">
              <div style={{ fontSize: "30px", color: "#000" }}>File Uploaded Successfully</div>
              <div style={{ fontSize: "18px", color: "#000" }}>
                Result: (Uploaded Entries: {successMsg.success}, Failed Entries: {successMsg.failed})
              </div>
              {successMsg.failed > 0 && (
                <div style={{ fontSize: "13px", color: "#000" }}>
                  Please check the "Upload Result File" downloaded for more details
                </div>
              )}
              {/* <CustomDialogTitle disableTypography>
                File {successMsg.success} Record Uploaded Successfully. Failed Record {successMsg.failed} Failed
              </CustomDialogTitle> */}
            </Box>
          </>
        );
      case "error":
        return (
          <>
            <Box display="flex" justifyContent="center" flexDirection="column" py={2} minWidth="550px">
              {showErrorGif && <img alt="error" src={ErrorGif} width="100%" />}
              <CustomErrorDialogContent>
                <Box maxHeight="40vh">
                  {error?.map((err) => (
                    <Alert style={{ marginBottom: "4px" }} severity="error">
                      <b>Error:</b> {err}
                    </Alert>
                  ))}
                </Box>
              </CustomErrorDialogContent>
            </Box>
            <Box mt={2} display="flex" justifyContent="center">
              <PrevBtn
                onClick={() => {
                  setCurrentScreen("");
                  toggleModal();
                  setContractFile(null);
                  setBiderFile(null);
                }}
              >
                Close
              </PrevBtn>
            </Box>
          </>
        );
      default:
        return (
          <>
            <TabContainer style={{ backgroundColor: "#fff", border: "1px solid #fff" }}>
              <PageTabs value={tabValue} onChange={handleTabValue}>
                <Tab
                  label="Tender/Contract"
                  classes={{ root: classes.root, wrapper: classes.tabWrapper }}
                  activetab={(tabValue === 0).toString()}
                />
                <Tab
                  label="Bidders"
                  classes={{ root: classes.root, wrapper: classes.tabWrapper }}
                  activetab={(tabValue === 1).toString()}
                />
              </PageTabs>
            </TabContainer>
            {tabValue === 0 && (
              <ContractUploadTab
                contractDropzone={contractDropzone}
                setContractFile={setContractFile}
                contractFile={contractFile}
              />
            )}
            {tabValue === 1 && (
              <BidderUploadTab contractDropzone={bidderDropzone} setContractFile={setBiderFile} contractFile={biderFile} />
            )}
            <Box>
              <SubmitButton
                onClick={handleContractUpload}
                style={{ marginRight: 16 }}
                disabled={![isLoading, !!contractFile, !!biderFile, isLoadingBidder].includes(true)}
              >
                {isLoading ? "Uploading..." : "Upload New"}
              </SubmitButton>
              <PrevBtn
                onClick={() => {
                  toggleModal();
                  setContractFile(null);
                  setBiderFile(null);
                }}
              >
                Cancel
              </PrevBtn>
            </Box>
          </>
        );
    }
  };

  const handleContractUpload = () => {
    const formDataContract = new FormData();
    const formDataBidder = new FormData();
    formDataContract.append("File", contractFile);
    formDataBidder.append("File", biderFile);

    if (contractFile) {
      mutateContract(formDataContract);
    } else {
      if (biderFile) mutateBidder(formDataBidder);
    }
  };

  function fileSizeValidator(file) {
    if (file.size > fileMaxSize) {
      return {
        code: "tooLarge",
        message: `File size is larger than ${fileMaxSize / 1000}kb`,
      };
    }

    return null;
  }

  const fileMaxSize = MaxFileSize;

  const contractDropzone = useDropzone({
    onDrop: (files) => {
      setContractFile(files[0]);
    },
    multiple: false,
    validator: fileSizeValidator,
  });

  const bidderDropzone = useDropzone({
    onDrop: (files) => {
      setBiderFile(files[0]);
    },
    multiple: false,
    validator: fileSizeValidator,
  });

  return (
    <div>
      <CustomDialog scroll="body" onClose={toggleModal} open={open}>
        <CustomDialogTitleWrapper>
          <CustomDialogTitle disableTypography>Upload Contracts</CustomDialogTitle>
        </CustomDialogTitleWrapper>
        {contentSwitch(currentScreen)}
      </CustomDialog>
    </div>
  );
};

export default UploadModal;
