import React from "react";
import { Drawer, DrawerTitle, DrawerForm, DrawerFormRow, DrawerActions, CancelButton, SaveButton } from "./style";
import { useForm } from "react-hook-form";
import { Dropdown, InputField } from "../../../../../components";
import InputAdornment from "@material-ui/core/InputAdornment";
import { ErrorContainer } from "../../../../../util/theme";
import { queryCache, useMutation, useQuery } from "react-query";
import adminAPI from "../../../../../redux/api/adminAPI";
import ToastContext from "../../../../../util/toastContext";

const typeToRolesMap = {
  1: ["Commissioner", "SSG", "Governor", "Executive"],
  2: ["Tender Board", "State Ministerial Tender Board"],
};

export default function AddPermissionDrawer({ show, onClose, data }) {
  const { register, handleSubmit, errors, reset } = useForm();
  const inputFieldProps = { style: { padding: 0, margin: 0 } };
  const { showAlert } = React.useContext(ToastContext);
  const [threshold, setThreshold] = React.useState(null);
  const [selectedRole, setSelectedRole] = React.useState(3);
  const [thresholdType, setThresholdType] = React.useState(1);
  const [selectedRoleLowerLimitThreshold, setSelectedRoleLowerLimitThreshold] = React.useState(0);
  const [selectedRoleUpperLimitThreshold, setSelectedRoleUpperLimitThreshold] = React.useState(0);

  const [createRoleThreshold, roleThresholdResp] = useMutation(adminAPI.createPermissionThreshold, {
    onSuccess() {
      showAlert({
        severity: "success",
        message: "Your threshold has been successfully created",
        durationInMs: 3000,
      });
      setThreshold(data);
      queryCache.invalidateQueries("thresholds-list");
      reset({});
      onClose();
    },
    onError(error) {
      showAlert({
        severity: "error",
        message: error.message,
        durationInMs: 3000,
      });
      queryCache.invalidateQueries("thresholds-list");
      onClose();
    },
  });

  const [updateRoleThreshold, updateRoleThresholdResp] = useMutation(adminAPI.updatePermissionThreshold, {
    onSuccess({ data }) {
      showAlert({
        severity: "success",
        message: "Your threshold has been successfully updated",
        durationInMs: 3000,
      });
      setThreshold(data);
      queryCache.invalidateQueries("thresholds-list");
      reset({});
      onClose();
    },
    onError(error) {
      showAlert({
        severity: "error",
        message: error.message,
        durationInMs: 3000,
      });
      queryCache.invalidateQueries("thresholds-list");
      onClose();
    },
  });

  const thresholds = useQuery(["rolesThreshold", selectedRole, thresholdType], adminAPI.getRoleThreshold, {
    onSuccess({ data }) {
      // queryCache.cancelQueries("rolesThreshold");
      if (data?.length) {
        // const limitObject = data?.find(v => v?.status?.toLowerCase() === "inactive")
        setThreshold(data[0]);
        setSelectedRoleLowerLimitThreshold(data[0]?.lowerLimitThreshold ?? 0);
        setSelectedRoleUpperLimitThreshold(data[0]?.upperLimitThreshold ?? 0);
      } else {
        setThreshold(null);
        setSelectedRoleLowerLimitThreshold(0);
        setSelectedRoleUpperLimitThreshold(0);
      }
    },
    onError(data) {
      if (!data.response) {
        setSelectedRoleLowerLimitThreshold(0);
        setSelectedRoleUpperLimitThreshold(0);
      }
    },
  });

  const submitHandler = (values) => {
    if (!threshold?.id) {
      const data = {
        ...values,
        type: thresholdType,
      };
      createRoleThreshold(data);
    } else {
      updateRoleThreshold({
        lowerLimitThreshold: values.lowerLimitThreshold,
        upperLimitThreshold: values.upperLimitThreshold,
        roleId: threshold?.id,
        type: thresholdType,
      });
    }
  };

  const handleOnChange = (e) => {
    setSelectedRole(e);
  };

  const handleSelectThreshold = (e) => {
    setThresholdType(e);
  };

  const maybeRenderError = (name) => {
    const error = errors[name];
    if (error) return <ErrorContainer>{error.message}</ErrorContainer>;
    else return null;
  };

  const isInvalid = (name) => !!errors[name];

  return (
    <Drawer anchor="right" open={show} onClose={onClose}>
      <DrawerTitle>Set Threshold</DrawerTitle>
      <DrawerForm onSubmit={handleSubmit(submitHandler)}>
        <DrawerFormRow>
          <Dropdown
            label="Type"
            name="type"
            onChange={handleSelectThreshold}
            initialState={thresholdType}
            values={[
              { label: "Tender approval", value: 1 },
              { label: "Vendor recommendation", value: 2 },
            ]}
            inputRef={register({
              required: "Please select type",
            })}
            {...inputFieldProps}
          />
          {maybeRenderError("type")}
        </DrawerFormRow>
        <DrawerFormRow>
          <Dropdown
            label="Role"
            name="roleId"
            onChange={handleOnChange}
            initialState={selectedRole}
            values={
              data &&
              [{ title: "Select Role", value: 1 }, ...data]
                ?.filter((x) => typeToRolesMap[thresholdType].includes(x.title))
                ?.map((eachVal) => ({ label: eachVal.title, value: eachVal.id }))
            }
            inputRef={register({
              required: "Please select a role",
              validate: (value) => value > 0 || "Please select a role",
            })}
            {...inputFieldProps}
          />
          {maybeRenderError("roleId")}
        </DrawerFormRow>
        <DrawerFormRow>
          <InputField
            type="number"
            label="Lower Limit Threshold"
            name="lowerLimitThreshold"
            key="lowerLimitThreshold"
            defaultValue={0}
            value={selectedRoleLowerLimitThreshold}
            setValue={setSelectedRoleLowerLimitThreshold}
            error={isInvalid("lowerLimitThreshold")}
            inputRef={register({
              required: "Please provide a lower limit threshold for this role",
            })}
            inputProps={{
              startAdornment: (
                <InputAdornment style={{ margin: "16px 0 0" }} position="start">
                  NGN
                </InputAdornment>
              ),
            }}
            {...inputFieldProps}
          />
          {maybeRenderError("lowerLimitThreshold")}
        </DrawerFormRow>
        <DrawerFormRow>
          <InputField
            type="number"
            label="Upper Limit Threshold"
            name="upperLimitThreshold"
            key="upperLimitThreshold"
            defaultValue={0}
            setValue={setSelectedRoleUpperLimitThreshold}
            value={selectedRoleUpperLimitThreshold}
            error={isInvalid("upperLimitThreshold")}
            inputRef={register({
              required: "Please provide a upper limit threshold for this role",
            })}
            inputProps={{
              startAdornment: (
                <InputAdornment style={{ margin: "16px 0 0" }} position="start">
                  NGN
                </InputAdornment>
              ),
            }}
            {...inputFieldProps}
          />
          {maybeRenderError("upperLimitThreshold")}
        </DrawerFormRow>

        <DrawerActions>
          <CancelButton
            disabled={thresholds.isLoading || roleThresholdResp.isLoading || updateRoleThresholdResp.isLoading}
            onClick={onClose}
          >
            Cancel
          </CancelButton>
          <SaveButton
            disabled={roleThresholdResp.isLoading || updateRoleThresholdResp.isLoading || thresholds.isLoading}
            type="submit"
          >
            {roleThresholdResp.isLoading || updateRoleThresholdResp.isLoading || thresholds.isLoading ? "Please wait..." : "Save"}
          </SaveButton>
        </DrawerActions>
      </DrawerForm>
    </Drawer>
  );
}
