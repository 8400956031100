import { useMutation, useQuery } from "react-query";
import handleApiError from "../../redux/api/handleApiError";
import procurementAPI from "../../redux/api/procurementAPI";

export const useFetchSupplementaryList = ({ params, setDataSource, showAlert }) => {
  const { data, isLoading, ...rest } = useQuery(
    [`useFetchSupplementaryList`, { params }],
    () => procurementAPI.getSupplementaryList({ params }),
    {
      onSuccess: (values) => {
        console.log({ values });
        if (setDataSource) {
          setDataSource(values?.data || []);
        }
      },
      onError: (err) => {
        if (showAlert) {
          showAlert({
            severity: "error",
            message: handleApiError(err),
          });
        }
      },
    }
  );

  return {
    supplementaryList: data?.data || [],
    fetchingSupplementarylist: isLoading,
    ...rest,
  };
};

export const useFetchSupplementaryByAppId = ({ annualProcurementPlanId, setDataSource, showAlert }) => {
  const { data, isLoading, ...rest } = useQuery(
    [`useFetchSupplementaryByAppId`, annualProcurementPlanId],
    () => procurementAPI.getSupplementaryByAppId({ id: annualProcurementPlanId }),
    {
      enabled: !!annualProcurementPlanId,
      onSuccess: (values) => {
        if (setDataSource) {
          setDataSource(values?.data || []);
        }
      },
      onError: (err) => {
        if (showAlert) {
          showAlert({
            severity: "error",
            message: handleApiError(err),
          });
        }
      },
    }
  );

  return {
    supplementaryAPPList: data?.data || [],
    fetchingSupplementaryAppList: isLoading,
    ...rest,
  };
};

export const useFetchSupplementaryDetail = ({ budgetId, showAlert }) => {
  const { data, isLoading, ...rest } = useQuery(
    [`useFetchSupplementaryDetail-${budgetId}`, budgetId],
    () => procurementAPI.getSupplementaryById({ id: budgetId }),
    {
      enabled: !!budgetId,
      onError: (err) => {
        if (showAlert) {
          showAlert({
            severity: "error",
            message: handleApiError(err),
          });
        }
      },
    }
  );

  return {
    supplementaryDetails: data?.data,
    fetchingSupplementaryDetails: isLoading,
    ...rest,
  };
};

export const useCreateSupplementaryBudget = ({ queryClient, setModal, setSuccess, showAlert }) => {
  const [mutate, { isLoading, ...rest }] = useMutation(
    ({ payload, id }) => procurementAPI.createSupplementaryBudget({ id, payload }),
    {
      onSuccess: (values) => {
        if (setModal) {
          setModal(null);
        }
        if (setSuccess) {
          setSuccess(true);
        }
        if (queryClient) {
          queryClient.invalidateQueries([`useFetchSupplementaryByAppId`, "useFetchSupplementaryList"]);
        }
      },
      onError: (err) => {
        if (showAlert) {
          showAlert({
            severity: "error",
            message: handleApiError(err),
          });
        }
      },
    }
  );

  return {
    createSupplementaryBudget: mutate,
    creatingSupplementary: isLoading,
    ...rest,
  };
};

export const useSupplementaryDecision = ({ queryClient, setModal, setSuccess, showAlert }) => {
  const [mutate, { isLoading, ...rest }] = useMutation(
    ({ status, budgetId }) => procurementAPI.updateSupplementaryStatusDecision({ budgetId, status }),
    {
      onSuccess: (values) => {
        if (setModal) {
          setModal(null);
        }
        if (setSuccess) {
          setSuccess(true);
        }
        if (queryClient) {
          queryClient.invalidateQueries([`useFetchSupplementaryByAppId`, "useFetchSupplementaryList"]);
        }
      },
      onError: (err) => {
        if (showAlert) {
          showAlert({
            severity: "error",
            message: handleApiError(err),
          });
        }
      },
    }
  );

  return {
    supplementaryStatus: mutate,
    updatingStatus: isLoading,
    ...rest,
  };
};
