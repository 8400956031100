import React from 'react'
import PropTypes from 'prop-types'
import { TextButton as MuiTextButton } from './style'

const TextButton = ({ variant, color, onClick, disabled, content, ...rest }) => {
  return (
    <MuiTextButton
      variant={variant}
      color={color}
      disabled={disabled}
      onClick={onClick}
      {...rest}
    >
      {content}
    </MuiTextButton>
  )
}

TextButton.propTypes = {
  variant: PropTypes.oneOf(['contained', "outlined", "text"]),
  color: PropTypes.string
}
TextButton.defaultProps = {
  color: "primary"
}

export default TextButton
