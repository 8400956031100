import React, { useState } from "react";
import { Box, Button } from "@material-ui/core";
import AddBidderModal from "../../AddBidderModal";
import SecondaryTable from "../SecondaryTable";

const BiddersTab = ({ refetch, data: { bidders = [] }, isPublic }) => {
  const [open, toggleModal] = useState(false);

  const secondaryTableHeader = [
    { id: "s/n", title: "#", width: 50, align: "alignCenter", indexed: true },
    { id: "companyName", title: "Company Name", grid: 3 },
    { id: "evaluationComment", title: "Evaluation Comment", grid: 3 },
    { id: "evaluationStatus", title: "Evaluation Status", grid: 5 },
  ];

  const tableBody = bidders.map((bidder) => ({
    companyName: bidder.companyName,
    evaluationComment: bidder.evaluationComment,
    evaluationStatus: bidder.evaluationStatus,
    id: bidder.id,
  }));

  return (
    <>
      {!isPublic && (
        <Box style={{ background: "transparent", padding: 15 }}>
          <Button variant="contained" color="primary" onClick={() => toggleModal(true)}>
            Add Bidder
          </Button>
          <AddBidderModal open={open} refetch={refetch} toggleModal={toggleModal} />
        </Box>
      )}
      <Box style={{ overflowX: "scroll" }}>
        <SecondaryTable tableBody={tableBody} refetch={refetch} tableHeader={secondaryTableHeader} />
      </Box>
    </>
  );
};

export default BiddersTab;
