import styled from "styled-components";
import { Link } from "react-router-dom";

export const CrumbLink = styled(Link)`
  text-decoration: none;
  color: #47c3f3;
  font-weight: 500;
  font-size: 12px !important;
`;

export const CurrentTab = styled("span")({
  display: "inline-block",
  color: "#47c3f3",
  fontWeight: 500,
  fontSize: "12px",
});
