import React from "react";
import PropTypes from "prop-types";
import { Alerts } from "../../../shared";
import { dashCapitaliseWord } from "../../../util/stringTranform";

const StatusAlert = ({ open, onClose, onAction, selected, loading, statusKey, reviewType }) => {
  const titleMapping = {
    rejected: `Reject ${dashCapitaliseWord(reviewType)}`,
    reviewed: `Review ${dashCapitaliseWord(reviewType)} `,
    endorsed: `Endorse ${dashCapitaliseWord(reviewType)} `,
    approved: `Approve ${dashCapitaliseWord(reviewType)} `,
  };

  const okTextMapping = {
    rejected: "Reject",
    reviewed: "Review",
    endorsed: "Endorsed",
    approved: "Approve",
  };

  // const severityMapping = {
  //   rejected: "Reject",
  //   reviewed: "Review",
  //   endorsed: "Endorsed",
  //   approved: "Approve",
  // }

  const okTextLoadingMapping = {
    rejected: "Rejecting",
    reviewed: "Reviewing....",
    endorsed: "Endorsing...",
    approved: "Approving...",
  };

  const bodyMapping = {
    rejected: `Are you sure you want to ${okTextMapping[statusKey]} this request`,
    reviewed: `Are you sure you want to ${okTextMapping[statusKey]} this request`,
    endorsed: `Are you sure you want to ${okTextMapping[statusKey]} this request`,
    approved: `Are you sure you want to ${okTextMapping[statusKey]} this request`,
  };
  return (
    <Alerts
      open={open}
      onSecAction={onClose}
      onAction={() => onAction({ ...selected })}
      title={titleMapping[statusKey]}
      content={bodyMapping[statusKey]}
      isSecAction
      actionText={loading ? okTextLoadingMapping[statusKey] : okTextMapping[statusKey]}
    />
  );
};

StatusAlert.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default StatusAlert;
