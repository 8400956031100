import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { InputField } from "../../../../../shared";
import { CancelButton, UpdateButton } from "../../../Administration/Settings/style";
import { ErrorContainer } from "../../../../../util/theme";
import NumberFormat from "react-number-format";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DateRangeWrapper } from "../../../../../components/DateRangePIcker/style";
import DateFnsUtils from "@date-io/date-fns";
import { useParams } from "react-router-dom";
import { contractAwardedDefaultValues, contractAwardedResolver } from "./validator";

const EditAwardedCompanyTab = ({ data, mutate, isLoading, handleEdit }) => {
  const { errors, reset, control, handleSubmit } = useForm({
    defaultValues: contractAwardedDefaultValues,
    resolver: contractAwardedResolver,
  });
  const params = useParams();

  useEffect(() => {
    let payload = {};

    if (data) {
      payload.awardedDate = data?.awardedDate || new Date();
      payload.projectDecription = data?.description || data?.title || "";
      payload.projectName = data?.title || "";
      payload.budgetDescription = data?.budgetDescription || "";
      payload.awardCriteria = data?.awardCriteria || "";
      payload.contractSum = data?.contractSum || 0;
      payload.contractor = data?.contractor || "";
    }
    reset(payload);
  }, [reset, data]);

  const onSubmit = (e) => {
    const payload = {
      awardDate: new Date(e.awardedDate),
      awardCriteria: e.awardCriteria,
    };
    mutate({ payload, archiveId: params.id, type: "award" });
  };
  const onRenderError = (error) => <ErrorContainer>{error && error.message}</ErrorContainer>;
  return (
    <div>
      <div>
        <DateRangeWrapper width={{ root: "97%" }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              control={control}
              name="awardedDate"
              render={(props) => (
                <KeyboardDatePicker
                  label="Date of Award"
                  disableToolbar
                  openTo="year"
                  views={["year", "month", "date"]}
                  variant="inline"
                  format="dd-MMM-yyyy"
                  placeholder="MM/DD/YYYY"
                  margin="normal"
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...props}
                />
              )}
            />
          </MuiPickersUtilsProvider>
        </DateRangeWrapper>

        {onRenderError(errors.awardDate)}
      </div>
      <div>
        <Controller
          control={control}
          name="projectName"
          render={({ ref, ...props }) => (
            <InputField
              disabled
              label="Project Title"
              // inputRef={register({
              //   required: "Project TItle is required",
              // })}
              inputRef={ref}
              name="projectName"
              style={{ background: "#ffffff" }}
              {...props}
            />
          )}
        />
      </div>
      <div>
        <Controller
          control={control}
          name="projectDecription"
          render={({ ref, ...props }) => (
            <InputField
              disabled
              label="Project Desciption"
              // inputRef={register({
              //   required: "Project TItle is required",
              // })}
              inputRef={ref}
              // name="projectDescription"
              style={{ background: "#ffffff" }}
              multiline
              {...props}
            />
          )}
        />{" "}
      </div>
      <div>
        <Controller
          control={control}
          name="budgetDescription"
          render={({ ref, ...props }) => (
            <InputField
              disabled
              label="Budget Description"
              // inputRef={register({
              //   required: "Project TItle is required",
              // })}
              inputRef={ref}
              multiline
              style={{ background: "#ffffff" }}
              {...props}
            />
          )}
        />{" "}
        {onRenderError(errors.budgetDescription)}
      </div>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        <div>
          <Controller
            control={control}
            name="awardCriteria"
            render={({ ref, ...props }) => (
              <InputField
                label="Award Remark/Criteria"
                // inputRef={register({})}
                inputRef={ref}
                name="budgetDescription"
                style={{ background: "#ffffff" }}
                {...props}
              />
            )}
          />{" "}
          {onRenderError(errors.budgetDescription)}
        </div>
        <div style={{ background: "#ffffff" }}>
          <Controller
            control={control}
            name="contractSum"
            render={(props) => (
              <NumberFormat
                customInput={InputField}
                label="Contract Sum"
                thousandSeparator
                prefix="₦"
                variant="outlined"
                disabled
                {...props}
              />
            )}
          />{" "}
        </div>
      </div>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        <div>
          <Controller
            control={control}
            name="contractor"
            render={({ ref, ...props }) => (
              <InputField
                disabled
                label="Contractor"
                // inputRef={register({
                //   required: "Project TItle is required",
                // })}
                inputRef={ref}
                style={{ background: "#ffffff" }}
                {...props}
              />
            )}
          />{" "}
          {onRenderError(errors.contractor)}
        </div>
      </div>
      <div style={{ display: "grid", gridTemplateColumns: "150px 1fr", placeItems: "center start", marginTop: "30px" }}>
        <UpdateButton onClick={handleSubmit(onSubmit)}>{isLoading ? "Saving Changes..." : "Save Changes"}</UpdateButton>
        <CancelButton onClick={handleEdit}>Cancel</CancelButton>
      </div>
    </div>
  );
};

EditAwardedCompanyTab.propTypes = {};

export default EditAwardedCompanyTab;
