import React, { forwardRef, memo, useEffect, useImperativeHandle, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Grid from "@material-ui/core/Grid";
import TableRow from "@material-ui/core/TableRow";
import { useParams } from "react-router-dom";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import Paper from "@material-ui/core/Paper";
import { useMutation, useQuery } from "react-query";

import Dropdown from "../../../../../components/DropdownField";
import SearchInputField from "../../../../../components/SearchInputField";
import { SearchButton } from "../../../../../components/Forms/Common/style";
import Loader from "../../../../../components/Common/Loader";
import {
  DatasheetTableContainer,
  RenderOptionStyle,
  SearchSection,
  TabContent,
  TabContentBox,
  TabContentTitle
} from "./style";
import procurementAPI from "../../../../../redux/api/procurementAPI";
import EmptyTableBody from "./EmptyTableBody";
import { EmptyTableSubTitle } from "../../../Contracts/ContractTable/style";

const DatasheetTab = forwardRef(({ setTabIndex, showAlert, isChecker, isDisabled, isMaker }, ref) => {
  const [searchBy, setSearchBy] = useState("name");
  const [searchValue, setSearchValue] = useState("");
  const [appendedVendors, setAppendedVendors] = useState([]);
  const [interestedVendors, setInterestedVendors] = useState([]);
  const [makeRequest, setMakeRequest] = useState(false);
  const [removedVendor, setRemovedVendor] = useState([]);
  const { procurementId, activityId } = useParams();


  const [bidEvaluationWorkflow] = useMutation(procurementAPI.bidEvaluationWorkflow, {
    onSuccess: () => {
      showAlert({
        severity: "success",
        message: "Successful",
      });
      setTabIndex(2)
    },
    onError: (error) => {
      showAlert({ severity: "error", message: error.message });
    },
  });

  const { status, data, status : vendorStatus } = useQuery(["getAllInterestedVendors", procurementId], procurementAPI.getAllInterestedVendors)

  useEffect(() => {
    if(vendorStatus === "success"){
      setAppendedVendors(data)
    }
  }, [vendorStatus, data])



  const searchResult = useQuery(
    ["searchVendors", { RegisterId: searchBy === "id" ? searchValue : null, name: searchBy === "name" ? searchValue : null }],
    procurementAPI.searchVendors,
    {
      enabled: makeRequest,
    }
  );
  useEffect(() => {
    if (searchResult.isSuccess || searchResult.isError) {
      setMakeRequest(false)
    }
  }, [searchResult.isSuccess, searchResult.isError])

  const handleChangeSearchValue = (e, inputValue) => {
    if (inputValue) setSearchValue(inputValue);
  };

  const appendVendorToInterestedBidders = (vendor) => {
    const vendorIndex = appendedVendors.findIndex((el) => el.id === vendor.id);
    if (vendorIndex === -1) {
      // const newAppendedVendors = [...appendedVendors];
      // newAppendedVendors.unshift(vendor);
      setAppendedVendors((prevState) => [...prevState, vendor]);
    }
  };

  const handleClickSearch = () => setMakeRequest(true);

  const removeInterestedVendor = (vendor) => {
    const newAppendedVendors = [...appendedVendors];
    const newInterestedVendors = [...interestedVendors];

    const vendorIndex = appendedVendors.findIndex((el) => el.id === vendor.id);
    const interestedVendorIndex = interestedVendors.findIndex((el) => el.userId === vendor.userId);

    if (vendorIndex !== -1) {
      newAppendedVendors.splice(vendorIndex, 1);
      setAppendedVendors(newAppendedVendors);
    } else if (interestedVendorIndex !== -1) {
      newInterestedVendors.splice(interestedVendorIndex, 1);
      setRemovedVendor((prevState) => [...prevState, vendor]);
      setInterestedVendors(newInterestedVendors);
    }
  };

  const renderTableLoading = () =>
    status === "loading" ?  (
      <Loader />
    ) : data.length === 0 && appendedVendors.length === 0 ? (
      <EmptyTableBody>
        <EmptyTableSubTitle>There are currently no vendors</EmptyTableSubTitle>
      </EmptyTableBody>
    ) : null;

  const renderOption = (option) => (
    <RenderOptionStyle
      style={{ borderBottom: "1px solid #E4E9EC", width: "100%", padding: "5px" }}
      onClick={() => appendVendorToInterestedBidders(option)}
    >
      {`${option.companyName}`} <span> - {option.cacRegistrationNumber}</span>
    </RenderOptionStyle>
  );

  useImperativeHandle(ref, () => ({ 
    async handleSubmit() {
      if (isChecker){
        setTabIndex(2)
    }
    else{
      bidEvaluationWorkflow({
        data: {
          addedVendors: [...interestedVendors.map(a => a.userId), ...appendedVendors.map(a => a.userId)],
          removedVendors: [...removedVendor]
        },
        procurementPlanActivityId: activityId
      })
     }
    },
    goBack() {
      setTabIndex(0)
    }
  }));

  return (
    <TabContent>
      <TabContentTitle>Select/Add Suppliers, Firms, or Individuals</TabContentTitle>
      <TabContentBox>
        <SearchSection>
          <Grid container justify="space-between">
          <Grid item xs={2}>
            <Dropdown
              name="search-by"
              label="Search by"
              onChange={setSearchBy}
              value={searchBy}
              defaultValue={undefined}
              values={[
                { label: "Registered ID", value: "id" },
                { label: "Name", value: "name" },
              ]}
              disabled={isDisabled}
            />
          </Grid>
          <Grid item xs={7} >
            <SearchInputField
              type="text"
              label={`Search by ${searchBy === "id" ? "Registered ID" : "Name"}`}
              required
              options={searchResult.data?.data?.data || []}
              searchValue={searchValue}
              // getOptionLabel={(option) => {
              //   return searchBy === "id" ? option.id : `${option.firstName} ${option.firstName}`;
              // }}
              renderOption={renderOption}
              onInputChange={handleChangeSearchValue}
              isLoading={searchResult.isLoading}
              disabled={isDisabled}
            />
          </Grid>
          <Grid item xs={2}>
            <SearchButton onClick={handleClickSearch} loading={searchResult.isLoading} disabled={isDisabled}>
              Search
            </SearchButton>
          </Grid>
          </Grid>
        </SearchSection>
      </TabContentBox>
      <DatasheetTableContainer component={Paper}>
        {renderTableLoading()}

        <Table aria-label="datasheet table">
          <TableBody>
            {appendedVendors.map((row) => (
              <TableRow key={row.id}>
                <TableCell className="name-id-col">
                  {`${!!row.companyName ? row.companyName : row.vendorProfile.companyName}`}
                  <span>
                    {" "}
                    / {!!row.cacRegistrationNumber ? row.cacRegistrationNumber : row.vendorProfile.cacRegistrationNumber}
                  </span>
                </TableCell>
                {isMaker && (
                <TableCell className="action-col">
                  <IconButton size="small" onClick={() => removeInterestedVendor(row)}>
                    <DeleteOutlinedIcon />
                  </IconButton>
                </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DatasheetTableContainer>
    </TabContent>
  );
});

export default memo(DatasheetTab);
