import React from "react";
import { Button, Box } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { CustomDialogContent } from "../../../components/Forms/ConfirmVerificationModal/style";
import uploadTemplate from "../../../assets/bidder_upload_format_upload.csv";
import { DropzoneArea } from "../../../components/DocumentUploadField/style";
import FileProgress from "../../../components/FileProgress/FileProgress";

function BidderUploadTab({ contractFile, setContractFile, contractDropzone }) {
  return (
    <div>
      <CustomDialogContent>
        <Box>
          <Button variant="text" color="primary" style={{ textTransform: "none" }}>
            <a
              style={{ textDecoration: "none", color: "#445571" }}
              href={uploadTemplate}
              download="bidder_upload_format_upload.csv"
            >
              <b>Download sample file here</b>
            </a>
          </Button>
        </Box>
        <Alert severity="info">
          <b>Info:</b> You agree to uploaded the contract detail of the corresponding bidder and have thoroughly gone through the
          draft and agree that the information are valid and correct?
        </Alert>
        <Box mt={1} mb={2}>
          {contractFile ? (
            <FileProgress
              progress={100}
              fileName={contractFile.name}
              fileSize={contractFile.size}
              hasCancel
              onCancel={() => setContractFile(null)}
            />
          ) : (
            <DropzoneArea {...contractDropzone.getRootProps()}>
              <input aria-labelledby="upload file" {...contractDropzone.getInputProps()} />
              <div>
                <p>Upload or drop file here</p>
                <span style={{ fontSize: ".9em" }}>{"File size should not exceed 5,000kb (5mb)"}</span>
              </div>
            </DropzoneArea>
          )}
        </Box>
      </CustomDialogContent>
    </div>
  );
}

BidderUploadTab.propTypes = {};

export default BidderUploadTab;

// archive_upload_template
