import React from "react";
import PropTypes from "prop-types";
import { HoverTableStyle } from "../../../util/theme";
import EmptyTableBody from "../../../components/EmptyTableBody";
import { EmptyTableSubTitle } from "../../../components/EmptyTableBody/style";
import { ReusableButton, TableChip } from "../../../shared";
import { StyledTableCell } from "./SupplementaryTable";
import { capitalize } from "lodash";
import { format } from "date-fns";
import { formatCurrency } from "../../../util/formatCurrency";
import { supplementChipMapping } from "../../../util/constants";
import MenuDropDown from "./MenuDropDown";
import { dashCapitaliseWord } from "../../../util/stringTranform";

const TableBody = ({ dataSource, onMenuClick }) => {
  return dataSource?.length > 0 ? (
    dataSource?.map((row, i) => (
      <HoverTableStyle key={`${row.id}_${i}`}>
        <StyledTableCell>{row?.dateRequested ? format(new Date(row?.dateRequested), "dd/MMM/yyyy") : "---"}</StyledTableCell>
        <StyledTableCell>{dashCapitaliseWord(row?.type || "")}</StyledTableCell>
        <StyledTableCell>{formatCurrency(row?.approvedAmount, true, true)}</StyledTableCell>
        <StyledTableCell>{formatCurrency(row?.amountRequested, true, true)}</StyledTableCell>
        <StyledTableCell>
          <TableChip text={capitalize(row.status || "")} {...supplementChipMapping[row.status.toLowerCase() || "pending"]} />
        </StyledTableCell>
        <StyledTableCell>
          <MenuDropDown data={row} menuActionClick={onMenuClick} />
        </StyledTableCell>
      </HoverTableStyle>
    ))
  ) : (
    <EmptyTableBody>
      <EmptyTableSubTitle>Not Supplementary Budget</EmptyTableSubTitle>
      <span style={{ width: "60%", marginBottom: "12px", fontSize: "17px" }}>
        You currently have no supplementary list. Click the button below to start.
      </span>
      <ReusableButton label="Request Upward Review" />
    </EmptyTableBody>
  );
};

TableBody.propTypes = {
  dataSource: PropTypes.array.isRequired,
  onMenuClick: PropTypes.func,
};

TableBody.defaultProps = {
  onMenuClick: () => {},
};

export default TableBody;
