import React from "react";
import { Grid, Box } from "@material-ui/core";

import { ContractDetailTabText } from "./style";
import { dateFormat } from "../../../../util/formatDate";

const TenderDetailsTab = ({ data: { tender, ...otherDetails } = {} }) => {
  const details = [
    { title: "Project Title	", value: otherDetails?.title },
    {
      title: "Tender Date	",
      value: dateFormat(otherDetails?.bidSolicitationDate),
    },
    { title: "Tender Status	", value: tender?.tenderStatus || "--" },
    { title: "Package Number	", value: tender?.packageNumber || "--" },
    { title: "Procurement Method	", value: tender?.procurementMethod || "--" },

    { title: "Number of Tenderers	", value: tender?.numberOfTenderers ?? "--" },
    { title: "Tendering Stage	", value: tender?.tenderingStage || "--" },
    { title: "Review Method	", value: tender?.reviewMethod || "--" },
    {
      title: "Bid Opening Date		",
      value: dateFormat(otherDetails?.bidOpeningDate),
    },
  ];

  return (
    <>
      <Box>
        {details.map((detail, i) => (
          <Grid key={i} style={{ borderBottom: "1px solid #CBD4DA" }} container>
            <Grid item xs={3} style={{ background: "#e4e9ec" }}>
              <ContractDetailTabText style={{ background: "#e4e9ec", boxShadow: "0px -1px 0px #C9D4DB" }}>
                {detail.title}
              </ContractDetailTabText>
            </Grid>
            <Grid item sm style={{ borderLeft: "1px solid #CBD4DA" }}>
              <ContractDetailTabText>{detail.value}</ContractDetailTabText>
            </Grid>
          </Grid>
        ))}
      </Box>
    </>
  );
};

export default TenderDetailsTab;
