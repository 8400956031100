import React, { useEffect } from "react";
import { Container, Box, Button, SvgIcon } from "@material-ui/core";
import { useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import { TenderTitle } from "./style";
// import { BreadcrumbActive, BreadcrumbLabel } from "../TenderList/style";
import { BreadcrumbActive, BreadcrumbLabel } from "../../../Homepage/TenderList/style";
import Loader from "../../../../components/Common/Loader";
import { getArchiveContractDetails } from "../../../../redux/reducer/adminReducer";
import ContractDetailsCardTable from "./ContractDetailsCardTable";

import { ReactComponent as CodeSvg } from "../../../../assets/code.svg";
import { ReactComponent as PrintPdf } from "../../../../assets/print.svg";
import withAdminDashboard from "../../HOC/withAdminDashboard";
import { GeneralTitlePage } from "../../Procurement/Activitiies/SpecificProcurementNotice/style";
import { useRoles } from "../../../../hooks/useUserDetails";
import { Roles } from "../../../../components/AccessControl/UserRoles";

const OcdsContractDetails = () => {
  const classes = useStyles();
  const { role } = useRoles();
  const isAdmin = role === Roles.DSPPC;
  const [isEdit, setIsEdit] = React.useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const { archiveContractDetails: adminContractDetails } = useSelector((state) => state.admin);
  const linkStyle = { textDecoration: "none", color: "#61AFFE" };
  const contractInfo = adminContractDetails.contractDetails;

  useEffect(() => {
    dispatch(getArchiveContractDetails(id));
  }, [dispatch, id]);

  const handleIsEdit = () => {
    if (isAdmin) {
      setIsEdit(true);
    }
  };

  const handleEditToFalse = () => {
    setIsEdit(false);
  };

  const handleDownloadJson = () => {
    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(adminContractDetails));
    var downloadAnchorNode = document.createElement("a");
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", `awarded-contract-detail-${Date.now()}.json`);
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  };
  return (
    <div>
      <Container maxWidth="130%" style={{ marginBottom: 100 }}>
        <div style={{ marginTop: "2rem" }}>
          {" "}
          <GeneralTitlePage> {`  ${adminContractDetails?.ocidNumber?.toUpperCase() || ""}`}</GeneralTitlePage>
        </div>{" "}
        <div style={{ display: "grid", gridTemplateColumns: "1fr auto", placeItems: "center start" }}>
          <BreadcrumbLabel>
            {/* <Link style={linkStyle} to="/admin/dashboard/overview">
              Overview
            </Link> */}
            <BreadcrumbActive>
              <Link style={linkStyle} to="/admin/OCDS/archive">
                {`Overview  `}
              </Link>
              /
              <Link style={linkStyle} to="/admin/OCDS/archive">
                {`  Archive Contracts  `}
              </Link>
              /
              <Link style={linkStyle} to="/admin/OCDS/archive">
                {`  ${adminContractDetails?.ocidNumber?.toUpperCase() || ""}`}
              </Link>
            </BreadcrumbActive>
          </BreadcrumbLabel>
          <div>
            {!isEdit && isAdmin && (
              <Button variant="contained" color="primary" onClick={handleIsEdit}>
                Edit
              </Button>
            )}
          </div>
        </div>
        {Object.keys(adminContractDetails).length < 1 ? (
          <Loader />
        ) : (
          <>
            <TenderTitle>{contractInfo?.contractName}</TenderTitle>
            {!isEdit && (
              <Box my={3}>
                <Box display="flex">
                  <Box mr={2}>
                    <Button
                      classes={{ startIcon: classes.btnStartIcon, root: classes.downLoadBtn }}
                      startIcon={<SvgIcon component={CodeSvg} style={{ color: "#fff", height: 30, width: 30, margin: 0 }} />}
                      onClick={handleDownloadJson}
                    >
                      Download JSON
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      classes={{ startIcon: classes.btnStartIcon, root: classes.downLoadBtn }}
                      startIcon={<SvgIcon component={PrintPdf} style={{ color: "#fff", height: 30, width: 30, margin: 0 }} />}
                      onClick={() => window.print()}
                    >
                      Print
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}
            <ContractDetailsCardTable handleEdit={handleEditToFalse} isEdit={isEdit} isAdmin={isAdmin} />
          </>
        )}
      </Container>
    </div>
  );
};

OcdsContractDetails.propTypes = {};

export default withAdminDashboard(OcdsContractDetails);

const useStyles = makeStyles((theme) => ({
  btnStartIcon: {
    margin: 0,
  },
  downLoadBtn: {
    border: "1px solid #E5E5EA",
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    textTransform: "none",
  },
}));
