import React, { useContext, useState } from "react";
import { Tab, makeStyles } from "@material-ui/core";

import { CardContentWrapper, PageTabs } from "../../../../util/theme";
import { PlanCardContainer, TabContainer } from "../../../Admin/Procurement/Activitiies/SpecificProcurementNotice/style";
import ContractDetailTab from "./ContractDetailTab";
import TenderDetailsTab from "./TenderDetailsTab";
import AwardedCompanyTab from "./AwardedCompanyTab";
import ContactBiddersTab from "./ContactBiddersTab";
import EvaluatorsTab from "./EvaluatorsTab";
import EditContactBiddersTab from "./EditTab/EditContactBiddersTab";
import EditTenderDetailsTab from "./EditTab/EditTenderDetailsTab";
import EditBiddersTab from "./EditTab/EditBiddersTab";
import EditAwardedCompanyTab from "./EditTab/EditAwardedCompanyTab";
import EditEvaluatorsTab from "./EditTab/EditEvaluatorsTab";
import EditContractDetailTab from "./EditTab/EditContractDetailTab";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import contractsAPI from "../../../../redux/api/contractAPI";
import ToastContext from "../../../../util/toastContext";

const useStyles = makeStyles({
  tabWrapper: {
    textTransform: "none",
  },
  root: {
    minWidth: 90,
  },
});

const ContractDetailsCardTable = ({ isEdit, handleEdit, isAdmin, isPublic }) => {
  const [value, setValue] = useState(0);
  const classes = useStyles();
  const params = useParams();
  const { showAlert } = useContext(ToastContext);
  const { data: singleContract, refetch } = useQuery(
    ["getSingleContractQuery", { id: params.id }],
    contractsAPI.getSingleContractQuery,
    {
      enabled: !!params.id,
    }
  );

  // const [mutate, { isLoading: updatingContract }] = useMutation(contractsAPI.updateArchivedContract, {
  //   onSuccess: () => {
  //     showAlert({
  //       message: "Successfully updated contract",
  //       severity: "success",
  //     });
  //     refetch();
  //     handleEdit();
  //   },
  // });

  const [mutate, { isLoading: updatingContract }] = useMutation(contractsAPI.updateArchiveContractType, {
    onSuccess: () => {
      showAlert({
        message: "Successfully updated contract",
        severity: "success",
      });
      refetch();
      handleEdit();
    },
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <CardContentWrapper>
      <PlanCardContainer>
        <TabContainer>
          <PageTabs value={value} onChange={handleChange}>
            <Tab
              label="Planning"
              classes={{ root: classes.root, wrapper: classes.tabWrapper }}
              activetab={(value === 0).toString()}
            />
            <Tab
              label="Tender"
              classes={{ root: classes.root, wrapper: classes.tabWrapper }}
              activetab={(value === 1).toString()}
            />
            <Tab
              label="Bidders"
              classes={{ root: classes.root, wrapper: classes.tabWrapper }}
              activetab={(value === 2).toString()}
            />
            <Tab
              label="Award"
              classes={{ root: classes.root, wrapper: classes.tabWrapper }}
              activetab={(value === 3).toString()}
            />
            <Tab
              label="Contract"
              classes={{ root: classes.root, wrapper: classes.tabWrapper }}
              activetab={(value === 4).toString()}
            />
            <Tab
              label="Implementation"
              classes={{ root: classes.root, wrapper: classes.tabWrapper }}
              activetab={(value === 5).toString()}
            />
          </PageTabs>
        </TabContainer>
        {isEdit && isAdmin && (
          <>
            {value === 0 && (
              <EditContractDetailTab
                mutate={mutate}
                isLoading={updatingContract}
                data={singleContract}
                handleEdit={handleEdit}
                refetch={refetch}
              />
            )}
            {value === 1 && (
              <EditTenderDetailsTab
                handleEdit={handleEdit}
                data={singleContract}
                mutate={mutate}
                isLoading={updatingContract}
                refetch={refetch}
              />
            )}
            {value === 2 && (
              <EditBiddersTab
                handleEdit={handleEdit}
                data={singleContract}
                mutate={mutate}
                isLoading={updatingContract}
                refetch={refetch}
              />
            )}
            {value === 3 && (
              <EditAwardedCompanyTab
                handleEdit={handleEdit}
                data={singleContract}
                mutate={mutate}
                isLoading={updatingContract}
                refetch={refetch}
              />
            )}
            {value === 4 && (
              <EditContactBiddersTab
                handleEdit={handleEdit}
                data={singleContract}
                mutate={mutate}
                isLoading={updatingContract}
                refetch={refetch}
              />
            )}
            {value === 5 && (
              <EditEvaluatorsTab
                handleEdit={handleEdit}
                data={singleContract}
                mutate={mutate}
                isLoading={updatingContract}
                refetch={refetch}
              />
            )}
          </>
        )}
        {!isEdit && (
          <>
            {value === 0 && <ContractDetailTab data={singleContract} refetch={refetch} />}
            {value === 1 && <TenderDetailsTab data={singleContract} refetch={refetch} />}
            {value === 2 && <EditBiddersTab data={singleContract} refetch={refetch} isPublic={isPublic} />}
            {value === 3 && <AwardedCompanyTab data={singleContract} refetch={refetch} />}
            {value === 4 && <ContactBiddersTab data={singleContract} refetch={refetch} />}
            {value === 5 && <EvaluatorsTab data={singleContract} refetch={refetch} />}
          </>
        )}
      </PlanCardContainer>
    </CardContentWrapper>
  );
};

export default ContractDetailsCardTable;
