import { Roles } from "../../../components/AccessControl/UserRoles"

const createData = ({
  requestedDate,
  approvedAmount,
  amountRequested,
  status,
  stage,
  id
}) => ({
  requestedDate,
  approvedAmount,
  amountRequested,
  status,
  stage,
  id
})

export const supplementaryListData = [
  createData({
    requestedDate: new Date(),
    approvedAmount: 300000,
    amountRequested: 40000,
    status: "Endorsed",
    stage: Roles.PROCUREMENT_OFFICER,
    id: 1
  }),
  createData({
    requestedDate: new Date(),
    approvedAmount: 300000,
    amountRequested: 40000,
    status: "Pending",
    stage: Roles.DSPPC,
    id: 2
  }),
  createData({
    requestedDate: new Date(),
    approvedAmount: 300000,
    amountRequested: 40000,
    status: "Endorsed",
    stage: Roles.PROCUREMENT_OFFICER,
    id: 3
  }),
  createData({
    requestedDate: new Date(),
    approvedAmount: 300000,
    amountRequested: 40000,
    status: "Approved",
    stage: Roles.COMMISSIONER,
    id: 4
  }),
  createData({
    requestedDate: new Date(),
    approvedAmount: 300000,
    amountRequested: 40000,
    status: "Endorsed",
    stage: Roles.PROCUREMENT_OFFICER,
    id: 6
  }),
  createData({
    requestedDate: new Date(),
    approvedAmount: 300000,
    amountRequested: 40000,
    status: "Endorsed",
    stage: Roles.GOVERNOR,
    id: 8
  }),
]
