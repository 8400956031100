import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export const contractDetailDefaultProps = {
  budgetYear: 0,
  procuringEntity: "",
  procurementPlan: 0,
  budgetAmount: 0,
  procurementCategory: "",
  bidSolicitationDate: null,
  bidOpeningDate: null,
  projectDescription: "",
  budgetDescription: "",
  projectName: "",
  startTenderPeriod: null,
  endTenderPeriod: null,
};

export const contractDetailSchema = yup.object({
  budgetYear: yup.date().required("Budget Year is required"),
  procuringEntity: yup.string().required("MDA is required"),
  procurementPlan: yup.string().required(),
  budgetAmount: yup.number(),
  procurementCategory: yup.string().required("Procurement Category is required"),
  bidSolicitationDate: yup.date().required("Bid solicitation date is required"),
  bidOpeningDate: yup.date().required("Bid openning date is required"),
  projectDescription: yup.string().required("Project description is required"),
  budgetDescription: yup.string().nullable(),
  projectName: yup.string().nullable(),
  startTenderPeriod: yup.date().nullable(),
  endTenderPeriod: yup.date().nullable(),
});

export const contractDetailResolver = yupResolver(contractDetailSchema);

export const contractTenderDefaultProps = {
  tenderStatus: "",
  packageNumber: "",
  procurementMethod: "",
  tenderingStage: "",
  reviewMethod: "",
  bidOpeningDate: null,
  projectName: "",
  numberOfTenderers: 0,
  tenderDate: null,
};

export const contractTenderSchema = yup.object({
  tenderStatus: yup.string().required("Tender Status is required"),
  packageNumber: yup.string().required("Package Number is required"),
  procurementMethod: yup.string().required("Procurement method is required"),
  tenderingStage: yup.string().required("Tendering Stage is required"),
  reviewMethod: yup.string().required("Review method is required"),
  bidOpeningDate: yup.date().required("Bid Opening Date is required"),
  projectName: yup.string().nullable(),
  numberOfTenderers: yup.number().nullable(),
  tenderDate: yup.date().required("Tender Date is required"),
});

export const contractTenderResolver = yupResolver(contractTenderSchema);

export const contractContractDefaultProps = {
  contractorPhone: "",
  contractorAddress: "",
  contractorEmail: "",
  contractStatus: "",
  awardSigningDate: null,
  duration: 0,
  contractStartDate: null,
  contractEndDate: null,
  projectName: "",
  projectDescription: "",
  contractor: "",
  durationType: "",
};

export const contractContractSchema = yup.object({
  contractorPhone: yup.string().required("contractor Phone number is required"),
  contractorAddress: yup.string().required("contractor Address is required"),
  contractorEmail: yup.string().email().required("contractor Email is required"),
  contractStatus: yup.string().required("Contract Status is required"),
  awardSigningDate: yup.date().required("Award Signing Date is required"),
  duration: yup.string().required("Duration is required"),
  contractStartDate: yup.date().required("Contract Start Date is required"),
  contractEndDate: yup.date().required("Contract End Date is required"),
  projectName: yup.string().nullable(),
  projectDescription: yup.string().nullable(),
  contractor: yup.string().nullable(),
  durationType: yup.string().nullable(),
});

export const contracContractResolver = yupResolver(contractContractSchema);

export const contractImplementationDefaultValues = {
  revisedContractAmount: 0,
  percentageCompletion: 0,
  projectStatus: 0,
  contractVariation: 0,
  contractSum: 0,
};

export const contractImplementationSchema = yup.object({
  revisedContractAmount: yup.number().required("Revised contract Amount is required"),
  percentageCompletion: yup.number().required("Percentage Completion is required"),
  projectStatus: yup.number().required("Project Status is required"),
  contractVariation: yup.number().required("Contract Variation is required"),
  contractSum: yup.number().required("Contract Sum is required"),
});

export const contractImplementationResolver = yupResolver(contractImplementationSchema);

export const contractAwardedDefaultValues = {
  awardedDate: null,
  awardCriteria: "",
  projectName: "",
  projectDecription: "",
  budgetDescription: "",
  contractSum: 0,
  contractor: "",
};

export const contractAwardedSchema = yup.object({
  awardedDate: yup.date().required("Awarded Date is rquired"),
  awardCriteria: yup.string().required("Award Criteria is required"),
  projectName: yup.string().nullable(),
  projectDecription: yup.string().nullable(),
  budgetDescription: yup.string().nullable(),
  contractSum: yup.number().nullable(),
  contractor: yup.string().nullable(),
});

export const contractAwardedResolver = yupResolver(contractAwardedSchema);
