import { useQuery } from "react-query";
import generalPlansAPI from "../../redux/api/generalPlansAPI";
import handleApiError from "../../redux/api/handleApiError";

export const useFetchProcurementPlanByPlanId = ({ params, id, setDataSource, setTableParams, showAlert }) => {
  const { data, isLoading, ...rest } = useQuery(
    [`useFetchProcurementPlanByPlanId`],
    () => generalPlansAPI.getProcurementPlans("", id, params),
    {
      enabled: !!id,
      onSuccess: (values) => {
        console.log({ values });
        if (setDataSource) {
          setDataSource(values?.data || values?.results || []);
        }

        if (setTableParams) {
          setTableParams((prev) => ({
            ...prev,
            pagination: {
              ...prev.pagination,
              total: values?.pagination?.totalEntries,
            },
          }));
        }
      },
      onError: (err) => {
        if (showAlert) {
          showAlert({
            severity: "success",
            message: handleApiError(err),
          });
        }
      },
    }
  );

  return {
    procurementPlan: data,
    fetchingProcurementPlan: isLoading,
    ...rest,
  };
};

export const useFetchProcurementDetails = ({ id, showAlert }) => {
  const { data, isLoading, ...rest } = useQuery(
    [`useFetchProcurementDetails`],
    () => generalPlansAPI.getGeneralPlansDetails("", id),
    {
      enabled: !!id,
      onError: (err) => {
        if (showAlert) {
          showAlert({
            severity: "success",
            message: handleApiError(err),
          });
        }
      },
    }
  );

  return {
    procurementPlanDetail: data,
    fetchingPlanDetail: isLoading,
    ...rest,
  };
};
