import baseApiMethod from "./baseApiMethod";
import { handleAxiosError } from "./handleApiError";
import baseAxiosMethod from "./baseAxiosMethod";
import Axios, { CancelToken } from "axios";

const adminAPI = {};

const axiosCancelTokens = {};

adminAPI.checkAccessToken = async () => {
  const accessToken = localStorage.getItem("token");
  const refreshToken = localStorage.getItem("refreshToken");
  try {
    const response = await baseAxiosMethod.post(`users/refreshToken`, { accessToken, refreshToken });

    const { accessToken: newAccessToken, refreshToken: newRefreshToken } = response.data.data;

    localStorage.setItem("token", newAccessToken);
    localStorage.setItem("refreshToken", newRefreshToken);

    return response.data.data;
  } catch (error) {
    throw new Error(handleAxiosError(error));
  }
};

adminAPI.getArchiveContract = async (params) => {
  try {
    const { data } = await baseApiMethod({ params }, `contracts/archives`, "get", { params });
    return data?.data || data?.results || data;
  } catch (error) {
    throw new Error(handleAxiosError(error));
  }
};
adminAPI.getArchiveContractDetails = async (archiveId) => {
  try {
    const response = await baseApiMethod(null, `contracts/archives/${archiveId}`, "get");
    return response;
  } catch (error) {
    throw new Error(handleAxiosError(error));
  }
};

adminAPI.getPermissions = async (key, param) => {
  try {
    const response = await baseAxiosMethod(`roles?Search=${param.Search}`);
    return response.data.data;
  } catch (error) {
    throw new Error(handleAxiosError(error));
  }
};

adminAPI.getRoleThreshold = async (key, roleId, type) => {
  try {
    const response = await baseAxiosMethod(`roles/threshold/${roleId}?Type=${type}`);
    return response.data;
  } catch (error) {
    throw new Error(handleAxiosError(error));
  }
};

adminAPI.getThresholds = async (key, params) => {
  try {
    const response = await baseAxiosMethod(`roles/thresholds`, { params });
    return response.data;
  } catch (error) {
    throw new Error(handleAxiosError(error));
  }
};

adminAPI.createPermissionThreshold = async ({ lowerLimitThreshold, upperLimitThreshold, roleId, type }) => {
  try {
    const response = await baseAxiosMethod.post(`roles/threshold/${roleId}`, {
      lowerLimitThreshold: parseInt(lowerLimitThreshold, 10),
      upperLimitThreshold: parseInt(upperLimitThreshold, 10),
      type,
    });
    return response.data;
  } catch (error) {
    throw new Error(handleAxiosError(error));
  }
};

adminAPI.updatePermissionThreshold = async ({ lowerLimitThreshold, upperLimitThreshold, roleId, type }) => {
  try {
    const response = await baseAxiosMethod.put(`roles/threshold/${roleId}`, { lowerLimitThreshold, upperLimitThreshold, type });
    return response.data;
  } catch (error) {
    throw new Error(handleAxiosError(error));
  }
};

adminAPI.getStaffSummary = (ministryId) => baseApiMethod(null, `staffs/summary?MinistryId=${ministryId ?? ""}`, "get");

adminAPI.getStaff = async (key, params) => {
  try {
    const response = await baseAxiosMethod("staffs", {
      params,
    });

    const {
      data,
      meta: { pagination },
    } = response.data;

    return { data, pagination };
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

adminAPI.updateStaffStatus = async (formData) => {
  const staffId = formData.get("staffId");
  try {
    const response = await baseAxiosMethod.put(`staffs/${staffId}`, formData);
    return response.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

//Page size zero to make the backend return all ministries
adminAPI.getMinistries = () => baseApiMethod(null, "ministries?pageSize=0", "get");

adminAPI.getMinistriesAxios = async (key, params) => {
  try {
    axiosCancelTokens.getMinistriesAxios && axiosCancelTokens.getMinistriesAxios();

    const response = await baseAxiosMethod("ministries", {
      params,
      cancelToken: new CancelToken(function executor(c) {
        axiosCancelTokens.getMinistriesAxios = c;
      }),
    });

    const {
      data,
      meta: { pagination },
    } = response.data;

    return { data, pagination };
  } catch (e) {
    if (Axios.isCancel(e)) return;
    throw new Error(handleAxiosError(e));
  }
};

adminAPI.getPendingStaffInvite = ({ PageNumber = 1, PageSize = 10 }) =>
  baseApiMethod(null, `staffs/pendingInvites?PageNumber=${PageNumber}&PageSize=${PageSize}`, "get");
adminAPI.inviteStaffs = (data) => baseApiMethod({ userInvite: data }, "staffs/invite", "post");
adminAPI.getVendors = async (key, { SearchBy, Search, ...params }) => {
  try {
    axiosCancelTokens.getVendors && axiosCancelTokens.getVendors();

    params[SearchBy] = Search;

    const response = await baseAxiosMethod(`vendors`, {
      params,
      cancelToken: new CancelToken(function executor(c) {
        axiosCancelTokens.getVendors = c;
      }),
    });

    const {
      data,
      meta: { pagination },
    } = response.data;

    return { data, pagination };
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

adminAPI.getPendingVendors = async (key, { SearchBy, Search, ...params }) => {
  try {
    axiosCancelTokens.getPendingVendors && axiosCancelTokens.getPendingVendors();

    params[SearchBy] = Search;
    params.Status = 1;

    const response = await baseAxiosMethod(`vendors`, {
      params,
      cancelToken: new CancelToken(function executor(c) {
        axiosCancelTokens.getPendingVendors = c;
      }),
    });

    const {
      data,
      meta: { pagination },
    } = response.data;

    return { data, pagination };
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};
adminAPI.getVendorsSummary = () => baseApiMethod(null, "vendors/summary", "get");
// adminAPI.updateStaffStatus = (data) => baseApiMethod(data, `staffs/${data.id}`, "put");
adminAPI.resendInvite = (data) => baseApiMethod(data, "staffs/resendInvitation", "post");
adminAPI.revokeInvite = (data) => baseApiMethod(data, "staffs/revokeInvite", "post");

adminAPI.getAllProject = async (key) => {
  try {
    const response = await baseAxiosMethod("projects");
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

adminAPI.getVendorProjectSummary = async (_, vendorId) => {
  try {
    const response = await baseAxiosMethod(`projects/summary/vendor/${vendorId}`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

adminAPI.getContractSummary = async (key, filter) => {
  try {
    const response = await baseAxiosMethod("Summary/contract", { params: filter });
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

adminAPI.getAdminOverviewData = async (key) => {
  try {
    const response = await baseAxiosMethod("Summary/staffOverviewSummary");
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

adminAPI.getProjects = async (key) => {
  try {
    const response = await baseAxiosMethod("/projects");

    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

adminAPI.getVendorProjects = async (_, vendorId) => {
  try {
    const response = await baseAxiosMethod(`projects/${vendorId}/projects`);

    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

adminAPI.getVendorActivities = async (_, vendorId) => {
  try {
    const response = await baseAxiosMethod(`vendors/${vendorId}/Activities`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

adminAPI.getVendorContractsSummary = async (_, vendorId) => {
  try {
    const response = await baseAxiosMethod(`contracts/summary/vendor/${vendorId}`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

adminAPI.getVendorBidsSummary = async (_, vendorId) => {
  try {
    const response = await baseAxiosMethod(`bids/summary/${vendorId}/vendor`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

adminAPI.getSearchResults = async (key, searchText) => {
  try {
    const response = await baseAxiosMethod(`staffs/search?Search=${searchText}`);
    return response.data;
  } catch (error) {
    throw new Error(handleAxiosError(error));
  }
};

export default adminAPI;
