export const onNumberValidator = (e, onChange) => {
  const re = /^[0-9\b]+$/;
  if (e.target.value === "" || re.test(e.target.value)) {
    onChange(e);
  }
};

export const numberValidator = (evt) => {
  const ASCIICode = evt.which ? evt.which : evt.keyCode;
  if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) return false;
  return true;
};
