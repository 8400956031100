export const mainNews = {
  id: "main",
  title: "Delta state government rural water supply and sanitation agency",
  date: "3 August, 2018",
  synopsis: "Tender invitation to reputable contractors for the construction/completion of water and sanitation facilities",
  content: `
  <h4><em>1.0 </em>PREAMBLE</h4>
  <p><br />The Delta State Government through the Rural Water Supply and Sanitation Agency (RUWASA) with support from the European Union (EU) and UNICEF under the Rural Component of Niger Delta Support Programme (NDSP) hereby invites tenders from reputable contractors for the construction/completion of water supply schemes, reticulation of water pipelines and construction/completion of sanitation facilities in Ndokwa West and Isoko South Local Government Area of the State.</p>
  <h4>2.0 SCOPE OF WORK</h4>
  <p><br />&bull; *Water Supply*: Construction of 2Nos. of Solar Motorized Boreholes (SMBH) in 2 communities, completion of 4Nos Solar Motorized Boreholes (SMBH) in 4 communities and reticulation of 1.5km pipeline each in 9<br />communities.</p>
  <p><br />&bull; *Sanitation*: Construction of 6Nos. of 3 compartments pour flush (3-CPF) latrines and completion of 16Nos. of 3 compartments pour flush (3-CPF) latrines in 11 primary schools across Ndokwa South Local<br />Government Area of Delta State.</p>
  <p>The facilities are categorized into lots below:</p>
  <p><br />*Lot: WS-1*<br />*Description*: Completion of 3Nos SMBH in 3 communities.</p>
  <p>&nbsp;</p>
  <p>*Lot: WS-2*<br />*Description*: Construction of 2Nos and completion of 1No SMBH in 4 communities and reticulation of 1.5km pipeline each in 9 communities.</p>
  <p>&nbsp;</p>
  <p>*Lot: WS-3*<br />*Description*: Reticulation of 1.5km pipelines each in 9 communities.</p>
  <p>&nbsp;</p>
  <p>*Lot: SF-1*<br />*Description*: Completion of 5Nos 3-CPF latrines in 5 primary schools</p>
  <p>&nbsp;</p>
  <p>*Lot: SF-2*<br />*Description*: Completion of 3Nos 3-CPF latrines in 3 primary schools</p>
  <p>&nbsp;</p>
  <p>*Lot: SF-3*<br />*Description*: Completion of 2Nos 3-CPF latrines in 2 primary schools</p>
  <p>&nbsp;</p>
  <p>*Lot: SF-4*<br />*Description*: Completion of 2Nos 3-CPF latrines in 2 primary schools</p>
  <p>&nbsp;</p>
  <p>*Lot: SB-1*<br />*Description*: Construction of 10Nos signboards and delivery to RUWASA&rsquo;s Office.</p>
  <p>&nbsp;</p>
  <p>*MANDATORY REQUIREMENTS*<br />Companies interested in the project are to submit company profiles which must contain the following:</p>
  <p><br />a. Evidence of Registration with Corporate Affairs Commission (CAC).<br />b. Current Tax Clearance Certificate.<br />c. Evidence of registration with the RUWASA as a contractor.<br />d. Company&rsquo;s Audited Account (indicating turnover) and funding information over the past 3 years endorsed by a firm of chartered accountants.<br />e. Evidence of registration with Contractors Registration Board.<br />f. Evidence of financial capability from reputable Bank(s).<br />g. Company&rsquo; profile, organogram and resume of relevant key technical staff, including individual attestation of availability, include copies of professional certificates with seal and endorsement.<br />h. Verifiable evidence of similar projects previously undertaken, including letters of awards and Completion Certificates within the past three (3) years; indicate the financial value of these similar projects.<br />i. Description and list of relevant equipment owned by contractor; include proof of ownership or lease or license over the equipment.<br />j. Evidence of payment of Tender Fee of N20,000 (Twenty Thousand Naira).<br />k. Any additional information that will enhance the potential of the contractor.</p>
  <p>&nbsp;</p>
  <h4>4.0 COLLECTION AND SUBMISSION OF TENDER DOCUMENTS AND COMPANY PROFILES</h4>
  <p><br />Interested bidders are to purchase bid documents from the Department of Finance, Delta State RUWASA on payment of tender fee of Twenty Thousand Naira (N20,000.00) only into Zenith bank: Rural Water Supply Agency,<br />Asaba, Account No. 1010184940.</p>
  <p><br />Collection of tender documents shall commence from 26th June, 2018 between 9:00am to 4:00pm; Mondays to Fridays, except on public holidays.<br />Completed documents and company profile, which shall be in five copies (one original and four photocopies) with soft copies in a DVD and Flash Drive (containing spreadsheet copy of the completed BOQ and PDF Copies of other bid documents) are to be submitted in a sealed envelope clearly marked with the Company&rsquo;s bid invitation number, DELRUWASA/NDSP/18&hellip; at the top left-hand corner, and addressed to: The General Manager, Delta State Rural Water Supply Agency (RUWASA), 15 Nwanze-Ozie Street, Uda L/Out, P.M.B. 5050, Asaba. Sealed envelopes will be received at same address until 11am on 17th July, 2018. Bid opening shall follow immediately at 12 noon at the Conference Room of RUWASA. The representatives of the bidders and the general public are invited to witness the public opening of bids. In compliance with the above request for bids and subject to all the conditions thereof, the undersigned offers and agrees, if this proposal be accepted, to provide all services and items upon which prices are quoted, at the prices set for each of them, within the times and at the place indicated.<br />Bids should be valid for at least 90 days.<br />No late BIDS SHALL BE ACCEPTED.</p>
  <p>&nbsp;</p>
  <p>4.1 *PRE-BIDDING MEETING*<br />Interested bidders are invited to the pre-bidding meeting which shall come up by 11:00am on 3rd July, 2018 at Conference room Urban Water Board, Okpanam Road, Asaba, Delta State.</p>
  <p>&nbsp;</p>
  <p>5.0 *DISCLAIMER*<br />This advertisement for &ldquo;Invitation to Tender&rdquo; shall not be construed as a commitment on the part of the Delta State RUWASA, nor shall it entitle any company to make any claim whatsoever, and/or seek any indemnity from<br />the agency or from the State Government and/or any of its departments, by virtue of such company having responded to this advertisement.<br />All expenses incurred in preparing &ldquo;Invitation to Tender&rdquo; response and all expenses otherwise associated with &ldquo;Invitation to Tender&rdquo; response shall be borne solely by the prospective contractor.</p>
  <p>&nbsp;</p>
  <p>Programme: WATER, SANITATION AND HYGIENE (WASH)<br />For additional information regarding this invitation, please contact:<br />The General Manager, Delta State RUWASA, Asaba.</p>
  <p>&nbsp;</p>
  <p>Signed<br />The General Manager<br />Delta State RUWASA<br />08030917163<br />(PLEASE READ CAREFULLY INSTRUCTIONS TO BIDDERS ATTACHED)</p>
  <p>&nbsp;</p>
  <p>NB:<br />&bull; Forward bid with covering letter on company&rsquo;s letter head.<br />&bull; Ensure that you forward bid in a sealed envelope quoting the Lot/bid number (&hellip;..) on the top right hand corner.<br />&bull; Note that EU/UNICEF does not pay VAT or any type of Tax.</p>
  <p>&nbsp;</p>
  <p>*SOURCE: VANGUARD*</p>
  `,
  imgUrl: "https://deltappc.dl.gov.ng/wp/wp-content/uploads/2018/08/delta-600x550.png",
};

export const news = [
  {
    id: 1,
    title: "Ekweremadu Commissions Multiple Road Projects In Delta ",
    date: "23 July, 2020",
    synopsis: "To ensure a transparent, competitive, inclusive, sustainable...",
    content: `<p>Deputy President of the Senate, Senator Ike Ekweremadu, today (01/06/2018) commissioned multiple road projects in Delta State, describing the state Governor, Senator Dr Ifeanyi Okowa as a man who represents hope for Nigeria’s democracy.</p> 
      <p>Senator Ekweremadu who commissioned Nsukwa/Ndemili, Agbor-Alidinma roads, that criss-cross Aniocha South, Ndokwa West, Ika North-East and Ika South local government areas of the state, also, commissioned the Bonsaac, Nze Francis Street/Uche Wenwembu Street/Onwa Nwachinemelu Street in Asaba, Oshimili South local government area of the state.</p> 
      <p>Governor Ifeanyi Okowa, his Deputy, Deacon Kingsley Otuaro, Speaker of the Delta State House of Assembly, Rt. Hon. Sheriff Oborevwori, members of the National Assembly, members of the Delta State House of Assembly, commissioners, political office holders, traditional rulers, among others witnessed the commissioning exercise which made the Deputy Senate President to transverse parts of the oil-rich state for more than 5 hours.</p> 
      <p>“I feel very pleased that you are instilling in the people what purposeful leadership is about, with your performance in Delta State, from what we are seeing, there is a future for our country, there is hope for Nigeria and you (Governor Okowa) represent that hope,” the Senate Deputy President stated. 
      He continued, “I consider road infrastructure as the most important for our people because, I experienced the effects of bad roads for decades, I want to thank you (Governor Okowa) for changing the story of these communities where these roads are constructed, roads attract development as we can witness from the Petrol Station being constructed at the junction (Nsukwa Junction).” </p>
      <p>“We must continue on the part of excellence and I want to thank the people for their support for Governor Okowa’s administration, you (Governor Okowa) are a man of the people and to God be the glory,” he stated. </p>
      <p>Governor Okowa at places where the roads were commissioned amidst thunderous ovation and chant of Ekwueme (Okowa’s chieftancy title) stated, “we make promise and we keep to our promises, I am happy that we have finished this road and drainage (Bonsaac, Nze Francis Street/Uche Wenwembu Street/Onwa Nwachinemelu Street in Asaba), a lot more will be done, because of the dense population of people in Oduke, we have acquired land for the construction of Oduke Secondary School, continue to support this administration because, we will never let you down.”</p> 
      <p>“I thank the Senate President for coming to commission projects in Delta State, we have constructed a lot of roads across the state, we have stayed very strong on our youth empowerment programme, we train and equip them to become entrepreneurs, there are lots of success stories that we are moved to do more; we will continue to construct roads, build schools, encourage agriculture, our health insurance scheme have more than 200, 000 registered people as we believe that the state can show example that Nigerians can be beneficiaries of health insurance scheme.”
      Chairman of local government councils, representatives of different communities, among others who spoke at the venues, lauded Governor Okowa’s people-oriented programmes that are impacting positively on the communities and the lives of Deltans.</p>`,
    imgUrl: "https://deltappc.dl.gov.ng/wp/wp-content/uploads/2018/08/pix-7.jpg",
  },
  {
    id: 2,
    title: "Okowa, A Worthy Ambassador of the Senate… Mark ",
    date: "23 July, 2020",
    synopsis: "To ensure a transparent, competitive, inclusive, sustainable...",
    content: `<p>Former President of the Senate, Senator David Mark has described Delta State governor, Senator Ifeanyi Okowa as a worthy ambassador of the upper legislative chamber.</p> 
      <p>Senator Mark noted that Okowa’s silent revolution in terms of infrastructural development in Delta state is a testimony that he is effectively combining legislative and executive experiences to develop the oil rich state.</p> 
      <p>Senator Mark in a congratulatory message to Okowa on his 59th birthday noted that “Okowa is a focused and result oriented administrator. The number of projects so far executed by the Okowa’s administration in Delta state, the peace and unity on account of equity and justice to all gives me a sense of pride that his Senate experience is paying off”.</p> 
      <p>Senator Mark was the President of the 7th Senate during which Okowa served as chairman Senate Committee on health.</p> 
      <p>According Mark, “I can only add that you (Okowa) sustain and indeed improve on these giant strides in Delta state. The people deserve nothing less”.</p> 
      <p>He noted that Okowa is upholding tenaciously the manifesto of the Peoples Democratic Party (PDP) which the governor translated to “prosperity for all Deltans”.</p> 
      <p>Senator Mark recalled that Okowa cannot afford to do less judging from his experiences as secretary to local government, council chairman, commissioner, secretary to the state government, Senator of the Federal Republic and now a governor.</p> 
      <p>“He has wealth of experiences in leadership and administration which he is bringing to bear. Okowa is an asset and I am sure the people of Delta are proud of him”, Senator Mark added </p>`,
    imgUrl: "https://deltappc.dl.gov.ng/wp/wp-content/uploads/2018/08/Okowa-822x550.jpg",
  },
  {
    id: 3,
    title: "Okowa: Celebrating The ‘Ekwueme’ At 59",
    date: "23 July, 2020",
    synopsis:
      "Contemporary political observers watching the happenings in Delta State would rightly agree that hard work doesn’t kill...",
    content: `<p>Contemporary political observers watching the happenings in Delta State would rightly agree that hard work doesn’t kill; rather hard work in the right direction delivers enduring fruits. It yields life that history acknowledges ultimately. That is the enviable story of Governor Ifeanyi Okowa of Delta State as he marks his 59th birthday today July 8, 2018. </p>
      <p>To Okowa, the ideal celebration would not be to organize an elaborate feast, to bring friends and well-wishers together for a mother of all feasts. Doing so is not in his character. If there would be a get-together at all it would be with his immediate family as he has done in the last three years as the governor. Therefore, It’s only appropriate to seize the occasion of his birthday to examine his relentless work schedule that has benefited the state as he has turned the entire state to a construction site with over 300 road projects awarded in the three senatorial district and over 120 completed and commissioned.</p> 
      <p>For the first time in the history of the State, the Okowa led administration commissioned concret paved roads in Burutu, the headquarters of Burutu local government area. While addressing the people of the area, Gov. Okowa noted that he will like to be remembered as the Governor who developed the riverine areas of Delta State. In the same local government area, the Okowa administration is constructing the 19.2km Obotobo I, Obotobo II, Sokebolou and Yokri roads where a mobile asphalt plant had to be installed in the area because of the terrain.</p>
      <p>Just a few days ago precisely July 3, Governor Okowa commissioned 4 road projects in Aniocha North Local Government Area viz the Rehabilitated Issele-Uku/Issele-Mkpitime/Onicha-Olona Road, Phase I of Issele-Uku/Onicha-Uku/Ugbodu/Ukwu-Nzu/Obomkpa/Idumu-Ogo Road, the rehabilitated Issele-Uku/Onicha-Ugbo Road, and Phase I of Onicha-Ugbo/Ubulu-Uku Road.</p> 
      <p>The governor has received notable recognition for outstanding governance and the service to the people, his Alma Mater, the University of Ibadan awarded him the Alumni of the Year while the University of Nigeria, Nsukka awarded him the Dignity of Man Award while Daily Independent Newspapers awarded him the Independent Man of the Year 2017. For a man who has demonstrated severally that experience can indeed be an asset in governance, the governor is always calm and dutiful especially in a state that is so complex to govern. For sure, Delta under the pragmatic, humanist and visionary leadership of Governor Okowa can only get better.</p> 
      <p>That in marking his third year in office, Governor Okowa spent a whole month in commissioning several landmark projects dotted across the three Senatorial Districts of the state has become a template and a reference point for most of the states today. Governor’s Nyesom Wike of Rivers State, Aminu Tambuwal of Sokoto State, his Bayelsan counterpart Seriake Dickson and Governor Dankwambo of Gombe, National Chairman of the Peoples Democratic Party PDP Prince Uche Secondus were guests of Governor Okowa to commission several projects including the dualised Cable Point Road in Asaba, dualised Jesse Township Road, the Jesse Boboroku Road and the Sapele Modern Market, Amekpa, Afiesere, Ofuoma Road as well as the Abavo Circular Roads, Owerre-Olubor/Ekwuoma Road. </p>
      <p>As Delta State prepares to host the 2018 Confederation of Africa Athletics [CAA] Senior African Athletic Competition in Asaba in August, Governor Okowa has committed enormous resources towards the completion of the long abandoned Sephen Keshi Stadium in Asaba, venue of the championship. Over 52 African countries are expected at the 21st African Senior Athletics Championships that would take place August 1-5 in Asaba, with attendant benefits to the economy of the State.</p> 
      <p>Through consensus-building, thorny political issues have become free of tumult, acrimony and bad blood. This was made possible by Okowa’s calm persona on a very volatile system. By entrenching core values of selflessness and integrity, he has effectively earned the confidence and trust of his friends and foes alike. He has re-defined the concept of leadership and effective governance by effectively re-orientating the mindset of the political class to see politics as a call to service. </p>
      <p>As he clocks 59 years, his track-record in politics and in the leadership of Delta state sure sets him apart as a selfless and passionate leader who burns to put his state on the world developmental map. He detests waste because he is confronted daily by the lack that dots the socio-economic landscape of Delta State. That is why since he came into office, he requested that friends and well wishers should not place goodwill advertorials in the media. He rather urged them to convert such resources to assisting the needy. </p>
      <p>As he marks yet another anniversary of his birthday, his wish is that Deltans should continue to pray for him and the state in order for the state to remain peaceful for developments to take place. </p>
      
      Happy birthday sir! `,
    imgUrl: "https://deltappc.dl.gov.ng/wp/wp-content/uploads/2018/08/Gov.-Okowa2-2-1110x550.jpg",
  },
  {
    id: 4,
    title: "ROAD CONSTRUCTION: Okowa Urges Deltans To Be Patient With Contractors ",
    date: "23 July, 2020",
    synopsis: "You can see the quality of work going on at this drainage project...",
    content: `<p>Delta State Governor Senator Dr Ifeanyi Okowa has called for patience and understanding by the people whose movement have been restricted as a result of several road construction and drainage projects going on in different parts of the state.</p> 
      <p>Governor Okowa made the call today (09/07/18) while inspecting the service drain project along Summit Road, Asaba. </p>
      <p>According to the governor who was represented by the Commissioner For Works, Chief James Augoye, “the construction works going on in our state, no doubt, have restricted the movement of some persons, we regret such and appeal to them to be patient, the projects are designed for the comfort of all when completed and we are confident that in the next couple of days, the roads will be free for easy movement.” </p>
      <p>“You can see the quality of work going on at this drainage project, it will channel water from the Summit road, to the Amilimocha river, we believe that once these projects are completed the flooding being experienced in Asaba will be a thing of the past and we are happy with the quality of work at the projects and the contractors are working with speed to ensure early delivery,” he said.</p> 
      <p>Governor  Okowa reiterated  his administration’s commitment to constructing roads and checking flood in major cities and towns of the state through the construction of drainage and sewers, emphasising “with good roads, functional drainage system, the state will be more attractive to investors and more comfortable to those of us who live here,”</p> 
      <p>He commended the determination of the contractors to beat deadlines, emphasizing, “making Asaba free of flood is a task the Governor is determined to actualize and bearing in mind the fact that the state is playing host to international athletics competition in the next few days has made it imperative for the projects to be completed early enough.” </p>`,
    imgUrl: "https://deltaeprocurement.blob.core.windows.net/webresources/road%20construction.jpg",
  },
  {
    id: 5,
    title: "INVITATION TO TENDER FOR 2018 CAPITAL PROJECTS",
    date: "23 July, 2020",
    synopsis: "You can see the quality of work going on at this drainage project...",
    content: `
    <h3>INTRODUCTION</h3> 
    <p>The National Productivity Centre (NPC) is a Federal Government Parastatal under the Ministry of Labour and Employment. In line with the Centre’s mandate of enhancing individual and organizational productivity, it seeks to engage the services of competent Contractors/Consultants for the execution of the capital projects under 
    the 2018 budget as follows:</p>
    <br>
    <h3>2.0 SCOPE OF SERVICES/GOODS</h3> 
    LOT 1: DEVELOPMENT OF NATIONAL/SECTORAL PRODUCTIVITY INDICES<br>
    SCOPE:<br>
    1A. Design and Production of Questionnaires, Administration/Retrieval of Questionnaires, Data Analysis and Production of Report
    1B. Procurement of Licensed Software
    <br>
    <br>
    LOT 2: ANNIVERSARIES AND CELEBRATIONS: NATIONAL PRODUCTIVITY DAY 
    (NPD)/NATIONAL PRODUCTIVITY ORDER OF MERIT AWARD (NPOMA)
    SCOPE: To organize symposium, exhibition of Made-in-Nigeria goods, panel meetings and visits, road shows, production of plaques, Award Lectures, etc.
    <br>
    <br>
    LOT 3: DEVELOPMENT AND DEPLOYMENT OF PRODUCTIVITY SOLUTIONS<br>
    SCOPE: Development & Deployment of Managerial and Operational Tools/Templates for SMEs in Eight Clusters.
    <br><br>
    <br>   LOT 4: DEVELOPMENT OF PRODUCTIVITY LINKED WAGE SYSTEM FOR THE PUBLIC 
    SECTOR <br>
    SCOPE: Design and Production of Questionnaires, Administration/Retrieval of Questionnaires, Data Analysis, Capacity Building/Sensitization in MDAs, Production of Report.* 
    <br>
    <br>
    LOT 5: DEVELOPMENT OF PRODUCTIVITY PRACTITIONERS 
    SCOPE: <br>
    5A. Purchase of Desktop Computers, Purchase of Computer Printers, Purchase of Photocopying Machines, Purchase of Furniture and Fittings* 
    <br>
    5B. Training of 1200 Practitioners
    <br>
    <br>
    
    LOT 6: ESTABLISHMENT OF ISO QUALITY TRAINING CENTRE<br>
    SCOPE: Purchase of Furniture and Fittings, Purchase of Teaching and Learning Aid
    <br>
    <br>
    
    LOT 7: PRODUCTIVITY PROMOTION AND ADVOCACY <br>
    SCOPE: Productivity Promotion and Advocacy, Productivity Coaching and Mentoring for Schools and NYSC Members
    <br>
    <br>
    
    LOT 8: CONSULTANCY, SURVEY & SHORT TERM STUDIES <br>
    SCOPE: Staff Capacity Building Workshops & Seminars, In-plant Trainings, Training of Trainers, Monitoring and Evaluation/Impact Assessment
    <br>
    <br>
    LOT 9: SKILL-ACQUISITION TRAINING FOR WOMEN & YOUTH EMPOWERMENT IN 
    GAMAWA, BAUCHI <br>
    SCOPE: Training of Women & Youth in various Vocations, Soap making, Tailoring, Barbing
    <br>
    <br>
    
    LOT 10: SUPPLY OF PESTICIDES AND SPRAY PUMP IN GOMBE, KWAMI/FUNAKAYE 
    LGAs, GOMBE STATE <br>
    SCOPE: Supply of Spray Pumps and Pesticides
    <br>
    <br>
    LOT 11: TRAINING OF WOMEN IN BEADS MAKING IN BAKURA/MARADUN LGAs, 
    ZAMFARA STATE<br>
    SCOPE: Training in Beads Making
    <br>
    <br>
    LOT 12: SUPPLY OF TRICYCLES, MOTORCYCLES, VULCANIZING MACHINES AND 
    WHEEL CHAIRS FOR THE DISABLES IN MIKAN, QUWAANPAN SHENDAM LGAs IN 
    PLATEAU STATE <br>
    SCOPE: Purchase of Tricycles, Motorcycles, Air Pump Machines, Wheel Chairs and Crutches
    <br>
    <br>
    LOT 13: ENTREPRENEURSHIP TRAINING AND EMPOWERMENT FOR YOUTHS IN ORON, 
    MBO, OKOBO, UDUNG UKO, URUE-OFFONG ORUKO, AKWA-IBOM STATE<br>
    SCOPE: Training of Women & Youths in various Vocations, Soap Making, Tailoring, Barbing, Beads Making, Make-up and Food Processing*
    <br>
    <br>
    LOT 14: YOUTH AND WOMEN EMPOWERMENT IN UMUAHIA NORTH AND BENDE LGAs IN 
    ABIA STATE <br>
    SCOPE: Training of Women & Youths in various Vocations, Soap Making, Tailoring, Barbing, Beads Making, Hairstyles, Catering, Shoes and Bags, Food Processing and Leather Works
    <br>
    <br>
    LOT 15: SKILL ACQUISITION FOR WOMEN AND YOUTHS AT EFAKPA, OJOKO, ADUMU, 
    OJAMA ‘GBACHU, EFEKPE, OFORO AND ABO IN IMANE OLAMABORO LGA, KOGI STATE <br>
    SCOPE: Training of Women & Youths in various Vocations, Soap Making, Fashion design, Barbing, Beads Making, Catering, Shoes and Bags Making, Leather Works, Make-up, and Computer Training
    <br>
    <br>
    LOT 16: PRODUCTIVITY PROMOTION AND ADVOCACY ACROSS THE NATION TO 
    STIMULATE PRODUCTIVITY CONSCIOUSNESS TOWARDS DEVELOPING THE NECESSARY 
    MINDSET AND CULTURE FOR ECONOMIC GROWTH AND COMPETITIVENESS <br>
    SCOPE: Production of Audio and Visual Jingles, Production of Documentaries and Supplements, and Placements on National Media Platforms
    <br>
    <br>
    LOT 17: TRAINING OF YOUTHS AND WOMEN IN RICE FARMING IN BAKURA/MARADUN 
    FEDERAL CONSTITUENCY, ZAMFARA STATE <br>
    SCOPE: Training of Women & Youths in various Vocations, Soap Making, Tailoring, Barbing, Beads Making, Hairstyles, Catering, Shoes and Bags, Food Processing and Leather Works
    <br>
    <br>
    <h4>CONSTITUENCY PROJECTS/INTERVENTION PROJECTS</h4>
    LOT 18: EMPOWERMENT OF YOUTHS & WOMEN IN GOMBE/KWAMI/FUNAKAYE FEDERAL
    CONSTITUENCY, GOMBE STATE <br>
    SCOPE: Skill Acquisition Training for Youths and Women
    <br>
    <br>
    LOT 19: SUPPLY OF TRICYCLES AND SEWING MACHINES IN GOBE/KWAMI/FUNAKAYE 
    FEDERAL CONSTITUENCY, GOMBE STATE <br>
    SCOPE: Purchase of Tricycles and Sewing Machines
    <br>
    <br>
    LOT 20: SKILL ACQUISITION AND TRAINING PROGRAMME IN IMO NORTH 
    SENATORIAL DISTRICT, IMO STATE <br>
    SCOPE: Training of Women & Youths in various Vocations, Soap Making, Fashion design, Barbing, Beads Making, Catering, Shoes and Bags Processing, Leather Works, Make-up, and Computer Training
    <br>
    <br>
    
    LOT 21: POST SEASONAL TRAINING IN IMO NORTH SENATORIAL DISTRICT, IMO 
    STATE<br>
    SCOPE: Training Programme for Youths on Post Farming Harvest Management
    <br>
    <br>
    
    LOT 22: STRATEGIC EMPOWERMENT PROGRAMME FOR YOUTH GROUPS IN IMO NORTH 
    SENATORIAL DISTRICT, IMO STATE <br>
    SCOPE: Training of Youths in Skills Acquisition, Developing and Strengthening of the Skills
    <br>
    <br>
    
    LOT 23: CAPACITY BUILDING IN RIVERS SOUTH EAST SENATORIAL DISTRICT, 
    RIVERS STATE <br>
    SCOPE: Workshops and Training in Skills Acquisition, Developing and Strengthening the skills, instincts, abilities, processes and resources that organizations need to survive, Adapt and thrive fast
    <br>
    <br>
    
    LOT 24: EMPOWERMENT FOR YOUTHS IN RIVERS SOUTH EAST SENATORIAL 
    DISTRICT, RIVERS STATE<br>
    SCOPE: Skill Acquisition, Training & Empowerment
    <br>
    <br>
    
    LOT 25: CAPACITY BUILDING IN DELTA SOUTH SENATORIAL DISTRICT, DELTA STATE <br>
    SCOPE: Workshops and Training in Skills Acquisition, how to develop and Strengthen the skills, instincts, abilities, processes and resources that organizations need to survive, Adapt and thrive fast
    <br>
    <br>
    
    LOT 26: EMPOWERMENT FOR YOUTHS IN DELTA SOUTH SENATORIAL DISTRICT, 
    DELTA STATE <br>
    SCOPE: Skill Acquisition, Training & Empowerment
    <br>
    <br>
    
    LOT 27: START-UP CAPITAL FOR TRAINED YOUTH IN DELTA SOUTH SENATORIAL 
    DISTRICT, DELTA STATE <br>
    SCOPE: Mobilizing, Empowerment and Deployment of Capital to Trained Youths
    <br>
    <br>
    
    <h4>3.0 ELIGIBILITY REQUIREMENTS</h4>
    Interested Firms should submit the following documents: 
    <br>
    <br>
   <p> a) Evidence of Certificate of Incorporation with Corporate Affairs Commission (CAC) including Form CAC2 and CAC7. </p>
   <p>b) Evidence of Firm’s Tax Clearance Certificate for the last three (3) years (2015, 2016 and 2017) valid till 31st December, 2018.  </p>
   <p> c) Evidence of Current Pension Compliance Certificate valid till 31st December, 2018.  </p>
   <p> d) Evidence of current Industrial Training Fund (ITF) Compliance Certificate valid till 31st December, 2018.  </p>
   <p> e) Evidence of current Nigeria Social Insurance Trust Fund (NSITF) Compliance Certificate valid till 31st December, 2018.  </p>
   <p> f) Evidence of registration on the National Database of Federal Contractors, Consultants and Service Providers by submission of Interim Registration Report (IRR) certificate expiring on 1/1/2019 or valid Certificate issued by BPP.  </p>
   <p> g) Sworn Affidavit disclosing whether or not any officer of the relevant committees of the National Productivity Centre or the Bureau of Public Procurement is a former or present Director, Shareholder or has any pecuniary interest in the company and to confirm that all information presented in this bid are true and correct in all particulars.  </p>
    
   <p> h) Company’s Audited Account for the last three (3) years – 2015, 2016 & 2017.  </p>
   <p> i) Evidence of financial capability to execute the project by submission of Reference Letter from a reputable Commercial Bank indicating willingness to provide credit facility for the execution of the project when needed. </p> 
   <p> j) Company Profile with Curriculum Vitae of Key Staff to be deployed for the project, including copies of their Academic/Professional qualifications.  </p>
   <p> k) Verifiable documentary evidence of at least three (3) similar jobs executed in the last five (5) years including Letters of Awards, Valuation Certificates, Job Completion Certificates and Photographs of the projects.  </p>
   <p> l) A sworn affidavit:  </p>
    i. Disclosing whether or not any officer of the relevant committees of the National Productivity Centre or the Bureau of Public Procurement is a former or present Director, Shareholder or has any pecuniary interest in the bidding and to confirm that all information presented in its bid are true and correct in all particulars. <br>
    ii. Declaring that the company is not in receivership and/or the subject of any receivership or the subject of any form of winding up proceedings. <br>
    iii. Declaration that none of the company’s Directors has been convicted in any country for any criminal offences relating to fraud. <br>
    <p> m) Contractors bidding for the Supply of IT Equipment should be registered with Nigerian Information Technology Development Authority (NITDA) and/or with the Computer Professionals Registration Council.  </p>
    <p> n) For Training/Empowerment Programmes: Evidence of Firm’s Registration with the Centre for Management Development.  </p>
    <p> o) All documents for submission must be transmitted with a covering/forwarding letter under the Company/Firm’s Letter Head Paper bearing amongst others, the Registration Number (RC) as issued by the Corporate Affairs Commission (CAC), Contact Address, Telephone Numbers (preferably GSM No.), and e-mail address. The Letterhead Paper must bear the Names and Nationalities of the Directors of the Company at the bottom of the page, duly signed by the authorized officer of the Firm.  </p>
    <p> p) Please note that the Centre will carry out due diligence/verification of all the original documents mentioned above.  </p>
    <br>
<br>
    <h4>4.0 COLLECTION OF TENDER DOCUMENTS FOR GOODS</h4>
    <p> Interested companies (for Goods) are to collect the Standard Bidding Document (SBD) from the Office of the Director of Procurement of National Productivity Centre, Plot 2173, Cape Town Street, Wuse Zone 4, Abuja on evidence of payment of a non-refundable fee of N10,000.00 (Ten Thousand Naira) per Lot, paid into the National Productivity Centre’s REMITA Account in any reputable commercial bank. Interested Firms in Consultancy Services will not pay at this stage until after short-listing.  </p> 
    
    <h4>5.0 SUBMISSION OF BIDS FOR GOODS</h4>
    <p>Prospective bidders are to submit bid for each of the Lot desired, two (2) hard copies each of the technical and financial bids will softcopy of financial bid only in MS Excel format, packaged separately in sealed envelopes and clearly marked as “Technical Bid” or “Financial Bid”. </p>
    <br>
    <p>Thereafter, put the two sealed envelopes together in a bigger sealed envelope addressed to The Director of Procurement of National Productivity Centre, Plot 2173, Cape Town Street, Wuse Zone 4, Abuja and clearly marked with (the name of the project and the Lot number). 
    Furthermore, the reverse of each sealed envelope should have the name and address of the bidder and drop in the designated Tender Box in the Director of Procurement’s Office, not later than Monday, 13th August, 2018. 
    </p>
    <br>
<br>
    <h4>7.0 OPENING OF EOI/BIDS</h4>
    <p>The EOI will be opened immediately after the deadline for submission on Monday, at 12 noon on Monday, 13th August, 2018, at National Productivity Centre, Plot 2173, Cape Town Street, Wuse Zone 4, Abuja. </p>
    <p>Also, the technical bids will ne opened immediately after the deadline for submission at 12 noon on Monday, 10th September, 2018 at same address. </p>
    <br>
<br>
    <h4>8.0 NOTES</h4>
    <p>i) Only pre-qualified bidders for Goods will be invited at a later date for financial bid opening, while financial bids of unsuccessful bidders will be returned un-opened; </p>
    <p>ii) Only short-listed Consultants will be invited at a later date for collection<p>
<br>
<br>
   <p> <b>Signed:</s> </b>
<p><b>Director-General </b></p>
<p><b>National Productivity Centre </b></p>
    `,
    imgUrl: "https://deltappc.dl.gov.ng/wp/wp-content/uploads/2018/08/npcnewlogo-635x550.png",
  },
];
