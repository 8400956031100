import { Box } from "@material-ui/core";
import React from "react";
import { CuteModal, ModalWrapper, ModalHeader, ModalCloseBtn } from "./style";
import CheckImage from "../../../../../assets/fileSuccess.gif";

const SuccessModal = ({ open, setModal }) => {
  return (
    <CuteModal open={open}>
      <ModalWrapper width="41%">
        <Box style={{ display: "flex", justifyConetent: "center", flexDirection: "column" }}>
          <Box style={{ margin: "0 auto" }}>
            <img style={{ height: "18rem", width: "18rem" }} alt="success" src={CheckImage} />
          </Box>
          <ModalHeader style={{ margin: "1rem auto" }}>Bidder Added Successfully</ModalHeader>
          <Box style={{ margin: "auto" }}>
            <ModalCloseBtn style={{ background: "#F0F5FF", color: "#0050C8", width: "100%" }} onClick={() => setModal(false)}>
              Close
            </ModalCloseBtn>
          </Box>
        </Box>
      </ModalWrapper>
    </CuteModal>
  );
};

export default SuccessModal;
