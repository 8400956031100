import React from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import {
  FooterSection,
  FooterTitleText,
  FooterDescriptionText,
  FooterGroupText,
  FooterCopyRightContainer,
  TwitterIcon,
  GroupedIcon,
  FacebookIcon,
} from "./style";

const HomepageFooter = () => {
  const linkStyle = { color: "#fff", textDecoration: "none" };
  return (
    <FooterSection>
      <Container>
        <Grid container justify="space-between">
          <Grid item>
            <FooterTitleText>About DSEP</FooterTitleText>
            <FooterDescriptionText>
              Delta State is an oil and agricultural producing State of Nigeria, situated in the region known as the South-South
              geo-political zone with a population of 4,098,291.
              <br />
              <br />
              The capital city is Asaba, located at the northern end of the State with an estimated area of 762 square kilometres
              (294 sq mi), while Warri is the economic nerve center of the State and also the…
            </FooterDescriptionText>
          </Grid>
          <Grid item>
            <FooterTitleText>Important Links</FooterTitleText>
            <FooterDescriptionText>
              <Link to="/login" style={linkStyle}>
                Vendor Registration
              </Link>
            </FooterDescriptionText>
            <FooterDescriptionText>
              <Link to="/resources" style={linkStyle}>
                Resources
              </Link>
            </FooterDescriptionText>
            <FooterDescriptionText>
              <Link to="/news-and-bulletins" style={linkStyle}>
                News & Bulletin
              </Link>
            </FooterDescriptionText>
            <FooterDescriptionText>
              <Link to="/ocds-portal/awarded-contracts" style={linkStyle}>
                Awarded Contracts
              </Link>
            </FooterDescriptionText>
            <FooterDescriptionText>Frequently asked questions</FooterDescriptionText>
          </Grid>
          <Grid item>
            <FooterTitleText>Contact Us</FooterTitleText>
            <FooterDescriptionText>Address</FooterDescriptionText>
            <FooterGroupText>No 6 Okpanam Road, Opposite Shoprite, Asaba Delta State.</FooterGroupText>
            <br />
            <FooterDescriptionText>Telephone</FooterDescriptionText>
            <FooterGroupText>08114344724</FooterGroupText>
            <FooterGroupText>08115446568</FooterGroupText>
            <br />
            <FooterGroupText>Email</FooterGroupText>
            <FooterGroupText>Dsppcmessagebox@gmail.com</FooterGroupText>
          </Grid>
        </Grid>
        <FooterCopyRightContainer>
          <GroupedIcon>
            <TwitterIcon />
            <FacebookIcon />
          </GroupedIcon>
          <FooterGroupText>Delta state government E-procurment</FooterGroupText>
          <FooterGroupText>All rights reserved | (C) Copyright 2020</FooterGroupText>
        </FooterCopyRightContainer>
      </Container>
    </FooterSection>
  );
};

export default HomepageFooter;
