import React, { useMemo, useState, useContext } from "react";
import { Tooltip, 
  // IconButton, 
  makeStyles, CircularProgress } from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link, useParams } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { useQuery, useMutation, queryCache } from "react-query";
import moment from "moment";
import {
  BoldCardContent,
  BreadCrumbWrapper,
  CardBody,
  CardContent,
  CardLabel,
  CardSection,
  CardWrapper,
  ContentWrapper,
  Divider,
  PageTitle,
  SerialText,
  StatusCircle,
  StatusCirclePending,
  StatusCircleSuccess,
  TableHeader,
  TableTop,
  TableWrapper,
  TopSection,
  UnstyledLink,
  ButtonWrapper,
  HeadContent,
  EditRoadmap,
  ErrorContainer,
} from "./style";
import withAdminDashboard from "../HOC/withAdminDashboard";
import procurementAPI from "../../../redux/api/procurementAPI";
import Loader from "../../../components/Common/Loader";
import LinearProgressBar from "../../../components/LinearProgressBar";
import FileProgress from "../../../components/FileProgress/FileProgress";
import Tag from "../../../components/Tag";
import { formatCurrency } from "../../../util/formatCurrency";
import ApprovalMemo from "./ApprovalMemo";
import { useRoles } from "../../../hooks/useUserDetails";

import { Roles } from "../../../components/AccessControl/UserRoles";
// import { ReactComponent as EditIcon } from "../../../assets/edit.svg";
// import EditIcon from "../../../assets/edit.svg";
import { Modal } from "../../../shared";
import ToastContext from "../../../util/toastContext";
import LoadingButton from "../../../components/LoadingButton/LoadingButton";
import { Controller, useForm } from "react-hook-form";
import DatePicker from "../../../components/DatePicker";
import { isAfter, isBefore } from "date-fns";

const statusCircles = {
  Amendment: <StatusCirclePending marginRight={30} />,
  Pending: <StatusCirclePending marginRight={30} />,
  Awaiting: <StatusCirclePending marginRight={30} />,
  Approved: <StatusCircleSuccess marginRight={30} />,
  InActive: <StatusCircle marginRight={30} />,
};
const useStyles = makeStyles({
  modalTitle: {
    fontWeight: 600,
  },
  modalBox: {
    backgroundColor: "white",
    padding: "40px",
    borderRadius: "5px",
  },
  formFlex: {
    display: "flex",
    gap: "50px",
    padding: "10px 0",
    marginBottom: "20px",
  },
});

const PlanDetails = () => {
  const classes = useStyles();
  const { role } = useRoles();
  const { showAlert } = useContext(ToastContext);
  const { handleSubmit, control, errors, watch, clearErrors } = useForm({ mode: "all" });
  const [openModal, setOpenModal] = useState({
    open: false,
    data: {
      startDate: "",
      endDate: "",
    },
  });
  const [updateDate, setUpdateDate] = useState({
    newStartDate: null,
    newEndDate: null,
  });

  const [editRoadmap, setEditRoadmap] = useState(false);

  const isProcurementOfficer = Roles.PROCUREMENT_OFFICER === role;
  const { procurementId } = useParams();
  const {
    data = {},
    isLoading,
    isSuccess,
  } = useQuery({
    queryKey: ["generalPlanDetails", procurementId],
    queryFn: procurementAPI.getPlanDetails,
    config: {
      enabled: !!procurementId,
      cacheTime: 3600 * 100,
      staleTime: 3600 * 100,
    },
  });

  const memoFileQueryKey = ["getMemoForApproval", { procurementId, params: { objectType: "4" } }];

  const memoFileQuery = useQuery({
    queryKey: memoFileQueryKey,
    queryFn: procurementAPI.getProcurementApprovalMemo,
    config: {
      enabled: !!procurementId,
    },
  });

  const planningStages = data?.procurementPlanActivities?.filter((x) => x.procurementPlanType === "PROCUREMENTPLANNING") || [];
  const executionStages = data?.procurementPlanActivities?.filter((x) => x.procurementPlanType === "PROCUREMENTEXECUTION") || [];

  const [mutate, { isLoading: updatingProcurement }] = useMutation(procurementAPI.extendStageDates, {
    onSuccess: () => {
      showAlert({
        message: "Stage dates updated successfully",
        severity: "success",
      });
      queryCache.invalidateQueries("generalPlanDetails");
      setEditRoadmap(false);
    },
    onError: (e) => {
      showAlert({
        message: "Stage dates failed to update",
        severity: "error",
      });
    },
  });

  const onSubmit = (data) => {
    const payload = Object.entries(data).map(([key, { startDate, endDate }]) => ({
      activityId: key.split("_")[1],
      startDate,
      endDate,
    }));

    mutate({ activities: payload });
  };

  const getActivityName = (title) =>
    title
      .replace(/[^\w\s]/gi, "")
      .split(" ")
      .join("")
      .toLowerCase();

  const percentageCompleted = useMemo(() => {
    if (!isSuccess) {
      return 0;
    }

    const done = data.procurementPlanActivities.filter(
      ({ procurementPlanActivityStatus }) => procurementPlanActivityStatus === "Approved"
    ).length;

    return (done / data.procurementPlanActivities.length) * 100;
  }, [data, isSuccess]);

  const renderStage = (stage = "") => {
    const textMapping = {
      INPROGRESS: "In Progress",
      COMPLETED: "Completed",
      NOTSTARTED: "Not Started",
    };

    switch (stage) {
      case "INPROGRESS":
        return <Tag text={textMapping[stage]} color="yellow" rounded={false} />;
      case "COMPLETED":
        return <Tag text={textMapping[stage]} color="blue" rounded={false} />;
      case "NOTSTARTED":
      default:
        return <Tag text={textMapping[stage]} color="green" rounded={false} />;
    }
  };

  if (isLoading) {
    return (
      <ContentWrapper>
        <Loader />
      </ContentWrapper>
    );
  }

  const redirectToFileUrl = (url) => {
    window.open(url, "_blank");
  };

  const onRenderError = (error) => <ErrorContainer>{error && error.message}</ErrorContainer>;

  return (
    <ContentWrapper>
      <PageTitle>{data.name}</PageTitle>
      <BreadCrumbWrapper>
        <Breadcrumbs>
          <Link to={"/admin/dashboard/overview"}>Home</Link>
          <Link to={"/admin/procurement/plans"}>Plans</Link>
          <Link to={`/admin/procurement/plans/${data?.annualProcurementPlanId}`}>{data?.ministryCode}</Link>
        </Breadcrumbs>
      </BreadCrumbWrapper>
      <CardWrapper>
        <TopSection>
          {renderStage(data.stage)}
          <SerialText>{data.packageNumber}</SerialText>
        </TopSection>
        <LinearProgressBar percentageOfCompletion={percentageCompleted?.toFixed(2)} />
        <CardSection style={{ marginTop: 20 }}>
          <CardLabel>Description</CardLabel>
          <CardLabel>{data.description}</CardLabel>
        </CardSection>
        <Divider />
        <CardSection>
          <CardLabel>Budget</CardLabel>
          <BoldCardContent>{formatCurrency(data.budget, true, true)}</BoldCardContent>
        </CardSection>
        <Divider />
        <CardBody>
          <CardSection>
            <CardLabel>Procurement Category</CardLabel>
            <CardContent>{data?.procurementCategory?.name}</CardContent>
          </CardSection>
          <CardSection>
            <CardLabel>Procurement Method</CardLabel>
            <CardContent>{data?.procurementMethod?.name}</CardContent>
          </CardSection>
          <CardSection>
            <CardLabel>Tendering Method</CardLabel>
            <CardContent>{data?.tenderingStage?.name}</CardContent>
          </CardSection>
          <CardSection>
            <CardLabel>Review Method</CardLabel>
            <CardContent>{data?.reviewMethod?.name}</CardContent>
          </CardSection>
        </CardBody>
        {Array.isArray(memoFileQuery.data) && memoFileQuery.data.length > 0 && (
          <div style={{ marginTop: 20 }}>
            <CardLabel>Approval Memo</CardLabel>
            <CardBody style={{ gridColumnGap: 15, marginTop: 10 }} spacing={4}>
              {memoFileQuery.data.map((memo) => {
                return (
                  <CardSection>
                    <FileProgress
                      progress={100}
                      fileName={memo?.file.original_filename}
                      fileSize={memo?.file.bytes}
                      hasEyes={true}
                      onClickEyes={() => redirectToFileUrl(memo?.file.url)}
                    />
                  </CardSection>
                );
              })}
            </CardBody>
          </div>
        )}
      </CardWrapper>
      <CardWrapper noPadding gray>
        {Array.isArray(memoFileQuery.data) && memoFileQuery.data.length === 0 && (
          <ApprovalMemo
            procurementId={procurementId}
            isProcurementOfficer={isProcurementOfficer}
            memoFileQueryKey={memoFileQueryKey}
          />
        )}

        {editRoadmap ? (
          <EditRoadmap>
            <HeadContent>
              <div className="title">Roadmap</div>
              <button onClick={() => setEditRoadmap(false)}>cancel</button>
            </HeadContent>
            <div className="stage">Procurement Planning</div>
            {planningStages.map(({ title, id, startDate, endDate }, index) => (
              <div key={id}>
                <div className="stageName">{title}</div>
                <div className="row">
                  <div style={{ width: "50%" }}>
                    <Controller
                      autoOk
                      name={`field${index}_${id}.startDate`}
                      render={({ onChange }) => {
                        const handleChange = (value) => {
                          if (isAfter(new Date(watch(`field${index}_${id}.endDate`)), new Date(value))) {
                            clearErrors(`field${index}_${id}.endDate`);
                          }
                          onChange(value);
                        };
                        return (
                          <DatePicker
                            rounded
                            label="Start Date"
                            value={startDate}
                            handleChange={handleChange}
                            style={{ width: "100%" }}
                          />
                        );
                      }}
                      control={control}
                      defaultValue={startDate}
                      rules={{
                        required: "Please enter start date",
                        validate: {
                          endDateGreaterThanStartDate: (value) => {
                            if (isAfter(new Date(value), new Date(watch(`field${index}_${id}.endDate`)))) {
                              return "Start date should be less than end date";
                            } else if (
                              index !== 0 &&
                              isAfter(
                                new Date(watch(`field${index - 1}_${planningStages[index - 1].id}.endDate`)),
                                new Date(value)
                              )
                            ) {
                              return "Start date should be ahead of previous end date";
                            } else {
                              clearErrors(`field${index}_${id}.startDate`);
                            }
                          },
                        },
                      }}
                    />
                    <div className="error">{onRenderError(errors[`field${index}_${id}`]?.startDate)}</div>
                  </div>
                  <div style={{ width: "50%" }}>
                    <Controller
                      autoOk
                      name={`field${index}_${id}.endDate`}
                      render={({ onChange }) => {
                        const handleChange = (value) => {
                          if (isBefore(new Date(watch(`field${index}_${id}.startDate`)), new Date(value))) {
                            clearErrors(`field${index}_${id}.startDate`);
                          }
                          onChange(value);
                        };
                        return (
                          <DatePicker
                            rounded
                            label="End Date"
                            handleChange={handleChange}
                            value={endDate}
                            style={{ width: "100%" }}
                          />
                        );
                      }}
                      control={control}
                      defaultValue={endDate}
                      rules={{
                        required: "Please enter end date",
                        validate: {
                          endDateGreaterThanStartDate: (value) => {
                            if (isAfter(new Date(value), new Date(watch(`field${index}_${id}.startDate`)))) {
                              clearErrors(`field${index}_${id}.endDate`);
                            } else {
                              return "End date should be ahead of start date";
                            }
                          },
                        },
                      }}
                    />
                    <div className="error">{onRenderError(errors[`field${index}_${id}`]?.endDate)}</div>
                  </div>
                </div>
              </div>
            ))}
            <Divider />
            <div className="stage">Procurement Execution</div>
            {executionStages.map(({ title, id, startDate, endDate }, index) => (
              <div key={id}>
                <div className="stageName">{title}</div>
                <div className="row">
                  <div style={{ width: "50%" }}>
                    <Controller
                      autoOk
                      name={`field${index + planningStages.length}_${id}.startDate`}
                      render={({ onChange }) => {
                        const handleChange = (value) => {
                          if (isAfter(new Date(watch(`field${index}_${id}.endDate`)), new Date(value))) {
                            clearErrors(`field${index}_${id}.endDate`);
                          }
                          onChange(value);
                        };
                        return (
                          <DatePicker
                            rounded
                            label="Start Date"
                            value={startDate}
                            handleChange={handleChange}
                            style={{ width: "100%" }}
                          />
                        );
                      }}
                      control={control}
                      defaultValue={startDate}
                      rules={{
                        required: "Please enter start date",
                        validate: {
                          endDateGreaterThanStartDate: (value) => {
                            if (
                              isAfter(new Date(value), new Date(watch(`field${index + planningStages.length}_${id}.endDate`)))
                            ) {
                              return "Start date should be less than end date";
                            } else if (index === 0 && isAfter( new Date(watch(`field${(index + planningStages.length) - 1}_${planningStages[planningStages.length - 1].id}.endDate`)), new Date(value))) {
                              return "Start date should be ahead of previous end date";
                            } 
                            else if (index > 0 && isAfter( new Date(watch(`field${(index + planningStages.length) - 1}_${executionStages[index - 1].id}.endDate`)), new Date(value))) {
                              return "Start date should be ahead of previous end date";
                            }
                            else {
                              clearErrors(`field${index}_${id}.startDate`);
                            }
                          },
                        },
                      }}
                    />
                    <div className="error">{onRenderError(errors[`field${index + planningStages.length}_${id}`]?.startDate)}</div>
                  </div>
                  <div style={{ width: "50%" }}>
                    <Controller
                      autoOk
                      name={`field${index + planningStages.length}_${id}.endDate`}
                      render={({ onChange }) => {
                        const handleChange = (value) => {
                          if (
                            isBefore(new Date(watch(`field${index + planningStages.length}_${id}.startDate`)), new Date(value))
                          ) {
                            clearErrors(`field${index + planningStages.length}_${id}.startDate`);
                          }
                          onChange(value);
                        };
                        return (
                          <DatePicker
                            rounded
                            label="End Date"
                            handleChange={handleChange}
                            value={endDate}
                            style={{ width: "100%" }}
                          />
                        );
                      }}
                      control={control}
                      defaultValue={endDate}
                      rules={{
                        required: "Please enter end date",
                        validate: {
                          endDateGreaterThanStartDate: (value) => {
                            if (
                              isAfter(new Date(value), new Date(watch(`field${index + planningStages.length}_${id}.startDate`)))
                            ) {
                              clearErrors(`field${index + planningStages.length}_${id}.endDate`);
                            } else {
                              return "End date should be ahead of start date";
                            }
                          },
                        },
                      }}
                    />
                    <div className="error">{onRenderError(errors[`field${index + planningStages.length}_${id}`]?.endDate)}</div>
                  </div>
                </div>
              </div>
            ))}
            {updatingProcurement ? (
              <CircularProgress style={{ color: "#0050C8", width: "24px", height: "24px" }} />
            ) : (
              <button disabled={Object.keys(errors).length > 0} className="save-btn" onClick={() => handleSubmit(onSubmit)()}>
                Save
              </button>
            )}
          </EditRoadmap>
        ) : (
          <TableWrapper>
            <TableTop>
              <TableHeader>
                <HeadContent>
                  <div>Roadmap</div>
                  <button onClick={() => setEditRoadmap(true)}>Edit</button>
                </HeadContent>
              </TableHeader>
            </TableTop>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Procurement Planning</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {planningStages.map((row) => {
                  const { title, startDate, endDate, id, procurementPlanActivityStatus, procurementPlanId } = row;
                  return (
                    <TableRow key={title}>
                      <TableCell>
                        {statusCircles[procurementPlanActivityStatus]}
                        {procurementPlanActivityStatus === "InActive" ? (
                          <Tooltip
                            title="Can't move to next activity if the preceding activity has not been completed"
                            aria-label="add"
                          >
                            <span> {title}</span>
                          </Tooltip>
                        ) : (
                          <>
                            {Array.isArray(memoFileQuery.data) && memoFileQuery.data.length > 0 ? (
                              <UnstyledLink
                                to={{
                                  pathname: `/admin/procurement/activity/${getActivityName(title)}/${id}/${procurementPlanId}`,
                                  state: { reviewMethod: data?.reviewMethod?.name },
                                }}
                              >
                                {title}
                              </UnstyledLink>
                            ) : (
                              <span> {title}</span>
                            )}
                          </>
                        )}
                      </TableCell>
                      <TableCell>{startDate ? moment(startDate).format("DD, MMMM, yyyy") : ""}</TableCell>
                      <TableCell>{endDate ? moment(endDate).format("DD, MMMM, yyyy") : " "}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableHead>
                <TableRow>
                  <TableCell>Procurement Execution</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {executionStages.map((row) => {
                  const { title, startDate, endDate, id, procurementPlanActivityStatus, procurementPlanId } = row;
                  return (
                    <TableRow key={title}>
                      <TableCell>
                        {procurementPlanActivityStatus === "Pending" && <StatusCirclePending marginRight={30} />}
                        {procurementPlanActivityStatus === "Approved" && <StatusCircleSuccess marginRight={30} />}
                        {procurementPlanActivityStatus === "InActive" && <StatusCircle marginRight={30} />}
                        {procurementPlanActivityStatus === "InActive" ? (
                          <Tooltip
                            title="Can't move to next activity if the preceding activity has not been completed"
                            aria-label="add"
                          >
                            <span> {title}</span>
                          </Tooltip>
                        ) : (
                          <>
                            {Array.isArray(memoFileQuery.data) && memoFileQuery.data.length > 0 ? (
                              <UnstyledLink
                                to={`/admin/procurement/activity/${getActivityName(title)}/${id}/${procurementPlanId}`}
                              >
                                {title}
                              </UnstyledLink>
                            ) : (
                              <span> {title}</span>
                            )}
                          </>
                        )}
                      </TableCell>

                      <TableCell>{startDate ? moment(startDate).format("DD, MMMM, yyyy") : ""}</TableCell>
                      <TableCell>{endDate ? moment(endDate).format("DD, MMMM, yyyy") : " "}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableWrapper>
        )}
      </CardWrapper>
      <Modal open={openModal.open} onClose={() => setOpenModal({ ...openModal, open: false })}>
        <Box className={classes.modalBox}>
          <Typography variant="h6" className={classes.modalTitle}>
            Edit Date
          </Typography>
          <Divider />
          <form onSubmit={handleSubmit}>
            <Box className={classes.formFlex}>
              <TextField
                id="date"
                label="Start Date"
                type="date"
                defaultValue={moment(openModal?.data?.startDate).format("YYYY-MM-DD")}
                className={classes.textField}
                onChange={(e) => setUpdateDate({ ...updateDate, newStartDate: e.target.value })}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                id="date"
                label="End Date"
                type="date"
                defaultValue={moment(openModal?.data?.endDate).format("YYYY-MM-DD")}
                className={classes.textField}
                onChange={(e) => setUpdateDate({ ...updateDate, newEndDate: e.target.value })}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
            <ButtonWrapper>
              <LoadingButton loading={updatingProcurement} type="submit">
                Update
              </LoadingButton>
            </ButtonWrapper>
          </form>
        </Box>
      </Modal>
    </ContentWrapper>
  );
};

export default withAdminDashboard(PlanDetails);
