import React, { useState, useEffect, useRef, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import { useMutation, useQuery } from "react-query";
import ExpressPay from "../../../assets/payment-gateways/express-pay.png";
import { ReactComponent as Info } from "../../../assets/round_caution.svg";
import {
  CustomDialog,
  CustomDialogTitle,
  Caption,
  CustomDialogContent,
  CustomDialogTitleWrapper,
  RegistrationCategoryWrapper,
  Subtitle,
  ApplicationPeriod,
  ApplicationDates,
  PayText,
  RegistrationCategoryContent,
  RegistrationCategoryClass,
  RegistrationCategorySubtitle,
  RegistrationCategoryValue,
  SubmitButton,
  GatewayCard,
  OfflinePayCard,
  PrevBtn,
  ProofBtn,
} from "./style";
import { formatNumber, separateNumByComma } from "../../../util/truncateValue";
import paymentAPI from "../../../redux/api/paymentAPI";
import { Radio } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import FileProgress from "../../../components/FileProgress/FileProgress";
import { useSelector } from "react-redux";
import ToastContext from "../../../util/toastContext";

const ConfirmVerificationModal = ({ toggleModal, isOpen, vendorProfileData }) => {
  const [paymentType, setPaymentType] = useState("online");
  const [initiatePayment, setInitiatePayment] = useState(false);
  const { data: paymentPayload, status } = useQuery("initiatePayment", paymentAPI.initiatePayment, {
    enabled: initiatePayment,
  });
  const [file, setFile] = useState(null);
  const inputRef = useRef();
  const {
    user: { UserId },
  } = useSelector((state) => state.user);
  const { showAlert } = useContext(ToastContext);

  const style = useStyles();

  const { contractMinValue, contractMaxValue, fee, grade } = vendorProfileData;

  const handleSelectFile = (e) => {
    if (e.target.files && e.target.files[0]) setFile(e.target.files[0]);
  };

  useEffect(() => {
    if (paymentPayload) {
      const body = {
        publicKey: paymentPayload.publicKey,
        transactionId: paymentPayload.transactionId,
        amount: paymentPayload.amount,
        currency: paymentPayload.currency,
        country: paymentPayload.country,
        email: paymentPayload.email,
        phoneNumber: paymentPayload.phoneNumber,
        firstName: paymentPayload.firstName,
        lastName: paymentPayload.lastName,
        hash: paymentPayload.hash,
        callbackUrl: paymentPayload.callbackUrl,
        logoURL: paymentPayload.logoUrl,
      };

      window.xpressPayonlineSetup(body);

      setInitiatePayment(false);
      toggleModal(false);
    }
  }, [paymentPayload, toggleModal]);

  const handlePayment = () => {
    setInitiatePayment(true);
  };

  const handleCancel = () => setFile(null);

  const [uploadPaymentProof, { isLoading, isSuccess }] = useMutation(paymentAPI.vendorRegistrationPaymentProof, {
    throwOnError: true,
    onError: (error) => {
      showAlert({
        severity: "error",
        durationInMs: 5000,
        message: error,
      });
    },
    onSuccess: () => {
      showAlert({
        severity: "success",
        durationInMs: 5000,
        message: "Proof has been uploaded for verification successfully",
      });
      toggleModal(false);
    },
  });

  const handleProof = async () => {
    await uploadPaymentProof({
      vendorId: UserId,
      file,
    });
  };

  return (
    <CustomDialog scroll="body" onClose={() => toggleModal(false)} open={isOpen}>
      <CustomDialogTitleWrapper>
        <CustomDialogTitle disableTypography>Check before proceeding</CustomDialogTitle>
        <Caption variant="caption">Ensure the information on this page is correct before clicking the “Submit” button.</Caption>
      </CustomDialogTitleWrapper>

      <CustomDialogContent>
        <RegistrationCategoryWrapper>
          <Subtitle>Registration Category</Subtitle>
          <RegistrationCategoryContent>
            <RegistrationCategoryClass>{grade && grade[6]}</RegistrationCategoryClass>
            <div>
              <Grid container>
                <RegistrationCategorySubtitle>Contract Value and Threshold:</RegistrationCategorySubtitle>
                {formatNumber(contractMinValue) === "100M" ? (
                  <RegistrationCategoryValue>100M and Above</RegistrationCategoryValue>
                ) : (
                  <RegistrationCategoryValue>
                    {formatNumber(contractMinValue)} - {formatNumber(contractMaxValue)}
                  </RegistrationCategoryValue>
                )}
              </Grid>
              <Grid container>
                <RegistrationCategorySubtitle>Service Fee:</RegistrationCategorySubtitle>
                <RegistrationCategoryValue>N{separateNumByComma(Number(fee))}</RegistrationCategoryValue>
              </Grid>
            </div>
          </RegistrationCategoryContent>
        </RegistrationCategoryWrapper>

        <ApplicationPeriod>
          <Subtitle>Application Period</Subtitle>
          <ApplicationDates>January 23, 2020 - December 31, 2020</ApplicationDates>
        </ApplicationPeriod>
        <hr />
        <Subtitle>Select Payment Type</Subtitle>
        <Grid container direction="row" justify="flex-start" alignItems="center">
          <BlueRadio
            checked={paymentType === "online"}
            onChange={(e) => setPaymentType(e.target.value)}
            value="online"
            name="payment"
            inputProps={{ "aria-label": "online" }}
          />
          <PayText checked={paymentType === "online"}>Online Payment</PayText>
          <BlueRadio
            checked={paymentType === "offline"}
            onChange={(e) => setPaymentType(e.target.value)}
            value="offline"
            name="payment"
            inputProps={{ "aria-label": "offline" }}
          />
          <PayText checked={paymentType === "offline"}>Offline Payment</PayText>
        </Grid>

        {paymentType === "online" && (
          <>
            <GatewayCard>
              <img className={style.img} src={ExpressPay} alt="" />
              <p>Express Pay</p>
            </GatewayCard>
          </>
        )}

        {paymentType === "offline" && (
          <>
            <OfflinePayCard>
              <div className="icon">
                <Info />
              </div>
              <p className="title">Offline Payment Instruction</p>
              {isSuccess ? (
                <p className="instruction">
                  Your payment has been sent for verification, you will be notified after it has been reviewed
                </p>
              ) : (
                <p className="instruction">
                  {" "}
                  Make a payment of N{separateNumByComma(Number(fee))} to Delta State IGR account in any bank close to you
                </p>
              )}
              {file && (
                <FileProgress fileName={file.name} fileSize={file.size} progress={100} hasCancel onCancel={handleCancel} />
              )}
              <ProofBtn disabled={!!file} onClick={() => inputRef.current.click()}>
                Upload Proof of Payment
              </ProofBtn>
              <input ref={inputRef} type="file" style={{ display: "none" }} onChange={handleSelectFile} />
            </OfflinePayCard>
          </>
        )}
      </CustomDialogContent>

      <Grid container justify="space-between">
        <PrevBtn onClick={() => toggleModal(false)}>Previous</PrevBtn>
        {paymentType === "online" ? (
          <SubmitButton disabled={status === "loading"} onClick={handlePayment}>
            {status === "loading" ? "Please wait..." : "Pay Now"}
          </SubmitButton>
        ) : (
          <SubmitButton disabled={!file || isLoading} onClick={handleProof}>
            {isLoading ? "Uploading proof of payment..." : "Send for Verification"}
          </SubmitButton>
        )}
      </Grid>
    </CustomDialog>
  );
};

export default ConfirmVerificationModal;

const BlueRadio = withStyles({
  root: {
    color: "#5F7483",
    "&$checked": {
      color: "#2D9CDB",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const useStyles = makeStyles({
  img: {
    height: "35px",
    width: "47px",
    display: "block",
    margin: "0 auto 0",
  },
});
