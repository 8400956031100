import React from "react";
import { Box, Container } from "@material-ui/core";
import { useParams } from "react-router-dom";
import WatermarkOne from "../../../../../assets/watermark11.png";
import WatermarkTwo from "../../../../../assets/watermarkBL.png";
import LoadingIcon from "../../../../../assets/QR_Loading.gif";
import ErrorIcon from "../../../../../assets/QR_Error.gif";
import Logo from "../../../../../assets/deltaLogo.png";
import { ReactComponent as Checkmark } from "../../../../../assets/verify_cert_checkmark.svg";
import {
  VerifyWatermark1, VerifyWatermark2, GreenTitle, Title,
  StyledTableTitle,
  VerifySignatureContainer,
  SignaturePostion,
  // OfficerName
} from "./style";
import { format } from "date-fns/esm";
import { formatCurrency } from "../../../../../util/formatCurrency";
import { useFetchCertificateDetails } from "../../../../../hooks/queries/useCertificate";

const VerifyCertificate = () => {
  const { id } = useParams();

  const { data, isLoading, isSuccess, isError } = useFetchCertificateDetails({ id })

  return (
    <Container maxWidth="sm">
      <VerifyWatermark1>
        <img src={WatermarkOne} alt="Delta state logo" />
      </VerifyWatermark1>
      <VerifyWatermark2>
        <img src={WatermarkTwo} alt="Delta state logo" />
      </VerifyWatermark2>
      <Box pt={5} px={4}>
        <Box mb={5} display="flex" justifyContent="space-between">
          <Box style={{ flex: 1, display: "grid", placeItems: "center" }}>
            <img src={Logo} alt="Delta state logo" />
          </Box>

          <Box mb={2} mt="8px" style={{
            position: "absolute", top: "28px", right: "40px"
          }}>
            <StyledTableTitle>Date issued</StyledTableTitle>
            <StyledTableTitle fontWeight={700}>{data && format(new Date(), "dd/MMMM/yyyy")}</StyledTableTitle>
          </Box>
        </Box>

        <Title color="#DA1414" fontWeight={600} fontSize={14}>
          GOVERNMENT OF DELTA STATE NIGERIA
        </Title>
        <Title fontWeight={700}>DELTA STATE PUBLIC PROCUREMENT COMMISSION</Title>
        <Box display="flex" justifyContent="center" my={5}>
          {isLoading && <img width={200} height={200} src={LoadingIcon} alt="verifying certificate" />}
          {isError && <img width={200} height={200} src={ErrorIcon} alt="Failed to verify certificate" />}
          {isSuccess && <Checkmark />}
        </Box>

        {isSuccess && (
          <>
            <GreenTitle>CERTIFICATE OF NO OBJECTION FOR THE AWARD OF CONTRACT</GreenTitle>
            <Title>Issued to <strong> {data?.contractor || 'Not Applicable'}</strong></Title>
            <Box mb={2} mt="8px">
              <StyledTableTitle>Project description</StyledTableTitle>
              <StyledTableTitle fontSize="14px" fontWeight={700} >{data?.projectDescription || "Not Applicable"}</StyledTableTitle>
            </Box>
            <Box mb={2} mt="8px">
              <StyledTableTitle>Project cost as Requested</StyledTableTitle>
              <StyledTableTitle fontSize="14px" fontWeight={700} >{formatCurrency(data?.quotation || 0, true)}</StyledTableTitle>
            </Box>
            <Box mb={2} mt="8px">
              <StyledTableTitle>DSPPC reviewed Total Project Cost</StyledTableTitle>
              <StyledTableTitle fontSize="14px" fontWeight={700}>{formatCurrency(data?.reviewedQuotation || 0, true)}</StyledTableTitle>
            </Box>
            <Box mb={2} mt="8px">
              <StyledTableTitle>Cost Reduction</StyledTableTitle>
              <StyledTableTitle fontSize="14px" fontWeight={700}>{formatCurrency(data?.costReduction || 0, true)}</StyledTableTitle>
            </Box>
            <Box mb={2} mt="8px">
              <StyledTableTitle>client ministry/department/agency</StyledTableTitle>
              <StyledTableTitle fontSize="14px" fontWeight={700}>{data?.ministry || "Not Applicable"}</StyledTableTitle>
            </Box>
            <Box mb={2} mt="8px">
              <StyledTableTitle>Head/Sub-head/appropriation Code</StyledTableTitle>
              <StyledTableTitle fontSize="14px" fontWeight={700}>{`${data?.headCode || ""}/${data?.subHeadCode || ""}/${data?.appropriationCode || ""}`}</StyledTableTitle>
            </Box>
            {/* </>
            )} */}
          </>
        )}
        <VerifySignatureContainer>
          <div>
            <div>
              <img src={data?.signatureUrl} height="90px" width="70%" alt="Officer Signature" />
            </div>
            {/* <OfficerName>
              {data?.name || "Not Applicable"}
            </OfficerName> */}
            <SignaturePostion>
              {data?.position1 || "DIRECTOR-GENERAL"}
            </SignaturePostion>
          </div>
        </VerifySignatureContainer>
      </Box>
    </Container>
  );
};

export default VerifyCertificate;
