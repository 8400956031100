import React from "react";
import { Grid, Box, Tooltip } from "@material-ui/core";
import moment from "moment";

import { ContractDetailTabText } from "./style";
import { formatCurrency } from "../../../../util/formatCurrency";

const AwardedCompanyTab = ({
  data: { awardedDate, awardCriteria, budgetDescription, title, description, amount, contractor, contractSum } = {},
}) => {
  const details = [
    {
      title: "Date of award",
      value: awardedDate ? moment(awardedDate).format("DD-MMM-yyyy") : "--",
    },
    { title: "Project Title", value: title || "--", maxLength: 120 },
    { title: "Project description", value: description || title || "--", maxLength: 120 },
    { title: "Budget Description	", value: budgetDescription || "--", maxLength: 120 },
    // { title: "Award Acceptance Date ", value: contractDetails.acceptanceDate ? moment(contractDetails.acceptanceDate).format("DD, MMMM, yyyy") : "--" },
    {
      title: "Award Remark/Criteria	",
      value: awardCriteria || "--",
    },

    { title: "Contract Sum", value: formatCurrency(contractSum || 0, true) || "--" },
    { title: "Contractor", value: contractor || "--" },
  ];

  return (
    <>
      <Box>
        {details.map((detail, i) => (
          <Grid key={i} style={{ borderBottom: "1px solid #CBD4DA" }} container>
            <Grid item xs={3} style={{ background: "#e4e9ec" }}>
              <ContractDetailTabText style={{ background: "#e4e9ec", boxShadow: "0px -1px 0px #C9D4DB" }}>
                {detail.title}
              </ContractDetailTabText>
            </Grid>
            <Grid item sm style={{ borderLeft: "1px solid #CBD4DA" }}>
              {detail.maxLength && detail.length > detail.maxLength ? (
                <Tooltip title={detail.value} aria-label="add">
                  <ContractDetailTabText>
                    {detail.value.length > detail.maxLength ? `${detail.value.substr(0, detail.maxLength)}...` : detail.value}
                  </ContractDetailTabText>
                </Tooltip>
              ) : (
                <ContractDetailTabText>{detail.value}</ContractDetailTabText>
              )}
            </Grid>
          </Grid>
        ))}
      </Box>
    </>
  );
};

export default AwardedCompanyTab;
