import React from "react";
import SearchTable, { StyledTableCell, StyledTableRow } from "../SearchTable";
import { Box, TableCell } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { ModdedAvatar, TableCellEmail } from "../../pages/Admin/Procurement/Activitiies/ReceiptOfResponse/VendorsTable/style";
import IconButton from "@material-ui/core/IconButton";
import { ReactComponent as RightIcon } from "../../assets/right_icon.svg";
import { useHistory, useParams } from "react-router-dom";
import { formatCurrency } from "../../util/formatCurrency";

const recommendedMap = {
  1: "Recommended",
  9: "Not Recommended",
};

const columns = {
  withRec: ["NAME", "QUOTATION", "TAX", "DSIR", "MEMO", "PROFILE", "PAST JOB", "RECOMMENDATION STATUS", ""],
  withoutRec: ["NAME", "QUOTATION", "TAX", "DSIR", "MEMO", "PROFILE", "PAST JOB", ""],
};

const EvaluatedVendorsTable = ({
  pageNumber,
  pageSize,
  setPageNumber,
  setPageSize,
  totalPages,
  isLoading,
  vendors = [],
  showRecommended = false,
  type,
}) => {
  const history = useHistory();
  const { activityId, procurementId } = useParams();

  const redirectUser = (vendorId) => {
    if (type === "certificate") {
      return history.push(
        `/admin/procurement/activity/certificateofnoobjection/${activityId}/${procurementId}/evaluation/${vendorId}`
      );
    } else {
      return history.push(
        `/admin/procurement/activity/receiptofbiddersresponse/${activityId}/${procurementId}/evaluation/${vendorId}`
      );
    }
  };

  return (
    <SearchTable
      columns={showRecommended ? columns.withRec : columns.withoutRec}
      page={pageNumber}
      setPage={setPageNumber}
      rowsPerPage={pageSize}
      setRowsPerPage={setPageSize}
      loading={isLoading}
      totalNumberOfPages={totalPages}
      empty={vendors.length === 0}
      emptyMessage="You do not have any pending Expression of Interest to attend to at the moment"
    >
      {vendors.map(
        (
          { companyName, userId, email, quotation, taxClearance, dsirCard, companyMemo, companyProfile, pastJobs, type },
          index
        ) => (
          <StyledTableRow key={index}>
            <TableCell>
              <Grid spacing={1} container wrap="nowrap">
                <Box mr={2}>
                  <ModdedAvatar size={34} color="#50B83C">
                    {companyName?.slice(0, 2)}
                  </ModdedAvatar>
                </Box>
                <Grid container direction="column" spacing={1}>
                  <span>{companyName}</span>
                  <TableCellEmail>{email}</TableCellEmail>
                </Grid>
              </Grid>
            </TableCell>
            <StyledTableCell>{formatCurrency(quotation, true)}</StyledTableCell>
            <StyledTableCell>{String(taxClearance)}</StyledTableCell>
            <StyledTableCell>{String(dsirCard)}</StyledTableCell>
            <StyledTableCell>{String(companyMemo)}</StyledTableCell>
            <StyledTableCell>{String(companyProfile)}</StyledTableCell>
            <StyledTableCell>{String(pastJobs)}</StyledTableCell>
            {showRecommended && <StyledTableCell>{recommendedMap[type]}</StyledTableCell>}
            <TableCell align="right">
              <IconButton onClick={() => redirectUser(userId)}>
                <RightIcon fill="#637381" width={18} />
              </IconButton>
            </TableCell>
          </StyledTableRow>
        )
      )}
    </SearchTable>
  );
};

export default EvaluatedVendorsTable;
