import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { ContractDetailTabText, ContractRemoveText } from "./style";
import EmptyTableBody from "../../../../components/EmptyTableBody";
import { EmptyTableSubTitle } from "../../../../components/EmptyTableBody/style";
import DeletesModal from "../components/delete";
import { useMutation } from "react-query";
import contractsAPI from "../../../../redux/api/contractAPI";
import { useParams } from "react-router-dom";
const useStyles = makeStyles({
  alignCenter: {
    textAlign: "center",
  },
});

const SecondaryTable = ({ tableBody, tableHeader, refetch }) => {
  const classes = useStyles();
  const [isOpen, setModal] = useState(false);
  const params = useParams();
  const [bidder, setBidder] = useState(null);
  const [deleteBidder, { isLoading }] = useMutation(contractsAPI.deleteBidder, {
    onSuccess: () => {
      setModal(false);
      refetch();
    },
  });

  const onBidderDelete = (detail) => () => {
    setModal(true);
    setBidder(detail);
  };

  const onClickDelete = () => {
    deleteBidder({ contractId: params.id, id: bidder.id });
  };

  return (
    <>
      <DeletesModal isLoading={isLoading} bidder={bidder} onClickDelete={onClickDelete} open={isOpen} setModal={setModal} />
      <Grid direction="row" style={{ borderBottom: "1px solid #CBD4DA", width: "100%", backgroundColor: "#CBD4DA" }} container>
        {tableHeader.map((detail, i) => (
          <Grid
            key={i}
            className={classes[detail.align]}
            item
            xs={detail.grid}
            style={{ backgroundColor: detail.color, width: detail.width, borderLeft: "1px solid #CBD4DA" }}
          >
            <ContractDetailTabText style={{ color: "#fff", paddingTop: 10 }}>{detail.title}</ContractDetailTabText>
          </Grid>
        ))}
      </Grid>
      {tableBody.map((el, i) => (
        <Grid key={i} direction="row" style={{ borderBottom: "1px solid #CBD4DA" }} container>
          {tableHeader.map((detail) => (
            <Grid
              key={detail.id + i}
              className={classes[detail.align]}
              item
              xs={detail.grid}
              style={{ width: detail.width, borderLeft: "1px solid #CBD4DA" }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <ContractDetailTabText>{detail.indexed ? i + 1 : el[detail.id]}</ContractDetailTabText>
                {detail.id === "evaluationStatus" && (
                  <ContractRemoveText style={{ cursor: "pointer" }} onClick={onBidderDelete(el)}>
                    Remove
                  </ContractRemoveText>
                )}
              </div>
            </Grid>
          ))}
        </Grid>
      ))}
      {tableBody.length === 0 && (
        <EmptyTableBody>
          <EmptyTableSubTitle>No data yet</EmptyTableSubTitle>
        </EmptyTableBody>
      )}
    </>
  );
};

export default SecondaryTable;
