import React from "react";
import { Grid, Tooltip } from "@material-ui/core";
// import moment from "moment";
import { ContractDetailTabText } from "./style";
import { formatCurrency } from "../../../../util/formatCurrency";
import { dateFormat } from "../../../../util/formatDate";

const ContractDetailTab = ({ data: contractDetails = {} }) => {
  const details = [
    { title: "Project Title", value: contractDetails.title || "--", maxLength: 120 },
    {
      title: "Project Description",
      value: contractDetails.description || contractDetails.title || "--",
      maxLength: 120,
    },
    { title: "Budget Description", value: contractDetails.budgetDescription || "--", maxLength: 120 },
    { title: "Budget Year", value: contractDetails.budgetYear || "--" },
    {
      title: "Budgeted Amount	",
      value: contractDetails.amount ? formatCurrency(contractDetails.amount || 0, true) : "--",
    },
    { title: "Procurement Plan	", value: contractDetails.procurementPlan || "--" }, // depends on busgdget desc
    {
      title: "Tender Period	",
      value: `${dateFormat(contractDetails.bidSolicitationDate)} to ${dateFormat(contractDetails.bidOpeningDate)}`,
    },
    { title: "Procuring Entity / SubSector	", value: contractDetails.procuringEntity || "--", maxLength: 120 },
    { title: "Procurement Category	", value: contractDetails.category || "--" },

    {
      title: "Bid Solicitation Date	",
      value: dateFormat(contractDetails.bidSolicitationDate),
    },
    {
      title: "Bid Opening Date		",
      value: dateFormat(contractDetails.bidOpeningDate),
    },
  ];

  return (
    <>
      {details.map((detail, i) => (
        <Grid key={i} style={{ borderBottom: "1px solid #CBD4DA" }} container>
          <Grid item xs={12} sm={3} style={{ background: "#e4e9ec" }}>
            <ContractDetailTabText style={{ boxShadow: "0px -1px 0px #C9D4DB" }}>{detail.title}</ContractDetailTabText>
          </Grid>
          <Grid item xs={12} sm={9} style={{ borderLeft: "1px solid #CBD4DA" }}>
            {detail.maxLength && detail.length > detail.maxLength ? (
              <Tooltip title={detail.value} aria-label="add">
                <ContractDetailTabText>
                  {detail.value.length > detail.maxLength ? `${detail.value.substr(0, detail.maxLength)}...` : detail.value}
                </ContractDetailTabText>
              </Tooltip>
            ) : (
              <ContractDetailTabText>{detail.value}</ContractDetailTabText>
            )}
          </Grid>
        </Grid>
      ))}
    </>
  );
};

export default ContractDetailTab;
