import React from "react";
import moment from "moment";
import PropTypes from 'prop-types';
import {
  Page,
  View,
  Text,
  Font,
  Document,
  StyleSheet
} from '@react-pdf/renderer';
import { formatCurrency } from "../../../util/formatCurrency";

Font.register({
  family: 'Roboto',
  fonts: [
    { src: '/fonts/Roboto-Regular.ttf' },
    { src: '/fonts/Roboto-Bold.ttf' }
  ]
});

const styles = StyleSheet.create({
  subtitle2: { fontSize: 9, fontWeight: 700 },
  alignRight: { textAlign: 'right' },
  page: {
    padding: '40px 24px 0 24px',
    fontSize: 9,
    lineHeight: 1.6,
    fontFamily: 'Roboto',
    backgroundColor: '#fff',
    textTransform: 'capitalize'
  },

  table: { display: 'flex', width: 'auto', 
  boxShadow:"0 2px 4px 0 #cbd4da", 
borderBottomWidth: 1,
borderStyle: 'solid',
borderColor: '#DFE3E8',
  borderRadius: "3px" },
  tableHeader: {
      backgroundColor: "#cbd4da"
  },
  tableBody: {},
  tableRow: {
    padding: '8px 0',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: '#DFE3E8'
  },
  tableCell_1: { padding: 2, width: '150px' },
  tableCell_2: { padding: 2, width: '200px' },
  tableCell_3: { padding: 2, width: '280px' }
});

ContractsPDF.propTypes = {
    contracts: PropTypes.array
};

export default function ContractsPDF({ contracts = [] }) {
  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>

        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <View style={styles.tableRow}>
              <View style={styles.tableCell_1}>
                <Text style={styles.subtitle2}>OCID</Text>
              </View>
              <View style={styles.tableCell_3}>
                <Text style={styles.subtitle2}>Project Name</Text>
              </View>
              
              <View style={styles.tableCell_1}>
                <Text style={styles.subtitle2}>Date Published</Text>
              </View>
              <View style={styles.tableCell_3}>
                <Text style={styles.subtitle2}>Ministry</Text>
              </View>
              <View style={styles.tableCell_1}>
                <Text style={styles.subtitle2}>Award Date</Text>
              </View>

              <View style={styles.tableCell_3}>
                <Text style={styles.subtitle2}>Contractor</Text>
              </View>      
              <View style={styles.tableCell_2}>
                <Text style={styles.subtitle2}>Amount</Text>
              </View>      
            </View>
          </View>

          <View style={styles.tableBody}>
            {contracts.map((contract) => {
                return (
              <View style={styles.tableRow} key={contract.id}>
                <View style={styles.tableCell_1}>
                  <Text>{contract.ocidNumber}</Text>
                </View>
                <View style={styles.tableCell_3}>
                  <Text style={styles.subtitle2}>{contract.title}</Text>
                </View>
                <View style={styles.tableCell_1}>
                  <Text>{moment(contract.datePublished).format("DD MMM, YYYY")}</Text>
                </View>
                <View style={styles.tableCell_3}>
                  <Text>{contract?.ministry}</Text>
                </View>

                <View style={styles.tableCell_1}>
                  <Text>{moment(contract.awardedDate).format("DD MMM, YYYY")}</Text>
                </View>
                <View style={styles.tableCell_3}>
                  <Text>{contract.contractor}</Text>
                </View>
                <View style={styles.tableCell_2}>
                  <Text>NGN{formatCurrency(contract.amount)}</Text>
                </View>
               
              </View>
            )})}
          </View>
        </View>
      </Page>
    </Document>
  );
}
