import React, { useState } from "react";
import { Grid, Container, useMediaQuery } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import moment from "moment";

import {
  VendorLoginButton,
  BackgroundOverlay,
  BackgroundCarousel,
  VendorRegistrationButton,
  WelcomeText,
  LandingDividerLine,
  LandingInfo,
  NewsContainer,
  NewsContent,
  NewsSection,
  UpdateText,
  LatestUpdateContainer,
  WelcomeSpeechSection,
  WelcomeSpeechImage,
  WelcomeSpeechText,
  OpenQuote,
  CloseQuote,
  AboutUsSection,
  AboutUsContainer,
  AboutUsContentContainer,
  LandingTitleText,
  SeparatorLine,
  LandingComment,
  CompanyDetailsContainer,
  WelcomeTextContainer,
  HandIcon,
  RoleContentCard,
  RoleContentCardTitle,
  RoleContentCardFooter,
  WelcomeSpeechContainerContent,
  ProjectInfoSection,
  ProjectInfoContainer,
  ProjectInfoSectionWrapper,
  ProjectContent,
  ProjectContentValue,
  ProjectContentLabel,
  RequestTenderWrapper,
  BlankSection,
  BlankSectionWrapper,
  NewsUpdateSection,
  FlexContainer,
  ViewAllText,
  NewsDateLabel,
  NewsCardWrapper,
  CompletedProjectSection,
  CompletedProjectContainer,
  CompletedProjectImages,
  CompletedProjectCards,
  CompletedProjectCardsContent,
  CompletedProjectAvatar,
  CompletedProjectAvatarText,
  CompletedProjectCaptionText,
  Hero,
  SliderContent,
  NewsSectionSliderContent,
  RolesAndResponsibilitySectionSliderContent,
} from "./style";

import withHeaderFooterHOC from "../HOC/withHeaderFooterHOC";
import TendersOrContractCard from "../../../components/TendersOrContractsListCard";
import NewsHeadlinesCard from "../../../components/NewsHeadlinesCard";
import { NewsHeadlinesClickListCard } from "../../../components/NewsHeadlineListCard/index";

import { news, mainNews } from "../NewsAndBulletins/mock";
import procurementAPI from "../../../redux/api/procurementAPI";
import { EmptyTableSubTitle } from "../../../components/EmptyTableBody/style";
import Loader from "../../../components/Common/Loader";
import DeltaGovImg from "../../../assets/delta_gov.png";

const linkStyle = { color: "#fff", textDecoration: "none" };

const newsHeadlines = [
  {
    headline: "Acting NDDC boss collapses during House hearing…",
    date: "July 16, 2020",
  },
  {
    headline: "Nigeria at 60, a wake of a new dawn…",
    date: "July 16, 2020",
  },
  {
    headline: "Post Covid19 era, what to look out for…",
    date: "July 16, 2020",
  },
];

const Sliders = () => {
  return (
    <SliderContent>
      <BackgroundOverlay>
        <Container>
          <WelcomeText>Welcome to Delta State</WelcomeText>
          <WelcomeText>E-Procurement Vendor Platform</WelcomeText>
          <LandingDividerLine />
          <LandingInfo>
            Are you interested in bidding for a contract for your organization or for an individual? Click the link below to
            register
          </LandingInfo>
          <VendorRegistrationButton>
            <Link to="/create-account" style={linkStyle}>
              Vendor Registration
            </Link>
          </VendorRegistrationButton>
        </Container>
      </BackgroundOverlay>
    </SliderContent>
  );
};

const MissionCard = () => (
  <Grid item xs={12} sm={6} md={3}>
    <RoleContentCard>
      <HandIcon />
      <RoleContentCardTitle>Creating Policies & Guidelines</RoleContentCardTitle>
      <LandingComment>
        To ensure a transparent, competitive, inclusive, sustainable and value-for-money procurement system.
      </LandingComment>
      <RoleContentCardFooter>Learn more</RoleContentCardFooter>
    </RoleContentCard>
  </Grid>
);

const CompleteProjectNewsCard = () => (
  <Grid item xs={12} sm={6} md={4}>
    <CompletedProjectCards>
      <CompletedProjectImages />
      <CompletedProjectCardsContent>
        <FlexContainer>
          <NewsDateLabel>15 Jul, 2020</NewsDateLabel>
          <FlexContainer>
            <CompletedProjectAvatar />
            <CompletedProjectAvatarText>DEP</CompletedProjectAvatarText>
          </FlexContainer>
        </FlexContainer>
        <CompletedProjectCaptionText>
          The development of the oke - osa local government area link road and 10 overhead bridge.
        </CompletedProjectCaptionText>
        <p>
          His excellency sen. Ifeanyi arthur okowa, has partnered with the external bodies association to make the transparency of
          procurement…
        </p>
      </CompletedProjectCardsContent>
    </CompletedProjectCards>
  </Grid>
);

const Landing = () => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const [currentMainNews, setCurrentMainNews] = useState(mainNews);
  const { data: procurementResponse, status } = useQuery(["getAllProcurementsData"], procurementAPI.getAllProcurements);
  const procurementResponseData = procurementResponse?.data || [];
  const procurementResponseMetaData = procurementResponse?.meta;
  const handleCardClick = (news) => {
    setCurrentMainNews(news);
  };

  const getTenderStatus = (closeDate) => {
    let status;
    let date = new Date(closeDate);
    let now = new Date();

    if (date > now) {
      status = "Opened";
    } else {
      status = "Closed";
    }
    return status;
  };

  return (
    <div>
      <Hero>
        <BackgroundCarousel showThumbs={false} autoPlay infiniteLoop showArrows={false} interval={5000}>
          {[1, 2, 3].map((eachElement) => (
            <Sliders key={eachElement} />
          ))}
        </BackgroundCarousel>
      </Hero>

      <NewsSection>
        <Container style={{ background: "#F2F2F2" }}>
          {isSmallScreen ? (
            <BackgroundCarousel
              alignDots="center"
              mb="20px"
              showThumbs={false}
              // autoPlay
              infiniteLoop
              showArrows={false}
              centerMode={true}
            >
              {newsHeadlines.map((eachElement, i) => (
                <Container
                  style={{
                    background: "#F2F5F7",
                  }}
                >
                  <NewsSectionSliderContent>
                    <UpdateText>{eachElement.headline}</UpdateText>
                    <UpdateText>{eachElement.date}</UpdateText>
                  </NewsSectionSliderContent>
                </Container>
              ))}
            </BackgroundCarousel>
          ) : (
            <NewsContainer container>
              <LatestUpdateContainer item xs={3}>
                <UpdateText>Latest PPA Updates</UpdateText>
              </LatestUpdateContainer>
              {newsHeadlines.map((eachElement) => (
                <NewsContent item xs={3}>
                  <p>{eachElement.headline}</p>
                  <span>{eachElement.date}</span>
                </NewsContent>
              ))}
            </NewsContainer>
          )}
        </Container>
      </NewsSection>

      <Container>
        <WelcomeSpeechSection>
          <WelcomeSpeechImage>
            <img
              src="https://res.cloudinary.com/prunedge/image/upload/v1704812853/Delta%20State%20Seal/delta_gov_yhtggx.webp"
              alt="delta_gov"
            />
          </WelcomeSpeechImage>
          <WelcomeSpeechContainerContent>
            <div>
              <WelcomeTextContainer>
                <OpenQuote>“</OpenQuote>
                <WelcomeSpeechText>
                  Welcome to the Delta State Public Procurement Agency Website. This website has been set up to meet your
                  information needs on the operations of the Agency. We remain committed to serving you as best as possible.
                </WelcomeSpeechText>
                <CloseQuote>“</CloseQuote>
              </WelcomeTextContainer>
            </div>
          </WelcomeSpeechContainerContent>
        </WelcomeSpeechSection>
      </Container>
      <Container>
        <AboutUsSection>
          <AboutUsContainer container spacing={3}>
            <AboutUsContentContainer item xs={12} sm={6}>
              <LandingTitleText>About Us</LandingTitleText>
              <SeparatorLine />
              <LandingComment>
                The Delta State Public Procurement Agency (the Agency) was established on the 2nd of April, 2012 under the Delta
                State Public procurement Law, 2011 (the Law), The objectives of the Agency are to ensure probity, accountability
                and transparency in Public Procurement throughout Delta State. The Agency is therefore charged with the
                responsibility of formulating Policies and guidelines relating to Procurement in Delta State as well as certifying
                all Procurements prior to, during and after the awards of Contract.
              </LandingComment>
              <VendorLoginButton>
                <Link to="/about" style={linkStyle}>
                  Learn More
                </Link>
              </VendorLoginButton>
            </AboutUsContentContainer>
            <CompanyDetailsContainer item xs={12} sm={6}>
              <div>
                <div>
                  <HandIcon />
                  <p>Vision</p>
                  <span>To be a model regulator assuring best practices in public procurement</span>
                </div>

                <div>
                  <HandIcon />
                  <p>Mission</p>
                  <span>
                    To ensure a transparent, competitive, inclusive, sustainable and value-for-money procurement system that
                    supports the state’s developmental goals
                  </span>
                </div>
              </div>
            </CompanyDetailsContainer>
          </AboutUsContainer>
        </AboutUsSection>
      </Container>
      <Container>
        <section>
          <LandingTitleText>Roles & Responsibilities</LandingTitleText>
          <SeparatorLine />

          {isSmallScreen ? (
            <BackgroundCarousel alignDots="center" mb="0px" showThumbs={false} infiniteLoop showArrows={false} centerMode={true}>
              {[1, 2, 3, 4, 5, 6, 7, 8].map((eachJobCard, i) => (
                <Container key={i}>
                  <RolesAndResponsibilitySectionSliderContent>
                    <MissionCard key={eachJobCard} />
                  </RolesAndResponsibilitySectionSliderContent>
                </Container>
              ))}
            </BackgroundCarousel>
          ) : (
            <Grid container spacing={3}>
              {[1, 2, 3, 4, 5, 6, 7, 8].map((eachJobCard) => (
                <MissionCard key={eachJobCard} />
              ))}
            </Grid>
          )}
        </section>
      </Container>
      <Container>
        <ProjectInfoSectionWrapper>
          <ProjectInfoSection>
            <ProjectInfoContainer>
              <Grid container spacing={3} style={{ height: "100%" }}>
                <ProjectContent item xs={12} sm={4}>
                  <ProjectContentValue>345+</ProjectContentValue>
                  <ProjectContentLabel>Completed Projects</ProjectContentLabel>
                </ProjectContent>
                <ProjectContent item xs={12} sm={4}>
                  <ProjectContentValue>345+</ProjectContentValue>
                  <ProjectContentLabel>Staff Capacity</ProjectContentLabel>
                </ProjectContent>
                <ProjectContent item xs={12} sm={4}>
                  <ProjectContentValue>2005</ProjectContentValue>
                  <ProjectContentLabel>Established</ProjectContentLabel>
                </ProjectContent>
              </Grid>
            </ProjectInfoContainer>
          </ProjectInfoSection>
        </ProjectInfoSectionWrapper>
      </Container>
      <Container>
        <div>
          <RequestTenderWrapper>
            <LandingTitleText>Special Procurement Notice</LandingTitleText>
            <FlexContainer>
              <SeparatorLine />
              <ViewAllText>
                <Link style={{ ...linkStyle, color: "inherit" }} to="/ocds-portal/tenders-list">
                  View All
                </Link>
              </ViewAllText>
            </FlexContainer>
            {procurementResponseMetaData?.totalEntries === 0 && (
              <div style={{ textAlign: "center", margin: 50 }}>
                <EmptyTableSubTitle>
                  <p>There are no tenders yet</p>
                </EmptyTableSubTitle>
              </div>
            )}
            {status === "loading" && (
              <div style={{ width: "100%" }}>
                <Loader feedback="Fetching tenders..." />
              </div>
            )}

            {isSmallScreen ? (
              <BackgroundCarousel
                alignDots="center"
                mb="0px"
                showThumbs={false}
                infiniteLoop
                showArrows={false}
                centerMode={true}
              >
                {procurementResponseData.slice(0, 6).map((data, i) => (
                  <Container key={data.id}>
                    <TendersOrContractCard
                      urlLink={`/ocds-portal/tenders-list/${data.id}`}
                      awardedContracts={false}
                      service={data.name}
                      status={getTenderStatus(data.closeDate)}
                      description={data.description}
                      openOrAwardedDate={moment(data.openDate).format("DD/MM/YY")}
                      closeOrExpiryDate={moment(data.closeDate).format("DD/MM/YY")}
                      category={data.procurmentCategory}
                      ministry={data?.ministry?.name}
                    />
                  </Container>
                ))}
              </BackgroundCarousel>
            ) : (
              <Grid container spacing={3}>
                {procurementResponseData.slice(0, 6).map((data, i) => (
                  <TendersOrContractCard
                    key={data.id}
                    urlLink={`/ocds-portal/tenders-list/${data.id}`}
                    awardedContracts={false}
                    service={data.name}
                    status={getTenderStatus(data.closeDate)}
                    description={data.description}
                    openOrAwardedDate={moment(data.openDate).format("DD/MM/YY")}
                    closeOrExpiryDate={moment(data.closeDate).format("DD/MM/YY")}
                    category={data.procurmentCategory}
                    ministry={data?.ministry?.name}
                  />
                ))}
              </Grid>
            )}
          </RequestTenderWrapper>
        </div>
      </Container>
      <Container>
        <BlankSection>
          <BlankSectionWrapper>
            <p>Are you interested in submitting a bid for any available tender? Register now!</p>
            <Link to="/create-account" style={linkStyle}>
              Vendor Registration
            </Link>
          </BlankSectionWrapper>
        </BlankSection>
      </Container>
      <Container>
        <NewsUpdateSection>
          <LandingTitleText>News Updates</LandingTitleText>
          <FlexContainer>
            <SeparatorLine />
            <ViewAllText>View All</ViewAllText>
          </FlexContainer>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <NewsHeadlinesCard news={currentMainNews} />
            </Grid>
            {!isSmallScreen && (
              <Grid item xs={12} sm={6}>
                <NewsCardWrapper>
                  {news.slice(0, 3).map((post) => (
                    <NewsHeadlinesClickListCard
                      handleClick={() => handleCardClick(post)}
                      key={post.id}
                      id={post.id}
                      cardImg={post.imgUrl}
                      title={post.title}
                      date={post.date}
                      synopsis={post.synopsis}
                      content={post.content}
                    />
                  ))}
                </NewsCardWrapper>
              </Grid>
            )}
          </Grid>
        </NewsUpdateSection>
      </Container>
      <Container>
        <CompletedProjectSection>
          <CompletedProjectContainer>
            <LandingTitleText>Completed Projects</LandingTitleText>
            <FlexContainer>
              <SeparatorLine />
              <ViewAllText>View All</ViewAllText>
            </FlexContainer>
            <Grid container spacing={3}>
              {isSmallScreen ? (
                <CompleteProjectNewsCard />
              ) : (
                <>
                  {[1, 2, 3].map((eachCard) => (
                    <CompleteProjectNewsCard key={eachCard} />
                  ))}
                </>
              )}
            </Grid>
          </CompletedProjectContainer>
        </CompletedProjectSection>
      </Container>
    </div>
  );
};

export default withHeaderFooterHOC(Landing);
