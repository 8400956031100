import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { theme } from "../../util/theme";

export const MuiBlueButton = styled(Button)({
  "&.MuiButtonBase-root": {
    border: "1px solid #ebebeb",
    borderRadius: "3px",
    backgroundColor: (prop) => prop.disabled ? "#c9d4db" : prop.color,
    boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.15)",
    color: "#fff",
    fontSize: "14px",
    fontWeight: 600,
    textAlign: 'center',
    display: 'flex',
    alignItems: "center",
    // marginLeft: (prop) => prop.marginLeft || "auto",
    transition: "300ms",
    textTransform: "capitalize"
  },
  "&.MuiButtonBase-root:hover": {
    backgroundColor: (prop) => prop.disabled ? "#c9d4db" : prop.color,
    filter: "brightness(70%)"
  },
  "&.MuiButtonBase-root:disabled": {
    backgroundColor: "#c9d4db",
    cursor: "not-allowed"
  },
  '&.MuiButton-outlinedPrimary': {
    color: '#0050C8',
    border: '1px solid rgba(63, 81, 181, 0.5)',
    backgroundColor: '#F0F5FF'
  }
})


export const TextButton = styled(MuiBlueButton)({
  backgroundColor: "inherit",
  border: "none",
  boxShadow: "none",
  marginRight: "12px",
  height: "41px",
  "& .MuiButton-label": {
    color: theme.color.primary
  },
  "&:hover": {
    background: theme.color.primaryLight,
    color: "#fff"
  },
  "&.MuiButtonBase-root": {
    boxShadow: "none",
    border: "none",
    textTransform: "capitalize",
    "&:hover": {
      color: "#fff",
      border: "1px solid blue"
    }
  }
})
