import { Button } from "@material-ui/core";
import styled from "styled-components";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";
import { FormTitle } from "../../../util/theme";
import TableContainer from "@material-ui/core/TableContainer";
import { theme } from "../../../util/theme";

export const TableWrapper = styled(TableContainer)`
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 #cbd4da !important;

  .MuiTableCell-body {
    color: ${theme.color.secondary} !important;
  }

  .MuiTableHead-root {
    border-top: 1px solid #cbd4da;

    th {
      text-transform: uppercase;
      padding: 16px 10px;
    }

    tr:first-child {
      outline: 1px solid #cbd4da;
      th {
        line-height: 11px;
      }
      th:last-child {
        text-align: center;
      }
    }
  }

  .MuiTable-stickyHeader {
    border-top: 1px solid #cbd4da;
  }

  th:first-child {
    border-left: 1px solid #cbd4da;
  }

  th,
  td {
    text-align: left;
  }

  th {
    font-size: 12px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 16px;
    color: #637381;
  }

  p,
  span {
    font-size: 14px;
  }

  span {
    font-size: 12px;
    font-weight: 400;
  }

  .MuiTableCell-body:last-child {
    span {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      width: 22px;
      text-align: center;
    }
  }

  .MuiTableCell-body:first-child {
    div:nth-child(2) {
      margin-left: 10px;
    }
  }

  .MuiTablePagination-root {
    border-left: 1px solid #cbd4da;
  }

  .MuiTableBody-root .MuiTableCell-root {
    padding: 0 10px;
    height: 59px;

    :last-child {
      text-align: center;
    }
  }

  .MuiChip-label {
    text-transform: capitalize;
  }

  .MuiTableBody-root > .MuiBox-root {
    position: absolute;
    margin-left: 25%;
    text-transform: capitalize;
  }

  .MuiCircularProgress-colorPrimary {
    color: #03adeb;
  }
`;

export const ResultDetailContainer = styled.div`
  /* padding-top: 25px; */
  box-sizing: border-box;
  height: 61px;
  border: 1px solid #c9d4db;
  border-radius: 3px 3px 0 0;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 #c9d4db;
  display: flex;
  align-items: center;
  padding: 0 19px;
  justify-content: space-between;
`;

export const ResultShowText = styled(Typography)`
  height: 19px;
  color: #5f7483;
  font-size: 14px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 19px !important;
`;

export const TableContainerWrapper = styled.div`
  margin-top: ${({ nopadding }) => (nopadding === "true" ? "20px" : 0)};
`;

export const ActionButton = styled(Button)`
  &.MuiButtonBase-root {
    border: 1px solid #e5e5ea;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    border-adius: 4px;
    text-transform: none;
    margin-right: 16px;
  }

  & span.MuiButton-startIcon {
    margin: 4px 0 0 2px;
  }
`;

export const UploadButton = styled(ButtonBase)`
  &.MuiButtonBase-root {
    border: 1px solid #ebebeb;
    border-radius: 3px;
    background-color: ${(props) => (props.disabled ? "#c9d4db" : "#00b0f1")};
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
    padding: 5px 22px;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
  }
`;

export const EmptyTableTitle = styled(FormTitle)`
  font-size: 17px !important;
  color: #1e2b37;
  font-weight: 600;
`;

export const EmptyTableSubTitle = styled(FormTitle)`
  font-size: 17px !important;
  color: #1e2b37;
`;

export const DrawerWrapper = styled.div`
  width: 609px;
  padding: 0 1rem;

  .MuiTab-root {
    min-width: 80px;
    text-transform: none;
  }

  .MuiTabs-root {
    padding: 0 1rem;
  }

  .MuiTabs-indicator {
    background-color: ${theme.color.primary};
  }

  .MuiTabs-Fixed {
    padding: 0 7px !important;
  }
`;

export const DrawerFormTitle = styled(FormTitle)`
  font-size: 24px !important;
  font-weight: bold !important;
  padding-top: 25.5px !important;
  padding-left: 5.5px !important;
  text-transform: uppercase;
`;

export const DrawerArchiveContractTitle = styled(FormTitle)`
  font-size: 24px !important;
  font-weight: bold !important;
  padding-top: 25.5px !important;
  padding-left: 5.5px !important;
`;

export const ClearFilter = styled("div")(() => ({
  fontSize: "12px",
  color: "#47C3F3",
  "&:hover": {
    cursor: "pointer",
    textDecoration: "underline",
  },
}));
