import React from "react";
import PropTypes from "prop-types";
import { CrumbLink, CurrentTab } from "./style";

const BreadCrumbs = ({ values, current }) => {
  return (
    <span>
      {values.map((value, index) => (
        <CrumbLink to={value.url} key={index}>
          {value.title} {index !== values.length - 1 ? "/ " : ""}
        </CrumbLink>
      ))}
      {current && <CurrentTab style={{ display: "inline-block", color: "#47c3f3" }}>/ {current}</CurrentTab>}
    </span>
  );
};

BreadCrumbs.propTypes = {
  values: PropTypes.array.isRequired,
};

export default BreadCrumbs;
