import Grid from "@material-ui/core/Grid";
import React from "react";
import { capitalize } from "lodash";
import { queryCache } from "react-query";
import { Box, Paper, Typography } from "@material-ui/core";
import { ReactComponent as PolarisIcon } from "../../../../assets/polaris_icon.svg";
import { useParams } from "react-router-dom";
import { BreadCrumbs, ReusableButton, TableChip } from "../../../../shared";
import { ContentWrapper } from "../style";
import { supplementChipMapping, supplementaryBudgetStatus } from "../../../../util/constants";
import { useSliceWord } from "../../../../hooks/useSliceWord";
import { formatCurrency } from "../../../../util/formatCurrency";
import SearchTable from "../../../../components/SearchTable";
import TableBody from "./TableBody";
import { useFetchProcurementDetails, useFetchProcurementPlanByPlanId } from "../../../../hooks/queries/useGeneralPlans";
import StatusAlert from "../StatusAlert";
import { useFetchSupplementaryDetail, useSupplementaryDecision } from "../../../../hooks/queries/useSupplementaryBudget";
import ToastContext from "../../../../util/toastContext";
import { Roles } from "../../../../components/AccessControl/UserRoles";
import { useRoles } from "../../../../hooks/useUserDetails";
import { dashCapitaliseWord } from "../../../../util/stringTranform";

const valuess = {
  description: "loremdsdskabdsjabsdn,nsdnf,sdnjfskd,fbsdabnbfnsb knbfhkasbkdbjfbjdsbalsdjfljsdnfjlsdnfljnsdfnbsdfnsdfnlsdflsndlf",
};

const SupplementaryDetail = () => {
  const { procurementId, supplementaryId } = useParams();
  const { role } = useRoles();
  const { showAlert } = React.useContext(ToastContext);
  const [dataSource, setDataSource] = React.useState([]);
  const [review, setReview] = React.useState(null);
  const [tableParams, setTableParams] = React.useState({
    search: "",
    sort: "",
    status: "",
    pagination: {
      pageNumber: 0,
      pageSize: 30,
      total: 1,
    },
  });
  const supplementaryList = [
    { url: "/admin/dashboard/overview", title: "Home" },
    { url: `/admin/procurement/${procurementId}/supplementarylist`, title: "Supplementary Budget" },
  ];

  const { fetchingSupplementaryDetails, supplementaryDetails } = useFetchSupplementaryDetail({
    showAlert,
    budgetId: supplementaryId,
  });

  console.log({ supplementaryDetails });

  const accessPermission = (key) => {
    switch (key) {
      case Roles.COMMISSIONER: {
        return {
          text: "Endorse",
          value: "Endorsed",
          disabled: supplementaryDetails?.status?.toLowerCase() !== supplementaryBudgetStatus.pending,
        };
      }
      case Roles.COMMISSIONER: {
        return {
          text: "Endorse",
          value: "Endorsed",
          disabled: supplementaryDetails?.status?.toLowerCase() !== supplementaryBudgetStatus.pending,
        };
      }
      case Roles.PERMANENT_SECRETARY: {
        return {
          text: "Endorse",
          value: "Endorsed",
          disabled: supplementaryDetails?.status?.toLowerCase() !== supplementaryBudgetStatus.pending,
        };
      }
      case Roles.DSPPC: {
        return {
          text: "Approve",
          value: "Approved",
          disabled: supplementaryDetails?.status?.toLowerCase() !== supplementaryBudgetStatus.endorsed,
        };
      }
      // case Roles.GOVERNOR: {
      //   return {
      //     text: "Approve",
      //     value: "Approved",
      //     disabled: data?.status?.toLowerCase() !== supplementaryBudgetStatus.endorsed,
      //   };
      // }

      default:
        return {
          text: "Endorse",
          value: "Endorsed",
          disabled: true,
        };
    }
  };

  const params = {
    search: tableParams.search,
    pageNumber: tableParams.pagination.pageNumber,
    pageSize: tableParams.pagination.pageSize,
    // category
  };

  const handleStatusAction = (key) => () => {
    switch (key) {
      case "approve": {
        setReview({
          menuOpen: true,
          menuValue: "Rejected",
          type: "Rejected",
        });

        break;
      }
      case "reject": {
        setReview({
          menuOpen: true,
          menuValue: "Rejected",
          type: "Rejected",
        });

        break;
      }

      default:
        break;
    }
  };

  const { procurementPlanDetail } = useFetchProcurementDetails({ id: procurementId });
  const { description, handleViewMore, viewMore } = useSliceWord(procurementPlanDetail?.description || "--", 40);

  // console.log({ procurementPlanDetail });
  const { supplementaryStatus, updatingStatus } = useSupplementaryDecision({
    showAlert,
    queryClient: queryCache,
    setModal: setReview,
  });

  const { fetchingProcurementPlan } = useFetchProcurementPlanByPlanId({ id: procurementId, setDataSource, setTableParams });

  const toggleStatus = (values) => {
    console.log(values);
    const payload = {
      status: values?.menuValue?.toLowerCase() !== "rejected",
      budgetId: values?.id,
    };

    supplementaryStatus({
      ...payload,
    });
  };

  return (
    <>
      <ContentWrapper>
        <Grid container spacing={3} style={{ margin: "24px 5px" }}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={10}>
                <Box>
                  <Typography color="text.main" className="header-topic">
                    {`${dashCapitaliseWord(supplementaryDetails?.type || "")} Request`}
                  </Typography>
                  <Box>
                    <BreadCrumbs
                      values={supplementaryList}
                      current={`${dashCapitaliseWord(supplementaryDetails?.type || "")} Request`}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Box style={{ display: "grid", gridTemplateColumns: "repeat(2,1fr)", gap: "20px" }}>
                  <ReusableButton
                    label="Reject"
                    variant="outlined"
                    onClick={handleStatusAction("reject")}
                    disabled={accessPermission(role).disabled || updatingStatus}
                  />
                  <ReusableButton
                    label="Endorse"
                    onClick={handleStatusAction("approve")}
                    disabled={accessPermission(role).disabled || updatingStatus}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={0} style={{ backgroundColor: "#fff", padding: "24px", boxShadow: "1px 0px 2px rgba(0,0,0,0.1)" }}>
              <Box style={{ display: "flex", gap: "20px" }}>
                <TableChip
                  text={`${capitalize(procurementPlanDetail?.status?.toLowerCase())}`}
                  {...supplementChipMapping[procurementPlanDetail?.status?.toLowerCase() || "pending"]}
                />{" "}
                <Typography variant="h5">{procurementPlanDetail?.title || ""}</Typography>
              </Box>
              <Box style={{ margin: "30px 0px" }}>
                <Typography variant="body1" style={{ fontWeight: 400, fontSize: "14px", color: "#6B6C7E" }}>
                  Note
                </Typography>
                <Typography variant="body1" style={{ fontWeight: 400, fontSize: "14px", color: "#6B6C7E" }}>
                  {description} &nbsp; &nbsp;
                  <span
                    style={{ color: "#0050C8", cursor: "pointer", fontSize: "14px", display: "inline-block" }}
                    onClick={handleViewMore}
                  >
                    {viewMore}
                  </span>
                </Typography>
              </Box>
              <Grid
                container
                spacing={3}
                style={{ borderWidth: "1px 0px", borderStyle: "solid", borderColor: "#CDCED9", padding: "24px 0px" }}
              >
                <Grid item xs={12} sm={9}>
                  <Box>
                    <Typography style={{ color: "#6B6C7E", fontSize: "14px", lineHeight: 1.5 }}>Initial Budget</Typography>
                    <Typography style={{ color: "#272833", fontSize: "16px", lineHeight: 1.5, fontWeight: 600 }}>
                      {formatCurrency(supplementaryDetails?.approvedAmount || 0, true, false)}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Box>
                    <Typography style={{ color: "#6B6C7E", fontSize: "16px", lineHeight: 1.5, fontWeight: 600 }}>
                      Review Amount Requested
                    </Typography>
                    <Typography style={{ color: "#272833", fontSize: "24px", lineHeight: 1.5, fontWeight: 700 }}>
                      {formatCurrency(supplementaryDetails?.amountRequested || 0, true, false)}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <SearchTable
              columns={[
                "PACKAGE NO./DESCRIPTION",
                "CATEGORY",
                "METHOD",
                "STATUS",
                "BUDGET",
                // "REQUESTED AMOUNT",
                <PolarisIcon />,
              ]}
            >
              <TableBody dataSource={dataSource} />
            </SearchTable>
          </Grid>
        </Grid>
      </ContentWrapper>
      <StatusAlert
        reviewType={review?.type || ""}
        statusKey={review?.menuValue?.toLowerCase() || "rejected"}
        selected={review}
        open={Boolean(review?.menuOpen)}
        onClose={() => setReview(null)}
        onAction={toggleStatus}
      />
    </>
  );
};

export default SupplementaryDetail;
