import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import { Caption, SideNavSection } from "./style";
import Navbar from "../../../components/AdminDashboardNav";
import Sidebar from "../../../components/Sidebar";
import useAlert from "../../../hooks/useAlert";
import ToastContext from "../../../util/toastContext";
import { useRoles } from "../../../hooks/useUserDetails";

import CircularProgress from "@material-ui/core/CircularProgress";

const withAdminDashboard = (WrappedComponent) => (props) => {
  const { showAlert, Toast, hideAlert } = useAlert();
  const { isSuccess, isError} = useRoles()

  useEffect(()=>{
    if (isError){
    showAlert({
      severity: "error",
      message: "Network Error. Kindly Refresh",
    })
  }
  }, [isError, showAlert])

  return (
    <ToastContext.Provider value={{ showAlert, Toast, hideAlert }}>
      <SideNavSection>
        <Caption>
          Delta State
          </Caption>
        <Sidebar />
      </SideNavSection>
      <div style={{ marginLeft: "250px", background: "#FAFBFC" }}>
        <Navbar />
        <Toast float/>
        <Box paddingLeft="33.5px" paddingRight="33.5px" minHeight="94vh">
          {isSuccess ?(
          <WrappedComponent {...props} />
          ):( <Box
            padding={10}
            width={"100%"}
            display={"flex"}
            justifyContent={"center"}
          >
            <CircularProgress size={20}/>
          </Box>)}
        </Box>
      </div>
    </ToastContext.Provider>
  );
};

export default withAdminDashboard;
