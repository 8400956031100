import axios from "axios";

const baseAxiosMethod = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
});

baseAxiosMethod.interceptors.request.use(
  (config) => {
    // Add authorization key to config object if it exist
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.common.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

baseAxiosMethod.interceptors.response.use(
  (config) => {
    if (config?.status === 401) {
      console.log(config, "logout");
    }
    return config;
  },
  (error) => Promise.reject(error)
);
export default baseAxiosMethod;
