import React, { useContext, useState } from "react";
import Certificate from "./Certificate";
// import procurementAPI from "../../../../../redux/api/procurementAPI";
import Loader from "../../../../../components/Common/Loader";
import { PageContainer, PageTitle, ContentWrapper, BreadCrumbWrapper } from "./style";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link, useParams, useHistory } from "react-router-dom";
import { useMutation } from "react-query";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import ToastContext from "../../../../../util/toastContext";
import LoadingButton from "../../../../../components/LoadingButton/LoadingButton";
import certificateOfNoObjectionApi from "../../../../../redux/api/certificateOfNoObjectionApi";
import { CancelButton } from "../../../Administration/Settings/style";
import { Box } from "@material-ui/core";
import { useFecthCertificate } from "../../../../../hooks/queries/useCertificate";
import { useProcurementPlanDetails } from "../../../../../hooks/queries/useProcurement";
import CertificationDialog from "./CertificateModal";

function CertificatePage() {
  const { activityId, procurementId } = useParams();
  const ref = React.createRef();
  const { showAlert } = useContext(ToastContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const certifiedData = useFecthCertificate({ procurementId })
  const { data = {}, isFetching } = useProcurementPlanDetails({ procurementId })
  const [isPreview, setIsPreview] = React.useState(certifiedData?.data?.length && true)
  const [dialog, setDialog] = React.useState(false)

  const [uploadCertificate, certy] = useMutation(certificateOfNoObjectionApi.uploadCertificateOfObjection, {
    onSuccess: () => {
      setLoading(false);
      showAlert({
        message: "Certificate of no objection has been generated successfully",
        severity: "success",
      });
      setTimeout(() => {
        history.push(`/admin/procurement/activity/certificateofnoobjection/${activityId}/${procurementId}`);
      }, 500);
    },
    onError: (error) => {
      setLoading(false);
      showAlert({
        message: error.message,
        severity: "error",
      });
    },
  });

  const handleDownloadPdf = async () => {
    setLoading(true);
    setIsPreview(false)
    const element = ref.current;
    const canvas = await html2canvas(element, { useCORS: true });
    const data = canvas.toDataURL("image/png");

    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("CNO_award_contract.pdf");

    var blob = pdf.output("blob", "CNO_award_contract.pdf");

    uploadCertificate({
      procurementId,
      File: blob,
    });
  };

  if (isFetching) {
    return (
      <ContentWrapper>
        <Loader />
      </ContentWrapper>
    );
  }

  return (
    <>
      <PageContainer>
        <PageTitle>Certificate of No Objection</PageTitle>
        <BreadCrumbWrapper>
          <Breadcrumbs>
            <Link to={"/admin/dashboard/overview"}>Home</Link>
            <Link to={"/admin/procurement/plans"}>Plans</Link>
            {/* <Link to={`/admin/procurement/certificate-of-no-objection/${procurementId}`}>{data?.name}</Link> */}
            <Link to={`/admin/procurement/activity/certificateofnoobjection/${activityId}/${procurementId}`}>{data?.name}</Link>
          </Breadcrumbs>
          <Box ml="auto" display="flex" justifyContent="flex-end">
            <Box mr={2}>
              <CancelButton
                onClick={() => history.push(`/admin/procurement/activity/certificateofnoobjection/${activityId}/${procurementId}`)}
                loading={false}
              >
                Cancel
              </CancelButton>
            </Box>
            <LoadingButton
              onClick={() => {
                setIsPreview(true)
                setDialog(true)
              }} loading={loading}>
              Generate Certificate
            </LoadingButton>
          </Box>
        </BreadCrumbWrapper>
        <div style={{ width: "70%" }}>
          <Certificate
            CertRef={ref}
            isPreview={isPreview}
            certifiedData={certifiedData}
          />
        </div>
      </PageContainer>
      <CertificationDialog
        open={dialog}
        onAction={handleDownloadPdf}
        loading={certy.isLoading}
        onClose={() => {
          setIsPreview(false)
          setDialog(false)
        }}
      />

    </>
  );
}

export default CertificatePage;
