import React from "react";
import PropTypes from "prop-types";
import { MuiBlueButton } from "./style";

const ReusableButton = ({ label, onClick, variant, color, loading, disabled, ...rest }) => {
  return (
    <MuiBlueButton variant={variant} onClick={onClick} color={color} disableRipple disabled={loading || disabled} {...rest}>
      {label}
    </MuiBlueButton>
  );
};

ReusableButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  variant: PropTypes.oneOf(["contained", "outlined"]),
  color: PropTypes.oneOf(["inherit", "primary", "secondary"]),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};
ReusableButton.defaultProps = {
  onClick: () => {},
  label: "",
  variant: "contained",
  color: "primary",
  loading: false,
  disabled: false,
};

export default ReusableButton;
