import React, { useContext } from "react";
import { Drawer, Box } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import { queryCache, useMutation, useQuery } from "react-query";
import { lowerCase } from "lodash";
import { Dropdown, InputField } from "../../../components";
import Loader from "../../../components/Common/Loader";
import contractsAPI from "../../../redux/api/contractAPI";
import { ErrorContainer } from "../../../util/theme";
import ToastContext from "../../../util/toastContext";
import { BtnBox, CancelButton, UpdateButton } from "../Administration/Settings/style";
import { DrawerArchiveContractTitle, DrawerWrapper } from "./style";
import moment from "moment";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { DateRangeWrapper } from "../../../components/DateRangePIcker/style";
import { archiveCategoryFunc, archiveCategoryOptions } from "./OcdsContractDetails/utils/useArchiveHokk";

const archiveDetails = {
  budgetDescription: "",
  datePublished: new Date(),
  procuringEntity: "",
  category: 0,
  contractStartDate: new Date(),
  contractEndDate: new Date(),
  contractor: "",
  contractSum: 0,
  projectTitle: "",
  ocidNumber: "",
};

const EditDrawer = ({ open, toggleDrawer, id }) => {
  const { showAlert } = useContext(ToastContext);
  const { register, handleSubmit, errors, reset, control } = useForm({ defaultValues: { awardedDate: "" } });
  // const [awardedDate, setAwardedDate] = useState(null);
  // const categoryOptions = ["Works", "Goods", "Consultation", "Non-Consultation"].map((item) => ({ label: item, value: item }));

  const { isLoading } = useQuery(["single-contract", id], contractsAPI.getSingleArchivedContract, {
    enabled: !!id,
    onSuccess: (data) => {
      let payload = {};
      const fields = Object.keys(archiveDetails);
      fields.forEach((field) => {
        if (field === "datePublished") {
          payload[field] = data?.datePublished
            ? moment(new Date(data.datePublished)).format("YYYY-MM-DD")
            : archiveDetails[field];
        } else if (field === "projectTitle") {
          payload[field] = data?.title || archiveDetails[field];
        } else if (field === "contractStartDate") {
          payload[field] = data?.contractStartDate
            ? moment(new Date(data.contractStartDate)).format("YYYY-MM-DD")
            : archiveDetails[field];
        } else if (field === "contractEndDate") {
          payload[field] = data?.contractEndDate
            ? moment(new Date(data.contractEndDate)).format("YYYY-MM-DD")
            : archiveDetails[field];
        } else if (field === "category") {
          payload[field] = archiveCategoryFunc(lowerCase(data?.category || ""));
        } else {
          payload[field] = data?.[field] || archiveDetails[field];
        }
      });

      payload = {
        ...payload,
        contractor: data?.contractor || "",
      };
      reset(payload);
    },
    onError: (e) => {
      showAlert({
        message: "Error fetching contract",
        severity: "error",
      });
    },
  });

  const [mutate, { isLoading: updatingContract }] = useMutation(contractsAPI.patchArchiveContract, {
    onSuccess: () => {
      showAlert({
        message: "Successfully updated contract",
        severity: "success",
      });
      toggleDrawer();
      queryCache.invalidateQueries("archived-contracts");
    },
  });

  const onSubmit = (values) => {
    let payload = {};
    const fields = Object.keys(archiveDetails);
    fields.forEach((field) => {
      if (field === "category") {
        payload[field] = parseInt(values[field], 10);
      } else if (field === "datePublished") {
        payload[field] = new Date(values[field]).toUTCString();
      } else if (field === "contractStartDate") {
        payload[field] = new Date(values[field]).toUTCString();
      } else if (field === "contractEndDate") {
        payload[field] = new Date(values[field]).toUTCString();
      } else if (field === "contractSum") {
        payload.amount = values[field];
      } else {
        payload[field] = values[field];
      }
      payload.id = id;
    });
    mutate(payload);
  };
  const onRenderError = (error) => <ErrorContainer style={{ marginLeft: "1rem" }}>{error && error.message}</ErrorContainer>;

  return (
    <div>
      <Drawer anchor="right" open={open} onClose={toggleDrawer}>
        <DrawerWrapper>
          {isLoading ? (
            <Box mt="50%">
              <Loader feedback="loading.." />
            </Box>
          ) : (
            <>
              <DrawerArchiveContractTitle>Edit Archived Contract </DrawerArchiveContractTitle>
              <div>
                <InputField
                  label="OCDS Number"
                  name="ocidNumber"
                  style={{ background: "#ffffff" }}
                  inputRef={register({
                    required: "Enter OCDS NUmber",
                  })}
                />
                {onRenderError(errors.ocidNumber)}
              </div>
              <div>
                <InputField
                  label="Project Title"
                  name="projectTitle"
                  style={{ background: "#ffffff" }}
                  inputRef={register({
                    required: "Enter Project Name",
                  })}
                />
                {onRenderError(errors.projectName)}
              </div>
              <div>
                <InputField
                  label="Budget Description"
                  name="budgetDescription"
                  style={{ background: "#ffffff" }}
                  inputRef={register({
                    required: "Enter Budget Description",
                  })}
                />
                {onRenderError(errors.budgetDescription)}
              </div>
              <div>
                <DateRangeWrapper width={{ root: "97%" }}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Controller
                      control={control}
                      name="datePublished"
                      render={(props) => (
                        <KeyboardDatePicker
                          label="Date Published"
                          disableToolbar
                          openTo="year"
                          views={["year", "month", "date"]}
                          variant="inline"
                          format="dd-MMM-yyyy"
                          placeholder="MM/DD/YYYY"
                          margin="normal"
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          maxDate={new Date()}
                          {...props}
                        />
                      )}
                    />
                  </MuiPickersUtilsProvider>
                </DateRangeWrapper>

                {onRenderError(errors.datePublished)}
              </div>
              <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                <div>
                  <Controller
                    control={control}
                    name="procuringEntity"
                    render={(props) => (
                      <InputField label="MDA" name="procuringEntity" style={{ background: "#ffffff" }} {...props} />
                    )}
                  />
                </div>
                <div>
                  <Controller
                    control={control}
                    name="category"
                    render={(props) => (
                      <Dropdown
                        // defaultValue={watch("category")}
                        style={{ width: "inherit" }}
                        label="Category"
                        values={archiveCategoryOptions}
                        {...props}
                      />
                    )}
                  />
                  {onRenderError(errors.category)}
                </div>
              </div>
              <div>
                <InputField
                  label="Contractor Name"
                  name="contractor"
                  inputRef={register({
                    required: "Enter contractor's name",
                  })}
                />
              </div>
              <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                <div>
                  <DateRangeWrapper width={{ root: "97%" }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Controller
                        control={control}
                        name="contractStartDate"
                        render={(props) => (
                          <KeyboardDatePicker
                            label="Start Date"
                            disableToolbar
                            openTo="year"
                            views={["year", "month", "date"]}
                            variant="inline"
                            format="dd-MMM-yyyy"
                            placeholder="MM/DD/YYYY"
                            margin="normal"
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            maxDate={new Date()}
                            {...props}
                          />
                        )}
                      />
                    </MuiPickersUtilsProvider>
                  </DateRangeWrapper>

                  {onRenderError(errors.startDate)}
                </div>
                <div>
                  <DateRangeWrapper width={{ root: "97%" }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Controller
                        control={control}
                        name="contractEndDate"
                        render={(props) => (
                          <KeyboardDatePicker
                            label="End Date"
                            disableToolbar
                            openTo="year"
                            views={["year", "month", "date"]}
                            variant="inline"
                            format="dd-MMM-yyyy"
                            placeholder="MM/DD/YYYY"
                            margin="normal"
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            {...props}
                          />
                        )}
                      />
                    </MuiPickersUtilsProvider>
                  </DateRangeWrapper>

                  {onRenderError(errors.endDate)}
                </div>
              </div>
              <div style={{ background: "#ffffff" }}>
                <Controller
                  control={control}
                  name="contractSum"
                  render={({ onChange, ...props }) => (
                    <NumberFormat
                      customInput={InputField}
                      name="contractSum"
                      label="Contract Amount"
                      thousandSeparator
                      prefix="₦"
                      variant="outlined"
                      onValueChange={(values) => {
                        const { value } = values;
                        onChange(value);
                      }}
                      {...props}
                    />
                  )}
                />
              </div>
              <BtnBox>
                <CancelButton onClick={toggleDrawer}>Cancel</CancelButton>
                <UpdateButton style={{ marginLeft: "1rem" }} onClick={handleSubmit(onSubmit)} disabled={updatingContract}>
                  Save
                </UpdateButton>
              </BtnBox>
            </>
          )}
        </DrawerWrapper>
      </Drawer>
    </div>
  );
};

export default EditDrawer;
