import React from "react"
import { Chip, styled } from '@material-ui/core';
import PropTypes from 'prop-types';

const TableChip = ({ text, bgColor, textColor, ...rest }) => {
  return <StyledChip
    style={{
      backgroundColor: bgColor || "#FFF5EA",
      color: textColor || "#B98900"
    }}
    label={text} {...rest} />;
};

TableChip.propTypes = {
  text: PropTypes.string
};

TableChip.defaultProps = {
  text: ''
};

const StyledChip = styled(Chip)({
  fontSize: 12,
  borderRadius: 8,
  height: 'unset',
  lineHeight: 1.5,

  '& .MuiSvgIcon-root': {
    fontSize: 16,
    marginRight: 0,
    marginLeft: 8,
    color: 'transparent'
  },

  '&.MuiChip-colorInfo': {
    backgroundColor: '#DEECF9',

    '& .MuiChip-label': {
      color: '#2B88D8'
    }
  },

  '&.MuiChip-colorSuccess': {
    backgroundColor: '#EDF9F0',

    '& .MuiChip-label': {
      color: '#287D3C'
    }
  },

  '&.MuiChip-colorWarning': {
    backgroundColor: '#FFF5EA',

    '& .MuiChip-label': {
      color: '#B98900'
    }
  },

  '&.MuiChip-colorPrimary': {
    backgroundColor: '#F1F2F6',

    '& .MuiChip-label': {
      color: '#0050C8'
    }
  },

  '&.MuiChip-colorDefault': {
    backgroundColor: '#D2D0CE',

    '& .MuiChip-label': {
      color: '#605E5C'
    }
  },

  '& .MuiChip-label': {
    padding: '4px 8px'
  }
});


export default TableChip
