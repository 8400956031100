/* eslint-disable no-nested-ternary */
import React from "react";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { TableContainerWrapper, TableWrapper } from "./style";
import {
  EmptyState,
  EmptyStateDescription,
  EmptyStateHeader,
} from "../../../pages/Admin/Procurement/Activitiies/ReceiptOfResponse/VendorsTable/style";


export const StyledTableCell = withStyles(() => ({
  head: {
    borderRadius: 0,
  },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    overflowX: "scroll",
    overflowY: "hidden",
  },
  columnBold: {
    fontWeight: "bold",
    fontSize: "17px",
    color: "#1E2B37",
    textTransform: "capitalize",
  },
});

const getRowCount = (rows) => {
  let count = "";
  if (!!rows) {
    if (Array.isArray(rows)) {
      count = rows.length;
    }
    if (Array.isArray(rows.data)) {
      count = rows.data.length;
    }
    if (!isNaN(parseInt(rows))) {
      count = rows;
    }
  }

  return !!parseInt(count) ? (count > 1 ? `Showing ${count} results` : `Showing 1 result`) : `Showing result`;
};

const SearchTable = ({
  columns,
  children,
  variant,
  showPadding = true,
  columnBold,
  loading = false,
  rowsPerPage,
  setRowsPerPage,
  rows,
  page,
  setPage,
  totalNumberOfPages,
  tableHeaderText,
  containerStyle,
  empty = false,
  emptyMessage = "",
}) => {
  const classes = useStyles();
  tableHeaderText = getRowCount(rows);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1); //TablePagination is zero indexed by default from material-ui so we have add 1
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  return (
    <TableContainerWrapper nopadding={showPadding.toString()}>
      <TableWrapper style={containerStyle} component={Paper}>
        {loading ? (
          <Box pt={20} pb={20} display="flex" alignItems="center" flexDirection="column">
            <CircularProgress size={40} />
            <Box mt={2}>
              <p>Loading...</p>
            </Box>
          </Box>
        ) : (
          <>
            {empty && !loading ? (
              <EmptyState>
                <EmptyStateHeader>All caught up!</EmptyStateHeader>
                <EmptyStateDescription>{emptyMessage}</EmptyStateDescription>
              </EmptyState>
            ) : (
              <Box className={classes.table}>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        style={{
                          position: "sticky",
                          borderRight: "2px solid #C9D4DB",
                          left: 0,
                          width: 200,
                          backgroundColor: "#E5E9EC",
                        }}
                      >
                        <p> OCID</p>{" "}
                      </StyledTableCell>
                      {columns.map((column) => (
                        <StyledTableCell
                          className={(!!columnBold && classes.columnBold).toString()}
                          style={{ backgroundColor: variant === "secondary" ? "transparent" : "#E5E9EC" }}
                          key={column}
                        >
                          {column}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>{children}</TableBody>
                </Table>
              </Box>
            )}
            {totalNumberOfPages > page && (
              <TablePagination
                rowsPerPageOptions={[10, 25]}
                component="div"
                count={totalNumberOfPages}
                rowsPerPage={rowsPerPage}
                page={Math.max(0, page - 1)} //TablePagination is zero indexed by default from material-ui so we have subtract 1
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            )}
          </>
        )}
      </TableWrapper>
    </TableContainerWrapper>
  );
};

export default SearchTable;
