import React from "react";
import * as Yup from "yup";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers/yup";
import NumberFormat from "react-number-format";
import { ControlledRadio, InputField, Modal, ReusableButton } from "../../../shared";
import { theme } from "../../../util/theme";
import { Controller, useForm } from "react-hook-form";
import { onNumberValidator } from "../../../util/numberValidator";

const typeOptions = [
  {
    label: "Upward  Review",
    value: "2",
  },
  {
    label: "Downward Review",
    value: "1",
  },
];

const defaultValues = {
  type: "1",
  budget: 0,
  newBudget: 0,
};

const suppSchema = Yup.object({
  type: Yup.string().required("Supplementary type is required"),
  budget: Yup.number().required("Budget amount is required"),
  newBudget: Yup.number().required("Review Amount is required"),
});

const resolver = yupResolver(suppSchema);

const RequestReviewModal = ({ open, onClose, onAction, title, subtitle, budget, loading }) => {
  const { control, reset, errors, handleSubmit, watch } = useForm({
    defaultValues,
    resolver,
  });

  React.useEffect(() => {
    if (open && budget) {
      reset({
        budget: budget?.budget || 0,
        type: "1",
        newBudget: 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, budget]);

  console.log({ budget, watch: watch(), errors });
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        style={{
          padding: "24px 24px",
          display: "grid",
          gridTemplateRows: "46px auto 46px",
          gap: "20px",
        }}
      >
        <Box>
          <Typography
            style={{
              fontWeight: 700,
              fontSize: "24px",
              lineHeight: 1.5,
              color: theme.color.textColor1,
            }}
          >
            {title}
          </Typography>
          {subtitle && (
            <Typography
              style={{
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: 1.5,
                color: theme.color.textColor1,
              }}
            >
              {subtitle}
            </Typography>
          )}
        </Box>
        <Box
          style={{
            display: "grid",
            gridTemplateRows: "auto",
            boxSizing: "border-box",
            marginTop: "24px",
          }}
        >
          <Box>
            <ControlledRadio
              control={control}
              name="type"
              options={typeOptions}
              formLabel="Type"
              row
              style={{
                "& .MuiFormGroup-root": {
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "5px",
                },
              }}
            />
          </Box>
          <Controller
            control={control}
            name="budget"
            render={({ ref, ...props }) => (
              <NumberFormat
                customInput={InputField}
                label="Approved Amount"
                thousandSeparator
                prefix="₦"
                variant="outlined"
                disabled
                {...props}
              />
            )}
          />
          <Controller
            control={control}
            name="newBudget"
            render={({ ref, onChange, ...props }) => (
              <InputField
                label="Approved Amount"
                // inputRef={register({
                //   required: "Package Number is required",
                // })}
                inputRef={ref}
                style={{ background: "#ffffff" }}
                onChange={(e) => onNumberValidator(e, onChange)}
                {...props}
              />
              // <NumberFormat
              //   customInput={InputField}
              //   label="Review Amount Requested"
              //   thousandSeparator
              //   prefix="₦"
              //   variant="outlined"
              //   {...props}
              // />
            )}
          />
        </Box>
        <Box style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", boxSizing: "border-box" }}>
          <ReusableButton
            label="Send Request"
            style={{ marginRight: "20px" }}
            onClick={handleSubmit(onAction)}
            loading={loading}
          />
          <ReusableButton variant="outlined" label="Cancel" onClick={onClose} color="primary" loading={loading} />
        </Box>
      </Box>
    </Modal>
  );
};

RequestReviewModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onAction: PropTypes.func,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  budget: PropTypes.object,
  loading: PropTypes.bool,
};

RequestReviewModal.defaultProps = {
  onClose: () => null,
  onAction: () => null,
  subtitle: "",
  budget: null,
  loading: false,
};

export default RequestReviewModal;
