import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import Radio from "./Radio";

const ControlledRadio = ({ control, name, formLabel, options, allValue, allLabel, row, watch, style, ...rest }) => {
  const handleChange = (evt, onChange) => {
    onChange(evt.target.value);
  };
  return (
    <Controller
      control={control}
      name={name}
      render={({ ref, onChange, ...fields }) => (
        <Radio
          options={options}
          allLabel={allLabel}
          allValue={allValue}
          formLabel={formLabel}
          onChange={(evt) => handleChange(evt, onChange)}
          row={row}
          style={{ ...style }}
          {...rest}
          {...fields}
        />
      )}
    />
  );
};

ControlledRadio.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ).isRequired,
  formLabel: PropTypes.string.isRequired,
  row: PropTypes.bool,
  name: PropTypes.string.isRequired,
  allLabel: PropTypes.string,
  allValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  control: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]).isRequired,
};

ControlledRadio.defaultProps = {
  row: false,
  allLabel: "",
  allValue: "",
  value: "",
};

export default ControlledRadio;
