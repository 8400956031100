import React from "react";
import PropTypes from "prop-types";
import ReusableButton from "../Button/ReusableButton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const Alert = ({
  open,
  onClose,
  title,
  content,
  onAction,
  isSecAction,
  onSecAction,
  actionText,
  secondaryText,
  children,
  loading,
  actionColor,
  disabled,
}) => {
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>
        <ReusableButton
          loading={loading}
          style={{ borderRadius: "8px" }}
          disabled={disabled}
          color="primary"
          label={secondaryText || "Cancel"}
          onClick={isSecAction ? onSecAction : onClose}
          variant="outlined"
        />
        {actionText && (
          <ReusableButton
            style={{ borderRadius: "8px" }}
            loading={loading}
            disabled={disabled}
            color={actionColor || "primary"}
            label={actionText}
            onClick={onAction}
          />
        )}
      </DialogActions>
    </Dialog>
  );
};

Alert.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.string,
  onAction: PropTypes.func,
  onSecAction: PropTypes.func,
  actionText: PropTypes.string,
  secondaryText: PropTypes.string,
  children: PropTypes.node,
  loading: PropTypes.bool,
  actionColor: PropTypes.oneOf(["inherit", "primary", "secondary"]),
  disabled: PropTypes.bool,
};
Alert.defaultProps = {
  onClose: () => {},
  title: "",
  content: "",
  onAction: () => {},
  onSecAction: () => {},
  actionText: "",
  secondaryText: "Cancel",
  children: <></>,
  loading: false,
  disabled: false,
  actionColor: "primary",
};

export default Alert;
