import { Grid } from "@material-ui/core";
import React from "react";
import { useParams } from "react-router-dom";
import Breadcrumb from "../../../Common/PageElements/Breadcrumb";
import { PageTitle } from "../../../Common/style";
import { ContentWrapper, TitleContainer } from "../../Administration/Staff/style";
import withAdminDashboard from "../../HOC/withAdminDashboard";
import {
  MainTitle,
  TopCard,
  StatusTag,
  HL,
  BlueLink,
  MainText,
  InfoCardWrapper,
  Pad,
  FileCard,
  FileBoard,
  FileText,
  CuteGrid,
  CardWrap,
} from "./style";
import Loader from "../../../../components/Common/Loader";
import { ReactComponent as FolderIcon } from "../../../../assets/folder.svg";
import { DocumentSection } from "../../../Vendor/Procurement/style";
import procurementAPI from "../../../../redux/api/procurementAPI";
import { useQuery } from "react-query";
import { separateNumByComma } from "../../../../util/truncateValue";
import FileDownload from "../../../../components/FileDownload";
import { BiddingDocumentContainer } from "../../../Vendor/BidDetails/BidInfo/style";
import { ReactComponent as CheckIcon } from "../../../../assets/check_mark.svg";
import { ReactComponent as PendingIcon } from "../../../../assets/pending_icon.svg";
import UploadContractDocument from "./UploadContractDocument";

const SingleContractDetails = () => {
  const { procurementId } = useParams();

  const { data: vendor } = useQuery(["recommendedVendor", procurementId], procurementAPI.getRecommendedVendor);

  const { data: signedContract = [] } = useQuery(
    ["getVendorSignedContract", {procurementId}],
    procurementAPI.getVendorSignedContract
  );
  const signedFile = signedContract[0]?.file || {};

  const { data: contractDraft = [] } = useQuery(["getContractDraft", procurementId], ()  => procurementAPI.getContractDraft(procurementId));
  const file = contractDraft[0]?.file;

  const { data: tenderDetails, isLoading } = useQuery(["tenderDetails", procurementId], procurementAPI.getTenderDetails);
  const { data: documents = [] } = useQuery(["exchangedDocuments", procurementId], procurementAPI.getExchangedDocuments);

  const BreadcrumbLinks = [
    { url: "/admin/contractsList", title: "Home", forwardSlash: true },
    { url: "/admin/contractsList", title: " Procurement plans", forwardSlash: true },
    { url: "/admin/contractsList", title: " MOA", forwardSlash: true },
    {
      url: "/admin/contractsList",
      title: " Delta Irrigated Agriculture Improvement",
      forwardSlash: true,
    },
    { url: "/admin/contractsList", title: " Contract", forwardSlash: false },
  ];

  return !!tenderDetails ? (
    <ContentWrapper>
      <TitleContainer>
        <div>
          <PageTitle>Contract Document</PageTitle>
          <Breadcrumb values={BreadcrumbLinks} />
        </div>
      </TitleContainer>

      <TopCard>
        {!!vendor ? (
          <>
            <Grid container justify="flex-start">
              <StatusTag>Recommended</StatusTag>
              <MainTitle mt="5px">{vendor.companyName}</MainTitle>
              <BlueLink>View Profile</BlueLink>
            </Grid>
            <HL mt="20px" />
            <MainText mt="20px" fontSize="13px">
              Quotation
            </MainText>
            <MainTitle>N{separateNumByComma(vendor.quotation)}</MainTitle>
            <HL mt="20px" />
            <Grid container justify="space-between">
              <div>
                <MainText mt="20px" fontSize="13px">
                  Tax clearance
                </MainText>
                <MainText fw="600" fontSize="14px">
                  {vendor.taxClearance ? "Yes" : "No"}
                </MainText>
              </div>
              <div>
                <MainText mt="20px" fontSize="13px">
                  DSIR card
                </MainText>
                <MainText fw="600" fontSize="14px">
                  {vendor.dsirCard ? "Yes" : "No"}
                </MainText>
              </div>
              <div>
                <MainText mt="20px" fontSize="13px">
                  Company memorandum
                </MainText>
                <MainText fw="600" fontSize="14px">
                  {vendor.companyMemo ? "Yes" : "No"}
                </MainText>
              </div>
              <div>
                <MainText mt="20px" fontSize="13px">
                  Company Profile
                </MainText>
                <MainText fw="600" fontSize="14px">
                  {vendor.companyProfile ? "Yes" : "No"}
                </MainText>
              </div>
            </Grid>
            <MainText mt="15px" fontSize="13px">
              Past jobs
            </MainText>
            <MainText fw="600" fontSize="14px">
              {vendor.pastJobs ? "Yes" : "No"}
            </MainText>
          </>
        ) : (
          <StatusTag red>No vendor recommended yet</StatusTag>
        )}
      </TopCard>

      <CardWrap>
        <Pad>
          <InfoCardWrapper>
            <MainTitle>Procurement Information</MainTitle>
            {isLoading && <Loader />}
            {!isLoading && (
              <>
                <MainText mt="5px" fontSize="14px">
                  {tenderDetails.ministry}
                </MainText>
                <MainTitle mt="43px">{tenderDetails.name}</MainTitle>
                <MainText mt="20px" fontSize="13px">
                  Tender Description
                </MainText>
                <MainText mt="10px" fontSize="14px">
                  {tenderDetails.description}
                </MainText>
                <HL mt="20px" />
                <MainText mt="20px" fontSize="13px">
                  Budget
                </MainText>
                <MainTitle>N{separateNumByComma(tenderDetails.budget)}</MainTitle>
                <HL mt="20px" />
                <CuteGrid>
                  <div>
                    <MainText mt="20px" fontSize="13px">
                      Procurement Category
                    </MainText>
                    <MainText fw="600" fontSize="14px">
                      {tenderDetails.procurementCategory}
                    </MainText>
                  </div>
                  <div>
                    <MainText mt="20px" fontSize="13px">
                      Procurement Method
                    </MainText>
                    <MainText fw="600" fontSize="14px">
                      {tenderDetails.procurementMethod}
                    </MainText>
                  </div>
                  <div>
                    <MainText mt="20px" fontSize="13px">
                      Tendering Method
                    </MainText>
                    <MainText fw="600" fontSize="14px">
                      {tenderDetails.reviewMethod}
                    </MainText>
                  </div>
                </CuteGrid>
                <HL mt="20px" />
                <MainText mt="20px" fontSize="13px">
                  procurement ID
                </MainText>
                <MainTitle>{tenderDetails.packageNumber}</MainTitle>
              </>
            )}
            <div className="topRightCard">
              <CuteGrid>
                <div>
                  <MainText mt="20px" mr="10px" fontSize="13px">
                    Date Published
                  </MainText>
                  <MainText fw="600" fontSize="14px">
                    05/09/2020
                  </MainText>
                </div>
                <div>
                  <MainText mt="20px" fontSize="13px">
                    Expiry Date
                  </MainText>
                  <MainText fw="600" fontSize="14px">
                    05/12/2020
                  </MainText>
                </div>
              </CuteGrid>
            </div>
          </InfoCardWrapper>
          <MainTitle mt="40px">Documents</MainTitle>
          <MainText mt="15px" fontSize="14px">
            List of all documents exchanged
          </MainText>
        </Pad>
        {documents.length ? (
          <FileBoard>
            <CuteGrid start>
              {documents.map((doc) => (
                <div key={doc.id}>
                  <FileCard>
                    <FolderIcon />
                    <FileText mt="15px" color="#00B0F1" fontSize="13px">
                      {doc.count} files
                    </FileText>
                  </FileCard>
                  <FileText mt="20px" fontSize="14px" wd="77%">
                    {doc.title}
                  </FileText>
                </div>
              ))}
            </CuteGrid>
          </FileBoard>
        ) : (
          <Pad>
            {" "}
            <MainText mt="10px">No documents yet</MainText>{" "}
          </Pad>
        )}
      </CardWrap>

      <UploadContractDocument />

      <CardWrap mt="20px">
        <Pad>
          <MainTitle>Contract Document</MainTitle>
        </Pad>
        <DocumentSection>
          <MainTitle>Uploaded Contract Draft Document</MainTitle>
          <MainText mt="20px"></MainText>
          <BiddingDocumentContainer>
            {file ? (
              <CuteGrid>
                <div style={{ width: "25%" }}>
                  <FileDownload url={file?.url} lighttheme name={file?.original_filename} size={file?.bytes} />
                </div>
                <CheckIcon />
              </CuteGrid>
            ) : (
              <Pad>
                {" "}
                <MainText mt="10px">No document yet</MainText>{" "}
              </Pad>
            )}
          </BiddingDocumentContainer>
        </DocumentSection>
        {signedContract.length ? (
          <DocumentSection mt="20px">
            <MainTitle mb="20px">Signed Contract</MainTitle>
            <BiddingDocumentContainer>
              <CuteGrid>
                <div style={{ width: "25%" }}>
                  <FileDownload url={signedFile?.url} lighttheme name={signedFile?.original_filename} size={signedFile?.bytes} />
                </div>
                <CheckIcon />
              </CuteGrid>
            </BiddingDocumentContainer>
          </DocumentSection>
        ) : (
          <DocumentSection mt="20px">
            <MainTitle>Signed Contract</MainTitle>
            <CuteGrid>
              <MainText>Awaiting signed contract from vendor</MainText>
              <PendingIcon />
            </CuteGrid>
          </DocumentSection>
        )}
      </CardWrap>
    </ContentWrapper>
  ) : (
    <Loader />
  );
};

export default withAdminDashboard(SingleContractDetails);
