import baseApiMethod from "./baseApiMethod";
import baseAxiosMethod from "./baseAxiosMethod";
import handleApiError, { handleAxiosError, handleApiErrorModified, handleValidationError } from "./handleApiError";
import { transformContractSigningToFrontendModel, transformReviewToFrontendModel } from "../../util/transforms/procurement";
import { sectionedDocs } from "../../util/sectionedDocs";
import { ContractStatus } from "../../util/constants";
import format from "date-fns/format";

const procurementAPI = {};

procurementAPI.getProjectDetails = async (key, { tenderId }) => {
  try {
    const response = await baseAxiosMethod(`projects/tender/${tenderId}`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};
procurementAPI.getVendorPaymentProof = async (key, { vendorId }) => {
  try {
    const response = await baseAxiosMethod(`vendors/${vendorId}/paymentproofs?paymentType=1`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.getPaymentHistory = async (key, { vendorProfileId }) => {
  try {
    const response = await baseAxiosMethod(`vendors/GetVendorRegistrationPayment/${vendorProfileId}`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.getProjectCompletionProof = async (key, { projectId }) => {
  try {
    const response = await baseAxiosMethod(`documentUpload/${projectId}/documents?objectType=1`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.getAdminPaymentProof = async (key, { invoiceId }) => {
  try {
    const response = await baseAxiosMethod(`documentUpload/${invoiceId}/documents?objectType=1`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.getMediaFiles = async (key, { taskId }) => {
  try {
    const response = await baseAxiosMethod(`documentUpload/${taskId}/documents?objectType=2`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.getProjectDocument = async (key, { projectId }) => {
  try {
    const response = await baseAxiosMethod(`projects/${projectId}/reportDocuments`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.getProjectMilestone = async (key, { projectId }) => {
  try {
    const response = await baseAxiosMethod(`projects/${projectId}/milestone`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.getProjectMilestoneDetails = async (key, { projectId, milestoneId }) => {
  try {
    const response = await baseAxiosMethod(`projects/${projectId}/milestone/${milestoneId}`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.getProjectMilestoneTask = async (key, { milestoneId }) => {
  try {
    const response = await baseAxiosMethod(`projects/milestone/${milestoneId}/milestoneTasks`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.getProjectMilestoneTaskDetails = async (key, { milestoneId, taskId }) => {
  try {
    const response = await baseAxiosMethod(`projects/${milestoneId}/milestoneTasks/${taskId}/`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.getProjectDocumentList = async (key, { tenderId }) => {
  try {
    const response = await baseAxiosMethod(`procurements/${tenderId}/documents`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.getProjectMilestoneInvoice = async (key, { milestoneId }) => {
  try {
    const response = await baseAxiosMethod(`projects/${milestoneId}/milestoneInvoice`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.uploadInvoicePaymentProof = async ({ formData, invoiceId }) => {
  try {
    const response = await baseAxiosMethod.post(`projects/confirmPayment/${invoiceId}`, formData);
    return response.data.data;
  } catch (error) {
    throw new Error(handleAxiosError(error));
  }
};

procurementAPI.uploadProjectCompletionProof = async ({ formData, projectId }) => {
  try {
    const response = await baseAxiosMethod.post(`projects/${projectId}/completionProof`, formData);
    return response.data.data;
  } catch (error) {
    throw new Error(handleAxiosError(error));
  }
};

procurementAPI.savePlanDocument = async ({ mandatory, supporting, removed, activityId }) => {
  try {
    const formData = sectionedDocs({ mandatory, supporting, removed });

    const response = await baseAxiosMethod.post(`procurements/${activityId}/ProcurementPlanDocument`, formData);
    return response.data.data;
  } catch (error) {
    throw new Error(handleAxiosError(error));
  }
};

procurementAPI.getProcurementPlanDocument = async function getProcurementPlanDocument(key, activityId) {
  try {
    const response = await baseAxiosMethod(`procurements/${activityId}/procurementPlanDocument`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.getProcurementApprovalMemo = async function getProcurementPlanDocument(key, { procurementId, params }) {
  try {
    const response = await baseAxiosMethod(`procurements/${procurementId}/approvalDocument`, { params });
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.getTenderType = async function getProcurementPlanDocument(key) {
  try {
    const response = await baseAxiosMethod(`procurements/tenderingStages`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.bidEvaluationWorkflow = async function bidEvaluationWorkflow({ data, procurementPlanActivityId }) {
  try {
    const response = await baseApiMethod(data, `procurements/${procurementPlanActivityId}/bidEvaluationWorkflow`, "post");
    return response.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.searchVendors = async function searchVendors(key, { name, RegisterId }) {
  try {
    const response = await baseAxiosMethod(
      `vendors/search?${!!RegisterId ? `RegisterId=${RegisterId}` : ""}${!!name ? `&Name=${name}` : ""}`
    );
    return response;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.getPlanDocuments = async function (_, activityId) {
  try {
    const response = await baseAxiosMethod(`procurements/${activityId}/procurementPlanDocument`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};
procurementAPI.getReviews = async function getReviews(key, objectId) {
  try {
    const response = await baseAxiosMethod(`procurements/${objectId}/review`);
    const { data: messages } = response.data;
    return messages.map(transformReviewToFrontendModel);
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.getProcurementPlanInformation = async function getProcurementPlanInformation(key, procurementId) {
  try {
    const response = await baseAxiosMethod(`procurements/${procurementId}/procurementNotice`);
    return response.data.data;
  } catch (e) {
    throw handleApiErrorModified(e.response?.data, e.response.status);
  }
};

procurementAPI.approveProcurementPlan = async ({ procurementPlanId, file }) => {
  try {
    const response = await baseAxiosMethod.post(`procurements/${procurementPlanId}/approveProcurement`);
    return response.data.message;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.uploadProcurementApprovalMemo = async ({ procurementPlanId, file }) => {
  const fd = new FormData();

  fd.set("File", file);

  try {
    const response = await baseAxiosMethod.post(`procurements/${procurementPlanId}/approvalDocument`, fd);
    return response.data.message;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.needsAmendment = async function needsAmendment({ procurementPlanId }) {
  try {
    const response = await baseAxiosMethod.post(`procurements/${procurementPlanId}/needAmendment`);
    return response.data.message;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};
procurementAPI.sendForApproval = async function sendForApproval({ procurementPlanId }) {
  try {
    const response = await baseAxiosMethod.post(`procurements/${procurementPlanId}/sendForApproval`);
    return response.data.message;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};
procurementAPI.addReview = async function addReview({ objectId, message }) {
  try {
    const response = await baseAxiosMethod.post(`procurements/${objectId}/review`, {
      body: message,
    });
    return transformReviewToFrontendModel(response.data.data);
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};
procurementAPI.getPlanDetails = async (key, id) => {
  try {
    const response = await baseAxiosMethod(`procurements/${id}`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.getAllInterestedVendors = async (key, id) => {
  try {
    const response = await baseAxiosMethod(`procurements/${id}/bidVendors`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.getMinistryGeneralPlans = async (key, id) => {
  try {
    const response = await baseAxiosMethod(`generalPlans/${id}`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.getMinistryGeneralPlansDocument = async (key, id) => {
  try {
    const response = await baseAxiosMethod(`generalPlans/${id}/document`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.getGeneralPlansSummary = (ministryId) =>
  baseApiMethod(null, `generalPlans/summary?ministryId=${ministryId}`, "get");

procurementAPI.getGeneralPlans = async (key, params) => {
  try {
    const response = await baseAxiosMethod(`generalPlans`, {
      params,
    });

    const {
      data,
      meta: { pagination },
    } = response.data;

    return { data, pagination };
  } catch (e) {}
};

procurementAPI.deleteGeneralPlans = async (planId) => {
  try {
    const response = await baseAxiosMethod.delete(`generalPlans/${planId}/delete`);
    return response.data.data;
  } catch (e) {
    throw handleApiError(e.response?.data, e.response?.statusCode);
  }
};

procurementAPI.getMinistryGeneralPlan = (id) => baseApiMethod(null, `generalPlans/${id}`, "get");
procurementAPI.createProcurementPlan = ({ data, generalPlanId }) => baseApiMethod(data, `procurements/${generalPlanId}`, "post");
procurementAPI.getProcurementPlan = (id) => baseApiMethod(null, `procurements/${id}`, "get");
procurementAPI.getProcurementCategories = () => baseApiMethod(null, "procurements/procurementCategories", "get");
procurementAPI.getProcessTypes = () => baseApiMethod(null, "procurements/procurementProcesses", "get");
procurementAPI.getProcurementMethods = () => baseApiMethod(null, "procurements/procurementMethods", "get");
procurementAPI.getQualificationMethods = () => baseApiMethod(null, "procurements/qualificationMethods", "get");
procurementAPI.getReviewMethods = () => baseApiMethod(null, "procurements/reviewMethods", "get");
procurementAPI.getPackageNumber = ({ ministry, procurementCategory, procurementMethod }) =>
  baseApiMethod(
    null,
    `procurements/procurementPlanNumber?Ministry=${ministry}&ProcurementCategory=${procurementCategory}&ProcurementMethod=${procurementMethod}`,
    "get"
  );

procurementAPI.getPackageNumberAxios = async ({ ministry, procurementCategory, procurementMethod }) => {
  try {
    const response = await baseAxiosMethod(
      `procurements/procurementPlanNumber?Ministry=${ministry}&ProcurementCategory=${procurementCategory}&ProcurementMethod=${procurementMethod}`
    );

    return response.data.data.procurementPlanNumber;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.getKeyDetailsFormOptions = async () => {
  try {
    const [procurementCategories, tenderStages, procurementMethods, qualificationMethods, reviewMethods] = await Promise.all([
      baseAxiosMethod("procurements/procurementCategories"),
      baseAxiosMethod("procurements/tenderingStages"),
      baseAxiosMethod("procurements/procurementMethods"),
      baseAxiosMethod("procurements/qualificationMethods"),
      baseAxiosMethod("procurements/reviewMethods"),
    ]);

    const response = { procurementCategories, tenderStages, procurementMethods, qualificationMethods, reviewMethods };

    Object.keys(response).forEach((key) => {
      response[key] = response[key].data?.data;
    });

    return response;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.getKeyFormOptions = async () => {
  try {
    const [procurementCategories, procurementMethods] = await Promise.all([
      baseAxiosMethod("procurements/procurementCategories"),
      baseAxiosMethod("procurements/procurementMethods"),
    ]);

    const response = { procurementCategories, procurementMethods };

    Object.keys(response).forEach((key) => {
      response[key] = response[key].data?.data;
    });

    return response;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.createProcurementPlanAxios = async ({ data, generalPlanId }) => {
  try {
    const response = await baseAxiosMethod.post(`procurements/${generalPlanId}`, data);

    return response.data.data;
  } catch (e) {
    throw handleApiErrorModified(e.response?.data, e.response.status);
  }
};

procurementAPI.getProcurementPlanAxios = async function (_, procurementId) {
  try {
    // console.log("PaymentRequiredNotice response.data.data 1 ");
    const response = await baseAxiosMethod(`/procurements/${procurementId}`);
    // console.log("PaymentRequiredNotice response.data.data  ", response.data.data);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.getSpecificProcurementNotice = async function (_, procurementPlanId) {
  try {
    const response = await baseAxiosMethod(`/procurements/${procurementPlanId}/noticeInformation`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.createSpecificProcurementNotice = async function (values) {
  const { procurementId, ...formValues } = values;
  try {
    await baseAxiosMethod.post(`procurements/${procurementId}/noticeInformation`, formValues);
  } catch (e) {
    throw handleAxiosError(e.response?.data, e.response?.statusCode);
  }
};

procurementAPI.createTenderDocument = async function (values) {
  const { procurementId, formData } = values;
  try {
    await baseAxiosMethod.post(`procurements/${procurementId}/tenderPreparation`, formData);
  } catch (e) {
    throw handleApiErrorModified(e.response?.data, e.response.status);
  }
};

procurementAPI.getTenderDocument = async function (key, { procurementId }) {
  try {
    const response = await baseAxiosMethod(`procurements/${procurementId}/tenderPreparation`);
    return response.data.data;
  } catch (e) {
    throw handleApiErrorModified(e.response?.data, e.response.status);
  }
};

procurementAPI.getSelectedVendorForTender = async function (key, { procurementId }) {
  try {
    const response = await baseAxiosMethod(`procurements/${procurementId}/selectedVendors`);
    return response.data.data;
  } catch (e) {
    throw handleApiErrorModified(e.response?.data, e.response.status);
  }
};

procurementAPI.getDepartments = async function () {
  try {
    const response = await baseAxiosMethod("departments");
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.getAllProcurements = async function (key, params = {}) {
  try {
    const response = await baseAxiosMethod("procurements/procurementNotice", { params });
    return response.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.getTenderDetails = async function (key, tenderId) {
  try {
    const response = await baseAxiosMethod(`procurements/${tenderId}/tender`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.getAwardedContracts = async function getAwardedContracts(key, planActivityId) {
  try {
    const response = await baseAxiosMethod(`/procurements/${planActivityId}/contracts`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};
procurementAPI.getContractSigningDocument = async function (key, planActivityId) {
  try {
    const response = await baseAxiosMethod(`/procurements/${planActivityId}/contractSigningDocument`);
    const { data } = response.data;
    return transformContractSigningToFrontendModel(data);
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};
const appendToFormData = (fd) => (key, items) => {
  items.forEach((item) => fd.append(key, item));
};
procurementAPI.uploadContractSigningDocument = async function ({ planActivityId, documents, datasheet }) {
  try {
    const fd = new window.FormData();
    const append = appendToFormData(fd);
    append("Documents.RemovedDocuments", documents.removed);
    append("Documents.MandatoryDocument", documents.mandatory);
    append("Documents.SupportingDocument", documents.supporting);
    fd.set("Description", datasheet.description);
    fd.set("Reference", datasheet.reference);
    fd.set("SignatureDate", datasheet.signatureDate);
    const response = await baseAxiosMethod.post(`/procurements/${planActivityId}/contractSigningDocument`, fd);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};
procurementAPI.editSpecificProcurementNotice = async (values) => {
  const { procurementId, newFormValues } = values;
  try {
    await baseAxiosMethod.post(`procurements/${procurementId}/noticeInformation`, newFormValues);
  } catch (e) {
    throw handleAxiosError(e);
  }
};
procurementAPI.getAllProcurementProcessTypes = async function () {
  try {
    const response = await baseAxiosMethod("procurements/tenderingStages");
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};
procurementAPI.getAllProcurementCategoryTypes = async function () {
  try {
    const response = await baseAxiosMethod("procurements/procurementCategories");
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};
procurementAPI.fetchAllProcumentNotice = async (_, payload) => {
  try {
    const response = await baseAxiosMethod("procurements/noticeInformation", {
      params: {
        Search: payload.title,
        ProcurementCategory: payload.procurementCategory,
        Ministry: payload.ministry,
        ExpiryDate: payload.expiryDate,
        PageNumber: payload.pageNumber,
        PageSize: payload.pageSize,
        Status: payload.status,
      },
    });

    const {
      data,
      meta: { pagination },
    } = response.data;

    return { notices: data, pagination };
  } catch (e) {
    throw handleAxiosError(e);
  }
};
procurementAPI.createActivities = ({ activities, id }) => baseApiMethod({ activities }, `procurements/${id}/activities`, "post");
procurementAPI.getPresetStages = ({ type, tenderStage }) =>
  baseApiMethod(null, `procurements/procurementPlanType?type=${type}&tenderingStage=${tenderStage}`, "get");
procurementAPI.getDocumentDataSheet = async (key, activityId) => {
  try {
    const response = await baseAxiosMethod(`procurements/${activityId}/documentDatasheet`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};
procurementAPI.postDocumentDataSheet = async function ({ mandatory, supporting, removed, submissionDeadline, activityId }) {
  const formData = sectionedDocs({ mandatory, supporting, removed, type: "datasheet" });
  formData.append("SubmissionDeadline", submissionDeadline);
  formData.append("Documents.ObjectType", "admin");

  try {
    await baseAxiosMethod.post(`procurements/${activityId}/documentDatasheet`, formData);
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};
procurementAPI.savePlanDocument = async function ({ mandatory, supporting, removed, activityId }) {
  const formData = sectionedDocs({ mandatory, supporting, removed });
  formData.append("ObjectType", "admin");

  try {
    await baseAxiosMethod.post(`procurements/${activityId}/procurementPlanDocument`, formData);
  } catch (error) {
    throw new Error(handleAxiosError(error));
  }
};
procurementAPI.getStageDocuments = async function (_, activityId) {
  try {
    const response = await baseAxiosMethod(`/procurements/${activityId}/procurementPlanDocument`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.getPresetStagesAxios = async (key, { type, tenderStage, reviewMethod }) => {
  try {
    const response = await baseAxiosMethod(
      `procurements/procurementPlanType?type=${type}&tenderingStage=${tenderStage}&reviewMethod=${reviewMethod}`
    );

    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.createActivitiesAxios = async ({ activities, id, replacementType }) => {
  try {
    const response = await baseAxiosMethod.post(`procurements/${id}/activities`, { activities, replacementType });

    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.createContract = async function ({ procurementPlanId, contractDetails }) {
  try {
    const response = await baseAxiosMethod.post(`/contracts/${procurementPlanId}`, contractDetails);
    return response.data.message;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};
procurementAPI.approveActivity = async function approveActivity(values) {
  const { procurementPlanId, procurementPlanActivityId } = values;
  try {
    const response = await baseAxiosMethod.post(`procurements/${procurementPlanId}/approve/${procurementPlanActivityId}`);
    return response.data.message;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.getPlanningAndTrackingInformation = async function (_, procurementPlanId) {
  try {
    const contracts = await procurementAPI.getAwardedContracts(_, procurementPlanId);
    const acceptedContract = contracts.find((contract) => contract.status === ContractStatus.Accepted);
    const manager = await baseAxiosMethod(`/users/${acceptedContract.contractor.userId}`).then((res) => res.data.data);
    const project = await baseAxiosMethod(`/projects/contract/${acceptedContract.id}`).then((res) => res.data.data);
    const milestones = await baseAxiosMethod(`/projects/${project.id}/mileStone`).then((res) => res.data.data);
    return {
      manager,
      contract: acceptedContract,
      milestones,
    };
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.getEvaluatedReport = async function (_, procurementPlanId) {
  try {
    const response = await baseAxiosMethod(`/procurements/${procurementPlanId}/evaluatedReport`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.getPreQualificationDocuments = async (key, activityId) => {
  try {
    const response = await baseAxiosMethod(`procurements/${activityId}/prequalification`);

    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.postPreQualificationDocuments = async ({ editorState, deadline, requiredDocuments, supporting, activityId }) => {
  try {
    const fd = new FormData();
    fd.set("Description", editorState);
    fd.set("Deadline", format(new Date(deadline), "yyyy-MM-dd"));

    requiredDocuments.forEach((document, index) => {
      fd.append(`RequiredDocuments[${index}].Title`, document.title);
      fd.append(`RequiredDocuments[${index}].Description`, document.description);
    });

    supporting.forEach((document) => {
      fd.append("SupportingDocument", document);
    });

    await baseAxiosMethod.post(`procurements/${activityId}/prequalification`, fd);
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.getExchangedDocuments = async function (_, tenderId) {
  try {
    const response = await baseAxiosMethod.get(`/procurements/${tenderId}/documents`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.getActivityById = async (key, { activityId }) => {
  try {
    const response = await baseAxiosMethod(`procurements/activity/${activityId}`);

    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.getTenderDetails = async (_, procurementId) => {
  try {
    const response = await baseAxiosMethod(`procurements/${procurementId}/details`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.getAnalytics = async (_) => {
  try {
    const response = await baseAxiosMethod(`Summary/procurement`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.getRecommendedVendor = async (_, procurementId) => {
  try {
    const response = await baseAxiosMethod(`procurements/${procurementId}/recommendedVendor`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.getExchangedDocuments = async (_, procurementId) => {
  try {
    const response = await baseAxiosMethod(`procurements/${procurementId}/documents`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.MOJUploadContract = async (values) => {
  const { activityId, formData } = values;
  try {
    await baseAxiosMethod.post(`procurements/${activityId}/contract-document`, formData);
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.getActivityId = async (procurementId) => {
  const params = {
    "activity-plan": "award of contract"
  }
  try {
    const res = await baseAxiosMethod.get(`procurements/${procurementId}`, {params });
    return res.data
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.getVendorSignedContract = async (key, { procurementId, objectType = 2 }) => {
  try {
    const response = await baseAxiosMethod.get(`procurements/${procurementId}/contractDocuments?objectType=${objectType}`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.getContractDraft = async (procurementId) => {
  try {
    const response = await baseAxiosMethod.get(`procurements/${procurementId}/contractDocuments?objectType=5`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.createAuthorizerSignature = async ({ formData }) => {
  try {
    const response = await baseAxiosMethod.post(`procurements/authorize-certificate`, formData);
    return response.data;
  } catch (error) {
    throw new Error(handleAxiosError(error));
  }
};

procurementAPI.getAuthorizerSignature = async () => {
  try {
    const response = await baseAxiosMethod.get(`procurements/authorize-certificate`);
    return response.data;
  } catch (error) {
    throw new Error(handleAxiosError(error));
  }
};

procurementAPI.getCertificateData = async (key, id) => {
  try {
    const response = await baseAxiosMethod.get(`procurements/${id}/certificate-noObjection-details`);
    return response.data;
  } catch (error) {
    throw new Error(handleAxiosError(error));
  }
};

procurementAPI.postCertificateInformation = async ({ data, activityId }) => {
  try {
    const response = await baseAxiosMethod.post(`procurements/requestCertificateofObjection/${activityId}`, data);
    return response.data;
  } catch (error) {
    throw new Error(handleAxiosError(error));
  }
};

// procurementAPI.patchCertificateInformation = async ({ formData, procurementId }) => {
//   console.log(procurementId)
//   try {
//     const response = await baseAxiosMethod.patch(`procurements/${procurementId}/certificate-noObjection-details`, formData);
//     return response.data;
//   } catch (error) {
//     throw new Error(handleAxiosError(error))
//   }
// }

procurementAPI.patchCertificateInformation = async ({ data, procurementId }) => {
  try {
    const response = await baseAxiosMethod.patch(`procurements/${procurementId}/certificate-noObjection-details`, data);
    return response.data;
  } catch (error) {
    throw new Error(handleAxiosError(error));
  }
};

procurementAPI.editProcurementDate = async function editProcurementDate({ procurementId, activityId, data }) {
  try {
    const response = await baseAxiosMethod.patch(`procurements/${procurementId}/activities/${activityId}`, data);
    return response.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

procurementAPI.extendStageDates = async (data) => {
  try {
    const response = await baseAxiosMethod.patch(`procurements/activities/`, data);
    return response;
  } catch (e) {
    throw new Error(handleValidationError(e));
  }
};

procurementAPI.requestAdmendmentCNO = async ({ payload, activityId }) => {
  try {
    const response = await baseAxiosMethod.post(`procurements/certificate-noObjection/${activityId}/amend`, payload);
    return response.data;
  } catch (error) {
    throw new Error(handleAxiosError(error));
  }
};

procurementAPI.getSupplementaryList = async ({ params }) => {
  const { data } = await baseAxiosMethod.get(`procurements/SupplementaryBudgets`, { params });

  return data;
};

procurementAPI.getSupplementaryById = async ({ id }) => {
  const params = { budgetId: id };
  const { data } = await baseAxiosMethod.get(`procurements/SupplementaryBudget`, { params });
  return data;
};

procurementAPI.getSupplementaryByAppId = async ({ id }) => {
  const params = {
    annualProcurementPlanId: id,
  };
  const { data } = await baseAxiosMethod.get(`procurements/SupplementaryBudgetsByAppId`, { params });
  return data;
};

procurementAPI.createSupplementaryBudget = async ({ payload, id }) => {
  const value = {
    ...payload,
    annualProcurementPlanId: id,
  };
  const { data } = await baseAxiosMethod.post(`procurements/CreateSupplementaryBudget`, value);
  return data;
};

procurementAPI.updateSupplementaryStatusDecision = async ({ budgetId, status }) => {
  const { data } = await baseAxiosMethod.post(
    `procurements/SupplementaryBudgetsDecision?budgetId=${budgetId}&decision=${status}`
  );
  return data;
};

export default procurementAPI;
