import { TableContainer } from "@material-ui/core";
import styled from "styled-components";
import { theme } from "../../../util/theme";

export const TitleContainer = styled.div({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "16px",
  alignItems: "center",
  "& .page-title": {
    fontSize: "26px",
    lineHeight: 1.5,
    textTransform: "capitalize",
    color: theme.color.textColor1,
    fontWeight: 400,
  },
  "& .left-title-container": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
});

export const ContentWrapper = styled("div")({
  padding: "40px 20px",
  "& .header-topic": {
    color: "#272833",
    fontSize: "26px",
    fontWeight: 600,
    lineHeight: "133%",
  },
});

export const TableContainerWrapper = styled.div`
  margin-top: ${({ nopadding }) => (nopadding === "true" ? "20px" : 0)};
`;

export const TableWrapper = styled(TableContainer)`
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 #cbd4da !important;

  .MuiTableCell-body {
    color: ${theme.color.secondary} !important;
  }

  .MuiTableHead-root {
    border-top: 1px solid #cbd4da;

    th {
      text-transform: uppercase;
      padding: 16px 10px;
    }

    tr:first-child {
      outline: 1px solid #cbd4da;
      th {
        line-height: 11px;
      }
      th:last-child {
        text-align: center;
      }
    }
  }

  .MuiTable-stickyHeader {
    border-top: 1px solid #cbd4da;
  }

  th:first-child {
    border-left: 1px solid #cbd4da;
  }

  th,
  td {
    text-align: left;
  }

  th {
    font-size: 12px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 16px;
    color: #637381;
  }

  p,
  span {
    font-size: 14px;
  }

  span {
    font-size: 12px;
    font-weight: 400;
  }

  .MuiTableCell-body:last-child {
    span {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      width: 22px;
      text-align: center;
    }
  }

  .MuiTableCell-body:first-child {
    div:nth-child(2) {
      margin-left: 10px;
    }
  }

  .MuiTablePagination-root {
    border-left: 1px solid #cbd4da;
  }

  .MuiTableBody-root .MuiTableCell-root {
    padding: 0 10px;
    height: 59px;

    :last-child {
      text-align: center;
    }
  }

  .MuiChip-label {
    text-transform: capitalize;
  }

  .MuiTableBody-root > .MuiBox-root {
    position: absolute;
    margin-left: 25%;
    text-transform: capitalize;
  }

  .MuiCircularProgress-colorPrimary {
    color: #03adeb;
  }
`;
