import React from "react";

export const useSliceWord = (values, length = 50) => {
  const [description, setDescription] = React.useState("");
  const [viewMore, setViewMore] = React.useState("view more");

  const handleViewMore = (e) => {
    e.stopPropagation();
    if (typeof values === "string") {
      if (values.length === description.length) {
        setViewMore("view more");
        if (description.length > length || values.length > length) {
          const sliceWOrd = values.slice(0, length);
          setDescription(sliceWOrd);
        } else {
          setViewMore("show less");
          setDescription(values);
        }
      } else {
        setViewMore("show less");
        const sliceWord = values.slice(0);
        setDescription(sliceWord);
      }
    }
  };

  React.useEffect(() => {
    if (typeof values === "string") {
      setViewMore("view more");
      if (values.length > length) {
        const sliceValue = values.slice(0, 50);
        setDescription(sliceValue);
      } else {
        setDescription(values);
      }
    }
  }, [values]);

  return {
    description,
    handleViewMore,
    viewMore,
  };
};
