import React from "react";
import PropTypes from "prop-types";
import { StyledBox } from "./style";
import MuiModal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";

const Modal = ({ open, onClose, children }) => {
  return (
    <MuiModal open={open} onClose={onClose}>
      <StyledBox>
        <Box sx={{ pb: 0, px: 0 }}>{children}</Box>
      </StyledBox>
    </MuiModal>
  );
};

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
};
Modal.defaultProps = {
  onClose: () => null,
};

export default Modal;
