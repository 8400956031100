import React, { useState, useContext } from "react";
import { TitleContainer, DeadlineRow, SubmitButton, BorderedBox } from "./style";
import ToastContext from "../../../../../util/toastContext";
import DatePicker from "../../../../../components/DatePicker";
import AccessControl from "../../../../../components/AccessControl";
import { Roles } from "../../../../../components/AccessControl/UserRoles";
import awardOfContractApi from "../../../../../redux/api/awardOfContractApi";
import procurementAPI from "../../../../../redux/api/procurementAPI";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import moment from "moment";

const ContractSigningDeadline = () => {
  const { procurementId, activityId } = useParams();
  const [deadline, setDeadline] = useState(null);
  const { showAlert } = useContext(ToastContext);

  const [updateDeadline, updateDeadlineQuery] = useMutation(awardOfContractApi.updateDeadline, {
    throwOnError: true,
    onSuccess: () => {
      showAlert({
        severity: "Success",
        durationInMs: 3000,
        message: "Deadline updated successfully",
      });
    },
  });

  const { data: contractDraft = [] } = useQuery(["getContractDraft", procurementId], () =>
    procurementAPI.getContractDraft(procurementId)
  );

  const lastDeadline = contractDraft?.[contractDraft?.length - 1]?.deadline;

  const handleUpdateDeadline = () => {
    updateDeadline({
      activityId,
      deadline,
    });
  };

  return (
    <div>
      <TitleContainer>Update Contract signing deadline</TitleContainer>
      <BorderedBox>
        <DeadlineRow>
          <DatePicker
            id="date"
            datePickerWrapperStyle={{ padding: 0 }}
            style={{ margin: "10px 0 10px", width: "30%" }}
            label="Deadline"
            value={lastDeadline ?? deadline}
            handleChange={(value) => setDeadline(moment(value).format("YYYY-MM-DD"))}
          />
          <AccessControl allowedRoles={[Roles.PROCUREMENT_OFFICER]}>
            <SubmitButton onClick={handleUpdateDeadline} disabled={contractDraft.length === 0}>
              {updateDeadlineQuery.isLoading ? "Updating" : "Update Deadline"}
            </SubmitButton>
          </AccessControl>
        </DeadlineRow>
      </BorderedBox>
    </div>
  );
};

export default ContractSigningDeadline;
