import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { upperCase, lowerCase } from "lodash";
import { InputField } from "../../../../../shared";
import { CancelButton, UpdateButton } from "../../../Administration/Settings/style";
import { ErrorContainer } from "../../../../../util/theme";
import { Dropdown } from "../../../../../components";
import { DateRangeWrapper } from "../../../../../components/DateRangePIcker/style";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useParams } from "react-router-dom";
import { contractTenderDefaultProps, contractTenderResolver } from "./validator";
import {
  archiveProcurementMethodFunc,
  archiveProcurementMethodOptions,
  archiveReviewMethodFunc,
  archiveReviewMethodOptions,
  archiveTenderStatusFunc,
  archiveTenderStatusOptions,
  archiveTenderingStageFunc,
  archiveTenderingStageOptions,
} from "../utils/useArchiveHokk";
import { onNumberValidator } from "../../../../../util/numberValidator";

const EditTenderDetailsTab = ({ data, isLoading, mutate, handleEdit }) => {
  const { register, errors, watch, reset, control, handleSubmit } = useForm({
    defaultValues: contractTenderDefaultProps,
    resolver: contractTenderResolver,
  });
  const params = useParams();
  useEffect(() => {
    let payload = {};
    if (data) {
      const fields = Object.keys(contractTenderDefaultProps);
      fields.forEach((field) => {
        if (field === "tenderStatus") {
          payload[field] = archiveTenderStatusFunc(lowerCase(data?.tender?.tenderStatus || ""));
        } else if (field === "packageNumber") {
          payload[field] = data?.tender?.packageNumber ? data?.tender?.packageNumber : contractTenderDefaultProps[field];
        } else if (field === "procurementMethod") {
          payload[field] = archiveProcurementMethodFunc(lowerCase(data?.tender?.procurementMethod || ""));
        } else if (field === "tenderingStage") {
          payload[field] = archiveTenderingStageFunc(lowerCase(data?.tender?.tenderingStage || ""));
        } else if (field === "reviewMethod") {
          payload[field] = archiveReviewMethodFunc(lowerCase(data?.tender?.reviewMethod || ""));
        } else if (field === "projectName") {
          payload[field] = data?.title || contractTenderDefaultProps[field];
        } else if (field === "tenderDate") {
          payload[field] =
            (data?.bidSolicitationDate ? new Date(data.bidSolicitationDate) : data?.tender?.tenderDate) ||
            contractTenderDefaultProps[field];
        } else if (field === "bidOpeningDate") {
          payload[field] = data?.bidOpeningDate ? new Date(data?.bidOpeningDate) : contractTenderDefaultProps[field];
        } else if (field === "projectName") {
          payload[field] = data?.title || contractTenderDefaultProps[field];
        } else {
          payload[field] = data[field] || contractTenderDefaultProps[field];
        }
      });
      payload = {
        ...payload,
      };
      reset({ ...payload });
    }
  }, [data, reset]);

  const onSubmit = (e) => {
    // e.projectTitle = e.title;
    const payload = {};
    const fields = Object.keys(contractTenderDefaultProps);
    fields.forEach((field) => {
      if (field === "procurementMethod") {
        const prMtd = e[field];
        payload[field] = prMtd;
      }
      payload[field] = e[field];
    });
    ["projectName", "numberOfTenderers", "tenderDate"].forEach((v) => {
      delete payload[v];
    });
    mutate({ payload, archiveId: params.id, type: "tender" });
  };

  const onRenderError = (error) => <ErrorContainer>{error && error.message}</ErrorContainer>;
  return (
    <div>
      <div>
        <Controller
          control={control}
          name="projectName"
          render={(props) => <InputField disabled label="Tender Title" style={{ background: "#ffffff" }} {...props} />}
        />
      </div>
      <div>
        <DateRangeWrapper width={{ root: "97%" }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              control={control}
              name="tenderDate"
              render={(props) => (
                <KeyboardDatePicker
                  label="Tender Date"
                  disableToolbar
                  openTo="year"
                  views={["year", "month", "date"]}
                  variant="inline"
                  format="dd-MMM-yyyy"
                  placeholder="DD-MM-YYYY"
                  margin="normal"
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...props}
                />
              )}
            />
          </MuiPickersUtilsProvider>
        </DateRangeWrapper>
      </div>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        <div>
          <Controller
            control={control}
            name="tenderStatus"
            render={(props) => (
              <Dropdown
                // inputRef={register({
                //   required: "Select Tender Stage",
                // })}
                // defaultValue={watch("tenderStatus")}
                style={{ width: "inherit" }}
                label="Tender Status"
                {...props}
                values={archiveTenderStatusOptions}
              />
            )}
          />{" "}
          {onRenderError(errors?.tenderStatus)}
        </div>
        <div>
          <Controller
            control={control}
            name="packageNumber"
            render={(props) => (
              <InputField
                label="Package Number"
                // inputRef={register({
                //   required: "Package Number is required",
                // })}
                style={{ background: "#ffffff" }}
                {...props}
              />
            )}
          />{" "}
          {onRenderError(errors?.packageNumber)}
        </div>
      </div>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        <div>
          <Controller
            control={control}
            name="procurementMethod"
            render={(props) => (
              <Dropdown
                // inputRef={register({
                //   required: "Select Procurement Method",
                // })}
                // defaultValue={watch("procurementMethod")}
                style={{ width: "inherit" }}
                label="Procurement Method"
                {...props}
                values={archiveProcurementMethodOptions}
              />
            )}
          />{" "}
          {onRenderError(errors.procurementMethod)}
        </div>
        <div>
          <Controller
            control={control}
            name="numberOfTenderers"
            render={({ ref, onChange, ...props }) => (
              <InputField
                disabled
                label="Number of Tenderers"
                // inputRef={register({
                //   required: "Package Number is required",
                // })}
                inputRef={ref}
                style={{ background: "#ffffff" }}
                onChange={(e) => onNumberValidator(e, onChange)}
                {...props}
              />
            )}
          />{" "}
          {onRenderError(errors.numberOfTenderers)}
        </div>
      </div>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}>
        <div>
          <Controller
            control={control}
            name="tenderingStage"
            render={(props) => (
              <Dropdown
                // defaultValue={watch("tenderingMethod")}
                name="tenderingStage"
                style={{ width: "inherit" }}
                label="Tendering Stages"
                {...props}
                values={archiveTenderingStageOptions}
              />
            )}
          />{" "}
        </div>
        <div>
          <Controller
            control={control}
            name="reviewMethod"
            render={(props) => (
              <Dropdown
                // inputRef={register({
                //   required: "Review Method",
                // })}
                // defaultValue={watch("reviewMethod")}
                name="reviewMethod"
                style={{ width: "inherit" }}
                label="Review Method"
                {...props}
                values={archiveReviewMethodOptions}
              />
            )}
          />{" "}
          {onRenderError(errors?.reviewMethod)}
        </div>
        <div>
          <DateRangeWrapper width={{ root: "97%" }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Controller
                control={control}
                name="bidOpeningDate"
                render={(props) => (
                  <KeyboardDatePicker
                    label="Bid Openning Date"
                    disableToolbar
                    openTo="year"
                    views={["year", "month", "date"]}
                    variant="inline"
                    format="dd-MMM-yyyy"
                    placeholder="MM/DD/YYYY"
                    margin="normal"
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    disabled
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...props}
                  />
                )}
              />
            </MuiPickersUtilsProvider>
          </DateRangeWrapper>
        </div>
      </div>
      <div style={{ display: "grid", gridTemplateColumns: "150px 1fr", placeItems: "center start", marginTop: "30px" }}>
        <UpdateButton onClick={handleSubmit(onSubmit)}>{isLoading ? "Saving Changes..." : "Save Changes"}</UpdateButton>
        <CancelButton onClick={handleEdit}>Cancel</CancelButton>
      </div>
    </div>
  );
};

EditTenderDetailsTab.propTypes = {};

export default EditTenderDetailsTab;
