import React from "react";
import withAdminDashboard from "../HOC/withAdminDashboard";
import Breadcrumb from "../../Common/PageElements/Breadcrumb";
import { queryCache } from "react-query";
import { ContentWrapper } from "../Procurement/style";
import { TitleContainer } from "./style";
import { ReusableButton } from "../../../shared";
import { useHistory, useParams, useLocation } from "react-router-dom";
import SearchTable from "./SupplementaryTable";
import { supplementaryListData } from "./mock";
import TableBody from "./TableBody";
import RequestReviewModal from "./RequestReviewModal";
import StatusAlert from "./StatusAlert";
import { useFetchProcurementDetails } from "../../../hooks/queries/useGeneralPlans";
import {
  useCreateSupplementaryBudget,
  useFetchSupplementaryList,
  useSupplementaryDecision,
} from "../../../hooks/queries/useSupplementaryBudget";
import ToastContext from "../../../util/toastContext";
import AccessControl from "../../../components/AccessControl";
import { Roles } from "../../../components/AccessControl/UserRoles";

const SuplementartList = () => {
  const { procurementId } = useParams();
  const { showAlert } = React.useContext(ToastContext);
  const [dataSource, setDataSource] = React.useState([]);
  // const queryClient = useQueryCache();
  const [tableParams, setTableParams] = React.useState({
    search: "",
    sort: "createdAt",
    status: "",
    pagination: {
      pageNumber: 0,
      pageSize: 10,
      total: 1,
    },
  });
  const location = useLocation();
  const [selected, setSelected] = React.useState(null);
  const history = useHistory();
  const [pageNumber, setPageNumber] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const handleModal = () => {
    setOpen(true);
  };

  // const suppListParams = {};
  const { fetchingSupplementarylist } = useFetchSupplementaryList({
    showAlert,
    setDataSource,
    // params: suppListParams,
  });

  const { supplementaryStatus, updatingStatus } = useSupplementaryDecision({
    showAlert,
    queryClient: queryCache,
    setModal: setSelected,
  });

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const supplementaryList = [
    { url: "/admin/dashboard/overview", title: "Home" },
    { url: `/admin/procurement/plans/${procurementId}`, title: "Plan" },
  ];

  const handleMenuClick = (values, keyType) => {
    switch (keyType) {
      case "view-list": {
        history.push(`${location.pathname}/${values?.id}`);
        break;
      }
      case "approve-list": {
        setSelected({ ...values, openMenu: true });
        break;
      }
      case "reject-list": {
        setSelected({ ...values, openMenu: true });
        break;
      }

      default:
        break;
    }
  };
  const { createSupplementaryBudget, creatingSupplementary } = useCreateSupplementaryBudget({
    showAlert,
    queryClient: queryCache,
    setModal: setOpen,
  });

  const onRequestSupplemary = (values) => {
    const payload = {
      type: values?.type ? parseInt(values?.type, 10) : 0,
      budget: values?.budget,
      newBudget: values?.newBudget,
    };

    createSupplementaryBudget({ payload, id: procurementId });
  };

  const { procurementPlanDetail } = useFetchProcurementDetails({ id: procurementId });

  const toggleStatus = (values) => {
    console.log(values);
    const payload = {
      status: values?.menuValue?.toLowerCase() !== "rejected",
      budgetId: values?.id,
    };

    supplementaryStatus({
      ...payload,
    });
  };
  return (
    <>
      <ContentWrapper>
        <TitleContainer>
          <div className="left-title-container">
            <p className="page-title">Supplementary List</p>
            <Breadcrumb values={supplementaryList} />
          </div>
          <AccessControl allowedRoles={[Roles.PROCUREMENT_OFFICER]}>
            <div>
              <ReusableButton label="Request Upward Review" onClick={handleModal} />
            </div>
          </AccessControl>
        </TitleContainer>
        <SearchTable
          rows={supplementaryListData}
          columns={["Date Requested", "Type", "Approved Amount", "Amount Requested", "status", ""]}
          page={pageNumber}
          setPage={setPageNumber}
          empty={supplementaryListData === 0}
          emptyMessage="No Supplementary available"
          loading={false}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          tableParams={tableParams}
          setTableParams={setTableParams}
        >
          <TableBody dataSource={dataSource} onMenuClick={handleMenuClick} />
        </SearchTable>
      </ContentWrapper>
      <RequestReviewModal
        open={Boolean(open)}
        onClose={() => setOpen(false)}
        title="Review Upward Review"
        subtitle="Upward review request for Annual Procurement Plan"
        budget={procurementPlanDetail}
        onAction={onRequestSupplemary}
        loading={creatingSupplementary}
      />
      <StatusAlert
        reviewType={selected?.type || ""}
        statusKey={selected?.menuValue?.toLowerCase() || "rejected"}
        selected={selected}
        open={Boolean(selected?.openMenu)}
        onClose={() => setSelected(null)}
        onAction={toggleStatus}
      />
    </>
  );
};

export default withAdminDashboard(SuplementartList);
