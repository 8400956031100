import React from "react";
import PropTypes from "prop-types";
import CustomRadio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import { StyledFormControl } from "./style";
import { capitalize } from "lodash";

const Radio = ({ formLabel, options, row, value, onChange, allLabel, allValue, name, ...rest }) => {
  return (
    <StyledFormControl component="fieldset" {...rest} fullWidth>
      <FormLabel component="legend">{formLabel}</FormLabel>
      <RadioGroup aria-label={name} name={name} value={value} onChange={onChange} row={row}>
        {allLabel && <FormControlLabel value={allValue || "all"} labelPlacement="end" control={<CustomRadio />} />}
        {options?.map((v, i) => (
          <FormControlLabel
            labelPlacement="end"
            key={`${v?.label}-${i}`}
            value={v?.value}
            control={<CustomRadio />}
            label={capitalize(v?.label)}
          />
        ))}
      </RadioGroup>
    </StyledFormControl>
  );
};

Radio.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ).isRequired,
  formLabel: PropTypes.string.isRequired,
  row: PropTypes.bool,
  onChange: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  name: PropTypes.string,
  allLabel: PropTypes.string,
  allValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
Radio.defaultProps = {
  row: false,
  onChange: () => {},
  name: "",
  allLabel: "",
  allValue: "",
};

export default Radio;
