import styled from "styled-components";
import FormControl from "@material-ui/core/FormControl";
import { theme } from "../../util/theme";

export const StyledFormControl = styled(FormControl)({
  fontSize: "14px",
  "& .MuiFormLabel-root": {
    color: theme.color.textColor1,
  },
  "& .MuiFormGroup-root": {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: "20px",
    "& .MuiFormControlLabel-root": {
      border: `1px solid ${theme.color.primary}`,
      borderRadius: "8px",
      padding: "8px",
      "& .MuiTypography-root": {
        color: theme.color.textColor1,
        fontSize: "14px",
      },
      "& .Mui-checked": {
        color: "blue",
      },
      "& .MuiSvgIcon-root": {
        fontSize: "16px",
      },
    },
  },
});
