import React from "react";
import { Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Caption, SideNavSection } from "./style";
import Navbar from "../../../components/AdminDashboardNav";
import Sidebar from "../VendorSidebar";
import useAlert from "../../../hooks/useAlert";
import ToastContext from "../../../util/toastContext";

const withVendorDashboard = (WrappedComponent) => (props) => {
  const { showAlert, Toast } = useAlert();
  const history = useHistory();

  return (
    <ToastContext.Provider value={{ showAlert, Toast }}>
      <SideNavSection>
        <Caption onClick={() => history.push("/")}>
          Delta State
          </Caption>
        <Sidebar />
      </SideNavSection>
      <div style={{ marginLeft: "250px", background: "#FAFBFC" }}>
        <Navbar />
        <Toast float />
        <Box paddingLeft="33.5px" paddingRight="33.5px" minHeight="94vh">
          <WrappedComponent />
        </Box>
      </div>

    </ToastContext.Provider>
  );
};

export default withVendorDashboard;
