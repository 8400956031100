import React from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import InputField from './InputField'

const ControlledInputField = ({
  control,
  name,
  label,
  ...rest
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ onChange, value, ref, name: _name, ...fields }) => (
        <InputField
          setValue={onChange}
          value={value}
          inputRef={ref}
          label={label}
          name={_name}
          {...fields}
          {...rest}
        />
      )}
    />
  )
}

ControlledInputField.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.oneOfType([
    PropTypes.object, PropTypes.func
  ])
}

ControlledInputField.defaultProps = {
  control: () => { }
}

export default ControlledInputField
