import React from "react";
import { Grid, Tooltip } from "@material-ui/core";

import { ContractDetailTabText } from "./style";
import { dateFormat } from "../../../../util/formatDate";

const ContactBiddersTab = ({ data: { tenderDetails = {}, ...contractDetails } }) => {
  const details = [
    { title: "Project Title", value: contractDetails.title || "--", maxLength: 120 },
    {
      title: "Project Description",
      value: contractDetails.description || contractDetails.title || "--",
      maxLength: 120,
    },
    { title: "Contractors Name", value: contractDetails.contractor, maxLength: 120 },
    { title: "Contractors Phone	", value: contractDetails.contractorPhone || "--" },
    { title: "Contractors Address	", value: contractDetails.contractorAddress || "--", maxLength: 120 },
    { title: "Contractors email	", value: contractDetails.contractorEmail || "--" },
    { title: "Contract Status	", value: contractDetails.contractStatus || "--" },
    {
      title: "Contract award signing date	",
      value: contractDetails.contractSignedDate ? dateFormat(contractDetails.contractSignedDate) : "Pending",
    },
    {
      title: "Contract duration",
      value: `${contractDetails.contractDuration} ${contractDetails?.durationType || ""}` || "--",
    },
    {
      title: "Start date",
      value: dateFormat(contractDetails.contractStartDate),
    },
    {
      title: "End date",
      value: dateFormat(contractDetails.contractEndDate),
    },
  ];

  return (
    <>
      {details.map((detail, i) => (
        <Grid key={i} style={{ borderBottom: "1px solid #CBD4DA" }} container>
          <Grid item xs={3} style={{ background: "#e4e9ec" }}>
            <ContractDetailTabText style={{ background: "#e4e9ec", boxShadow: "0px -1px 0px #C9D4DB" }}>
              {detail.title}
            </ContractDetailTabText>
          </Grid>
          <Grid item sm style={{ borderLeft: "1px solid #CBD4DA" }}>
            {detail.maxLength && detail.length > detail.maxLength ? (
              <Tooltip title={detail.value} aria-label="add">
                <ContractDetailTabText>
                  {detail.value.length > detail.maxLength ? `${detail.value.substr(0, detail.maxLength)}...` : detail.value}
                </ContractDetailTabText>
              </Tooltip>
            ) : (
              <ContractDetailTabText>{detail.value}</ContractDetailTabText>
            )}
          </Grid>
        </Grid>
      ))}
    </>
  );
};

export default ContactBiddersTab;
