import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Checkbox } from "@material-ui/core";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import LetterOfIntentUpload from "./LetterOfIntentUpload";
import {
  BoldCardContent,
  BorderedBox,
  BreadCrumbWrapper,
  CardBody,
  CardContent,
  CardLabel,
  CardSection,
  CardWrapper,
  CheckBoxText,
  ContentWrapper,
  Divider,
  Footer,
  GridContainer,
  PageTitle,
  ResponseContainer,
  ResponseText,
  ResponseTitle,
  SerialText,
  SubmissionResponseWrapper,
  SubTitle,
  TableWrapper,
  TitleContainer,
  TopSection,
} from "./style";
import withAdminDashboard from "../../../HOC/withAdminDashboard";
import Loader from "../../../../../components/Common/Loader";
import CheckEntryCard from "../../../../../components/CheckEntryCard";
import Tag from "../../../../../components/Tag";
import FileProgress from "../../../../../components/FileProgress/FileProgress";
import { ReactComponent as SentIcon } from "../../../../../assets/sent-checkmark.svg";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import ControlledDatePicker from "../../../../../components/ControlledDatePicker";
import { useMutation, useQuery } from "react-query";
import awardOfContractApi from "../../../../../redux/api/awardOfContractApi";
import { formatCurrency } from "../../../../../util/formatCurrency";
import certificateOfNoObjectionApi from "../../../../../redux/api/certificateOfNoObjectionApi";
import { openFileUrlInNewWindow } from "../../../../Common/util";
import ToastContext from "../../../../../util/toastContext";
import LoadingButton from "../../../../../components/LoadingButton/LoadingButton";
import { Roles } from "../../../../../components/AccessControl/UserRoles";
import { useRoles } from "../../../../../hooks/useUserDetails";
import { SaveButton } from "../../../../../components/Forms/Common/style";
import ContractSigningDeadline from "./ContractSigningDeadline";

const baseVendor = {
  userId: null,
  companyName: "",
  website: "",
  quotation: 0,
  taxClearance: null,
  dsirCard: null,
  companyMemo: null,
  companyProfile: null,
  pastJobs: null,
  completionNumber: 0,
  remark: "",
  documentCount: 0,
  type: 1,
  completionPeriod: 1,
  createAt: "2020-09-24T15:51:06.968Z",
  updatedAt: "2020-09-24T15:51:06.968Z",
};

const AwardOfContract = () => {
  const { role } = useRoles();
  const isDisabled = role === Roles.PROCUREMENT_OFFICER ? false : true;
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [deadline, setDeadline] = useState(null);

  const { procurementId } = useParams();
  const location = useLocation();
  const { reviewMethod } = location.state;
  const hasCNO = reviewMethod === "Prior Review";

  const recommendedVendor = useQuery({
    queryKey: [
      "recommendedVendor",
      {
        procurementId,
      },
    ],
    queryFn: awardOfContractApi.getRecommendedVendor,
  });

  const certificate = useQuery({
    queryFn: certificateOfNoObjectionApi.getCertificate,
    queryKey: [
      "certificateOfObjection",
      {
        procurementId,
      },
    ],
  });

  const { data: certificateData } = certificate;

  const [uploadLetter, uploadLetterQuery] = useMutation(awardOfContractApi.uploadLetterOfIntent, {
    throwOnError: true,
  });

  const { showAlert } = useContext(ToastContext);

  const history = useHistory();

  if (recommendedVendor.isLoading || certificate.isLoading) {
    return (
      <ContentWrapper>
        <Loader />
      </ContentWrapper>
    );
  }

  const handleCheckBoxChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleSubmit = async () => {
    const newDate = new Date(deadline);
    newDate.setHours(23, 59, 0, 0);
    setDeadline(newDate);
    try {
      await uploadLetter({
        procurementId,
        deadline,
        file: documents[0],
      });

      setTimeout(() => {
        history.push(`/admin/procurement/plan-details/${procurementId}`);
      }, 3000);
    } catch (e) {
      showAlert({
        severity: "error",
        message: e.message,
      });
    }
  };

  const file = certificateData?.[0];

  const renderDeadline = () => {
    return <ControlledDatePicker label="Deadline" value={deadline} onChange={setDeadline} margin={0} disabled={isDisabled} />;
  };

  const renderPageBeforeSubmission = () => {
    return (
      <div style={{ width: "75%" }}>
        <CardWrapper gray>
          <TableWrapper>
            <LetterOfIntentUpload documents={documents} setDocuments={setDocuments} renderDeadline={renderDeadline} />
            {hasCNO && (
              <BorderedBox>
                <TitleContainer>Send Certificate of No Objection</TitleContainer>
                <SubTitle> Send Certificate of No Objection To Ministry of Justice </SubTitle>
                <Grid container wrap="nowrap">
                  <Box width="340px" mr={2}>
                    <FileProgress
                      fileName={`CNO of award`}
                      fileSize={file?.file?.bytes}
                      progress={100}
                      hasEyes={true}
                      onClickEyes={() => openFileUrlInNewWindow(file?.file?.url)}
                    />
                  </Box>
                </Grid>
              </BorderedBox>
            )}

            <CheckBoxText>
              {" "}
              <Checkbox
                size="small"
                color="primary"
                className={classes.checkbox}
                checked={checked}
                onChange={handleCheckBoxChange}
              />
              I agree
            </CheckBoxText>
            <LoadingButton
              color="#219653"
              variant="contained"
              disabled={!(checked && documents.length > 0)}
              onClick={handleSubmit}
              loading={uploadLetterQuery.isLoading}
              style={{
                marginLeft: "0",
              }}
            >
              Send
            </LoadingButton>
          </TableWrapper>
        </CardWrapper>
        <Box mt="50px" />
        <CardWrapper>
          <TableWrapper>
            <ContractSigningDeadline />
          </TableWrapper>
        </CardWrapper>
      </div>
    );
  };

  const renderPageAfterSubmission = () => {
    return (
      <SubmissionResponseWrapper gray>
        <ResponseContainer>
          <Box mb={3}>
            <SentIcon />
          </Box>
          <ResponseTitle>Sent Successfully</ResponseTitle>
          <ResponseText>
            Letter of Intent and Cerficate of No Objection has been sent to the vendor and Ministry of Justice respectively.
          </ResponseText>
        </ResponseContainer>
        <Footer>
          Waiting for <span className="bold"> Contract Draft Document </span> from Ministry of Justcice
        </Footer>
      </SubmissionResponseWrapper>
    );
  };

  const vendor = Object.assign(baseVendor, recommendedVendor.data);

  return (
    <ContentWrapper>
      <PageTitle>Award Contract</PageTitle>
      <BreadCrumbWrapper>
        <Breadcrumbs>
          <Link to={"/admin/dashboard/overview"}>Home</Link>
          <Link to={"/admin/procurement/plans"}>Plans</Link>
          <Link to={`/admin/procurement/plan-details/${procurementId}`}>Plan Details</Link>
        </Breadcrumbs>
      </BreadCrumbWrapper>
      <CardWrapper>
        <TopSection>
          <Tag text="Recommended" color="dark_green" size={"large"} />
          <SerialText>{vendor.companyName}</SerialText>
        </TopSection>
        <Divider />
        <CardSection>
          <CardLabel>Budget</CardLabel>
          <BoldCardContent>{formatCurrency(vendor.quotation, true, true)}</BoldCardContent>
        </CardSection>
        <Divider />
        <CardBody>
          <CardSection>
            <CardLabel>Tax Clearance</CardLabel>
            <CardContent>{vendor.taxClearance ? "Yes" : "No"}</CardContent>
          </CardSection>
          <CardSection>
            <CardLabel>DSIR Card</CardLabel>
            <CardContent>{vendor.dsirCard ? "Yes" : "No"}</CardContent>
          </CardSection>
          <CardSection>
            <CardLabel>Company Memorandum</CardLabel>
            <CardContent>{vendor.companyMemo ? "Yes" : "No"}</CardContent>
          </CardSection>
          <CardSection>
            <CardLabel>Company Profile</CardLabel>
            <CardContent>{vendor.companyProfile ? "Yes" : "No"}</CardContent>
          </CardSection>
          <CardSection>
            <CardLabel>Pat Jobs</CardLabel>
            <CardContent>{vendor.pastJobs ? "Yes" : "No"}</CardContent>
          </CardSection>
        </CardBody>
      </CardWrapper>
      <GridContainer>
        {uploadLetterQuery.isSuccess ? renderPageAfterSubmission() : renderPageBeforeSubmission()}
        <CheckEntryCard
          cardTitle="Check Entries"
          textValues={hasCNO ? ["Letter of Intent", "CNO"] : ["Letter of Intent"]}
          completedForm={[documents.length ? 0 : null, checked ? 1 : null]}
        />
      </GridContainer>
    </ContentWrapper>
  );
};

const useStyles = makeStyles({
  checkbox: {
    paddingLeft: "0px !important",
  },
});

export default withAdminDashboard(AwardOfContract);
