import moment from "moment";
import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { CardInfoText, CardNumberText, DescriptionSection } from "../../../../../../components/AdminCard/style";
import TopStatisticsBar from "../../../../../../components/TopStatisticsBar";
import { CardContentWrapper } from "../../../../../../util/theme";
import { CardLabel } from "../../../../PlanDetails/style";
import { useQuery, useMutation } from "react-query";
import receiptOfBiddersResponseApi from "../../../../../../redux/api/receiptOfBiddersResponseApi";
import { Roles } from "../../../../../../components/AccessControl/UserRoles";
import AccessControl from "../../../../../../components/AccessControl";
import { GeneralInputField } from "../../SpecificProcurementNotice/style";
import DatePicker from "../../../../../../components/DatePicker";
import ErrorRenderer from "../../../../../../components/ErrorRenderer/ErrorRenderer";
import { SaveButton } from "../../../../../../components/Forms/Common/style";
import useAlert from "../../../../../../hooks/useAlert";

const UpdateDeadline = ({ expiryDate }) => {
  const { activityId, procurementId } = useParams();
  const { showAlert, Toast } = useAlert();
  const { control, errors, handleSubmit, reset } = useForm({
    defaultValues: {
      date: null,
      hours: "",
      minutes: "",
    }
  });
  const history = useHistory();


  useEffect(() => {
    if (!!expiryDate.data) {
      const expiredDateTime = new Date(expiryDate.data);
      const dateTime = moment(expiredDateTime)
      reset({
        hours: moment(dateTime).hours(),
        minutes: moment(dateTime).minutes(),
        date: moment(dateTime).format()
      })
    };
    // eslint-disable-next-line
  }, [expiryDate])

  const [updateSubmissionDeadline, updateSubmissionDeadlineQueryFeedback] = useMutation(
    receiptOfBiddersResponseApi.updateSubmissionDeadline,
    {
      onError: error => {
        showAlert({
          severity: "error",
          message: error.message,
        });
      },
      onSuccess: data => {
        showAlert({
          severity: "success",
          durationInMs: 3000,
          message: "Successfully updated deadline",
        });
        expiryDate.refetch()
      }
    });


  const manualPaymentStatistics = useQuery({
    queryKey: [
      "paymentStatistics",
      {
        TenderId: procurementId,
      },
    ],
    queryFn: receiptOfBiddersResponseApi.getPaymentStats,
  });


  const navigateToManualPayments = () => {
    history.push(`/admin/procurement/activity/receiptofbiddersresponse/${activityId}/${procurementId}/payments`);
  };

  const handleUpdateDeadline = (values) => {
    const dateTime = new Date(values.date).setHours(values.hours, values.minutes, 0, 0);
    const formatDateTime = moment(dateTime).format();

    updateSubmissionDeadline({
      id: procurementId,
      data: {
        submissionDeadline: formatDateTime
      }
    })
  };


  const renderUpdateDeadline = () => (
    <form onSubmit={handleSubmit(handleUpdateDeadline)}>
      <CardContentWrapper>
        <Grid container alignItems="center">
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Controller
              name="date"
              control={control}
              render={({ onChange, value }) => (
                <DatePicker
                  value={value}
                  label="Deadline"
                  handleChange={onChange}
                  style={{ width: "100%", margin: 0 }}
                />
              )}
              rules={{ required: "Date is required" }}
            />
            <ErrorRenderer message={"Please enter a valid submission deadline"} name={"date"} errors={errors} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Controller
              name={"hours"}
              control={control}
              render={({ onChange, value }) => (
                <GeneralInputField
                  label="Hours"
                  type="number"
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                />
              )}
            />
            <ErrorRenderer message={"Please enter hours"} name={"hours"} errors={errors} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Controller
              name={"minutes"}
              control={control}
              render={({ onChange, value }) => (
                <GeneralInputField
                  label="Minutes"
                  type="number"
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                />)}
            />
            <ErrorRenderer message={"Please enter minutes"} name={"minutes"} errors={errors} />
          </Grid>
          <SaveButton
            type="submit"
            loading={updateSubmissionDeadlineQueryFeedback.isLoading}
          >
            {updateSubmissionDeadlineQueryFeedback.isLoading ? "Updating" : "Update Deadline"}
          </SaveButton>
        </Grid>
      </CardContentWrapper>
    </form>
  )

  return (
    <>
      <Toast float />
      <TopStatisticsBar
        leftContent={
          <AccessControl allowedRoles={[Roles.PROCUREMENT_OFFICER]}>
            <DescriptionSection>
              <CardLabel>Payment Verification</CardLabel>
              <CardNumberText>{manualPaymentStatistics.data?.totalVendors}</CardNumberText>
              <CardInfoText onClick={() => navigateToManualPayments()} style={{ cursor: "pointer" }}>
                View all
              </CardInfoText>
            </DescriptionSection>
          </AccessControl>
        }
        centerContent={renderUpdateDeadline()}
        hasApproveStatus={false}
      />
    </>
  );
};

export default UpdateDeadline;
