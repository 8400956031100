import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { InputWrapper } from "./style";

const InputField = ({
  type,
  label,
  defaultValue,
  name,
  style,
  inputRef,
  value,
  setValue,
  inputProps = {},
  dateProps,
  ...restProps
}) => {
  const [showPassword, setPasswordVisiblity] = useState(false);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <InputWrapper style={style}>
      <TextField
        label={label}
        id={name}
        type={showPassword ? "text" : type}
        defaultValue={defaultValue}
        name={name}
        value={value}
        onChange={({ target: { value } }) => setValue?.(value)}
        InputProps={{
          endAdornment: type === "password" && (
            <InputAdornment position="end">
              <span
                aria-label="toggle password visibility"
                onClick={() => setPasswordVisiblity(!showPassword)}
                onMouseDown={handleMouseDownPassword}
              >
                {!showPassword ? "Show" : "Hide"}
              </span>
            </InputAdornment>
          ),
          ...inputProps,
          inputRef,
        }}
        inputProps={{ ...dateProps }}
        {...restProps}
        variant="filled"
      />
    </InputWrapper>
  );
};

export default InputField;
