import React, { useState } from "react";
import { Box, Button } from "@material-ui/core";
import { useMutation } from "react-query";
import SuccessModal from "./components/successModal";
import {
  CustomDialog,
  CustomDialogTitle,
  CustomDialogTitleWrapper,
  PrevBtn,
} from "../../../components/Forms/ConfirmVerificationModal/style";
import { CustomInputField, TextareaAutosize } from "../../../components/AddNewStaff/style";
import { Dropdown } from "../../../components";
import { Controller, useForm } from "react-hook-form";
import contractsAPI from "../../../redux/api/contractAPI";
import { useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
  companyName: yup.string().required("Company name is a required field"),
  evaluationStatus: yup.string().required("Evaluation status is a required field"),
});

const AddBidderModal = ({ open, toggleModal, refetch }) => {
  const [isOpen, setModal] = useState(false);
  const param = useParams();

  const [createBidder, { isLoading }] = useMutation(contractsAPI.createBidder, {
    onSuccess: () => {
      refetch();
      toggleModal(false);
      setModal(true);
    },
  });

  const { handleSubmit, register, errors, control } = useForm({
    defaultValues: {
      ocidNumber: "",
      companyName: "",
      evaluationStatus: "",
      evaluationComment: "",
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (payload) => {
    createBidder({ payload, id: param.id });
  };

  return (
    <Box display="flex" justifyContent="center" flexDirection="column" py={2} minWidth="550px">
      <SuccessModal open={isOpen} setModal={setModal} />
      <CustomDialog scroll="body" onClose={toggleModal} open={open}>
        <CustomDialogTitleWrapper>
          <CustomDialogTitle disableTypography>Add Bidder</CustomDialogTitle>
        </CustomDialogTitleWrapper>
        <div style={{ marginBottom: "2rem" }}>
          <Controller
            name="companyName"
            control={control}
            render={(props) => (
              <CustomInputField
                style={{ width: "35rem" }}
                label="company Name"
                onChange={() => {}}
                onBlur={() => {}}
                name="companyName"
                {...props}
              />
            )}
          />
          {errors?.companyName && <span style={{ color: "red", fontSize: "12px" }}>{errors?.companyName?.message}</span>}
        </div>
        <div style={{ width: "35rem", marginBottom: "2rem" }}>
          <Dropdown
            name="evaluationStatus"
            label="Evaluation Status"
            values={[
              { value: "Recommended", label: "Recommended" },
              { value: "Not Recommended", label: "Not Recommended" },
            ]}
            style={{ width: "34rem" }}
            inputRef={register({
              required: "Please evaluation status",
              validate: (value) => value.length > 0 || "Please select country",
            })}
          />
          {errors?.evaluationStatus && (
            <span style={{ color: "red", fontSize: "12px" }}>{errors?.evaluationStatus?.message}</span>
          )}
        </div>

        <Controller
          control={control}
          name="evaluationComment"
          render={(props) => (
            <TextareaAutosize
              placeholder="Evaluation Comment"
              aria-label="minimum height"
              style={{ width: "34rem", marginBottom: "2rem", padding: "1rem" }}
              rowsMin={7}
              name="evaluationComment"
              {...props}
            />
          )}
        />

        <Box>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={handleSubmit(onSubmit)}
            style={{ marginRight: 16, height: "41px", paddingLeft: "20px", paddingRight: "20px" }}
            disabled={isLoading}
          >
            {isLoading ? "Adding..." : "Add"}
          </Button>
          <PrevBtn
            onClick={() => {
              toggleModal();
            }}
          >
            Cancel
          </PrevBtn>
        </Box>
      </CustomDialog>{" "}
    </Box>
  );
};

export default AddBidderModal;
