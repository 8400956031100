import React, { useContext, useState } from "react";
// import withAdminDashboard from "../HOC/withAdminDashboard";
import { Box, SvgIcon, TablePagination } from "@material-ui/core";
import { useQuery } from "react-query";
import { PDFDownloadLink } from "@react-pdf/renderer";

import { ContentWrapper, TitleContainer } from "../Administration/Staff/style";
import Breadcrumb from "../../Common/PageElements/Breadcrumb";
import { PageTitle } from "../../Common/style";
import { ActionButton, ClearFilter } from "./style";
import { ReactComponent as DownloadIcon } from "../../../assets/code.svg";
import { ReactComponent as PdfIcon } from "../../../assets/file_pdf.svg";
// import { ReactComponent as PrintIcon } from "../../../assets/print.svg";
import AdminSearchFilter from "../../../components/AdminSearchFilter";
import { Dropdown, InputField } from "../../../components";
import DefaultDateRangePicker from "../../../components/DateRangePIcker";
import contractsAPI from "../../../redux/api/contractAPI";
import ToastContext from "../../../util/toastContext";
import UploadModal from "./UploadModal";
import SearchTable from "./SearchTable";
import ContractTable from "./ContractTable";
import { PaginationWrapper } from "../../Homepage/TenderList/style";
import { SubmitButton } from "../../../components/Forms/ConfirmVerificationModal/style";
import EditDrawer from "./EditDrawer";
import { CancelButton } from "../Administration/Settings/style";
import adminAPI from "../../../redux/api/adminAPI";
import { downloadJSONData } from "../../Common/util";
import AccessControl from "../../../components/AccessControl";
import { Roles } from "../../../components/AccessControl/UserRoles";
import { useRoles } from "../../../hooks/useUserDetails";
import ContractsPDF from "./ContractsPDF";
import { useHistory } from "react-router-dom";
import useDebounce from "../../../hooks/useDebounce";

const iconStyle = { color: "#fff", height: 30, width: 30 };

const OCDS = ({ isPublic = false }) => {
  const { showAlert } = useContext(ToastContext);
  const [openDrawer, setOpenDrawer] = useState({ id: null, isOpen: false, data: {} });
  const [openModal, setOpenModal] = useState(false);
  const [ministries, setMinistries] = useState([]);
  const [downloadData, setDownloadData] = useState({ type: "", start: true });
  const toggleModal = () => setOpenModal(!openModal);
  const toggleDrawer = () => setOpenDrawer(!openDrawer.isOpen);
  const { role } = useRoles();
  const isAdmin = role === Roles.DSPPC;
  const history = useHistory();
  const OCDSLinks = [{ url: "/admin/dashboard/overview", title: "Overview" }];
  const [tableParams, setTableParams] = useState({
    pageNumber: 1,
    pageSize: 10,
    startDate: null,
    endDate: null,
    filter: "",
    category: "",
    search: "",
    totalPages: null,
    totalEntries: null,
  });
  const debounceValue = useDebounce(tableParams.search, 600);
  const params = {
    startDate: tableParams.startDate,
    endDate: tableParams.endDate,
    // filter: tableParams.filter,
    category: tableParams.category,
    search: debounceValue,
    pageNumber: tableParams.pageNumber,
    pageSize: tableParams.pageSize,
  };

  // const [searchParams, setSearchParams] = useState({
  //   startDate: null,
  //   endDate: null,
  //   filter: "",
  //   category: "",
  //   search: "",
  // });

  useQuery(
    [
      "ministries",
      {
        pageSize: 0,
        pageNumber: null,
      },
    ],
    adminAPI.getMinistriesAxios,

    {
      onSuccess: ({ data }) => {
        const options = [
          {
            label: "All",
            value: "",
          },
        ];
        const ministryList = data.map(({ name }) => ({
          label: name,
          value: name,
        }));
        setMinistries(options.concat(ministryList));
      },
    }
  );

  const { data: archive, isLoading } = useQuery(
    [
      "archived-contracts",
      {
        // pageNumber: tableParams.pageNumber,
        // pageSize: tableParams.pageSize,
        // startDate: searchParams.startDate,
        // endDate: searchParams.endDate,
        // filter: searchParams.filter,
        // category: searchParams.category,
        // search: searchParams.search,
        ...params,
      },
    ],
    contractsAPI.getArchivedContracts,
    {
      onSuccess: (data) => {
        const {
          pagination: { totalEntries, totalPages },
        } = data.meta;
        setTableParams((prev) => ({
          ...prev,
          totalPages,
          totalEntries,
        }));
      },
      onError: (error) => {
        showAlert({
          message: error.message,
          severity: "error",
        });
      },
    }
  );

  const updateFilters = (key) => (value) => {
    setTableParams((params) => ({
      ...params,
      [key]: value,
    }));
  };

  const clearFilter = () => {
    setTableParams((prev) => ({
      ...prev,
      search: "",
      endDate: null,
      startDate: null,
    }));
    updateFilters("startDate")(null);
    updateFilters("endDate")(null);
  };

  const handleChangePage = (event, pageNumber) => {
    setTableParams({ ...tableParams, pageNumber: pageNumber + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    setTableParams({ ...tableParams, pageNumber: 1, pageSize: parseInt(event.target.value, 10) });
  };

  // const handleSearch = () => {
  //   const { pageNumber, pageSize, ...rest } = tableParams;
  //   setSearchParams({
  //     ...rest,
  //   });
  // };

  const dataDownload = useQuery(
    [
      "download-archived-contracts",
      {
        pageSize: tableParams.totalEntries,
      },
    ],
    contractsAPI.getArchivedContracts,
    {
      enabled: downloadData.start,
      onSuccess: ({ data }) => {
        downloadData.type === "JSON" && downloadJSONData(data);
      },
      onError: (error) => {
        showAlert({
          message: error.message,
          severity: "error",
        });
      },
      onSettled: () => {},
    }
  );

  const handleRowClick = (link) => (e) => {
    e.stopPropagation();
    history.push(link);
  };

  return (
    <ContentWrapper>
      <TitleContainer>
        <div>
          <PageTitle>Archived Contracts</PageTitle>
          <Breadcrumb values={OCDSLinks} />
        </div>
        <AccessControl allowedRoles={[Roles.DSPPC]}>
          <SubmitButton onClick={toggleModal}>Upload New</SubmitButton>
        </AccessControl>
      </TitleContainer>
      <Box mb={2} display="flex">
        <PDFDownloadLink
          document={<ContractsPDF contracts={dataDownload?.data?.data} />}
          fileName={`Archived-contracts-${Date.now()}`}
          style={{ textDecoration: "none" }}
        >
          {({ loading }) => (
            <ActionButton disabled={dataDownload.isLoading} startIcon={<SvgIcon component={PdfIcon} style={iconStyle} />}>
              {loading ? "Downloading" : "Download PDF"}
            </ActionButton>
          )}
        </PDFDownloadLink>
        <ActionButton
          disabled={dataDownload.isLoading}
          onClick={() => setDownloadData({ type: "JSON", start: true })}
          startIcon={<SvgIcon component={DownloadIcon} style={iconStyle} />}
        >
          Download JSON
        </ActionButton>
        {/* <ActionButton onClick={() => window.print()} startIcon={<SvgIcon component={PrintIcon} style={iconStyle} />}>
          Print
        </ActionButton> */}
      </Box>
      <AdminSearchFilter normalSearch>
        <div style={{ height: "100%", width: "100%" }}>
          <InputField
            style={{ width: "100%" }}
            type="text"
            label="Search ID, Title"
            setValue={updateFilters("search")}
            value={tableParams.search}
            required
          />
        </div>
        {false && (
          <div style={{ width: "95%" }}>
            <Dropdown
              defaultValue={ministries[0]?.value}
              name="ministry"
              style={{ width: "inherit" }}
              label="Ministry"
              values={ministries}
              onChange={(value) => setTableParams({ ...tableParams, filter: value })}
            />
          </div>
        )}
        <div style={{ width: "100%" }}>
          <DefaultDateRangePicker
            handleStartChange={updateFilters("startDate")}
            handleEndChange={updateFilters("endDate")}
            leftStyle={{ marginRight: "16px" }}
            label={["Awarded Start Date", "Awarded End Date"]}
          />
        </div>
        <div style={{ marginTop: "10px", marginLeft: "10px" }}>
          <CancelButton onClick={() => {}} style={{ width: 150, height: 55, fontSize: "16px", fontWeight: 700 }}>
            Search
          </CancelButton>{" "}
          <ClearFilter onClick={clearFilter}>Clear filter</ClearFilter>
        </div>
      </AdminSearchFilter>
      <Box mt={2}>
        <SearchTable
          rows={archive?.data?.length}
          showPadding={false}
          loading={isLoading}
          columns={[
            "Project Title",
            "Budget Description",
            "Date Published",
            "MDA",
            "Category",
            "Contractor Name",
            "Start Date",
            "End Date",
            !isPublic && "Last Modified",
            "Contract Amount",
            isAdmin && !isPublic && "Action",
          ]}
        >
          <ContractTable
            handleRowClick={handleRowClick}
            isPublic={isPublic}
            contracts={archive?.data}
            setOpenDrawer={!isPublic ? setOpenDrawer : () => {}}
          />
        </SearchTable>
        {tableParams.totalEntries > 0 && (
          <PaginationWrapper>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              component="div"
              count={tableParams.totalEntries}
              rowsPerPage={tableParams.pageSize}
              page={tableParams.pageNumber - 1}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </PaginationWrapper>
        )}
      </Box>
      <UploadModal open={openModal} toggleModal={toggleModal} />
      <EditDrawer open={openDrawer.isOpen} toggleDrawer={toggleDrawer} id={openDrawer.id} />
    </ContentWrapper>
  );
};

export default OCDS;
