import React from "react";
import jwt from "jwt-decode";
import { Redirect } from "react-router-dom";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";


export const RedirectHelper = (loginRoute, directedRoute, history) => {
  const token = localStorage.getItem("token");
  if (token) {
    if (jwt(token).exp < Date.now() / 1000) {
      localStorage.removeItem("token");
      return <Redirect to={loginRoute} />;
    }
    history.push(directedRoute);
  }
};

export const convertDocumentsToObject = (documents = []) => {
  return documents.reduce(
    (currDocuments, file) => {
      const moddedFile = {
        inDb: true,
        id: file.id,
        name: file.file.original_filename,
        size: file.file.bytes,
        downloadUrl: file.file.url,
      };

      if (file.status === "MANDATORY") {
        return {
          ...currDocuments,
          mandatory: currDocuments.mandatory.concat(moddedFile),
          removed: [],
        };
      }
      return {
        ...currDocuments,
        supporting: currDocuments.supporting.concat(moddedFile),
        removed: [],
      };
    },
    {
      mandatory: [],
      supporting: [],
    }
  );
}

export const openFileUrlInNewWindow = (url) => {
  window.open(url, 'blank')
}


export const downloadJSONData = (data) => {
  const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
    JSON.stringify(data)
  )}`;
  const link = document.createElement("a");
  link.href = jsonString;
  link.download = "Archived-Contracts.json";

  link.click();
};

export const downloadPDFData = async (ref) => {
  const element = ref.current;
  const canvas = await html2canvas(element);
  const data = canvas.toDataURL("image/png");

  const pdf = new jsPDF();
  const imgProperties = pdf.getImageProperties(data);
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

  pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
  pdf.save("Archive-Contracts.pdf");

};
