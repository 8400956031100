import React from "react";
import PropTypes from "prop-types";
import { capitalize } from "lodash";
import { HoverTableStyle } from "../../../../util/theme";
import EmptyTableBody from "../../../../components/EmptyTableBody";
import { ReactComponent as PolarisIcon } from "../../../../assets/polaris_icon.svg";
import { EmptyTableSubTitle } from "../../../../components/EmptyTableBody/style";
import { ReusableButton, TableChip } from "../../../../shared";
import { StyledTableCell } from "../SupplementaryTable";
import { StyledFirstCell } from "./style";
import { supplementChipMapping } from "../../../../util/constants";
import { formatCurrency } from "../../../../util/formatCurrency";

const TableBody = ({ dataSource }) => {
  return Array.isArray(dataSource) && dataSource?.length > 0 ? (
    dataSource?.map((row, i) => (
      <HoverTableStyle key={`${row?.id}_${i}`}>
        <StyledTableCell>
          <StyledFirstCell>
            <div className="top-cell">{row?.packageNumber || ""}</div>
            <span className="lower-cell">{row?.name || ""}</span>
          </StyledFirstCell>
        </StyledTableCell>
        <StyledTableCell>{row?.category?.name || ""}</StyledTableCell>
        <StyledTableCell>{row?.method?.code}</StyledTableCell>
        <StyledTableCell>
          <TableChip text={capitalize(row.status || "")} {...supplementChipMapping[row.status.toLowerCase() || "pending"]} />
        </StyledTableCell>
        <StyledTableCell>{formatCurrency(row?.budget || 0, true)}</StyledTableCell>
        {/* <StyledTableCell>''</StyledTableCell> */}
        <StyledTableCell>
          <PolarisIcon />
        </StyledTableCell>
      </HoverTableStyle>
    ))
  ) : (
    <EmptyTableBody>
      <EmptyTableSubTitle>No Supplementary detail available</EmptyTableSubTitle>
      <span style={{ width: "60%", marginBottom: "12px", fontSize: "17px" }}>
        You currently have no budget on the supplementary list. Click the button below to start.
      </span>
      <ReusableButton label="Request Upward Review" />
    </EmptyTableBody>
  );
};

TableBody.propTypes = {
  dataSource: PropTypes.array.isRequired,
};

export default TableBody;
