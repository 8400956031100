import moment from "moment";

export const dateFormat = (values) => {
  if (values) {
    return moment(values).format("DD-MMM-YYYY");
  }
  return "-";
};

export const dateTimeFormat = (values) => {
  if (values) {
    return moment(values).format("DD-MMM-YYYY - hh:mm:A");
  }
  return "-";
};
