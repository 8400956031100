import React from "react";
import HomepageNavBar from "../../../components/HomepageHeader";
import HomepageFooter from "../../../components/HomepageFooter";
import ToastContext from "../../../util/toastContext";
import useAlert from "../../../hooks/useAlert";

const withHeaderFooterHOC = (WrapComponent) => () => {
  const { showAlert, Toast, hideAlert } = useAlert();
  return (
    <div>
      <ToastContext.Provider value={{ showAlert, Toast, hideAlert }}>
        <HomepageNavBar />
        <WrapComponent />
        <HomepageFooter />
      </ToastContext.Provider>
    </div>
  );
};

export default withHeaderFooterHOC;
