import React from "react";
import { Controller, useForm } from "react-hook-form";
import { InputField } from "../../../../../shared";
import { CancelButton, UpdateButton } from "../../../Administration/Settings/style";
import { ErrorContainer } from "../../../../../util/theme";
import { Dropdown } from "../../../../../components";
import { useEffect } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { DateRangeWrapper } from "../../../../../components/DateRangePIcker/style";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useParams } from "react-router-dom";
import { contracContractResolver, contractContractDefaultProps } from "./validator";
import {
  archiveContractDurationOptions,
  archiveContractStatusFunc,
  archiveContractStatusOptions,
  archiveDurationFunc,
} from "../utils/useArchiveHokk";
import { onNumberValidator } from "../../../../../util/numberValidator";

const EditContactBiddersTab = ({ data, mutate, isLoading, handleEdit }) => {
  const { errors, handleSubmit, control, reset } = useForm({
    defaultValues: contractContractDefaultProps,
    resolver: contracContractResolver,
  });
  const params = useParams();
  // const contractStatusOption = ["ACCEPTED", "REJECTED", "ACTIVE", "TERMINATED", "COMPLETED"].map((item) => ({
  //   label: item,
  //   value: item,
  // }));

  useEffect(() => {
    let payload = {};
    if (data) {
      const fields = Object.keys(contractContractDefaultProps);
      fields.forEach((field) => {
        if (field === "awardSigningDate") {
          payload[field] = data?.contractSignedDate ? new Date(data?.contractSignedDate) : new Date();
        } else if (field === "contractStatus") {
          payload[field] = archiveContractStatusFunc(data?.contractStatus || "");
        } else if (field === "contractStartDate") {
          payload[field] = data?.contractStartDate ? new Date(data?.contractStartDate) : new Date();
        } else if (field === "contractEndDate") {
          payload[field] = data?.contractEndDate ? new Date(data?.contractEndDate) : new Date();
        } else if (field === "projectName") {
          payload[field] = data?.title || "";
        } else if (field === "projectDescription") {
          payload[field] = data?.description || data?.title || "";
        } else if (field === "durationType") {
          payload[field] = archiveDurationFunc(data?.durationType || contractContractDefaultProps[field]);
        } else if (field === "duration") {
          payload[field] = data?.contractDuration || contractContractDefaultProps[field];
        } else {
          payload[field] = data[field] || contractContractDefaultProps[field];
        }
      });

      payload = {
        ...payload,
      };

      reset(payload);
    }
    // reset(data);
  }, [reset, data]);

  const onSubmit = (e) => {
    const payload = {};
    const fields = Object.keys(contractContractDefaultProps);

    fields.forEach((field) => {
      if (field === "contractStatus") {
        payload[field] = parseInt(e[field], 10);
      } else if (field === "duration") {
        payload[field] = parseInt(e[field], 10);
      } else if (field === "durationType") {
        payload[field] = parseInt(e?.[field] || 0, 10);
      } else {
        payload[field] = e[field];
      }
    });

    ["projectName", "projectDescription", "contractor"].forEach((v) => {
      delete payload[v];
    });
    mutate({ payload, archiveId: params.id, type: "contract" });
  };
  const onRenderError = (error) => <ErrorContainer>{error && error.message}</ErrorContainer>;
  return (
    <div>
      <div>
        <DateRangeWrapper width={{ root: "97%" }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              control={control}
              name="awardSigningDate"
              render={(props) => (
                <KeyboardDatePicker
                  label="Contract Award Signing Date"
                  disableToolbar
                  openTo="year"
                  views={["year", "month", "date"]}
                  variant="inline"
                  format="dd-MMM-yyyy"
                  placeholder="MM/DD/YYYY"
                  margin="normal"
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...props}
                />
              )}
            />
          </MuiPickersUtilsProvider>
        </DateRangeWrapper>

        {onRenderError(errors.awardedDate)}
      </div>
      <div>
        <Controller
          control={control}
          name="projectName"
          render={({ ref, ...props }) => (
            <InputField
              label="Project Title"
              // inputRef={register({
              //   required: "Project TItle is required",
              // })}
              inputRef={ref}
              style={{ background: "#ffffff" }}
              disabled
              {...props}
            />
          )}
        />{" "}
        {onRenderError(errors.projectTitle)}
      </div>
      <div>
        <Controller
          control={control}
          name="projectDescription"
          render={({ ref, ...props }) => (
            <InputField
              label="Project Desciption"
              multiline
              inputRef={ref}
              style={{ background: "#ffffff" }}
              disabled
              {...props}
              // value={watch("title")}
            />
          )}
        />{" "}
        {onRenderError(errors.projectDescription)}
      </div>
      <div>
        <Controller
          control={control}
          name="contractorAddress"
          render={({ ref, ...props }) => (
            <InputField
              label="contractor Address"
              // inputRef={register({
              //   required: "Contractor address is required",
              // })}
              inputRef={ref}
              style={{ background: "#ffffff" }}
              multiline
              {...props}
            />
          )}
        />{" "}
        {onRenderError(errors.contractorAddress)}
      </div>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px" }}>
        <div>
          <Controller
            control={control}
            name="contractor"
            render={({ ref, ...props }) => (
              <InputField
                label="Contractor"
                // inputRef={register({
                //   required: "Project Contractor is required",
                // })}
                inputRef={ref}
                style={{ background: "#ffffff" }}
                disabled
                {...props}
              />
            )}
          />{" "}
          {onRenderError(errors.contractor)}
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "auto auto" }}>
          <div style={{ background: "#ffffff" }}>
            <Controller
              control={control}
              name="duration"
              render={({ ref, onChange, ...props }) => (
                <InputField
                  label="Contract Duration"
                  // inputRef={register({
                  //   required: "Project Duration is required",
                  // })}
                  // type="number"
                  inputRef={ref}
                  name="duration"
                  style={{ background: "#ffffff" }}
                  onChange={(e) => onNumberValidator(e, onChange)}
                  {...props}
                />
              )}
            />
            {onRenderError(errors.duration)}
          </div>
          <div>
            <Controller
              control={control}
              name="durationType"
              render={({ ref, ...props }) => (
                <Dropdown
                  inputRef={ref}
                  style={{ width: "inherit" }}
                  label="Contract Status"
                  values={archiveContractDurationOptions}
                  {...props}
                />
              )}
            />
            {onRenderError(errors.contractStatus)}
          </div>
        </div>
      </div>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px" }}>
        <div>
          <Controller
            control={control}
            name="contractorPhone"
            render={({ ref, onChange, ...props }) => (
              <InputField
                label="Contrator Phone Number"
                // inputRef={register({
                //   required: "Contrator Phone Number is required",
                // })}
                inputRef={ref}
                style={{ background: "#ffffff" }}
                onChange={(e) => onNumberValidator(e, onChange)}
                {...props}
              />
            )}
          />
          {onRenderError(errors.phoneNumber)}
        </div>
        <div>
          <Controller
            control={control}
            name="contractorEmail"
            render={({ ref, ...props }) => (
              <InputField
                label="Contrator Email"
                // inputRef={register({
                //   required: "Contractor Email is required",
                // })}
                inputRef={ref}
                name="contractorEmail"
                style={{ background: "#ffffff" }}
                {...props}
              />
            )}
          />{" "}
          {onRenderError(errors.contractorEmail)}
        </div>
      </div>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: "20px" }}>
        <div>
          <Controller
            control={control}
            name="contractStatus"
            render={({ ref, ...props }) => (
              <Dropdown
                // defaultValue={watch("tender.reviewMethod")}
                // inputRef={register({
                //   required: "Contract Status is required",
                // })}
                inputRef={ref}
                style={{ width: "inherit" }}
                label="Contract Status"
                values={archiveContractStatusOptions}
                {...props}
              />
            )}
          />
          {onRenderError(errors.contractStatus)}
        </div>
        <div>
          <DateRangeWrapper width={{ root: "97%" }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Controller
                control={control}
                name="contractStartDate"
                render={(props) => (
                  <KeyboardDatePicker
                    label="Contract Start Date"
                    disabled
                    disableToolbar
                    openTo="year"
                    views={["year", "month", "date"]}
                    variant="inline"
                    format="dd-MMM-yyyy"
                    placeholder="MM/DD/YYYY"
                    margin="normal"
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    InputLabelProps={{
                      disableUnderline: true,
                    }}
                    {...props}
                  />
                )}
              />
            </MuiPickersUtilsProvider>
          </DateRangeWrapper>
          {onRenderError(errors.startDate)}
        </div>
        <div>
          <DateRangeWrapper width={{ root: "97%" }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Controller
                control={control}
                name="contractEndDate"
                render={(props) => (
                  <KeyboardDatePicker
                    disabled
                    label="Contract End Date"
                    disableToolbar
                    openTo="year"
                    views={["year", "month", "date"]}
                    variant="inline"
                    format="dd-MMM-yyyy"
                    placeholder="MM/DD/YYYY"
                    margin="normal"
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    InputLabelProps={{
                      disableUnderline: true,
                    }}
                    {...props}
                  />
                )}
              />
            </MuiPickersUtilsProvider>
          </DateRangeWrapper>

          {onRenderError(errors.endDate)}
        </div>
      </div>
      <div style={{ display: "grid", gridTemplateColumns: "150px 1fr", placeItems: "center start", marginTop: "30px" }}>
        <UpdateButton onClick={handleSubmit(onSubmit)}>{isLoading ? "Saving Changes..." : "Save Changes"}</UpdateButton>
        <CancelButton onClick={handleEdit}>Cancel</CancelButton>
      </div>
    </div>
  );
};

EditContactBiddersTab.propTypes = {};

export default EditContactBiddersTab;
