import { makeStyles } from "@material-ui/styles";
import React from "react";
import { Tooltip, Box, Button } from "@material-ui/core";
import { dateFormat, dateTimeFormat } from "../../../util/formatDate";

import EmptyTableBody from "../../../components/EmptyTableBody";
import { StyledTableCell } from "../../../components/SearchTable";
import { StyledTableRow, theme } from "../../../util/theme";
import { EmptyTableSubTitle } from "./style";
import { formatCurrency } from "../../../util/formatCurrency";
import { useRoles } from "../../../hooks/useUserDetails";
import { Roles } from "../../../components/AccessControl/UserRoles";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles({
  contractNo: {
    fontSize: 14,
  },
  ocid: {
    fontSize: 14,
    color: `${theme.color.primary}`,
    fontWeight: 600,
    textTransform: "uppercase",
  },
});

const textOverFlowStyle = { width: 180, textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" };

const ContractTable = ({ contracts = [], setOpenDrawer, isPublic, handleRowClick = () => {} }) => {
  const classes = useStyles();
  const { role } = useRoles();
  const { pathname } = useLocation();
  const isAdmin = role === Roles.DSPPC;

  return contracts.length ? (
    contracts.map((eachContract) => {
      const link = `${pathname}/${eachContract.id}`;

      return (
        <StyledTableRow key={eachContract.id} onClick={handleRowClick(link)} style={{ backgroundColor: "#fff" }}>
          <StyledTableCell
            style={{
              position: "sticky",
              left: 0,
              bottom: 0,
              right: 0,
              top: 0,
              width: 200,
              backgroundColor: "#fff",
              paddingRight: 0,
            }}
          >
            <Box display="flex" alignItems="center" style={{ width: 200, height: "100%", borderRight: "2px solid #E4E9EC" }}>
              <p className={classes.ocid}>{eachContract?.ocidNumber}</p>
            </Box>
          </StyledTableCell>
          <StyledTableCell>
            <Tooltip title={eachContract.title} aria-label="add">
              <p className={classes.contractNo} style={textOverFlowStyle}>
                {eachContract.title || "--"}
              </p>
            </Tooltip>
          </StyledTableCell>
          <StyledTableCell>
            <Tooltip title={eachContract.budgetDescription} aria-label="add">
              <p style={{ width: 140 }}>{eachContract?.budgetDescription || "--"}</p>
            </Tooltip>
          </StyledTableCell>
          <StyledTableCell>
            <Tooltip title={eachContract.datePublished} aria-label="add">
              <p style={{ width: 140 }}>{dateFormat(eachContract.datePublished)}</p>
            </Tooltip>
          </StyledTableCell>
          <StyledTableCell>
            <Tooltip title={eachContract.procuringEntity || ""} aria-label="add">
              <p style={textOverFlowStyle}> {eachContract?.procuringEntity}</p>
            </Tooltip>
          </StyledTableCell>
          <StyledTableCell>
            <Tooltip title={eachContract?.category || "category"} aria-label="add">
              <p style={{ width: 140 }}>{eachContract?.category || "--"}</p>
            </Tooltip>
          </StyledTableCell>
          <StyledTableCell>
            <p style={{ width: 180 }}>{eachContract.contractor}</p>
          </StyledTableCell>
          <StyledTableCell>
            <p style={{ width: 140 }}>{dateFormat(eachContract.contractStartDate)}</p>
          </StyledTableCell>
          <StyledTableCell>
            <p style={{ width: 180 }}> {dateFormat(eachContract.contractEndDate)}</p>
          </StyledTableCell>
          {!isPublic && (
            <StyledTableCell>
              <p style={{ width: 140 }}>{dateTimeFormat(eachContract.updatedAt)}</p>
            </StyledTableCell>
          )}
          <StyledTableCell>
            <p style={{ width: 180 }}> {formatCurrency(eachContract.contractSum, true)}</p>
          </StyledTableCell>
          {isAdmin && !isPublic && (
            <StyledTableCell>
              <Button
                style={{ color: `${theme.color.primary}`, fontWeight: 700 }}
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenDrawer({ id: eachContract.id, isOpen: true, data: eachContract });
                }}
                variant="text"
              >
                Edit
              </Button>
            </StyledTableCell>
          )}
        </StyledTableRow>
      );
    })
  ) : (
    <EmptyTableBody>
      <EmptyTableSubTitle>There are currently no contracts</EmptyTableSubTitle>
    </EmptyTableBody>
  );
};

export default ContractTable;
