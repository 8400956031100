import React, { useState, useContext } from "react";
import { Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { FormContent, FormWrapper, MinistryAddNew, MinistryDelete, ButtonContainer, StaffCancelButton } from "./style";
import { SaveButton } from "../../Forms/Common/style";
import ToastContext from "../../../util/toastContext";

export const FORM_TYPES = {
  staff: "staff",
  ministry: "ministry",
};

const ROLE_IDS = {
  PROCUREMENT_OFFICER: 1,
  PERMANENT_SECRETARY: 2,
  COMMISSIONER: 3,
  GOVERNOR: 4,
  DSPPC: 5,
  MODERATOR: 6,
  EXECUTIVE: 7,
  SSG: 8,
  MINISTRY_OF_JUSTICE: 9,
  ADMIN: 10,
  TENDER_BOARD: 11,
  CONTRACTORS_REG_BOARD: 12,
};

const SingleTabForm = ({ handleCancel, children, defaultValues, multipleInviteAPI, formType, submitKey,  }) => {
  const [value, setValue] = useState(1);
  const [creatingStaff, setCreatingStaff] = useState(false);
  const [formValues, setFormValues] = useState([{}]);
  const [errors, setErrors] = useState([{}]);
  const [generalError, setGeneralError] = useState({});
  const dispatch = useDispatch();
  const { showAlert } = useContext(ToastContext);
  const [role, setRole] = useState(false)
  
  


  const handleChange = (e, i) => {
    const arr = formValues;
    arr[i][e.target.name] = e.target.value;
    setFormValues(arr);
    delete generalError[i];
    setGeneralError(generalError);
    setRole(disableMinistry(formValues[i]))
  //  console.log(role, "role")
  };

  const validateInput = (i, name, cb) => {
    const handleError = (message) => {
      const errorArray = [...errors];
      if (message) {
        !!errorArray[i] !== true && errorArray.push({});
        errorArray[i][name] = message;
        setErrors(errorArray);
      } else if (errorArray[i] && errorArray[i][name]) {
        delete errorArray[i][name];
        setErrors(errorArray);
      }
    };

    cb(formValues[i][name], handleError);
  };

  const isMinistryRequired = (values) => {
    const { roleId, ministryId } = values;
    const rolesWithMinistries = [
      ROLE_IDS.PROCUREMENT_OFFICER,
      ROLE_IDS.PERMANENT_SECRETARY,
      ROLE_IDS.COMMISSIONER,
      ROLE_IDS.MODERATOR,
      ROLE_IDS.TENDER_BOARD,
    ];

    if (rolesWithMinistries.includes(parseInt(roleId))) {
      if (!!ministryId === true) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };
  const disableMinistry = (values) => {
    const { roleId, ministryId } = values;
    const rolesWithMinistries = [
      ROLE_IDS.PROCUREMENT_OFFICER,
      ROLE_IDS.PERMANENT_SECRETARY,
      ROLE_IDS.COMMISSIONER,
      ROLE_IDS.MODERATOR,
      ROLE_IDS.TENDER_BOARD,
    ];

    if (rolesWithMinistries.includes(parseInt(roleId))) {
      if (ministryId === true) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

 
  
  const areFormFieldsValid = () => {
    let isError = false;
    const currentArr = Object.values(formValues[value - 1]);

    switch (formType) {
      case FORM_TYPES.staff:
        for (let i = 0; i < formValues.length; i++) {
          if (!!formValues[i].roleId !== true || !!formValues[i].email !== true || !isMinistryRequired(formValues[i])) {
            isError = true;
            return setGeneralError({ [i]: "Please fill required fields" });
          }
        }
        break;
      default:
        if (
          (errors.length && Object.values(errors[errors.length - 1]).length) ||
          currentArr.filter(String).length !== Object.keys(defaultValues).length
        ) {
          isError = true;
          return setGeneralError({ [value - 1]: "All fields are required" });
        }
    }
    return !isError;
  };

  const renderError = ({ message, errors }) => {
    return (
      <div>
        <div style={{ fontWeight: "bold" }}>{message}</div>
        <div>
          {Object.keys(errors).map((errorPath) => (
            <div style={{ marginTop: 10, fontSize: "0.8em" }} key={errorPath}>
              <span style={{ textTransform: "capitalize" }}>{errorPath}</span> - {errors[errorPath]}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const api = multipleInviteAPI(submitKey === "userInvite" ? formValues : { [submitKey]: formValues });
    if (areFormFieldsValid()) {
      setCreatingStaff(true);
      dispatch(api).then((res) => {
        setCreatingStaff(false);
        if (res.error) {
          const errorPayload = JSON.parse(res.payload);
          showAlert({
            severity: "error",
            message: renderError({
              message: errorPayload.message,
              errors: errorPayload.errors,
            }),
          });
          return;
        }
        showAlert({ severity: "success", message: res.payload.message });
        handleCancel();
      });
    }
  };

  const childrenWithProps = (i) =>
    React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          handleChange,
          formIndex: i,
          validateInput,
          errors,
          generalError,
          role,
          formValues,
          disableMinistry
        });
      }

      return child;
    });

  const updateFormArray = (val, action) => {
    const currentArr = Object.entries(formValues[val - 1]);
    const validationStatement =
      formType === FORM_TYPES.staff
        ? !!formValues[val - 1].roleId === true &&
          !!formValues[val - 1].email === true &&
          isMinistryRequired(formValues[val - 1]) === true
        : currentArr.filter(String).length === Object.keys(defaultValues).length;

    if (action === "inc") {
      if (validationStatement) {
        setValue(val + 1);
        const arr = formValues;
        arr[val] = { ...defaultValues };
        setFormValues(arr);
      } else {
        setGeneralError({ [val - 1]: formType === FORM_TYPES.staff ? "Please fill required fields" : "All fields are required" });
      }
    } else {
      setValue(val - 1);
      formValues.pop();
      setFormValues(formValues);
    }
  };

  return (
    <form>
      <FormWrapper>
        <FormContent>
          <Grid container item>
            {[...Array(value)].map((val, i) => childrenWithProps(i))}
          </Grid>
          <Grid item>
            <MinistryDelete onClick={() => value - 1 && updateFormArray(value, "dec")}>Delete</MinistryDelete>
          </Grid>
        </FormContent>
        <Grid>
          <MinistryAddNew onClick={() => updateFormArray(value, "inc")}>+ Add New</MinistryAddNew>
        </Grid>
      </FormWrapper>
      <ButtonContainer>
        <StaffCancelButton onClick={handleCancel}>Cancel</StaffCancelButton>
        <SaveButton disabled={creatingStaff} onClick={(e) => onSubmit(e)}>
          {creatingStaff ? "Please wait..." : "Create"}
        </SaveButton>
      </ButtonContainer>
      ;
    </form>
  );
};

export default SingleTabForm;
