import { useContext } from "react";
import React, { useState } from "react";
import withAdminDashboard from "../../HOC/withAdminDashboard";
import { TitleContainer, PageTitle, AddButton, CirclePlusIconWrapper } from "../../../Common/style";
import { ContentWrapper } from "../../Procurement/style";
import Breadcrumb from "../../../Common/PageElements/Breadcrumb";
import AdminSearchFilter from "../../../../components/AdminSearchFilter";
import { Box, capitalize } from "@material-ui/core";
import AddPermissionDrawer from "./AddPermissionDrawer";
import { useQuery } from "react-query";
import adminAPI from "../../../../redux/api/adminAPI";
import Loader from "../../../../components/Common/Loader";
import { Roles } from "../../../../components/AccessControl/UserRoles";
import AccessControl from "../../../../components/AccessControl";
import { Dropdown } from "../../../../components";
import SearchTable, { StyledTableCell, StyledTableRow } from "../../../../components/SearchTable";
import { formatCurrency } from "../../../../util/formatCurrency";
import moment from "moment";
import ToastContext from "../../../../util/toastContext";
import { theme } from "../../../../util/theme";
import { StyledStatus } from "./style";

function Permission() {
  const { showAlert } = useContext(ToastContext);
  const [showDrawer, setShowDrawer] = useState(false);
  const [role, setRole] = useState("");
  const [type, setType] = useState("");
  const [status, setStatus] = React.useState("");
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
    total: null,
    pageCount: null,
  });

  const { data: roles = [], isLoading } = useQuery(["permissions", { Search: "" }], adminAPI.getPermissions, {
    onError: () => {
      showAlert({
        severity: "error",
        message: "Failed to get permissions",
        durationInMs: 3000,
      });
    },
  });

  const { data: thresholds, isLoading: fetchingThresholds } = useQuery(
    [
      "thresholds-list",
      {
        PageSize: pagination.pageSize,
        PageNumber: pagination.pageNumber,
        role,
        type,
        status,
      },
    ],
    adminAPI.getThresholds,
    {
      onSuccess: (data) => {
        const { totalEntries, totalPages, perPage } = data.meta.pagination;
        setPagination((prevPagination) => ({
          ...prevPagination,
          total: totalEntries,
          pageSize: perPage,
          pageCount: totalPages,
        }));
      },
    }
  );

  const breadcrumbLinks = [
    {
      title: "Home",
      url: "/admin/dashboard/overview",
    },
    {
      title: "Administration",
    },
  ];

  const updatePagination = (key) => (value) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      [key]: value,
    }));
  };

  return (
    <ContentWrapper>
      <TitleContainer>
        <div>
          <PageTitle>Permissions</PageTitle>
          <Breadcrumb values={breadcrumbLinks} />
        </div>
        <AccessControl allowedRoles={Roles.DSPPC}>
          <AddButton onClick={() => setShowDrawer(true)}>
            <CirclePlusIconWrapper />
            Set Threshold
          </AddButton>
        </AccessControl>
      </TitleContainer>
      <Box mb={3.125}>
        <AdminSearchFilter title="Filter" normalSearch>
          <Box>
            <Dropdown
              label="Role"
              values={[{ id: "", title: "All roles" }, ...roles].map((role) => ({
                label: role.title,
                value: role.id,
              }))}
              style={{ width: "100%", margin: "0 !important" }}
              onChange={(value) => setRole(value)}
              name="roleId"
            />
          </Box>
          <Box ml={4}>
            <Dropdown
              label="Type"
              values={[
                { value: "", label: "All" },
                { value: 1, label: "Tender Approval" },
                { value: 2, label: "Vendor Recommendation" },
              ]}
              style={{ width: "100%", margin: "0 !important" }}
              onChange={(value) => setType(value)}
              name="type"
            />
          </Box>
          <Box ml={4}>
            <Dropdown
              label="Status"
              values={[
                { value: "", label: "All" },
                { value: "InActive", label: "InActive" },
                { value: "Active", label: "Active" },
              ]}
              style={{ width: "100%", margin: "0 !important" }}
              onChange={(value) => setStatus(value)}
              name="status"
            />
          </Box>
        </AdminSearchFilter>
      </Box>
      {isLoading && <Loader />}
      {!isLoading && (
        <SearchTable
          page={pagination.pageNumber}
          setPage={updatePagination("pageNumber")}
          rowsPerPage={pagination.pageSize}
          setRowsPerPage={updatePagination("pageSize")}
          totalNumberOfPages={pagination.total}
          loading={fetchingThresholds}
          empty={thresholds?.data?.length === 0}
          columns={[
            "S/N",
            "Role",
            "Type",
            "Lower threshold limit",
            "Upper threshold limit",
            "CREATION DATE",
            "EXPIRY DATE",
            "STATUS",
          ]}
        >
          {thresholds?.data?.map(({ role, lowerLimitThreshold, upperLimitThreshold, createdAt, status, expiryDate, type }, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell>{index + 1}</StyledTableCell>
              <StyledTableCell>{role}</StyledTableCell>
              <StyledTableCell>{type}</StyledTableCell>
              <StyledTableCell>{formatCurrency(lowerLimitThreshold, true)}</StyledTableCell>
              <StyledTableCell>{formatCurrency(upperLimitThreshold, true)}</StyledTableCell>
              <StyledTableCell>{createdAt ? moment(createdAt).format("MMM DD, yyyy") : "N/A"}</StyledTableCell>
              <StyledTableCell>{expiryDate ? moment(expiryDate).format("MMM DD, yyyy") : "N/A"}</StyledTableCell>
              <StyledTableCell style={{ textAlign: "center" }}>
                <StyledStatus
                  style={
                    status && status === "Active"
                      ? {
                          backgroundColor: theme.color.tags.approved.background,
                          border: `2px solid ${theme.color.tags.approved.background}`,
                          color: theme.color.tags.approved.text,
                        }
                      : status && status === "InActive"
                      ? {
                          backgroundColor: theme.color.tags.rejected.background,
                          border: `2px solid ${theme.color.tags.rejected.background}`,
                          color: theme.color.tags.rejected.text,
                        }
                      : {}
                  }
                >
                  {status ? capitalize(status) : "N/A"}
                </StyledStatus>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </SearchTable>
      )}
      <AddPermissionDrawer data={roles} show={showDrawer} onClose={() => setShowDrawer(false)} />
    </ContentWrapper>
  );
}

export default withAdminDashboard(Permission);
