import React from "react";
import { Grid, Container } from "@material-ui/core";
import {
  SectionWrapper,
  Section1,
  QuoteWrapper,
  Quote,
  Image,
  VisionOrMissionIcon,
  VisionOrMissionTitle,
  VisionOrMissionDescription,
  VisionAndMission,
  VisionOrMission,
  MemberInfo,
  MemberImage,
  MemberCard,
  MemberName,
  MemberRole,
  PeopleSection,
  SectionTitle,
  SectionSubtitle,
  // Members,
  PeopleSectionsContent,
  PeopleSections,
  OpenQuoteIcon,
  CloseQuoteIcon,
} from "./style";
import Hero from "../../../components/Hero";
import withHeaderFooterHOC from "../HOC/withHeaderFooterHOC";
import { team } from "./mock";

const About = () => (
  <div>
    <Hero
      title="About Us"
      desc="Are you interested in bidding for a contract for your organization or for an individual? Click the link below to register"
    />
    <SectionWrapper>
      <Container>
        <Section1>
          <QuoteWrapper>
            <OpenQuoteIcon>“</OpenQuoteIcon>
            <Quote>
              The Delta State Public Procurement bill was signed into law on the 15th Day of December, 2016 by His Excellency, 
              Senator Dr. Ifeanyi Okowa. The Provision of the Delta State Public Procurement Law apply to all procurement of
              goods, works, and services as well as disposal of public property, carried out by the State Government and all
              procuring/disposal entities. Its scope of operation shall Include the State Government, Local Government
              Councils/Development Authority and all procuring entities in the State.
            </Quote>
            <CloseQuoteIcon>“</CloseQuoteIcon>
          </QuoteWrapper>
          <Image></Image>
        </Section1>
      </Container>
    </SectionWrapper>
    <Container>
      <SectionWrapper>
        <VisionAndMission>
          <Grid container>
            <Grid item xs={12} sm={6} md={6}>
              <VisionOrMission>
                <VisionOrMissionIcon />
                <VisionOrMissionTitle>Vision</VisionOrMissionTitle>
                <VisionOrMissionDescription>
                  To be the foremost Public Procurement Regulator in Nigeria, upholding global best practices in Public
                  procurement.
                </VisionOrMissionDescription>
              </VisionOrMission>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <VisionOrMission>
                <VisionOrMissionIcon />
                <VisionOrMissionTitle>Mission</VisionOrMissionTitle>
                <VisionOrMissionDescription>
                  To operate a transparent, competitive, sustainable and value-for-money public procurement system that supports
                  the State’s development goals and engenders public confidence.
                </VisionOrMissionDescription>
              </VisionOrMission>
            </Grid>
          </Grid>
        </VisionAndMission>
      </SectionWrapper>
    </Container>
    <Container>
      <SectionWrapper>
        <PeopleSections>
          <SectionTitle>Our People</SectionTitle>
          <hr />
          <PeopleSectionsContent>
            <PeopleSection>
              <SectionSubtitle>Management Board</SectionSubtitle>
              <Grid container spacing={3}>
                {team.map((member) => (
                  <MemberCard item xs={6} sm={4} md={3} key={member.id}>
                    <MemberImage></MemberImage>
                    <MemberInfo>
                      <MemberName>
                        <strong>{member.name}</strong>
                      </MemberName>
                      <MemberRole>{member.position}</MemberRole>
                    </MemberInfo>
                  </MemberCard>
                ))}
              </Grid>
            </PeopleSection>
          </PeopleSectionsContent>
        </PeopleSections>
      </SectionWrapper>
    </Container>
  </div>
);

export default withHeaderFooterHOC(About);
