import React, { useState } from "react";
import { Box, Container, SvgIcon, TablePagination } from "@material-ui/core";
import { useQuery } from "react-query";
import { PDFDownloadLink } from "@react-pdf/renderer";

import { ContentWrapper } from "../Administration/Staff/style";
import { ActionButton, ClearFilter } from "./style";
import { ReactComponent as DownloadIcon } from "../../../assets/code.svg";
import { ReactComponent as PdfIcon } from "../../../assets/file_pdf.svg";
// import { ReactComponent as PrintIcon } from "../../../assets/print.svg";
import AdminSearchFilter from "../../../components/AdminSearchFilter";
import { Dropdown, InputField } from "../../../components";
import DefaultDateRangePicker from "../../../components/DateRangePIcker";
import contractsAPI from "../../../redux/api/contractAPI";
import SearchTable from "./SearchTable";
import ContractTable from "./ContractTable";
import { BreadcrumbActive, BreadcrumbLabel, PaginationWrapper } from "../../Homepage/TenderList/style";
import { CancelButton } from "../Administration/Settings/style";
import adminAPI from "../../../redux/api/adminAPI";
import { downloadJSONData } from "../../Common/util";
import { Roles } from "../../../components/AccessControl/UserRoles";
import { useRoles } from "../../../hooks/useUserDetails";
import useAlert from "../../../hooks/useAlert";
import ContractsPDF from "./ContractsPDF";
import { Link, useHistory } from "react-router-dom";
import useDebounce from "../../../hooks/useDebounce";

const iconStyle = { color: "#fff", height: 30, width: 30 };

const PublicOCDS = ({ isPublic = false }) => {
  const { showAlert } = useAlert();
  const [ministries, setMinistries] = useState([]);
  const [downloadData, setDownloadData] = useState({ type: "", start: true });
  const { role } = useRoles();
  const history = useHistory();
  const isAdmin = role === Roles.DSPPC;

  const [tableParams, setTableParams] = useState({
    pageNumber: 1,
    pageSize: 10,
    startDate: null,
    endDate: null,
    filter: "",
    category: "",
    search: "",
    totalPages: null,
    totalEntries: null,
  });
  const debounceValue = useDebounce(tableParams.search, 600);

  // const [searchParams, setSearchParams] = useState({
  //   startDate: null,
  //   endDate: null,
  //   filter: "",
  //   category: "",
  //   search: "",
  // });
  const params = {
    startDate: tableParams.startDate,
    endDate: tableParams.endDate,
    // filter: tableParams.filter,
    category: tableParams.category,
    search: debounceValue,
    pageNumber: tableParams.pageNumber,
    pageSize: tableParams.pageSize,
  };

  useQuery(
    [
      "ministries",
      {
        pageSize: 0,
        pageNumber: null,
      },
    ],
    adminAPI.getMinistriesAxios,

    {
      onSuccess: ({ data }) => {
        const options = [
          {
            label: "All",
            value: "",
          },
        ];
        const ministryList = data.map(({ name }) => ({
          label: name,
          value: name,
        }));
        setMinistries(options.concat(ministryList));
      },
    }
  );

  const { data: archive, isLoading } = useQuery(
    [
      "archived-contracts",
      {
        // pageNumber: tableParams.pageNumber,
        // pageSize: tableParams.pageSize,
        // startDate: searchParams.startDate,
        // endDate: searchParams.endDate,
        // filter: searchParams.filter,
        // category: searchParams.category,
        // search: searchParams.search,
        ...params,
      },
    ],
    contractsAPI.getArchivedContracts,
    {
      onSuccess: (data) => {
        const {
          pagination: { totalEntries, totalPages },
        } = data.meta;
        setTableParams({
          ...tableParams,
          totalPages,
          totalEntries,
        });
      },
      onError: (error) => {
        showAlert({
          message: error.message,
          severity: "error",
        });
      },
    }
  );

  const updateFilters = (key) => (value) => {
    setTableParams((params) => ({
      ...params,
      [key]: value,
    }));
  };

  const handleChangePage = (event, pageNumber) => {
    setTableParams({ ...tableParams, pageNumber: pageNumber + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    setTableParams({ ...tableParams, pageNumber: 1, pageSize: parseInt(event.target.value, 10) });
  };

  // const handleSearch = () => {
  //   const { pageNumber, pageSize, ...rest } = tableParams;
  //   setSearchParams({
  //     ...rest,
  //   });
  // };

  const handleRowClick = (link) => (e) => {
    e.stopPropagation();
    history.push(link);
  };

  const dataDownload = useQuery(
    [
      "download-archived-contracts",
      {
        pageSize: tableParams.totalEntries,
      },
    ],
    contractsAPI.getArchivedContracts,
    {
      enabled: downloadData.start,
      onSuccess: ({ data }) => {
        downloadData.type === "JSON" && downloadJSONData(data);
      },
      onError: (error) => {
        showAlert({
          message: error.message,
          severity: "error",
        });
      },
      onSettled: () => {
        setDownloadData({ type: "", start: false });
      },
    }
  );

  const clearFilter = () => {
    setTableParams((prev) => ({
      ...prev,
      search: "",
      endDate: null,
      startDate: null,
    }));
    updateFilters("startDate")(null);
    updateFilters("endDate")(null);
  };

  return (
    <Container>
      <Box my={3}>
        <BreadcrumbLabel>
          <BreadcrumbActive>
            <Link style={{ textDecoration: "none", color: "inherit" }} to="/">
              Home
            </Link>
          </BreadcrumbActive>
        </BreadcrumbLabel>
      </Box>
      <ContentWrapper>
        <Box mb={2} display="flex">
          <PDFDownloadLink
            document={<ContractsPDF contracts={dataDownload?.data?.data} />}
            fileName={`Archived-contracts-${Date.now()}`}
            style={{ textDecoration: "none" }}
          >
            {({ loading }) => (
              <ActionButton disabled={dataDownload.isLoading} startIcon={<SvgIcon component={PdfIcon} style={iconStyle} />}>
                {loading ? "Downloading" : "Download PDF"}
              </ActionButton>
            )}
          </PDFDownloadLink>
          <ActionButton
            disabled={dataDownload.isLoading}
            onClick={() => setDownloadData({ type: "JSON", start: true })}
            startIcon={<SvgIcon component={DownloadIcon} style={iconStyle} />}
          >
            Download JSON
          </ActionButton>
          {/* <ActionButton onClick={() => window.print()} startIcon={<SvgIcon component={PrintIcon} style={iconStyle} />}>
            Print
          </ActionButton> */}
        </Box>
        <AdminSearchFilter normalSearch>
          <div style={{ height: "100%", width: "50%" }}>
            <InputField
              style={{ width: "100%" }}
              type="text"
              label="Search ID, Title"
              setValue={updateFilters("search")}
              required
            />
          </div>
          {false && (
            <div style={{ width: "94%" }}>
              <Dropdown
                defaultValue={ministries[0]?.value}
                name="ministry"
                style={{ width: "inherit" }}
                label="Ministry"
                values={ministries}
                onChange={(value) => setTableParams({ ...tableParams, filter: value })}
              />
            </div>
          )}
          <div style={{ width: "100%" }}>
            <DefaultDateRangePicker
              handleStartChange={updateFilters("startDate")}
              handleEndChange={updateFilters("endDate")}
              leftStyle={{ marginRight: "16px" }}
              label={["Awarded Start Date", "Awarded End Date"]}
            />
          </div>
          <div style={{ marginTop: "10px", marginLeft: "10px" }}>
            <CancelButton onClick={() => {}} style={{ width: 150, height: 55, fontSize: "16px", fontWeight: 700 }}>
              Search
            </CancelButton>{" "}
            <ClearFilter onClick={clearFilter}>Clear filter</ClearFilter>
          </div>
        </AdminSearchFilter>
        <Box mt={2}>
          <SearchTable
            rows={archive?.data?.length}
            showPadding={false}
            loading={isLoading}
            columns={[
              "Project Title",
              "Budget Description",
              "Date Published",
              "MDA",
              "Category",
              "Contractor Name",
              "Start Date",
              "End Date",
              // "Last Modified",
              "Contract Amount",
              isAdmin && !isPublic && "Action",
            ]}
          >
            <ContractTable
              isPublic={isPublic}
              contracts={archive?.data}
              setOpenDrawer={() => {}}
              handleRowClick={handleRowClick}
            />
          </SearchTable>
          {tableParams.totalEntries > 0 && (
            <PaginationWrapper>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component="div"
                count={tableParams.totalEntries}
                rowsPerPage={tableParams.pageSize}
                page={tableParams.pageNumber - 1}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </PaginationWrapper>
          )}
        </Box>
      </ContentWrapper>
    </Container>
  );
};

export default PublicOCDS;
