import baseApiMethod from "./baseApiMethod";
import baseAxiosMethod from "./baseAxiosMethod";
import { handleAxiosError } from "./handleApiError";

const contractsAPI = {};

contractsAPI.getContracts = async (key, params) => {
  try {
    const response = await baseAxiosMethod("contracts", {
      params,
    });

    const {
      data,
      meta: { pagination },
    } = response.data;

    return { data, pagination };
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

contractsAPI.getContractsSummary = (param) => {
  return baseApiMethod({ param }, `contracts/summary${!!param.MinistryId ? `?MinistryId=${param.MinistryId}` : ""}`, "get", {
    param,
  });
};

contractsAPI.getSingleContract = (id) => baseApiMethod(null, `contracts/${id}`, "get");

contractsAPI.getSingleContractQuery = async (key, { id }) => {
  try {
    const response = await baseAxiosMethod.get(`contracts/archives/${id}`);
    return response.data?.data;
  } catch (error) {
    console.log({ error });
  }
};

contractsAPI.getAdminContractDetails = (id) => baseApiMethod(null, `contracts/${id}/details`, "get");

contractsAPI.getAwardedContract = async function (key, params = {}) {
  try {
    const response = await baseAxiosMethod("contracts/awardedProcurements", { params });
    return response.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

contractsAPI.getContractDetails = async function (key, contractId) {
  try {
    const response = await baseAxiosMethod(`contracts/${contractId}/awardedProcurement`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

contractsAPI.fetchContractSummary = async () => {
  try {
    const response = await baseAxiosMethod("contracts/summary");
    return response.data.data;
  } catch (e) {
    throw handleAxiosError(e);
  }
};
contractsAPI.fetchAllContract = async () => {
  try {
    const response = await baseAxiosMethod("contracts");
    return response.data.data;
  } catch (e) {
    throw handleAxiosError(e);
  }
};

contractsAPI.createContract = async (data) => {
  const { procurementId } = data;
  try {
    const response = await baseAxiosMethod.post(`contracts/${procurementId}`, data);
    return response.data;
  } catch (e) {
    throw handleAxiosError(e);
  }
};

contractsAPI.reviewVendor = async (data) => {
  const { procurementId } = data;
  try {
    const response = await baseAxiosMethod.post(`contracts/${procurementId}/reviewVendorBid`, data);
    return response.data;
  } catch (e) {
    throw handleAxiosError(e);
  }
};

contractsAPI.getArchivedContracts = async function (key, params = {}) {
  try {
    const response = await baseAxiosMethod("contracts/archives", { params });
    return response.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

contractsAPI.createBidder = async ({ id, payload }) => {
  try {
    const response = await baseAxiosMethod.post(`contracts/archives/${id}/bidder`, payload);
    return response.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

contractsAPI.deleteBidder = async ({ id, contractId }) => {
  try {
    const response = await baseAxiosMethod.delete(`/contracts/archives/${contractId}/bidder/${id}`);
    return response.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

contractsAPI.uploadArchiveContract = async function (formData) {
  try {
    const response = await baseAxiosMethod.post("contracts/archives/upload", formData);
    return response.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

contractsAPI.uploadArchiveBidder = async function (formData) {
  try {
    const response = await baseAxiosMethod.post("contracts/archives/bidders/upload", formData);
    return response.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

contractsAPI.getSingleArchivedContract = async function (key, id) {
  try {
    const response = await baseAxiosMethod(`contracts/archives/${id}`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};
contractsAPI.updateArchivedContract = async function (data) {
  try {
    const response = await baseAxiosMethod.put(`contracts/archives/${data.id}`, data);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

contractsAPI.updateArchiveContractType = async ({ type, payload, archiveId }) => {
  try {
    const { data } = await baseAxiosMethod.patch(`contracts/archives/${archiveId}/${type}`, payload);
    return data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

contractsAPI.patchArchiveContract = async (payload) => {
  try {
    const { data } = await baseAxiosMethod.patch(`contracts/archives/${payload.id}/details`, payload);
    return data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};
export default contractsAPI;
